import React from "react";

import reactor from "../../reactor";
import activities from "../../modules/activities";
import i18n from "../../modules/i18n";
import FormModal from "../forms/FormModal";

let getIntlMessage = i18n.messages.getIntlMessage;

var defaultValue = {};

var SetResumeActivity = React.createClass({
  mixins: [reactor.ReactMixin],

  propTypes: {
    activityId: React.PropTypes.number,
  },

  getDefaultProps() {
    return {
      activityId: null,
    };
  },

  getInitialState() {
    return {
      value: defaultValue,
    };
  },

  getDataBindings() {
    return {
      resumeActivityForm: activities.getters.resumeActivityFormGetter,
    };
  },

  componentWillReceiveProps(props) {
    if (!props.show) {
      this.setState({
        value: defaultValue,
      });
    }
  },

  componentDidUpdate(prevProps, prevState) {
    if (this.state.resumeActivityForm !== prevState.resumeActivityForm) {
      var form = this.state.resumeActivityForm.toJS();
      var prevForm = prevState.resumeActivityForm.toJS();
      if (form.feedback.type !== prevForm.feedback.type && form.feedback.type === "success") {
        this.props.onHide();
      }
    }
  },

  onFormSubmit(event, value) {
    if (!value.is_resuming_activity) {
      this.props.onHide();
      return;
    }
    if (value) {
      this.setState({
        value: value,
      });
      var val = {
        refid: "ongoing",
        is_current_step_seen: false,
      };
      activities.actions.setActivityResumeStart(true);
      activities.actions.setActivityResume(this.props.activityId, val);
      activities.actions.setActivitiesBoardReload(true);
    }
  },

  onFormChange(value) {
    this.setState({ value });
  },

  render() {
    var form = this.state.resumeActivityForm.toJS();
    return (
      <FormModal
        form={form}
        formType="set"
        value={this.state.value}
        onFormChange={this.onFormChange}
        onFormSubmit={this.onFormSubmit}
        bsStyle="warning"
        title={getIntlMessage("Resume Activity")}
        actionButtonTitle={getIntlMessage("Confirm")}
        showFeedback={false}
        {...this.props}
      />
    );
  },
});

export default SetResumeActivity;
