import IntlMessageFormat from "intl-messageformat";
import createFormatCache from "intl-format-cache";
import reactor from "../../reactor";
import * as getters from "./getters";

let getMessageFormat = createFormatCache(IntlMessageFormat);

export function getIntlMessage(message, values) {
  let i18n = reactor.evaluate(getters.i18nGetter).toJS();
  let output = getMessageFormat(i18n.messages[message], i18n.locales);
  return output.format(values);
}
