import React, { Component, PropTypes } from "react";
import { Row, Col } from "react-bootstrap";

import OfficeDirectorCurrentStats from "./OfficeDirectorCurrentStats";
import OfficeDirectorQuarterStats from "./OfficeDirectorQuarterStats";

export default class OfficeDirectorStats extends Component {
  static propTypes = {
    accounts: PropTypes.array,
    current: PropTypes.object,
    quarter: PropTypes.object,
  };

  static defaultProps = {
    accounts: [],
    current: {},
    quarter: {},
  };

  render() {
    return (
      <div>
        <Row>
          <Col md={6}>
            <OfficeDirectorCurrentStats accounts={this.props.accounts} current={this.props.current} />
          </Col>
          <Col md={6}>
            <OfficeDirectorQuarterStats accounts={this.props.accounts} quarter={this.props.quarter} />
          </Col>
        </Row>
      </div>
    );
  }
}
