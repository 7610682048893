import axios from "axios";

import reactor from "../../reactor";
import i18n from "../i18n";
import notifier from "../notifier";
import actionTypes from "./actionTypes";

let getIntlMessage = i18n.messages.getIntlMessage;

export function setProfileReset() {
  reactor.dispatch(actionTypes.SET_PROFILE_RESET);
}

export function getProfileStart(data) {
  reactor.dispatch(actionTypes.GET_PROFILE_START, { data });
}

export function getProfile() {
  axios
    .get(process.env.__APIURL__ + "/profile")
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.GET_PROFILE_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.GET_PROFILE_START, { data: false });
      });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.GET_PROFILE_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.GET_PROFILE_START, { data: false });
      });
    });
}

export function setProfileForm() {
  reactor.dispatch(actionTypes.SET_PROFILE_FORM);
}

export function setProfileStart(data) {
  reactor.dispatch(actionTypes.SET_PROFILE_START, { data });
}

export function setProfile(data) {
  axios
    .put(process.env.__APIURL__ + "/profile", data)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_PROFILE_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.SET_PROFILE_START, { data: false });
      });
      notifier.actions.notify({
        message: getIntlMessage("Update Successful"),
        type: "success",
      });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_PROFILE_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.SET_PROFILE_SUBMIT, { data: false });
      });
    });
}

export function setProfilePasswordForm() {
  reactor.dispatch(actionTypes.SET_PROFILE_PASSWORD_FORM);
}

export function setProfilePasswordStart(data) {
  reactor.dispatch(actionTypes.SET_PROFILE_PASSWORD_START, { data });
}

export function setProfilePassword(data) {
  axios
    .put(process.env.__APIURL__ + "/profile/password", data)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_PROFILE_PASSWORD_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.SET_PROFILE_PASSWORD_START, { data: false });
      });
      notifier.actions.notify({
        message: getIntlMessage("Update Successful"),
        type: "success",
      });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_PROFILE_PASSWORD_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.SET_PROFILE_PASSWORD_START, { data: false });
      });
    });
}

export function setProfileAvatarStart(data) {
  reactor.dispatch(actionTypes.SET_PROFILE_AVATAR_START, { data });
}

export function setProfileAvatar(data) {
  axios
    .put(process.env.__APIURL__ + "/profile/avatar", data)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_PROFILE_AVATAR_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.SET_PROFILE_AVATAR_START, { data: false });
      });
      notifier.actions.notify({
        message: getIntlMessage("Update Successful"),
        type: "success",
      });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_PROFILE_AVATAR_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.SET_PROFILE_AVATAR_START, { data: false });
      });
    });
}

export function remProfile() {
  reactor.dispatch(actionTypes.REM_PROFILE);
}
