import React, { Component, PropTypes } from "react";
import { Row, Col } from "react-bootstrap";
import _ from "lodash";

import i18n from "../../modules/i18n";
import DefaultLoader from "../Loaders";
import ActivityRatioStats from "./ActivityRatioStats";

let getIntlMessage = i18n.messages.getIntlMessage;

export default class QuarterlyStats extends Component {
  static propTypes = {
    stats: PropTypes.object,
  };

  static defaultProps = {
    stats: {},
  };

  getData = () => {
    return this.props.stats.stats[0];
  };

  render() {
    let data = this.getData();
    if (_.isEmpty(data)) {
      return <DefaultLoader />;
    }
    let averageWeeklyTotal = Math.ceil(data.average_weekly_total) || 0;
    let averageWeeklyTotalPercent = Math.ceil((averageWeeklyTotal * 100) / 25) || 0;
    let averageWeeklyCalled = Math.ceil(data.average_weekly_called) || 0;
    let averageWeeklyCalledPercent = Math.ceil((averageWeeklyCalled * 100) / 70) || 0;
    let MASeenMAFixed = Math.ceil(data.ma_seen_ma_fixed) || 0;
    let BTSeenMASeen = Math.ceil(data.bt_seen_ma_seen) || 0;
    let totalScheduled = Math.ceil(data.average_weekly_activities["total-scheduled"]) || 0;
    let totalSeen = Math.ceil(data.average_weekly_activities["total-seen"]) || 0;
    let totalScheduledPercent = Math.ceil((totalScheduled * 100) / 12) || 0;
    let totalSeenPercent = Math.ceil((totalSeen * 100) / 9) || 0;

    return (
      <Row>
        <Col md={6}>
          <div id="quarterly-stats" className="hpanel">
            <div className="panel-body h-325">
              <Row>
                <Col md={6}>
                  <div className="stats-title text-center">
                    <h4>{getIntlMessage("Prospects")}</h4>
                  </div>
                  <ActivityRatioStats
                    title={averageWeeklyTotalPercent.toString() + "%"}
                    value={averageWeeklyTotalPercent}
                    description={averageWeeklyTotal.toString() + " / 25 nouveaux"}
                  />
                  <ActivityRatioStats
                    title={averageWeeklyCalledPercent.toString() + "%"}
                    value={averageWeeklyCalledPercent}
                    description={averageWeeklyCalled.toString() + " / 70 appels"}
                  />
                </Col>
                <Col md={6}>
                  <div className="stats-title text-center">
                    <h4>{getIntlMessage("Activities")}</h4>
                  </div>
                  <ActivityRatioStats
                    title={totalScheduledPercent.toString() + "%"}
                    value={totalScheduledPercent}
                    description={totalScheduled + " / 12 fixés"}
                  />
                  <ActivityRatioStats
                    title={totalSeenPercent.toString() + "%"}
                    value={totalSeenPercent}
                    description={totalSeen + " / 9 vus"}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="text-center">
                  <p>Votre moyenne hebdomadaire sur l'objectif de réussite</p>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col md={6}>
          <div id="quarterly-stats" className="hpanel">
            <div className="panel-body h-325">
              <Row>
                <Col md={12}>
                  <div className="stats-title text-center">
                    <h4>{getIntlMessage("Ratios")}</h4>
                  </div>
                  <ActivityRatioStats
                    title={MASeenMAFixed.toString() + "%"}
                    value={MASeenMAFixed}
                    description="ADM vu / ADM fixé"
                  />
                  <ActivityRatioStats
                    title={BTSeenMASeen.toString() + "%"}
                    value={BTSeenMASeen}
                    description="TA vu / ADM vu"
                  />
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}
