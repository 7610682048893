import React, { Component, PropTypes } from "react";
import { Row, Col, Tooltip, OverlayTrigger } from "react-bootstrap";
import _ from "lodash";

import i18n from "../../modules/i18n";
import GradeChart from "./GradeChart";

let getIntlMessage = i18n.messages.getIntlMessage;

export default class ProspectStats extends Component {
  static propTypes = {
    stats: PropTypes.object,
  };

  static defaultProps = {
    stats: {},
  };

  getGradeStats = (stats) => {
    let colors = {
      A: "#d1da59",
      AH: "#b8c140",
      B: "#2e57a9",
      BH: "#153e90",
      C: "#f0ad4e",
      CH: "#d79435",
    };
    let grades = stats.map((item, index) => {
      return {
        color: colors[item[0]],
        highlight: colors[item[0] + "H"],
        value: item[1],
        label: item[0],
      };
    });
    return grades;
  };

  render() {
    let stats = this.props.stats;

    if (stats === undefined || _.isEmpty(stats)) {
      return <div></div>;
    }

    let gradeStats = this.getGradeStats(_.get(stats, "grades", []));

    if (gradeStats === undefined || _.isEmpty(gradeStats)) {
      return <div></div>;
    }

    let available = _.get(stats, "prospects.available") || 0;
    let totalAvailable = _.get(stats, "prospects.total_available") || 0;
    let listed = _.get(stats, "prospects.listed") || 0;
    let totalListed = _.get(stats, "prospects.total_listed") || 0;
    let total = _.get(stats, "prospects.total") || 0;

    const totalTooltip = (
      <Tooltip id="total-tooltip">Nombre de prospects frais incluant les frais de la liste d'appel</Tooltip>
    );
    const totalAvailableTooltip = (
      <Tooltip id="total-available-tooltip">
        Nombre de prospects frais et désuets incluant les frais et désuets de la liste d'appel
      </Tooltip>
    );
    const listedTooltip = <Tooltip id="listed-tooltip">Nombre de prospects frais de la liste d'appel</Tooltip>;
    const totalListedTooltip = (
      <Tooltip id="total-listed-tooltip">Nombre de prospects frais et désuets de la liste d'appel</Tooltip>
    );

    return (
      <div className="hpanel hbglightblue">
        <div id="prospect-stats" className="panel-body h-325">
          <Row>
            <Col xs={6} className="text-center">
              <h3>{getIntlMessage("Prospects")}</h3>
              <h3>
                <OverlayTrigger placement="top" overlay={totalTooltip}>
                  <span>{total}</span>
                </OverlayTrigger>{" "}
                •
                <OverlayTrigger placement="top" overlay={totalAvailableTooltip}>
                  <span className="text-primary"> {totalAvailable}</span>
                </OverlayTrigger>
              </h3>
            </Col>
            <Col xs={6} className="text-center">
              <h3>{getIntlMessage("Call List")}</h3>
              <h3>
                <OverlayTrigger placement="top" overlay={listedTooltip}>
                  <span>{listed}</span>
                </OverlayTrigger>{" "}
                •
                <OverlayTrigger placement="top" overlay={totalListedTooltip}>
                  <span className="text-primary"> {totalListed}</span>
                </OverlayTrigger>
              </h3>
            </Col>
          </Row>
          <Row className="stats-bottom">
            <Col className="grade-chart">
              <GradeChart data={gradeStats} />
            </Col>
          </Row>
          <Row>
            {/* <Col className="text-center">
              <p>
                <b>Frais</b>: disponibles, sans activités et/où -6 mois
                <br />
                <b>Désuets</b>: avec activités et/où +6 mois
              </p>
            </Col> */}
            <Col className="text-center">
              <p>
                <b>!! Le graphique est temporairement hors d'usage !!</b>
              </p>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
