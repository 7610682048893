import React from "react";
import { Router, Route, Redirect, IndexRedirect, hashHistory } from "react-router";

import reactor from "./reactor";
import i18n from "./modules/i18n";
import auth from "./modules/auth";
import notifier from "./modules/notifier";
import App from "./components/base/App";
import Public from "./components/base/Public";
import Manager from "./components/base/Manager";
import LoginProvider from "./components/auth/LoginProvider";
import Dashboard from "./components/dashboards/Dashboard";
import Offices from "./components/offices/Offices";
import Accounts from "./components/accounts/Accounts";
import Account from "./components/accounts/Account";
import Profile from "./components/profile/Profile";
import Roles from "./components/roles/Roles";
import Options from "./components/options/Options";
import OptionLists from "./components/optionlists/OptionLists";
import AvailableProspects from "./components/prospects/AvailableProspects";
import ExcludedProspects from "./components/prospects/ExcludedProspects";
import OutdatedProspects from "./components/prospects/OutdatedProspects";
import CanceledProspects from "./components/prospects/CanceledProspects";
import AllProspects from "./components/prospects/AllProspects";
import CallList from "./components/calls/CallList";
import Activities from "./components/activities/Activities";
import ActivityBoard from "./components/activities/Board";
import SoldActivities from "./components/activities/SoldActivities";
import CanceledActivities from "./components/activities/CanceledActivities";
import PendingActivities from "./components/activities/PendingActivities";
import PlanificationPage from "./components/planifications/PlanificationPage";
import Staffing from "./components/staffing/Staffing";
import Blank from "./components/Blank";
import Error404 from "./components/Error404";

let getIntlMessage = i18n.messages.getIntlMessage;
let locale = reactor.evaluateToJS(i18n.getters.i18nGetter);

function authorize(nextState, replace) {
  if (!auth.utils.authenticated()) {
    replace("/login");
    notifier.actions.notify({
      message: getIntlMessage("Access Denied"),
      type: "error",
    });
  }
}

function createElement(Component, props) {
  return <Component {...locale} {...props} />;
}

export default (
  <Router history={hashHistory} createElement={createElement}>
    <Route path="/" component={App}>
      <IndexRedirect from="" to="login" />
      <Route component={Public}>
        <Route path="login" component={LoginProvider} />
      </Route>
      <Route component={Manager}>
        <Route path="dashboard" component={Dashboard} onEnter={authorize} />
        <Route path="profile" component={Profile} onEnter={authorize} />
        <Redirect from="prospects" to="prospects/available" />
        <Route path="prospects">
          <Route path="available" component={AvailableProspects} onEnter={authorize} />
          <Route path="excluded" component={ExcludedProspects} onEnter={authorize} />
          <Route path="outdated" component={OutdatedProspects} onEnter={authorize} />
          <Route path="canceled" component={CanceledProspects} onEnter={authorize} />
          <Route path="all" component={AllProspects} onEnter={authorize} />
        </Route>
        <Redirect from="calls" to="calls/list" />
        <Route path="calls">
          <Route path="list" component={CallList} onEnter={authorize} />
        </Route>
        <Redirect from="activities" to="activities/board" />
        <Route path="activities">
          <Route path="board" component={ActivityBoard} onEnter={authorize} />
          <Route path="list" component={Activities} onEnter={authorize} />
          <Route path="sold" component={SoldActivities} onEnter={authorize} />
          <Route path="canceled" component={CanceledActivities} onEnter={authorize} />
          <Route path="pending" component={PendingActivities} onEnter={authorize} />
        </Route>
        <Route path="planifications" component={PlanificationPage} onEnter={authorize} />
        <Route path="staffing" component={Staffing} onEnter={authorize} />
        <Route path="organizational-chart" component={Blank} onEnter={authorize} />
        <Redirect from="system" to="system/offices" />
        <Route path="system">
          <Route path="offices" component={Offices} onEnter={authorize} />
          <Route path="accounts" component={Accounts} onEnter={authorize} />
          <Route path="accounts/:id" component={Account} onEnter={authorize} />
          <Route path="roles" component={Roles} onEnter={authorize} />
          <Route path="option-lists" component={OptionLists} onEnter={authorize} />
          <Route path="options" component={Options} onEnter={authorize} />
        </Route>
        <Route path="test" component={Blank} onEnter={authorize} />
      </Route>
      <Route component={Public}>
        <Route path="*" component={Error404} />
      </Route>
    </Route>
  </Router>
);
