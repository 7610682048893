import React, { Component, PropTypes } from "react";
import { connect } from "nuclear-js-react-addons";

import i18n from "../../modules/i18n";
import planifications from "../../modules/planifications";
import FormModal from "../forms/FormModal";

let getIntlMessage = i18n.messages.getIntlMessage;

let defaultValue = {
  advisor_id: "",
  planned: "",
  completed: "",
  commission: "",
  pac: "",
  iaah: "",
  excellence: "",
  reference: "",
};

export default
@connect((props) => ({
  addPlanificationAdvisorForm: planifications.getters.addPlanificationAdvisorFormGetter,
}))
class AddPlanificationAdvisor extends Component {
  static propTypes = {
    periodId: PropTypes.number,
  };

  static defaultProps = {
    periodId: null,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      value: defaultValue,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.show) {
      this.setState({
        value: defaultValue,
      });
    } else {
      if (this.props.addPlanificationAdvisorForm !== nextProps.addPlanificationAdvisorForm) {
        let form = this.props.addPlanificationAdvisorForm.toJS();
        if (form.feedback.type === "info") {
          this.setState({ value: defaultValue });
        }
      }
    }
  }

  onFormSubmit = (event, value) => {
    if (value) {
      this.setState({
        value: value,
      });
      planifications.actions.addPlanificationAdvisorStart(true);
      planifications.actions.addPlanificationAdvisor(this.props.periodId, value);
    }
  };

  onFormChange = (value) => {
    this.setState({ value });
  };

  render() {
    let form = this.props.addPlanificationAdvisorForm.toJS();
    return (
      <FormModal
        form={form}
        formType={"add"}
        value={this.state.value}
        onFormChange={this.onFormChange}
        onFormSubmit={this.onFormSubmit}
        title={getIntlMessage("Add Advisor")}
        {...this.props}
      />
    );
  }
}
