import React, { Component, PropTypes } from "react";
import { connect } from "nuclear-js-react-addons";

import i18n from "../../modules/i18n";
import prospects from "../../modules/prospects";
import FormModal from "../forms/FormModal";

let getIntlMessage = i18n.messages.getIntlMessage;

let defaultValue = {
  source_id: "",
  grade_id: "",
  comments: "",
  phones: [{ label_id: "" }],
};

export default
@connect((props) => ({
  addProspectForm: prospects.getters.addProspectFormGetter,
}))
class AddProspect extends Component {
  // static propTypes = {
  //   addProspectForm: PropTypes.object
  // };

  constructor(props, context) {
    super(props, context);
    this.state = {
      value: defaultValue,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.show) {
      this.setState({
        value: defaultValue,
      });
    } else {
      if (this.props.addProspectForm !== nextProps.addProspectForm) {
        let form = this.props.addProspectForm.toJS();
        if (form.feedback.type === "info") {
          this.setState({ value: defaultValue });
        }
      }
    }
  }

  onFormSubmit = (event, value) => {
    if (value) {
      this.setState({
        value: value,
      });
      prospects.actions.addProspectStart(true);
      prospects.actions.addProspect(value);
    }
  };

  onFormChange = (value) => {
    this.setState({ value });
  };

  render() {
    let form = this.props.addProspectForm.toJS();
    return (
      <FormModal
        form={form}
        formType={"add"}
        formLayout={"free"}
        value={this.state.value}
        onFormChange={this.onFormChange}
        onFormSubmit={this.onFormSubmit}
        title={getIntlMessage("New Prospect")}
        {...this.props}
      />
    );
  }
}
