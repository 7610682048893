import React from "react";
import classNames from "classnames";

var Content = React.createClass({
  propTypes: {
    loaded: React.PropTypes.bool,
    className: React.PropTypes.string,
  },

  getDefaultProps() {
    return {
      loaded: true,
      className: "",
    };
  },

  // render() {
  //   return (
  //     <Loader loaded={this.props.loaded}>
  //       <div className={classNames("content", this.props.className)}>
  //         {this.props.children}
  //       </div>
  //     </Loader>
  //   )
  // }

  render() {
    return <div className={classNames("content", this.props.className)}>{this.props.children}</div>;
  },
});

export default Content;
