import React, { Component, PropTypes } from "react";
import { Panel, ButtonGroup, Label } from "react-bootstrap";
import _ from "lodash";

var k = (React.Kendo = require("kendo-ui-react"));

import i18n from "../../modules/i18n";

let getIntlMessage = i18n.messages.getIntlMessage;

export class AccountSelectItem extends Component {
  static propTypes = {
    account: PropTypes.object,
  };

  static defaultProps = {
    account: {},
  };

  render() {
    let account = this.props.account;
    return (
      <div className="media social-profile clearfix">
        <a className="pull-left">
          <img src={account.avatar_url} alt="profile-picture" className="img-responsive img-circle" width="42" />
        </a>
        <div className="media-body">
          <h5>{account.fullname}</h5>
          {account.is_student === false && <small className="text-muted">{account.role}</small>}
          {account.is_student === true && (
            <Label bsStyle="warning" className="font-light">
              {account.role}
            </Label>
          )}
        </div>
      </div>
    );
  }
}

export class AccountSelect extends Component {
  static propTypes = {
    account: PropTypes.object,
    accounts: PropTypes.array,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    account: {},
    accounts: [],
    onChange: null,
  };

  onChange = (event) => {
    let item = event.sender.dataItem(event.item);
    this.props.onChange(item.id, item.role_refid);
  };

  render() {
    if (_.isEmpty(this.props.accounts)) {
      return null;
    }
    let options = {
      filter: "startswith",
      dataTextField: "fullname",
      dataValueField: "id",
      optionLabel: getIntlMessage("Select an Account"),
      height: 400,
      dataSource: {
        data: this.props.accounts,
        group: [{ field: "office", dir: "asc" }],
        sort: [
          { field: "role", dir: "asc" },
          { field: "lastname", dir: "asc" },
        ],
      },
      change: this.onChange,
      template: function (item) {
        return k.Template(<AccountSelectItem account={item} />);
      },
      fixedGroupTemplate: function (item) {
        return k.Template(
          <div className="accounts-dropdown-group-header">
            <h5>{item}</h5>
          </div>
        );
      },
    };
    return (
      <div className="dashboard-accounts">
        <k.DropDownList options={options} />
      </div>
    );
  }
}

export default class AccountSelector extends Component {
  static propTypes = {
    account: PropTypes.object,
    accounts: PropTypes.array,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    account: {},
    accounts: [],
    onChange: null,
  };

  renderOffice = (office) => {
    if (office) {
      return <span>{office} • </span>;
    }
    return null;
  };

  renderParents = (parents) => {
    if (parents && parents.length > 0) {
      return <span> ({parents[0]}) </span>;
    }
    return null;
  };

  renderRole = (refid, role) => {
    if (refid) {
      if (refid === "student") {
        return <span className="label label-warning">{role}</span>;
      }
      return role;
    }
    return null;
  };

  renderDescription = (account) => {
    let office = account.office_name;
    let role_refid = account.main_role_refid;
    let role = account.main_role;
    return (
      <p className="account-description">
        {this.renderOffice(office)} {this.renderRole(role_refid, role)}
        {this.renderParents(account.parent_names)}
      </p>
    );
  };

  render() {
    let account = this.props.account;
    return (
      <Panel className="hpanel panel-subheader account-selector">
        <div className="hpanel-body">
          <ButtonGroup className="pull-right">
            <AccountSelect {...this.props} />
          </ButtonGroup>
          <a className="pull-left account-picture">
            <img src={account.avatar_url} alt="account-picture" className="img-responsive img-circle" width="48" />
          </a>
          <h3>
            {account.fullname}
            {this.renderDescription(account)}
          </h3>
        </div>
      </Panel>
    );
  }
}
