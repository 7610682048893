import { Store, toImmutable } from "nuclear-js";

import forms from "../forms";
import i18n from "../i18n";
import actionTypes from "./actionTypes";
import * as schemas from "./schemas";

let getIntlMessage = i18n.messages.getIntlMessage;

let addOptionListFormDefaults = {
  type: schemas.AddOptionListSchema,
  options: schemas.AddOptionListSchemaOptions,
  feedback: {
    type: "info",
    messages: [],
  },
  started: false,
};

let setOptionListFormDefaults = {
  type: schemas.SetOptionListSchema,
  options: schemas.SetOptionListSchemaOptions,
  feedback: {
    type: "info",
    messages: [],
  },
  started: false,
};

let initialState = toImmutable({
  optionList: {},
  addOptionListForm: addOptionListFormDefaults,
  setOptionListForm: setOptionListFormDefaults,
  setOptionListsReload: false,
});

export default new Store({
  getInitialState() {
    return initialState;
  },

  initialize() {
    this.on(actionTypes.SET_OPTIONLIST_RESET, onSetOptionListReset);
    this.on(actionTypes.GET_OPTIONLIST_SUCCESS, onGetOptionListSuccess);
    this.on(actionTypes.GET_OPTIONLIST_FAIL, onGetOptionListFail);
    this.on(actionTypes.ADD_OPTIONLIST_FORM, onAddOptionListForm);
    this.on(actionTypes.ADD_OPTIONLIST_START, onAddOptionListStart);
    this.on(actionTypes.ADD_OPTIONLIST_SUCCESS, onAddOptionListSuccess);
    this.on(actionTypes.ADD_OPTIONLIST_FAIL, onAddOptionListFail);
    this.on(actionTypes.SET_OPTIONLIST_FORM, onSetOptionListForm);
    this.on(actionTypes.SET_OPTIONLIST_START, onSetOptionListStart);
    this.on(actionTypes.SET_OPTIONLIST_SUCCESS, onSetOptionListSuccess);
    this.on(actionTypes.SET_OPTIONLIST_FAIL, onSetOptionListFail);
    this.on(actionTypes.SET_OPTIONLISTS_RELOAD, onSetOptionListsReload);
    this.on(actionTypes.REM_OPTIONLIST, onRemOptionList);
  },
});

function onSetOptionListReset(state) {
  return initialState;
}

function onGetOptionListSuccess(state, { data }) {
  return state.set("optionList", toImmutable(data.optionlist));
}

function onGetOptionListFail(state, { data }) {
  return state.set("optionList", toImmutable({}));
}

function onAddOptionListForm(state) {
  return state.set("addOptionListForm", toImmutable(addOptionListFormDefaults));
}

function onAddOptionListStart(state, { data }) {
  return state.setIn(["addOptionListForm", "started"], data);
}

function onAddOptionListSuccess(state, { data }) {
  let feedback = {
    type: "success",
    messages: [getIntlMessage("Add Successful")],
  };
  let options = addOptionListFormDefaults.options;
  return state
    .setIn(["addOptionListForm", "feedback"], toImmutable(feedback))
    .setIn(["addOptionListForm", "options"], toImmutable(options));
}

function onAddOptionListFail(state, { data }) {
  let feedback = forms.utils.setFormFeedback(data);
  let options = forms.utils.setFormOptions(state.getIn(["addOptionListForm", "options"]).toJS(), data);
  return state
    .setIn(["addOptionListForm", "feedback"], toImmutable(feedback))
    .setIn(["addOptionListForm", "options"], toImmutable(options));
}

function onSetOptionListForm(state) {
  return state;
}

function onSetOptionListStart(state, { data }) {
  return state.setIn(["setOptionListForm", "started"], data);
}

function onSetOptionListSuccess(state, { data }) {
  return state
    .set("optionList", toImmutable(data.optionlist))
    .set("setOptionListForm", toImmutable(setOptionListFormDefaults));
}

function onSetOptionListFail(state, { data }) {
  let feedback = forms.utils.setFormFeedback(data);
  let options = forms.utils.setFormOptions(state.getIn(["setOptionListForm", "optionlists"]).toJS(), data);
  return state
    .setIn(["setOptionListForm", "feedback"], toImmutable(feedback))
    .setIn(["setOptionListForm", "options"], toImmutable(options));
}

function onSetOptionListsReload(state, { data }) {
  return state.set("setOptionListsReload", toImmutable(data));
}

function onRemOptionList(state) {
  return state.set("optionList", toImmutable({}));
}
