import { Store, toImmutable } from "nuclear-js";

import forms from "../forms";
import i18n from "../i18n";
import actionTypes from "./actionTypes";
import * as schemas from "./schemas";

let getIntlMessage = i18n.messages.getIntlMessage;

let addPlanificationAdvisorFormDefaults = {
  type: schemas.AddPlanificationAdvisorSchema,
  options: schemas.AddPlanificationAdvisorSchemaOptions,
  feedback: {
    type: "info",
    messages: [],
  },
  started: false,
};

let setPlanificationAdvisorFormDefaults = {
  type: schemas.SetPlanificationAdvisorSchema,
  options: schemas.SetPlanificationAdvisorSchemaOptions,
  feedback: {
    type: "info",
    messages: [],
  },
  started: false,
};

let deletePlanificationAdvisorFormDefaults = {
  feedback: {
    type: "info",
    messages: [],
  },
  started: false,
};

let initialState = toImmutable({
  summary: {
    data: {},
    started: false,
  },
  period: {
    selector: {},
    data: {},
    started: false,
  },
  advisor: {},
  addPlanificationAdvisorForm: addPlanificationAdvisorFormDefaults,
  setPlanificationAdvisorForm: setPlanificationAdvisorFormDefaults,
  deletePlanificationAdvisorForm: deletePlanificationAdvisorFormDefaults,
});

export default new Store({
  getInitialState() {
    return initialState;
  },

  initialize() {
    this.on(actionTypes.GET_PLANIFICATION_SUMMARY_SUCCESS, onGetPlanificationSummarySuccess);
    this.on(actionTypes.GET_PLANIFICATION_SUMMARY_FAIL, onGetPlanificationSummaryFail);
    this.on(actionTypes.GET_PLANIFICATION_SUMMARY_START, onGetPlanificationSummaryStart);
    this.on(actionTypes.GET_PLANIFICATION_PERIOD_SUCCESS, onGetPlanificationPeriodSuccess);
    this.on(actionTypes.GET_PLANIFICATION_PERIOD_FAIL, onGetPlanificationPeriodFail);
    this.on(actionTypes.GET_PLANIFICATION_PERIOD_START, onGetPlanificationPeriodStart);
    this.on(actionTypes.SET_PLANIFICATION_PERIOD_RESET, onSetPlanificationPeriodReset);
    this.on(actionTypes.ADD_PLANIFICATION_PERIOD_SUCCESS, onAddPlanificationPeriodSuccess);
    this.on(actionTypes.ADD_PLANIFICATION_PERIOD_FAIL, onAddPlanificationPeriodFail);
    this.on(actionTypes.ADD_PLANIFICATION_PERIOD_START, onAddPlanificationPeriodStart);
    this.on(actionTypes.SET_PLANIFICATION_PERIOD_SELECTOR, onSetPlanificationPeriodSelector);

    this.on(actionTypes.ADD_PLANIFICATION_ADVISOR_FORM, onAddPlanificationAdvisorForm);
    this.on(actionTypes.ADD_PLANIFICATION_ADVISOR_START, onAddPlanificationAdvisorStart);
    this.on(actionTypes.ADD_PLANIFICATION_ADVISOR_SUCCESS, onAddPlanificationAdvisorSuccess);
    this.on(actionTypes.ADD_PLANIFICATION_ADVISOR_FAIL, onAddPlanificationAdvisorFail);

    this.on(actionTypes.SET_PLANIFICATION_ADVISOR_FORM, onSetPlanificationAdvisorForm);
    this.on(actionTypes.SET_PLANIFICATION_ADVISOR_START, onSetPlanificationAdvisorStart);
    this.on(actionTypes.SET_PLANIFICATION_ADVISOR_SUCCESS, onSetPlanificationAdvisorSuccess);
    this.on(actionTypes.SET_PLANIFICATION_ADVISOR_FAIL, onSetPlanificationAdvisorFail);
    this.on(actionTypes.SET_PLANIFICATION_ADVISOR_RESET, onSetPlanificationAdvisorReset);

    this.on(actionTypes.DELETE_PLANIFICATION_ADVISOR_FORM, onDeletePlanificationAdvisorForm);
    this.on(actionTypes.DELETE_PLANIFICATION_ADVISOR_START, onDeletePlanificationAdvisorStart);
    this.on(actionTypes.DELETE_PLANIFICATION_ADVISOR_SUCCESS, onDeletePlanificationAdvisorSuccess);
    this.on(actionTypes.DELETE_PLANIFICATION_ADVISOR_FAIL, onDeletePlanificationAdvisorFail);

    this.on(actionTypes.GET_PLANIFICATION_ADVISOR_SUCCESS, onGetPlanificationAdvisorSuccess);
    this.on(actionTypes.GET_PLANIFICATION_ADVISOR_FAIL, onGetPlanificationAdvisorFail);
  },
});

function onGetPlanificationSummarySuccess(state, { data }) {
  return state.setIn(["summary", "data"], toImmutable(data.summary));
}

function onGetPlanificationSummaryFail(state, { data }) {
  return state.setIn(["summary", "data"], toImmutable({}));
}

function onGetPlanificationSummaryStart(state, { data }) {
  return state.setIn(["summary", "started"], data);
}

function onGetPlanificationPeriodSuccess(state, { data }) {
  return state.setIn(["period", "data"], toImmutable(data.planification));
}

function onGetPlanificationPeriodFail(state, { data }) {
  return state.setIn(["period", "data"], toImmutable({}));
}

function onGetPlanificationPeriodStart(state, { data }) {
  return state.setIn(["period", "started"], data);
}

function onSetPlanificationPeriodReset(state, { data }) {
  return initialState;
}

function onSetPlanificationPeriodSelector(state, { data }) {
  return state.setIn(["period", "selector"], toImmutable(data));
}

function onAddPlanificationPeriodSuccess(state, { data }) {
  return state.setIn(["period", "data"], toImmutable(data.planification));
}

function onAddPlanificationPeriodFail(state, { data }) {
  return state.setIn(["period", "data"], toImmutable({}));
}

function onAddPlanificationPeriodStart(state, { data }) {
  return state.setIn(["period", "started"], data);
}

function onAddPlanificationAdvisorForm(state) {
  return state.set("addPlanificationAdvisorForm", toImmutable(addPlanificationAdvisorFormDefaults));
}

function onAddPlanificationAdvisorStart(state, { data }) {
  return state.setIn(["addPlanificationAdvisorForm", "started"], data);
}

function onAddPlanificationAdvisorSuccess(state, { data }) {
  let feedback = {
    type: "success",
    messages: [getIntlMessage("Add Successful")],
  };

  let planification = state.getIn(["period", "data"]).toJS();
  planification.advisors.push(data.advisor);
  return state.setIn(["period", "data"], toImmutable(planification));
}

function onAddPlanificationAdvisorFail(state, { data }) {
  var feedback = forms.utils.setFormFeedback(data);
  var options = forms.utils.setFormOptions(state.getIn(["addPlanificationAdvisorForm", "options"]).toJS(), data);
  return state
    .set("advisor", toImmutable({}))
    .setIn(["addPlanificationAdvisorForm", "feedback"], toImmutable(feedback))
    .setIn(["addPlanificationAdvisorForm", "options"], toImmutable(options));
}

function onSetPlanificationAdvisorForm(state) {
  return state;
}

function onSetPlanificationAdvisorStart(state, { data }) {
  return state.setIn(["setPlanificationAdvisorForm", "started"], data);
}

function onSetPlanificationAdvisorSuccess(state, { data }) {
  let advisor = data.advisor;
  let planification = state.getIn(["period", "data"]).toJS();
  for (let i = 0; i < planification.advisors.length; i++) {
    if (planification.advisors[i].advisor_id == advisor.advisor_id) {
      Object.assign(planification.advisors[i], advisor);
      break;
    }
  }

  return state
    .setIn(["period", "data"], toImmutable(planification))
    .set("advisor", toImmutable(advisor))
    .set("setPlanificationAdvisorForm", toImmutable(setPlanificationAdvisorFormDefaults));
}

function onSetPlanificationAdvisorFail(state, { data }) {
  let feedback = forms.utils.setFormFeedback(data);
  let options = forms.utils.setFormOptions(state.getIn(["setPlanificationAdvisorForm", "options"]).toJS(), data);
  return state
    .setIn(["setPlanificationAdvisorForm", "feedback"], toImmutable(feedback))
    .setIn(["setPlanificationAdvisorForm", "options"], toImmutable(options));
}

function onSetPlanificationAdvisorReset(state) {
  return state.set("advisor", toImmutable({}));
}

function onDeletePlanificationAdvisorForm(state) {
  return state;
}

function onDeletePlanificationAdvisorStart(state, { data }) {
  return state.setIn(["deletePlanificationAdvisorForm", "started"], data);
}

function onDeletePlanificationAdvisorSuccess(state, { data }) {
  let advisorId = data.advisorId;
  let planification = state.getIn(["period", "data"]).toJS();
  for (let i = 0; i < planification.advisors.length; i++) {
    if (planification.advisors[i].advisor_id == advisorId) {
      planification.advisors.splice(i, 1);
      break;
    }
  }
  return state
    .setIn(["period", "data"], toImmutable(planification))
    .set("advisor", toImmutable({}))
    .set("deletePlanificationAdvisorForm", toImmutable(deletePlanificationAdvisorFormDefaults));
}

function onDeletePlanificationAdvisorFail(state, { data }) {
  let feedback = forms.utils.setFormFeedback(data);
  return state.setIn(["deletePlanificationAdvisorForm", "feedback"], toImmutable(feedback));
}

function onGetPlanificationAdvisorSuccess(state, { data }) {
  return state.set("advisor", toImmutable(data.advisor));
}

function onGetPlanificationAdvisorFail(state, { data }) {
  return state.set("advisor", toImmutable({}));
}
