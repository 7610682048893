var messages = {
  lang: "en",
  locales: "en-US",
  messages: {
    "A confirmation message has been sent to": "A message has been sent to",
    "Access Denied": "Access Denied",
    Account: "Account",
    Accounts: "Accounts",
    Action: "Action",
    Activity: "Activity",
    "Activity Ratios": "Activity Ratios",
    Activities: "Activities",
    Actual: "Actual",
    Add: "Add",
    "Add +": "Add +",
    "Add Advisor": "Add Advisor",
    "Add Call": "Add Call",
    "Add Candidate": "Add Candidate",
    "Add Failed": "Add Failed",
    "Add Note": "Add Note",
    "Add Phone Number": "Add Phone Number",
    "Add Successful": "Add Successful",
    "Add Successful with problems, prospect is on the DNCL": "Add Successful with problems, prospect is on the DNCL",
    "Add To Activities": "Add To Activities",
    "Add To Call List": "Add To Call List",
    Adding: "Adding",
    Address: "Address",
    Advisor: "Advisor",
    Advisors: "Advisors",
    All: "All",
    "An error occured": "An error occured",
    Appointment: "Appointment",
    Appointments: "Appointments",
    "Are you sure you want to cancel this prospect?": "Are you sure you want to cancel this prospect?",
    "Are you sure you want to delete this advisor?": "Are you sure you want to delete this advisor?",
    "Are you sure you want to pause the activity?": "Are you sure you want to pause the activity?",
    "Are you sure you want to resume the activity?": "Are you sure you want to resume the activity?",
    "Are you sure you want to delete this candidate?": "Are you sure you want to delete this candidate?",
    "Are you sure you want to resume this prospect?": "Are you sure you want to resume this prospect?",
    Avail: "Avail",
    Availability: "Availability",
    Available: "Available",
    "Available On": "Available On",
    "Available Prospects": "Available Prospects",
    "Back to Dashboard": "Back to Dashboard",
    Birthday: "Birthday",
    Board: "Board",
    "Break Time": "Break Time",
    Bulletins: "Bulletins",
    Call: "Call",
    Calls: "Calls",
    "Call List": "Call List",
    Cancel: "Cancel",
    "Cancel Activity": "Cancel Activity",
    "Cancel Last Step": "Cancel Last Step",
    "Cancel Prospect": "Cancel Prospect",
    Canceled: "Canceled",
    Candidate: "Candidate",
    Candidates: "Candidates",
    Category: "Category",
    Change: "Change",
    "Change Avatar": "Change Avatar",
    "Change Email": "Change Email",
    "Change Password": "Change Password",
    "Change Photo": "Change Photo",
    "Change Successful": "Change Successful",
    City: "City",
    Client: "Client",
    Clients: "Clients",
    Clone: "Clone",
    "Clone Activity": "Clone Activity",
    "Clone Successful": "Clone Successful",
    Close: "Close",
    Code: "Code",
    Comments: "Comments",
    Commission: "Commission",
    Completed: "Completed",
    Confirm: "Confirm",
    "Confirm Password": "Confirm Password",
    Connection: "Connection",
    Consent: "Consent",
    Contact: "Contact",
    Content: "Content",
    Country: "Country",
    "Created On": "Created On",
    Dashboard: "Dashboard",
    Day: "Day",
    Delete: "Delete",
    "Delete Advisor": "Delete Advisor",
    "Delete Candidate": "Delete Candidate",
    Directors: "Directors",
    Director: "Director",
    "Does the appointment took place?": "Does the appointment took place?",
    Edit: "Edit",
    "Edit Account": "Edit Account",
    "Edit Activity": "Edit Activity",
    "Edit Advisor": "Edit Advisor",
    "Edit Avatar": "Edit Avatar",
    "Edit Client": "Edit Client",
    "Edit Object": "Edit Object",
    "Edit Office": "Edit Office",
    "Edit Option": "Edit Option",
    "Edit Option List": "Edit Option List",
    "Edit Password": "Edit Password",
    "edit Profile": "Edit Profile",
    "Edit Prospect": "Edit Prospect",
    "Edit Role": "Edit Role",
    Email: "Email",
    "Ends On": "Ends On",
    English: "English",
    "Enter a password": "Enter a password",
    Evening: "Evening",
    Excellence: "Excellence",
    Excluded: "Excluded",
    "Excluded Phones": "Excluded Phones",
    Firstname: "Firstname",
    Form: "Form",
    French: "French",
    Grade: "Grade",
    "Grade Distribution": "Grade Distribution",
    Grades: "Grades",
    "Has Consent": "Has Consent",
    History: "History",
    Home: "Home",
    IAAH: "IAAH",
    ID: "ID",
    "Insert at least one phone number": "Insert at least one phone number",
    Label: "Label",
    "last time on": "last time on",
    "Last Called On": "Last Called On",
    Lastname: "Lastname",
    Latest: "Latest",
    List: "List",
    Log: "Log",
    Login: "Login",
    "Login Successful": "Login Successful",
    Logout: "Logout",
    Logs: "Logs",
    "Lost your password?": "Lost your password?",
    Manager: "Manager",
    Managers: "Managers",
    "Market Analysis": "Market Analysis",
    Message: "Message",
    Mobile: "Mobile",
    Name: "Name",
    "Network Connection Error": "Network Connection Error",
    New: "New",
    "New Account": "New Account",
    "New Activity": "New Activity",
    "New Client": "New Client",
    "New Object": "New Object",
    "New Office": "New Office",
    "New Option": "New Option",
    "New Option List": "New Option List",
    "New Prospect": "New Prospect",
    "New Prospects": "New Prospects",
    "New Role": "New Role",
    No: "No",
    None: "None",
    "Not Found!": "Not Found!",
    Note: "Note",
    Notes: "Notes",
    Number: "Number",
    Office: "Office",
    Offices: "Offices",
    "Office Director": "Office Director",
    "Office Directors": "Office Directors",
    Option: "Option",
    Options: "Options",
    "Option List": "Option List",
    "Option Lists": "Option  Lists",
    Optional: "Optional",
    "Organizational Chart": "Organizational Chart",
    Others: "Others",
    Outdated: "Outdated",
    Owner: "Owner",
    PAC: "PAC",
    "Page Not Found": "Page Not Found",
    Parent: "Parent",
    Parents: "Parents",
    Partner: "Partner",
    "Partner of": "Partner of",
    Password: "Password",
    "Pause Activity": "Pause Activity",
    Pending: "Pending",
    Permissions: "Permissions",
    Phone: "Phone",
    "Phone Session": "Phone Session",
    Photo: "Photo",
    Planifications: "Planifications",
    Planned: "Planned",
    "Planned Activities": "Planned Activities",
    Position: "Position",
    "Predicted Date": "Predicted Date",
    Print: "Print",
    Private: "Private",
    Profile: "Profile",
    Prospect: "Prospect",
    Prospects: "Prospects",
    "Prospect Consent": "Prospect Consent",
    "Prospect ID": "Prospect ID",
    Province: "Province",
    "Publish On": "Publish On",
    Quarter: "Quarter",
    Quarterly: "Quarterly",
    Ratios: "Ratios",
    Reference: "Reference",
    Referent: "Referent",
    "Referred by": "Referred by",
    RefID: "RefID",
    RefType: "RefType",
    Reload: "Reload",
    "Remote IP": "Remote IP",
    Remove: "Remove",
    "Remove From Call List": "Remove From Call List",
    Reset: "Reset",
    "Reset your password": "Reset your password",
    Result: "Result",
    Resume: "Resume",
    "Resume Activity": "Resume Activity",
    "Resume Prospect": "Resume Prospect",
    "Resume Successful": "Resume Successful",
    Role: "Role",
    Roles: "Roles",
    "Sales Director": "Sales Director",
    "Sales Directors": "Sales Directors",
    "Sales directors did not provide any data": "Sales directors did not provide any data",
    "Sales Interview": "Sales Interview",
    Schedule: "Schedule",
    Scheduled: "Scheduled",
    "Schedule Activity": "Schedule Activity",
    "Scheduling Successful": "Scheduling Successful",
    Schooling: "Schooling",
    Search: "Search",
    Seen: "Seen",
    "Select...": "Select...",
    "Select an Account": "Select an Account",
    Sell: "Sell",
    "Sell Activity": "Sell Activity",
    Send: "Send",
    "Set Candidate": "Set Candidate",
    Sold: "Sold",
    Source: "Source",
    Sources: "Sources",
    Staffing: "Staffing",
    "Starts On": "Starts On",
    State: "State",
    States: "States",
    Status: "Status",
    Step: "Step",
    "Step Revert Successful": "Step Revert Successful",
    "Step Revert Failed": "Step Revert Failed",
    Steps: "Steps",
    Student: "Student",
    Students: "Students",
    "Success Finder": "Success Finder",
    Supervisor: "Supervisor",
    Supervisors: "Supervisors",
    System: "System",
    Targeted: "Targeted",
    "The week you are looking for does not exist!": "The week you are looking for does not exist!",
    "There is no statistics available for this period": "There is no statistics available for this period",
    "Times Called": "Times Called",
    "This phone number is part of the national do not call list":
      "This phone number is part of the national do not call list",
    Token: "Token",
    Total: "Total",
    "Total Commission": "Commission",
    "Total Completed": "Completed",
    "Total Planned": "Planned",
    "Total PAC": "PAC",
    "Total IAAH": "IAAH",
    "Total Excellence": "Excellence",
    "Total Reference": "Reference",
    Translations: "Translations",
    Update: "Update",
    "Update Available": "Update Available",
    "Update Avatar": "Update Avatar",
    "Update Email": "Update Email",
    "Update Password": "Update Password",
    "Update Failed": "Update Failed",
    "Update Successful": "Update Successful",
    "Updated On": "Updated On",
    Updating: "Updating",
    Upgrade: "Upgrade",
    User: "User",
    Users: "Users",
    "Using Back Button is not recommended!": "Using Back Button is not recommended!",
    Video: "Video",
    Videos: "Videos",
    View: "View",
    "View Activity": "View Activity",
    Week: "Week",
    "Weekly Activities": "Weekly Activities",
    "Weekly Average": "Weekly Average",
    "Which Step?": "Which Step?",
    "Would you like to add this week to your planning?": "Would you like to add this week to your planning?",
    Yes: "Yes",
    "You are already logged in": "You are already logged in",
    "You did not provide any data": "You did not provide any data",
    "You've been logged in!": "You've been logged in!",
    "You've been logged out": "You've been logged out",
    "Your password has been changed": "Your password has been changed",
    "Your password has been resetted": "Your password has been resetted",
    "Your request has been accepted": "Your request has been accepted",
    "Your token is valid!": "Your token is valid!",
    "Zip Code": "Zip Code",
    // Datatables
    "No data available in table": "No data available in table",
    "Showing _START_ to _END_ of _TOTAL_ entries": "Showing _START_ to _END_ of _TOTAL_ entries",
    "Showing 0 to 0 of 0 entries": "Showing 0 to 0 of 0 entries",
    "(filtered from _MAX_ total entries)": "(filtered from _MAX_ total entries)",
    "Show _MENU_ entries": "Show _MENU_ entries",
    Loading: "Loading",
    Processing: "Processing",
    "No matching records found": "No matching records found",
    First: "First",
    Last: "Last",
    Next: "Next",
    Previous: "Previous",
    ": activate to sort column ascending": ": activate to sort column ascending",
    ": activate to sort column descending": ": activate to sort column descending",
    //
    DATE_ON: "On",
    DATE_AT: "At",
    DATE_BY: "By",
    ERROR_404_TEXT: "We are sorry, the page you are looking for has not been found.",
    ERROR_NETWORK_TEXT: "We are sorry, we were unable to connect to the network. Please reload or try again later.",
    ERROR_UPDATE_TEXT:
      "A newer version of prospectr is available. To update your browser with the latest release, please click Upgrade. Thank you.",
    PROSPECT_CONSENT_TEXT:
      "By making available a prospect of the National and Internal Do Not Call List (NDNCL/IDNCL), you confirm that you received the «express consent» of the prospect as defined in the CRTC rules and responsibilities.",
  },
};

module.exports = messages;
