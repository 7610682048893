import React, { Component, PropTypes } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

import i18n from "../../modules/i18n";

let getIntlMessage = i18n.messages.getIntlMessage;

export default class AdvisorsActivityPilotStats extends Component {
  static propTypes = {
    data: PropTypes.array,
  };

  static defaultProps = {
    data: [],
  };

  render() {
    let data = this.props.data;
    return (
      <div>
        <BootstrapTable ref="statsTable" data={data} striped={true} hover={true} condensed={true} height="450">
          <TableHeaderColumn isKey={true} dataSort={true} dataField={"name"}>
            {getIntlMessage("Name")}
          </TableHeaderColumn>
          <TableHeaderColumn dataSort={true} dataField={"office"}>
            {getIntlMessage("Office")}
          </TableHeaderColumn>
          <TableHeaderColumn dataSort={true} dataField={"prospects"} width="70">
            NP
          </TableHeaderColumn>
          <TableHeaderColumn dataSort={true} dataField={"market_analysis_scheduled"} width="70">
            ADMF
          </TableHeaderColumn>
          <TableHeaderColumn dataSort={true} dataField={"market_analysis_seen"} width="70">
            ADMV
          </TableHeaderColumn>
          <TableHeaderColumn dataSort={true} dataField={"break_time_scheduled"} width="70">
            TDAF
          </TableHeaderColumn>
          <TableHeaderColumn dataSort={true} dataField={"break_time_seen"} width="70">
            TDAV
          </TableHeaderColumn>
          <TableHeaderColumn dataSort={true} dataField={"sales_interview_scheduled"} width="70">
            EDVF
          </TableHeaderColumn>
          <TableHeaderColumn dataSort={true} dataField={"sales_interview_seen"} width="70">
            EDVV
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    );
  }
}
