import React, { Component, PropTypes } from "react";
import { Row, Col } from "react-bootstrap";
import _ from "lodash";

import i18n from "../../modules/i18n";
import AdvisorsActivityStats from "./AdvisorsActivityStats";

let getIntlMessage = i18n.messages.getIntlMessage;

export default class SalesDirectorQuarterStats extends Component {
  static propTypes = {
    accounts: PropTypes.array,
    quarter: PropTypes.object,
    width: PropTypes.string,
    height: PropTypes.string,
  };

  static defaultProps = {
    accounts: [],
    quarter: {},
    width: "auto",
    height: "auto",
  };

  getData = () => {
    let prospects = [];
    let marketAnalysis = [];
    let breakTime = [];
    let salesInterview = [];
    let current_quarter = _.get(this.props.quarter, "stats");
    _.forIn(this.props.accounts, (value, key) => {
      let aid = value.id;
      let aidstr = aid.toString();
      let stats = _.get(this.props.quarter, "stats");
      prospects.push({
        name: value.fullname,
        scheduled: _.get(stats, aidstr + ".prospects.total", 0),
        seen: _.get(stats, aidstr + ".prospects.available", 0),
      });
      marketAnalysis.push({
        name: value.fullname,
        scheduled: _.get(stats, aidstr + ".activities.market-analysis-scheduled", 0),
        seen: _.get(stats, aidstr + ".activities.market-analysis-seen", 0),
      });
      breakTime.push({
        name: value.fullname,
        scheduled: _.get(stats, aidstr + ".activities.break-time-scheduled", 0),
        seen: _.get(stats, aidstr + ".activities.break-time-seen", 0),
      });
      salesInterview.push({
        name: value.fullname,
        scheduled: _.get(stats, aidstr + ".activities.sales-interview-scheduled", 0),
        seen: _.get(stats, aidstr + ".activities.sales-interview-seen", 0),
      });
    });
    return {
      prospects: _.orderBy(prospects, ["scheduled"], ["desc"]),
      marketAnalysis: _.orderBy(marketAnalysis, ["scheduled"], ["desc"]),
      breakTime: _.orderBy(breakTime, ["scheduled"], ["desc"]),
      salesInterview: _.orderBy(salesInterview, ["scheduled"], ["desc"]),
    };
  };

  render() {
    let data = this.getData();
    return (
      <div>
        <Row>
          <Col xs={12}>
            <h2 className="stats-title">{getIntlMessage("Quarter")}</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <AdvisorsActivityStats
              data={data.prospects}
              title={getIntlMessage("New Prospects")}
              dataField1={"name"}
              dataName1={getIntlMessage("Name")}
              dataField2={"scheduled"}
              dataName2={getIntlMessage("Total")}
              dataField3={"seen"}
              dataName3={getIntlMessage("Avail")}
              width={this.props.width}
              height={this.props.height}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <AdvisorsActivityStats
              data={data.marketAnalysis}
              title={getIntlMessage("Market Analysis")}
              dataField1={"name"}
              dataName1={getIntlMessage("Name")}
              dataField2={"scheduled"}
              dataName2={getIntlMessage("Scheduled")}
              dataField3={"seen"}
              dataName3={getIntlMessage("Seen")}
              width={this.props.width}
              height={this.props.height}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <AdvisorsActivityStats
              data={data.breakTime}
              title={getIntlMessage("Break Time")}
              dataField1={"name"}
              dataName1={getIntlMessage("Name")}
              dataField2={"scheduled"}
              dataName2={getIntlMessage("Scheduled")}
              dataField3={"seen"}
              dataName3={getIntlMessage("Seen")}
              width={this.props.width}
              height={this.props.height}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <AdvisorsActivityStats
              data={data.salesInterview}
              title={getIntlMessage("Sales Interview")}
              dataField1={"name"}
              dataName1={getIntlMessage("Name")}
              dataField2={"scheduled"}
              dataName2={getIntlMessage("Scheduled")}
              dataField3={"seen"}
              dataName3={getIntlMessage("Seen")}
              width={this.props.width}
              height={this.props.height}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
