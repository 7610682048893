import React, { Component, PropTypes } from "react";
import { connect } from "nuclear-js-react-addons";
import { Table, Button } from "react-bootstrap";
import classNames from "classnames";
import moment from "moment";
import _ from "lodash";

import i18n from "../../modules/i18n";
import activities from "../../modules/activities";
import { ButtonLoader } from "../Loaders";

let getIntlMessage = i18n.messages.getIntlMessage;

export default
@connect((props) => ({
  activityStepRevert: activities.getters.setActivityStepRevertGetter,
}))
class ActivitySteps extends Component {
  static propTypes = {
    activity: PropTypes.object,
    activityStepRevert: PropTypes.object,
    steps: PropTypes.array,
  };

  static defaultProps = {
    steps: [],
  };

  renderRows = (data) => {
    var rows = data.map((item, index) => {
      return (
        <tr key={index}>
          <td>{item.step.name}</td>
          <td className="col-xs-2">{moment(item.starts_on).format("DD/MM/YYYY")}</td>
        </tr>
      );
    });
    return rows;
  };

  onRevertLastStep = () => {
    activities.actions.setActivityStepRevertStart(true);
    activities.actions.setActivityStepRevert(this.props.activity.id);
  };

  renderRevertLastStep = (data) => {
    if (!data || data.length <= 1) {
      return null;
    }
    let noLaterThan = new Date(new Date() - moment.duration(1, "hours"));
    let step = _.head(data);
    if (!step) {
      return null;
    }
    if (new Date(step.starts_on) < noLaterThan) {
      return null;
    }
    return (
      <div className="pull-right">
        <Button
          bsStyle="warning"
          bsSize="small"
          onClick={this.onRevertLastStep}
          disabled={this.props.activityStepRevert.started}
        >
          {getIntlMessage("Cancel Last Step")}
          <ButtonLoader loaded={!this.props.activityStepRevert.started} color={"#fff"} />
        </Button>
      </div>
    );
  };

  render() {
    return (
      <div className="activity-steps">
        <Table striped condensed hover>
          <tbody>{this.renderRows(this.props.steps)}</tbody>
        </Table>
        {this.renderRevertLastStep(this.props.steps)}
      </div>
    );
  }
}
