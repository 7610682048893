import React, { Component, PropTypes } from "react";

import auth from "../../modules/auth";
import i18n from "../../modules/i18n";
import FormModal from "../forms/FormModal";

let getIntlMessage = i18n.messages.getIntlMessage;

export default class PasswordLostView extends Component {
  static propTypes = {
    passwordLostForm: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      value: {},
    };
  }

  componentWillReceiveProps(props) {
    if (!props.show) {
      this.setState({
        value: {},
      });
    }
  }

  onFormSubmit = (event, value) => {
    if (value) {
      this.setState({
        value: value,
      });
      auth.actions.passwordLostStart(true);
      auth.actions.passwordLost(value);
    }
  };

  onFormChange = (value) => {
    this.setState({ value });
  };

  render() {
    var form = this.props.passwordLostForm;
    return (
      <FormModal
        form={form}
        formType="reset"
        value={this.state.value}
        onFormChange={this.onFormChange}
        onFormSubmit={this.onFormSubmit}
        title={getIntlMessage("Lost your password?")}
        actionButtonTitle={getIntlMessage("Reset")}
        {...this.props}
      />
    );
  }
}
