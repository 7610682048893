import axios from "axios";

import reactor from "../../reactor";
import actionTypes from "./actionTypes";

export function getDashboard(id, role) {
  getDashboardCurrent(id);
  let rid = role || "advisor";
  if (rid === "advisor" || rid === "student") {
    getDashboardWeeks(id);
    getDashboardQuarter(id);
  } else if (rid === "sales-director") {
    getDashboardAggregateWeeks(id);
    getDashboardAggregateQuarter(id);
  } else if (rid === "office-director") {
    getDashboardOfficeDirectorQuarter(id);
  } else if (rid === "supervisor" || rid === "manager") {
    // do nothing for now
  } else {
    setDashboardReset();
  }
}

export function setDashboardReset() {
  reactor.dispatch(actionTypes.SET_DASHBOARD_RESET);
}

export function getDashboardCurrent(id) {
  axios
    .get(process.env.__APIURL__ + "/dashboards/" + id)
    .then((res) => {
      reactor.dispatch(actionTypes.GET_DASHBOARD_CURRENT_SUCCESS, { data: res.data });
      reactor.dispatch(actionTypes.GET_DASHBOARD_CURRENT_START, { data: false });
    })
    .catch((res) => {
      reactor.dispatch(actionTypes.GET_DASHBOARD_CURRENT_FAIL, { data: res.data });
      reactor.dispatch(actionTypes.GET_DASHBOARD_CURRENT_START, { data: false });
    });
}

export function getDashboardCurrentStart(data) {
  reactor.dispatch(actionTypes.GET_DASHBOARD_CURRENT_START, { data });
}

export function getDashboardWeeks(id, weekAgo) {
  let _weekAgo = weekAgo || 0;
  axios
    .get(process.env.__APIURL__ + "/dashboards/" + id + "/weeks/" + _weekAgo)
    .then((res) => {
      reactor.dispatch(actionTypes.GET_DASHBOARD_WEEKS_SUCCESS, { data: res.data });
      reactor.dispatch(actionTypes.GET_DASHBOARD_WEEKS_START, { data: false });
    })
    .catch((res) => {
      reactor.dispatch(actionTypes.GET_DASHBOARD_WEEKS_FAIL, { data: res.data });
      reactor.dispatch(actionTypes.GET_DASHBOARD_WEEKS_START, { data: false });
    });
}

export function getDashboardWeeksStart(data) {
  reactor.dispatch(actionTypes.GET_DASHBOARD_WEEKS_START, { data });
}

export function getDashboardQuarter(id) {
  axios
    .get(process.env.__APIURL__ + "/dashboards/" + id + "/quarters/0")
    .then((res) => {
      reactor.dispatch(actionTypes.GET_DASHBOARD_QUARTER_SUCCESS, { data: res.data });
      reactor.dispatch(actionTypes.GET_DASHBOARD_QUARTER_START, { data: false });
    })
    .catch((res) => {
      reactor.dispatch(actionTypes.GET_DASHBOARD_QUARTER_FAIL, { data: res.data });
      reactor.dispatch(actionTypes.GET_DASHBOARD_QUARTER_START, { data: false });
    });
}

export function getDashboardQuarterStart(data) {
  reactor.dispatch(actionTypes.GET_DASHBOARD_QUARTER_START, { data });
}

export function getDashboardAggregateWeeks(id) {
  axios
    .get(process.env.__APIURL__ + "/dashboards/" + id + "/aggregate/weeks/0?weeks=8")
    .then((res) => {
      reactor.dispatch(actionTypes.GET_DASHBOARD_AGGREGATE_WEEKS_SUCCESS, { data: res.data });
      reactor.dispatch(actionTypes.GET_DASHBOARD_AGGREGATE_WEEKS_START, { data: false });
    })
    .catch((res) => {
      reactor.dispatch(actionTypes.GET_DASHBOARD_AGGREGATE_WEEKS_FAIL, { data: res.data });
      reactor.dispatch(actionTypes.GET_DASHBOARD_AGGREGATE_WEEKS_START, { data: false });
    });
}

export function getDashboardAggregateWeeksStart(data) {
  reactor.dispatch(actionTypes.GET_DASHBOARD_AGGREGATE_WEEKS_START, { data });
}

export function getDashboardAggregateQuarter(id) {
  axios
    .get(process.env.__APIURL__ + "/dashboards/" + id + "/aggregate/quarters/0")
    .then((res) => {
      reactor.dispatch(actionTypes.GET_DASHBOARD_AGGREGATE_QUARTER_SUCCESS, { data: res.data });
      reactor.dispatch(actionTypes.GET_DASHBOARD_AGGREGATE_QUARTER_START, { data: false });
    })
    .catch((res) => {
      reactor.dispatch(actionTypes.GET_DASHBOARD_AGGREGATE_QUARTER_FAIL, { data: res.data });
      reactor.dispatch(actionTypes.GET_DASHBOARD_AGGREGATE_QUARTER_START, { data: false });
    });
}

export function getDashboardAggregateQuarterStart(data) {
  reactor.dispatch(actionTypes.GET_DASHBOARD_AGGREGATE_QUARTER_START, { data });
}

export function getDashboardOfficeDirectorQuarter(id) {
  axios
    .get(process.env.__APIURL__ + "/dashboards/" + id + "/office-director-quarter")
    .then((res) => {
      reactor.dispatch(actionTypes.GET_DASHBOARD_OFFICE_DIRECTOR_QUARTER_SUCCESS, { data: res.data });
      reactor.dispatch(actionTypes.GET_DASHBOARD_OFFICE_DIRECTOR_QUARTER_START, { data: false });
    })
    .catch((res) => {
      reactor.dispatch(actionTypes.GET_DASHBOARD_OFFICE_DIRECTOR_QUARTER_FAIL, { data: res.data });
      reactor.dispatch(actionTypes.GET_DASHBOARD_OFFICE_DIRECTOR_QUARTER_START, { data: false });
    });
}

export function getDashboardOfficeDirectorQuarterStart(data) {
  reactor.dispatch(actionTypes.GET_DASHBOARD_OFFICE_DIRECTOR_QUARTER_START, { data });
}

export function setDashboardReload(data) {
  reactor.dispatch(actionTypes.SET_DASHBOARD_RELOAD, { data });
}
