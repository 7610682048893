import React, { Component, PropTypes } from "react";
import { connect } from "nuclear-js-react-addons";
import _ from "lodash";

import auth from "../../modules/auth";
import i18n from "../../modules/i18n";
import notifier from "../../modules/notifier";
import LoginView from "./LoginView";

let getIntlMessage = i18n.messages.getIntlMessage;

export default
@connect((props) => ({
  loginForm: auth.getters.loginFormGetter,
  passwordLostForm: auth.getters.passwordLostFormGetter,
  passwordResetForm: auth.getters.passwordResetFormGetter,
}))
class LoginProvider extends Component {
  static contextTypes = {
    router: React.PropTypes.object,
  };

  static propTypes = {
    loginForm: PropTypes.object,
    passwordLostForm: PropTypes.object,
    passwordResetForm: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      token: "",
      showPasswordLost: false,
      showPasswordReset: false,
    };
  }

  componentWillMount() {
    if (auth.utils.authenticated()) {
      this.context.router.push("/dashboard");
      notifier.actions.notify({
        message: getIntlMessage("You are already logged in"),
        type: "success",
      });
    }
    let params = this.props.location.query || {};
    if (!_.isEmpty(params) && params.action === "reset") {
      this.setState({ token: params.token });
      this.onShowPasswordReset();
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (auth.utils.authenticated()) {
      this.context.router.push("/dashboard");
    }
  }

  onShowPasswordLost = () => {
    this.setState({
      showPasswordLost: true,
    });
  };

  onHidePasswordLost = () => {
    this.setState({
      showPasswordLost: false,
    });
  };

  onShowPasswordReset = () => {
    this.setState({
      showPasswordReset: true,
    });
  };

  onHidePasswordReset = () => {
    this.setState({
      showPasswordReset: false,
    });
  };

  render() {
    return (
      <LoginView
        loginForm={this.props.loginForm.toJS()}
        passwordLostForm={this.props.passwordLostForm.toJS()}
        passwordResetForm={this.props.passwordResetForm.toJS()}
        token={this.state.token}
        showPasswordLost={this.state.showPasswordLost}
        showPasswordReset={this.state.showPasswordReset}
        onShowPasswordLost={this.onShowPasswordLost}
        onHidePasswordLost={this.onHidePasswordLost}
        onShowPasswordReset={this.onShowPasswordReset}
        onHidePasswordReset={this.onHidePasswordReset}
      />
    );
  }
}
