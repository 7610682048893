import { Store, toImmutable } from "nuclear-js";
import actionTypes from "./actionTypes";

export const LANGS = {
  fr: "fr-CA",
  en: "en-US",
};

export const LOCALE_PATHS = {
  fr: require("./locales/fr-CA"),
  en: require("./locales/en-US"),
};

export default new Store({
  getInitialState() {
    let locale = LOCALE_PATHS[process.env.__LOCALE__];
    return toImmutable(locale);
  },

  initialize() {
    this.on(actionTypes.CHANGE_LOCALE, onChangeLocale);
  },
});

function onLocale(state) {
  return state;
}

function onChangeLocale(state, { data }) {
  // let locale = require("./locales/" + LANGS[data]);
  let locale = LOCALE_PAHTS[data];
  return state;
  // .setIn(
  //   ['prospectForm', 'submitted'],
  //   data
  // );
}
