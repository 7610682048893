import keyMirror from "keymirror";

export default keyMirror({
  SET_PLANIFICATION_RESET: null,
  GET_PLANIFICATION_SUMMARY_SUCCESS: null,
  GET_PLANIFICATION_SUMMARY_FAIL: null,
  GET_PLANIFICATION_SUMMARY_START: null,
  GET_PLANIFICATION_PERIOD_SUCCESS: null,
  GET_PLANIFICATION_PERIOD_FAIL: null,
  GET_PLANIFICATION_PERIOD_START: null,
  ADD_PLANIFICATION_PERIOD_SUCCESS: null,
  ADD_PLANIFICATION_PERIOD_FAIL: null,
  ADD_PLANIFICATION_PERIOD_START: null,
  SET_PLANIFICATION_PERIOD_SELECTOR: null,

  ADD_PLANIFICATION_ADVISOR_FORM: null,
  ADD_PLANIFICATION_ADVISOR_START: null,
  ADD_PLANIFICATION_ADVISOR_SUCCESS: null,
  ADD_PLANIFICATION_ADVISOR_FAIL: null,
  SET_PLANIFICATION_ADVISOR_FORM: null,
  SET_PLANIFICATION_ADVISOR_START: null,
  SET_PLANIFICATION_ADVISOR_SUCCESS: null,
  SET_PLANIFICATION_ADVISOR_FAIL: null,
  SET_PLANIFICATION_ADVISOR_RESET: null,
  GET_PLANIFICATION_ADVISOR_SUCCESS: null,
  GET_PLANIFICATION_ADVISOR_FAIL: null,

  DELETE_PLANIFICATION_ADVISOR_FORM: null,
  DELETE_PLANIFICATION_ADVISOR_START: null,
  DELETE_PLANIFICATION_ADVISOR_SUCCESS: null,
  DELETE_PLANIFICATION_ADVISOR_FAIL: null,
});
