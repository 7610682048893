import React, { Component, PropTypes } from "react";
import { connect } from "nuclear-js-react-addons";
import moment from "moment";
import _ from "lodash";

import activities from "../../modules/activities";
import i18n from "../../modules/i18n";
import FormModal from "../forms/FormModal";

let getIntlMessage = i18n.messages.getIntlMessage;
let defaultValue = {};

export default
@connect((props) => ({
  scheduleActivityForm: activities.getters.scheduleActivityFormGetter,
}))
class ScheduleActivity extends Component {
  static propTypes = {
    // scheduleActivityForm: PropTypes.object,
    activityId: PropTypes.number,
    stepId: PropTypes.number,
  };

  static defaultProps = {
    scheduleActivityForm: null,
    activityId: null,
    stepId: null,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      value: defaultValue,
    };
  }

  componentWillReceiveProps(props) {
    if (!props.show) {
      this.setState({
        value: defaultValue,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.scheduleActivityForm !== prevProps.scheduleActivityForm) {
      var form = this.props.scheduleActivityForm.toJS();
      var prevForm = prevProps.scheduleActivityForm.toJS();
      if (form.feedback.type !== prevForm.feedback.type && form.feedback.type === "success") {
        this.props.onHide();
      }
    }
  }

  onFormSubmit = (event, value) => {
    if (value) {
      this.setState({
        value: value,
      });
      var val = _.cloneDeep(value);
      val.starts_on = moment(value.starts_on).format("YYYY-MM-DD");
      activities.actions.setActivityScheduleStart(true);
      activities.actions.setActivitySchedule(this.props.activityId, this.props.stepId, val);
    }
  };

  onFormChange = (value) => {
    this.setState({ value });
  };

  render() {
    let form = this.props.scheduleActivityForm.toJS();
    return (
      <FormModal
        form={form}
        formType="set"
        value={this.state.value}
        onFormChange={this.onFormChange}
        onFormSubmit={this.onFormSubmit}
        bsStyle="info"
        title={getIntlMessage("Schedule Activity")}
        actionButtonTitle={getIntlMessage("Schedule")}
        showFeedback={false}
        {...this.props}
      />
    );
  }
}
