import React, { Component, PropTypes } from "react";
import { connect } from "nuclear-js-react-addons";

import moment from "moment";

import i18n from "../../modules/i18n";
import staffing from "../../modules/staffing";
import FormModal from "../forms/FormModal";

let getIntlMessage = i18n.messages.getIntlMessage;

let defaultValue = {
  sales_director_id: "",
  firstname: "",
  lastname: "",
  appointment: "",
  source: "",
  schooling: "",
  successfinder: "",
  result: "",
};

export default
@connect((props) => ({
  setStaffingCandidateForm: staffing.getters.setStaffingCandidateFormGetter,
  candidate: staffing.getters.staffingCandidateGetter,
}))
class SetStaffingCandidate extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      value: defaultValue,
    };
  }

  componentWillReceiveProps(nextProps) {
    let candidate = this.props.candidate.toJS();
    let nextCandidate = nextProps.candidate.toJS();
    if (candidate && candidate !== nextCandidate) {
      this.setState({ value: nextCandidate });
    }
  }

  onFormSubmit = (event, value) => {
    if (value) {
      this.setState({
        value: value,
      });
      const candidate = this.props.candidate.toJS();
      const data = Object.assign({ sales_director_id: this.props.profile.id }, value);
      staffing.actions.setStaffingCandidateStart(true);
      staffing.actions.setStaffingCandidate(candidate.id, data);
      this.props.onHide();
    }
  };

  onFormChange = (value) => {
    this.setState({ value });
  };

  render() {
    let form = this.props.setStaffingCandidateForm.toJS();
    return (
      <FormModal
        form={form}
        formType={"set"}
        value={this.state.value}
        onFormChange={this.onFormChange}
        onFormSubmit={this.onFormSubmit}
        title={getIntlMessage("Set Candidate")}
        {...this.props}
      />
    );
  }
}
