import t from "tcomb-form";

import i18n from "../i18n";

let getIntlMessage = i18n.messages.getIntlMessage;

export var TranslationSchema = t.struct({
  en: t.maybe(t.Str),
  fr: t.Str,
});

export var TranslationSchemaOptions = {
  label: getIntlMessage("Translations"),
  fields: {
    en: {
      type: "text",
      label: getIntlMessage("English"),
    },
    fr: {
      type: "text",
      label: getIntlMessage("French"),
    },
  },
};

export var I18NSchemaOptions = {
  add: getIntlMessage("Add"),
  // down: 'Down',
  optional: " " + getIntlMessage("Optional"),
  // remove: 'Remove',
  // up: 'Up'
};
