import React, { Component, PropTypes } from "react";
import { Modal, Button } from "react-bootstrap";

import i18n from "../../modules/i18n";
import staffing from "../../modules/staffing";

let getIntlMessage = i18n.messages.getIntlMessage;

export default class DeleteStaffingCandidate extends Component {
  static propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func,
    candidateId: PropTypes.number,
  };

  static defaultProps = {
    candidateId: null,
  };

  onDelete = (event) => {
    staffing.actions.deleteStaffingCandidateStart(true);
    staffing.actions.deleteStaffingCandidate(this.props.candidateId);
    this.props.onHide();
  };

  render() {
    return (
      <Modal
        bsSize="medium"
        className="hmodal-danger"
        show={this.props.show}
        onHide={this.props.onHide}
        {...this.props}
      >
        <div className="color-line" />
        <Modal.Header>
          <Modal.Title>{getIntlMessage("Delete Candidate")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{getIntlMessage("Are you sure you want to delete this candidate?")}</Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onHide}>{getIntlMessage("Close")}</Button>
          <Button name="delete" bsStyle="danger" onClick={this.onDelete}>
            {getIntlMessage("Delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
