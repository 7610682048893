import React, { Component, PropTypes } from "react";
import { connect } from "nuclear-js-react-addons";
import { Panel, ButtonGroup, Label } from "react-bootstrap";
import moment from "moment";

import app from "../../modules/app";
import planifications from "../../modules/planifications";

var k = (React.Kendo = require("kendo-ui-react"));

import i18n from "../../modules/i18n";

let getIntlMessage = i18n.messages.getIntlMessage;

export default
@connect((props) => ({
  account: app.getters.appAccountGetter,
  period: planifications.getters.planificationPeriodGetter,
}))
class WeekSelector extends Component {
  static propTypes = {
    account: PropTypes.object,
  };

  static defaultProps = {
    account: {},
  };

  constructor(props, context) {
    super(props, context);
    let today = moment({ hour: 0, minute: 0, seconds: 0 });
    let weekNum = moment(today).week();
    let weekYear = moment(today).year();
    let weekStart = moment(today).day("Monday").year(weekYear).week(weekNum);
    let weekEnd = moment(today).day("Friday").year(weekYear).week(weekNum);
    let weeksAgo = weekStart.diff(weekStart, "weeks");
    this.state = {
      today: today,
      todayWeekNum: weekNum,
      todayWeekStart: weekStart,
      weekNum: weekNum,
      weekYear: weekYear,
      weekStart: weekStart,
      weekEnd: weekEnd,
      weeksAgo: weeksAgo,
    };
  }

  componentDidMount() {
    this.getData();
  }

  setWeekState(when) {
    let weekNum = moment(when).week();
    let weekYear = moment(when).year();
    let weekStart = moment(when).day("Monday").year(weekYear).week(weekNum).hours(0).minutes(0).seconds(0);
    let weekEnd = moment(when).day("Friday").year(weekYear).week(weekNum).hours(0).minutes(0).seconds(0);
    let weeksAgo = this.state.todayWeekStart.diff(weekStart, "weeks");
    this.setState({
      weekNum: weekNum,
      weekYear: weekYear,
      weekStart: weekStart,
      weekEnd: weekEnd,
      weeksAgo: weeksAgo,
    });
    this.getData();
  }

  getData() {
    let profile = this.props.profile;
    if (profile.main_role_refid === "sales-director") {
      account = profile;
    }
    let account = this.props.account.toJS();
    let weekYear = this.state.weekYear;
    let weekNum = this.state.weekNum;
    if (account.id !== undefined) {
      planifications.actions.setPlanificationPeriodSelector(weekYear, weekNum, account.id);
    }
    let role = account.main_role_refid;
    if (role === "sales-director") {
      planifications.actions.getPlanificationByPeriod(weekYear, weekNum, account.id);
    } else if (role === "sales-director" || role === "office-director" || role === "supervisor" || role === "manager") {
      planifications.actions.getPlanificationSummary(weekYear, weekNum);
    }
  }

  onChange = (event) => {
    let sender = event.sender;
    let value = sender.value();
    let when = moment(value);
    this.setWeekState(when);
  };

  getOptions = () => {
    return {
      culture: "fr-CA",
      format: "yyyy-MM-dd",
      change: this.onChange,
    };
  };

  render() {
    let options = this.getOptions();
    let weekNum = this.state.weekNum;
    let weekStart = this.state.weekStart.format("YYYY/MM/DD");
    let weekEnd = this.state.weekEnd.format("YYYY/MM/DD");
    return (
      <div>
        <div className="pull-left">
          <h3>
            {getIntlMessage("Week")} {weekNum} : {weekStart} - {weekEnd}
          </h3>
        </div>
        <div className="pull-right week-selector">
          <k.DatePicker options={options} tag={"div"} />
        </div>
      </div>
    );
  }
}
