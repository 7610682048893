import React from "react";
import { Table } from "react-bootstrap";
import classNames from "classnames";
import moment from "moment";

import i18n from "../../modules/i18n";

let getIntlMessage = i18n.messages.getIntlMessage;

var ProspectNotes = React.createClass({
  propTypes: {
    notes: React.PropTypes.array,
  },

  getDefaultProps() {
    return {
      notes: [],
    };
  },

  getCategoryClass(category) {
    if (category === "call") {
      return "fa fa-phone";
    }
    return "fa fa-comment-o";
  },

  renderRows(data) {
    var rows = data.map((item, index) => {
      let dt = moment(item.created_on);
      let date = dt.format("DD/MM/YYYY");
      let time = dt.format("HH:mm");
      return (
        <tr key={index}>
          <td>
            <div className="font-light">
              <i className={this.getCategoryClass(item.category)}></i>&nbsp;
              {getIntlMessage("DATE_ON").toLowerCase()}&nbsp;{date}&nbsp;
              {getIntlMessage("DATE_AT").toLowerCase()}&nbsp;{time}&nbsp;
              {getIntlMessage("DATE_BY").toLowerCase()} {item.owner.fullname}
            </div>
            <div dangerouslySetInnerHTML={{ __html: item.message }} />
          </td>
        </tr>
      );
    });
    return rows;
  },

  render() {
    return (
      <div className="activity-notes">
        <Table striped condensed hover>
          <tbody>{this.renderRows(this.props.notes)}</tbody>
        </Table>
      </div>
    );
  },
});

export default ProspectNotes;
