import { Store, toImmutable } from "nuclear-js";

import forms from "../forms";
import actionTypes from "./actionTypes";
import * as schemas from "./schemas";

let setProfileFormDefaults = {
  type: schemas.SetProfileSchema,
  options: schemas.SetProfileSchemaOptions,
  feedback: {
    type: "info",
    messages: [],
  },
  started: false,
};

let setProfilePasswordFormDefaults = {
  type: schemas.SetProfilePasswordSchema,
  options: schemas.SetProfilePasswordSchemaOptions,
  feedback: {
    type: "info",
    messages: [],
  },
  started: false,
};

let initialState = toImmutable({
  profile: {},
  started: false,
  setProfileForm: setProfileFormDefaults,
  setProfilePasswordForm: setProfilePasswordFormDefaults,
  setProfileAvatar: {
    feedback: {
      type: "info",
      messages: [],
    },
    started: false,
  },
});

export default new Store({
  getInitialState() {
    return initialState;
  },

  initialize() {
    this.on(actionTypes.SET_PROFILE_RESET, onSetProfileReset);
    this.on(actionTypes.GET_PROFILE_START, onGetProfileStart);
    this.on(actionTypes.GET_PROFILE_SUCCESS, onGetProfileSuccess);
    this.on(actionTypes.GET_PROFILE_FAIL, onGetProfileFail);
    this.on(actionTypes.SET_PROFILE_FORM, onSetProfileForm);
    this.on(actionTypes.SET_PROFILE_START, onSetProfileStart);
    this.on(actionTypes.SET_PROFILE_SUCCESS, onSetProfileSuccess);
    this.on(actionTypes.SET_PROFILE_FAIL, onSetProfileFail);
    this.on(actionTypes.SET_PROFILE_PASSWORD_FORM, onSetProfilePasswordForm);
    this.on(actionTypes.SET_PROFILE_PASSWORD_START, onSetProfilePasswordStart);
    this.on(actionTypes.SET_PROFILE_PASSWORD_SUCCESS, onSetProfilePasswordSuccess);
    this.on(actionTypes.SET_PROFILE_PASSWORD_FAIL, onSetProfilePasswordFail);
    this.on(actionTypes.SET_PROFILE_AVATAR_START, onSetProfileAvatarStart);
    this.on(actionTypes.SET_PROFILE_AVATAR_SUCCESS, onSetProfileAvatarSuccess);
    this.on(actionTypes.SET_PROFILE_AVATAR_FAIL, onSetProfileAvatarFail);
    this.on(actionTypes.REM_PROFILE, onRemProfile);
  },
});

function onSetProfileReset(state) {
  return initialState;
}

function onGetProfileStart(state, { data }) {
  return state.set("started", data);
}

function onGetProfileSuccess(state, { data }) {
  return state.set("profile", toImmutable(data.profile));
}

function onGetProfileFail(state, { data }) {
  return state.set("profile", toImmutable({}));
}

function onSetProfileForm(state) {
  return state;
}

function onSetProfileStart(state, { data }) {
  return state.setIn(["setProfileForm", "started"], data);
}

function onSetProfileSuccess(state, { data }) {
  return state.set("profile", toImmutable(data.profile)).set("setProfileForm", toImmutable(setProfileFormDefaults));
}

function onSetProfileFail(state, { data }) {
  let feedback = forms.utils.setFormFeedback(data);
  let options = forms.utils.setFormOptions(state.getIn(["setProfileForm", "options"]).toJS(), data);
  return state
    .setIn(["setProfileForm", "feedback"], toImmutable(feedback))
    .setIn(["setProfileForm", "options"], toImmutable(options));
}

function onSetProfilePasswordForm(state) {
  return state;
}

function onSetProfilePasswordStart(state, { data }) {
  return state.setIn(["setProfilePasswordForm", "started"], data);
}

function onSetProfilePasswordSuccess(state, { data }) {
  return state
    .set("profile", toImmutable(data.profile))
    .set("setProfilePasswordForm", toImmutable(setProfilePasswordFormDefaults));
}

function onSetProfilePasswordFail(state, { data }) {
  let feedback = forms.utils.setFormFeedback(data);
  let options = forms.utils.setFormOptions(state.getIn(["setProfilePasswordForm", "options"]).toJS(), data);
  return state
    .setIn(["setProfilePasswordForm", "feedback"], toImmutable(feedback))
    .setIn(["setProfilePasswordForm", "options"], toImmutable(options));
}

function onSetProfileAvatarStart(state, { data }) {
  return state.setIn(["setProfileAvatar", "started"], data);
}

function onSetProfileAvatarSuccess(state, { data }) {
  return state.set("profile", toImmutable(data.profile));
}

function onSetProfileAvatarFail(state, { data }) {
  return state;
}

function onRemProfile(state) {
  return state.set("profile", toImmutable({}));
}
