import React from "react";
import { ButtonGroup, Button } from "react-bootstrap";

import i18n from "../../modules/i18n";
import SubHeader from "../base/SubHeader";
import AddOption from "./AddOption";

let getIntlMessage = i18n.messages.getIntlMessage;

var Header = React.createClass({
  getInitialState() {
    return {
      showAddOption: false,
    };
  },

  onShowAddOption() {
    this.setState({
      showAddOption: true,
    });
  },

  onHideAddOption() {
    this.setState({
      showAddOption: false,
    });
  },

  render() {
    return (
      <SubHeader title={getIntlMessage("Options")}>
        <ButtonGroup>
          <Button name="add-option" bsSize="small" onClick={this.onShowAddOption}>
            {getIntlMessage("Add")}
          </Button>
        </ButtonGroup>
        <AddOption show={this.state.showAddOption} onHide={this.onHideAddOption} {...this.props} />
      </SubHeader>
    );
  },
});

export default Header;
