import React, { Component, PropTypes } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

export default class AdvisorsActivityStats extends Component {
  static propTypes = {
    data: PropTypes.array,
    title: PropTypes.string,
    dataField1: PropTypes.string,
    dataName1: PropTypes.string,
    dataField2: PropTypes.string,
    dataName2: PropTypes.string,
    dataField3: PropTypes.string,
    dataName3: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
  };

  static defaultProps = {
    data: [],
    title: "Title",
    dataField1: "field1",
    dataName1: "Name1",
    dataField2: "field2",
    dataName2: "Name2",
    dataField3: "field3",
    dataName3: "Name3",
    width: "auto",
    height: "auto",
  };

  render() {
    return (
      <div>
        <h4>{this.props.title}</h4>
        <BootstrapTable
          ref="statsTable"
          data={this.props.data}
          striped={true}
          hover={true}
          condensed={true}
          height={this.props.height}
        >
          <TableHeaderColumn isKey={true} dataSort={true} dataField={this.props.dataField1}>
            {this.props.dataName1}
          </TableHeaderColumn>
          <TableHeaderColumn dataSort={true} dataField={this.props.dataField2} width={this.props.width}>
            {this.props.dataName2}
          </TableHeaderColumn>
          <TableHeaderColumn dataSort={true} dataField={this.props.dataField3} width={this.props.width}>
            {this.props.dataName3}
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    );
  }
}
