import React, { Component, PropTypes } from "react";

import auth from "../../modules/auth";
import i18n from "../../modules/i18n";
import FormModal from "../forms/FormModal";

let getIntlMessage = i18n.messages.getIntlMessage;

export default class PasswordResetView extends Component {
  static propTypes = {
    passwordResetForm: PropTypes.object,
    token: PropTypes.string,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      value: {},
    };
  }

  componentDidMount() {
    this.setState({
      value: {
        token: this.props.token,
      },
    });
  }

  componentWillReceiveProps(props) {
    if (!props.show) {
      this.setState({
        value: {},
      });
    } else {
      this.setState({
        value: {
          token: props.token,
        },
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.passwordResetForm !== prevState.passwordResetForm) {
      let form = this.props.passwordResetForm;
      let prevForm = prevProps.passwordResetForm;
      if (form.feedback.type !== prevForm.feedback.type && form.feedback.type === "success") {
        this.setState({ value: {} });
      }
    }
  }

  onFormSubmit = (event, value) => {
    if (value) {
      this.setState({
        value: value,
      });
      auth.actions.passwordResetStart(true);
      auth.actions.passwordReset(value);
    }
  };

  onFormChange = (value) => {
    this.setState({ value });
  };

  render() {
    let form = this.props.passwordResetForm;
    return (
      <FormModal
        form={form}
        formType="reset"
        value={this.state.value}
        onFormChange={this.onFormChange}
        onFormSubmit={this.onFormSubmit}
        title={getIntlMessage("Reset your password")}
        actionButtonTitle={getIntlMessage("Reset")}
        {...this.props}
      />
    );
  }
}
