import { Store, toImmutable } from "nuclear-js";

import forms from "../forms";
import i18n from "../i18n";
import actionTypes from "./actionTypes";
import * as schemas from "./schemas";

let getIntlMessage = i18n.messages.getIntlMessage;

let addStaffingCandidateFormDefaults = {
  type: schemas.AddStaffingCandidateSchema,
  options: schemas.AddStaffingCandidateSchemaOptions,
  feedback: {
    type: "info",
    messages: [],
  },
  started: false,
};

let setStaffingCandidateFormDefaults = {
  type: schemas.SetStaffingCandidateSchema,
  options: schemas.SetStaffingCandidateSchemaOptions,
  feedback: {
    type: "info",
    messages: [],
  },
  started: false,
};

let deleteStaffingCandidateFormDefaults = {
  feedback: {
    type: "info",
    messages: [],
  },
  started: false,
};

let initialState = toImmutable({
  candidates: {
    data: {},
    started: false,
  },
  candidate: {},
  addStaffingCandidateForm: addStaffingCandidateFormDefaults,
  setStaffingCandidateForm: setStaffingCandidateFormDefaults,
  deleteStaffingCandidateForm: deleteStaffingCandidateFormDefaults,
});

export default new Store({
  getInitialState() {
    return initialState;
  },

  initialize() {
    this.on(actionTypes.GET_STAFFING_CANDIDATES_SUCCESS, onGetStaffingCandidatesSuccess);
    this.on(actionTypes.GET_STAFFING_CANDIDATES_FAIL, onGetStaffingCandidatesFail);
    this.on(actionTypes.GET_STAFFING_CANDIDATES_START, onGetStaffingCandidatesStart);

    this.on(actionTypes.ADD_STAFFING_CANDIDATE_FORM, onAddStaffingCandidateForm);
    this.on(actionTypes.ADD_STAFFING_CANDIDATE_START, onAddStaffingCandidateStart);
    this.on(actionTypes.ADD_STAFFING_CANDIDATE_SUCCESS, onAddStaffingCandidateSuccess);
    this.on(actionTypes.ADD_STAFFING_CANDIDATE_FAIL, onAddStaffingCandidateFail);

    this.on(actionTypes.SET_STAFFING_CANDIDATE_FORM, onSetStaffingCandidateForm);
    this.on(actionTypes.SET_STAFFING_CANDIDATE_START, onSetStaffingCandidateStart);
    this.on(actionTypes.SET_STAFFING_CANDIDATE_SUCCESS, onSetStaffingCandidateSuccess);
    this.on(actionTypes.SET_STAFFING_CANDIDATE_FAIL, onSetStaffingCandidateFail);
    this.on(actionTypes.SET_STAFFING_CANDIDATE_RESET, onSetStaffingCandidateReset);

    this.on(actionTypes.DELETE_STAFFING_CANDIDATE_FORM, onDeleteStaffingCandidateForm);
    this.on(actionTypes.DELETE_STAFFING_CANDIDATE_START, onDeleteStaffingCandidateStart);
    this.on(actionTypes.DELETE_STAFFING_CANDIDATE_SUCCESS, onDeleteStaffingCandidateSuccess);
    this.on(actionTypes.DELETE_STAFFING_CANDIDATE_FAIL, onDeleteStaffingCandidateFail);

    this.on(actionTypes.GET_STAFFING_CANDIDATE_SUCCESS, onGetStaffingCandidateSuccess);
    this.on(actionTypes.GET_STAFFING_CANDIDATE_FAIL, onGetStaffingCandidateFail);
  },
});

function onGetStaffingCandidatesSuccess(state, { data }) {
  return state.setIn(["candidates", "data"], toImmutable(data));
}

function onGetStaffingCandidatesFail(state, { data }) {
  return state.setIn(["candidates", "data"], toImmutable({}));
}

function onGetStaffingCandidatesStart(state, { data }) {
  return state.setIn(["candidates", "started"], data);
}

function onAddStaffingCandidateForm(state) {
  return state.set("addStaffingCandidateForm", toImmutable(addStaffingCandidateFormDefaults));
}

function onAddStaffingCandidateStart(state, { data }) {
  return state.setIn(["addStaffingCandidateForm", "started"], data);
}

function onAddStaffingCandidateSuccess(state, { data }) {
  let feedback = {
    type: "success",
    messages: [getIntlMessage("Add Successful")],
  };

  let candidate = data.candidate;
  let candidates = state.getIn(["candidates"]).toJS();
  candidates.data.candidates.push(data.candidate);
  return state.setIn(["candidates"], toImmutable(candidates));
}

function onAddStaffingCandidateFail(state, { data }) {
  var feedback = forms.utils.setFormFeedback(data);
  var options = forms.utils.setFormOptions(state.getIn(["addStaffingCandidateForm", "options"]).toJS(), data);
  return state
    .set("candidate", toImmutable({}))
    .setIn(["addStaffingCandidateForm", "feedback"], toImmutable(feedback))
    .setIn(["addStaffingCandidateForm", "options"], toImmutable(options));
}

function onSetStaffingCandidateForm(state) {
  return state;
}

function onSetStaffingCandidateStart(state, { data }) {
  return state.setIn(["setStaffingCandidateForm", "started"], data);
}

function onSetStaffingCandidateSuccess(state, { data }) {
  let candidate = data.candidate;
  let candidates = state.getIn(["candidates"]).toJS();
  for (let i = 0; i < candidates.data.candidates.length; i++) {
    if (candidates.data.candidates[i].id === candidate.id) {
      Object.assign(candidates.data.candidates[i], candidate);
      break;
    }
  }

  return state
    .setIn("candidate", toImmutable(candidate))
    .setIn(["candidates"], toImmutable(candidates))
    .set("setStaffingCandidateForm", toImmutable(setStaffingCandidateFormDefaults));
}

function onSetStaffingCandidateFail(state, { data }) {
  let feedback = forms.utils.setFormFeedback(data);
  let options = forms.utils.setFormOptions(state.getIn(["setStaffingCandidateForm", "options"]).toJS(), data);
  return state
    .setIn(["setStaffingCandidateForm", "feedback"], toImmutable(feedback))
    .setIn(["setStaffingCandidateForm", "options"], toImmutable(options));
}

function onSetStaffingCandidateReset(state) {
  return state.set("candidate", toImmutable({}));
}

function onDeleteStaffingCandidateForm(state) {
  return state;
}

function onDeleteStaffingCandidateStart(state, { data }) {
  return state.setIn(["deleteStaffingCandidateForm", "started"], data);
}

function onDeleteStaffingCandidateSuccess(state, { data }) {
  let candidateId = data.id;
  let candidates = state.getIn(["candidates"]).toJS();
  for (let i = 0; i < candidates.data.candidates.length; i++) {
    if (candidates.data.candidates[i].id === candidateId) {
      candidates.data.candidates.splice(i, 1);
      break;
    }
  }
  return state
    .setIn(["candidates"], toImmutable(candidates))
    .set("candidate", toImmutable({}))
    .set("deleteStaffingCandidateForm", toImmutable(deleteStaffingCandidateFormDefaults));
}

function onDeleteStaffingCandidateFail(state, { data }) {
  let feedback = forms.utils.setFormFeedback(data);
  return state.setIn(["deletePlanificationAdvisorForm", "feedback"], toImmutable(feedback));
}

function onGetStaffingCandidateSuccess(state, { data }) {
  return state.set("candidate", toImmutable(data.candidate));
}

function onGetStaffingCandidateFail(state, { data }) {
  return state.set("candidate", toImmutable({}));
}
