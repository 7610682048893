import keyMirror from "keymirror";

export default keyMirror({
  SET_OFFICE_RESET: null,
  GET_OFFICE_SUCCESS: null,
  GET_OFFICE_FAIL: null,
  ADD_OFFICE_FORM: null,
  ADD_OFFICE_START: null,
  ADD_OFFICE_SUCCESS: null,
  ADD_OFFICE_FAIL: null,
  SET_OFFICE_FORM: null,
  SET_OFFICE_START: null,
  SET_OFFICE_SUCCESS: null,
  SET_OFFICE_FAIL: null,
  DEL_OFFICE_SUCCESS: null,
  DEL_OFFICE_FAIL: null,
  REM_OFFICE: null,
  SET_OFFICES_RELOAD: null,
});
