import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "nuclear-js-react-addons";

import reactor from "./reactor";
import router from "./routes";
import "./config";

// Assets
import "./sass/app.scss";

ReactDOM.render(<Provider reactor={reactor}>{router}</Provider>, document.getElementById("root"));
