import t from "tcomb-form";

import forms from "../forms";
import i18n from "../i18n";

let getIntlMessage = i18n.messages.getIntlMessage;

export var AddOptionSchema = t.struct({
  category: t.Str,
  label: t.maybe(t.Str),
  refid: t.maybe(t.Str),
  name_translations: forms.schemas.TranslationSchema,
});

export var SetOptionSchema = AddOptionSchema;

export var AddOptionSchemaOptions = {
  config: {
    // for each of lg md sm xs you can specify the columns width
    horizontal: {
      lg: [4, 8],
      md: [4, 8],
    },
  },
  fields: {
    category: {
      type: "text",
      label: getIntlMessage("Category"),
    },
    label: {
      type: "text",
      label: getIntlMessage("Label"),
    },
    refid: {
      type: "text",
      label: getIntlMessage("RefID"),
    },
    name_translations: forms.schemas.TranslationSchemaOptions,
  },
};

export var SetOptionSchemaOptions = AddOptionSchemaOptions;
