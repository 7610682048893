import React from "react";
import { Glyphicon, Row, Col, Button, ButtonGroup, Alert } from "react-bootstrap";
import i18n from "../i18n";

let getIntlMessage = i18n.messages.getIntlMessage;

export function AddProspectLayout(locals) {
  let inputs = locals.inputs;
  return (
    <div>
      <Row>
        <Col xs={12}>{inputs.phones}</Col>
      </Row>
      <Row>
        <Col sm={6}>{inputs.firstname}</Col>
        <Col sm={6}>{inputs.lastname}</Col>
      </Row>
      <Row>
        <Col sm={6}>{inputs.address}</Col>
        <Col sm={6}>{inputs.city}</Col>
      </Row>
      <Row>
        <Col sm={6}>{inputs.email}</Col>
        <Col sm={3}>{inputs.source_id}</Col>
        <Col sm={3}>{inputs.grade_id}</Col>
      </Row>
      <Row>
        <Col sm={6}>{inputs.partner}</Col>
        <Col sm={6}>{inputs.referent}</Col>
      </Row>
      <Row>
        <Col xs={3}>
          <label className="control-label">{getIntlMessage("Availability")}</label>
          {inputs.is_available_day}
        </Col>
        <Col xs={3}>
          <label className="control-label">&nbsp;</label>
          {inputs.is_available_evening}
        </Col>
        <Col xs={6}>&nbsp;</Col>
      </Row>
      <Row>
        <Col xs={12}>{inputs.comments}</Col>
      </Row>
    </div>
  );
}

export function SetProspectLayout(locals) {
  let inputs = locals.inputs;
  return (
    <div>
      <Row>
        <Col sm={6}>{inputs.status_id}</Col>
      </Row>
      <Row>
        <Col xs={12}>{inputs.phones}</Col>
      </Row>
      <Row>
        <Col sm={6}>{inputs.firstname}</Col>
        <Col sm={6}>{inputs.lastname}</Col>
      </Row>
      <Row>
        <Col sm={6}>{inputs.address}</Col>
        <Col sm={6}>{inputs.city}</Col>
      </Row>
      <Row>
        <Col sm={6}>{inputs.email}</Col>
        <Col sm={3}>{inputs.source_id}</Col>
        <Col sm={3}>{inputs.grade_id}</Col>
      </Row>
      <Row>
        <Col sm={6}>{inputs.partner}</Col>
        <Col sm={6}>{inputs.referent}</Col>
      </Row>
      <Row>
        <Col xs={3}>
          <label className="control-label">{getIntlMessage("Availability")}</label>
          {inputs.is_available_day}
        </Col>
        <Col xs={3}>
          <label className="control-label">&nbsp;</label>
          {inputs.is_available_evening}
        </Col>
        <Col xs={6}>&nbsp;</Col>
      </Row>
      <Row>
        <Col xs={12}>{inputs.comments}</Col>
      </Row>
    </div>
  );
}

export function ProspectPhoneListLayout(locals) {
  function setLabel(label) {
    var value = "";
    switch (label) {
      case "Remove":
        value = <Glyphicon glyph="remove" />;
        break;
      case "Up":
        value = <Glyphicon glyph="triangle-top" />;
        break;
      case "Down":
        value = <Glyphicon glyph="triangle-bottom" />;
        break;
      default:
        value = <Glyphicon glyph="star" />;
    }
    return value;
  }

  function showErrors() {
    if (locals.error && locals.hasError) {
      return <Alert bsStyle="danger">{locals.error}</Alert>;
    }
    return <div />;
  }

  return (
    <div className="form-group">
      {showErrors()}
      {locals.items.map(function (item, index) {
        return (
          <Row key={index}>
            <Col xs={11}>{item.input}</Col>
            <Col xs={1}>
              <ButtonGroup className="pull-right">
                {item.buttons.map(function (button, index) {
                  return (
                    <Button bsStyle="link" onClick={button.click} key={index}>
                      {setLabel(button.label)}
                    </Button>
                  );
                })}
              </ButtonGroup>
            </Col>
          </Row>
        );
      })}
      <Button onClick={locals.add.click} bsStyle="info" block>
        {getIntlMessage("Add Phone Number")}
      </Button>
    </div>
  );
}

export function ProspectPhoneItemLayout(locals) {
  let inputs = locals.inputs;
  return (
    <div>
      <Row>
        <Col xs={8}>{inputs.number}</Col>
        <Col xs={4}>{inputs.label_id}</Col>
      </Row>
    </div>
  );
}

export function SetProspectConsentLayout(locals) {
  let inputs = locals.inputs;
  return (
    <div>
      <Row>
        <h4 className="font-light">{getIntlMessage("PROSPECT_CONSENT_TEXT")}</h4>
      </Row>
      <Row>
        <Col xs={12}>{inputs.has_consent}</Col>
      </Row>
    </div>
  );
}

export function AddProspectActivityLayout(locals) {
  let inputs = locals.inputs;
  return (
    <div>
      <Row>
        <h4 className="font-light">{getIntlMessage("Which Step?")}</h4>
      </Row>
      <Row>
        <Col xs={12}>{inputs.step}</Col>
      </Row>
    </div>
  );
}
