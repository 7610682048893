import axios from "axios";

import reactor from "../../reactor";
import i18n from "../i18n";
import notifier from "../notifier";
import actionTypes from "./actionTypes";

let getIntlMessage = i18n.messages.getIntlMessage;

export function setAccountReset() {
  reactor.dispatch(actionTypes.SET_ACCOUNT_RESET);
}

export function getAccount(id) {
  axios
    .get(process.env.__APIURL__ + "/accounts/" + id)
    .then((res) => {
      reactor.dispatch(actionTypes.GET_ACCOUNT_SUCCESS, { data: res.data });
    })
    .catch((res) => {
      reactor.dispatch(actionTypes.GET_ACCOUNT_FAIL, { data: res.data });
    });
}

export function addAccountForm() {
  reactor.dispatch(actionTypes.ADD_ACCOUNT_FORM);
}

export function addAccountStart(data) {
  reactor.dispatch(actionTypes.ADD_ACCOUNT_START, { data });
}

export function addAccount(data) {
  axios
    .post(process.env.__APIURL__ + "/accounts", data)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.ADD_ACCOUNT_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.ADD_ACCOUNT_START, { data: false });
        reactor.dispatch(actionTypes.SET_ACCOUNTS_RELOAD, { data: true });
      });
      notifier.actions.notify({
        message: getIntlMessage("Add Successful"),
        type: "success",
      });
      reactor.dispatch(actionTypes.SET_ACCOUNTS_RELOAD, { data: false });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.ADD_ACCOUNT_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.ADD_ACCOUNT_START, { data: false });
        reactor.dispatch(actionTypes.SET_ACCOUNTS_RELOAD, { data: false });
      });
    });
}

export function setAccountForm() {
  reactor.dispatch(actionTypes.SET_ACCOUNT_FORM);
}

export function setAccountStart(data) {
  reactor.dispatch(actionTypes.SET_ACCOUNT_START, { data });
}

export function setAccount(id, data) {
  axios
    .put(process.env.__APIURL__ + "/accounts/" + id, data)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_ACCOUNT_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.SET_ACCOUNT_START, { data: false });
        reactor.dispatch(actionTypes.SET_ACCOUNTS_RELOAD, { data: true });
      });
      notifier.actions.notify({
        message: getIntlMessage("Update Successful"),
        type: "success",
      });
      reactor.dispatch(actionTypes.SET_ACCOUNTS_RELOAD, { data: false });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_ACCOUNT_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.SET_ACCOUNT_SUBMIT, { data: false });
        reactor.dispatch(actionTypes.SET_ACCOUNTS_RELOAD, { data: false });
      });
    });
}

export function setAccountPasswordForm() {
  reactor.dispatch(actionTypes.SET_ACCOUNT_PASSWORD_FORM);
}

export function setAccountPasswordStart(data) {
  reactor.dispatch(actionTypes.SET_ACCOUNT_PASSWORD_START, { data });
}

export function setAccountPassword(id, data) {
  axios
    .put(process.env.__APIURL__ + "/accounts/" + id + "/password", data)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_ACCOUNT_PASSWORD_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.SET_ACCOUNT_PASSWORD_START, { data: false });
      });
      notifier.actions.notify({
        message: getIntlMessage("Update Successful"),
        type: "success",
      });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_ACCOUNT_PASSWORD_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.SET_ACCOUNT_PASSWORD_START, { data: false });
      });
    });
}

export function setAccountAvatarStart(data) {
  reactor.dispatch(actionTypes.SET_ACCOUNT_AVATAR_START, { data });
}

export function setAccountAvatar(id, data) {
  axios
    .put(process.env.__APIURL__ + "/accounts/" + id + "/avatar", data)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_ACCOUNT_AVATAR_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.SET_ACCOUNT_AVATAR_START, { data: false });
      });
      notifier.actions.notify({
        message: getIntlMessage("Update Successful"),
        type: "success",
      });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_ACCOUNT_AVATAR_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.SET_ACCOUNT_AVATAR_START, { data: false });
      });
    });
}

export function setAccountsReload(data) {
  reactor.dispatch(actionTypes.SET_ACCOUNTS_RELOAD, { data });
}

export function remAccount() {
  reactor.dispatch(actionTypes.REM_ACCOUNT);
}
