import axios from "axios";

import reactor from "../../reactor";
import i18n from "../i18n";
import notifier from "../notifier";
import { default as dashboardActionTypes } from "../dashboards/actionTypes";
import { default as activityActionTypes } from "../activities/actionTypes";
import actionTypes from "./actionTypes";

let getIntlMessage = i18n.messages.getIntlMessage;

export function setProspectReset() {
  reactor.dispatch(actionTypes.SET_PROSPECT_RESET);
}

export function getProspect(id) {
  axios
    .get(process.env.__APIURL__ + "/prospects/" + id)
    .then((res) => {
      reactor.dispatch(actionTypes.GET_PROSPECT_SUCCESS, { data: res.data });
    })
    .catch((res) => {
      reactor.dispatch(actionTypes.GET_PROSPECT_FAIL, { data: res.data });
    });
}

export function addProspectForm() {
  reactor.dispatch(actionTypes.ADD_PROSPECT_FORM);
}

export function addProspectStart(data) {
  reactor.dispatch(actionTypes.ADD_PROSPECT_START, { data });
}

export function addProspect(data) {
  axios
    .post(process.env.__APIURL__ + "/prospects", data)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.ADD_PROSPECT_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.ADD_PROSPECT_START, { data: false });
        reactor.dispatch(actionTypes.SET_PROSPECTS_RELOAD, { data: true });
        reactor.dispatch(dashboardActionTypes.SET_DASHBOARD_RELOAD, { data: true });
      });
      let status = res.data.prospect.status.status.refid;
      if (status === "excluded") {
        notifier.actions.notify({
          message: getIntlMessage("Add Successful with problems, prospect is on the DNCL"),
          type: "error",
        });
      } else {
        notifier.actions.notify({
          message: getIntlMessage("Add Successful"),
          type: "success",
        });
      }
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_PROSPECTS_RELOAD, { data: false });
        reactor.dispatch(dashboardActionTypes.SET_DASHBOARD_RELOAD, { data: false });
        reactor.dispatch(actionTypes.SET_PROSPECT_RESET);
      });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.ADD_PROSPECT_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.ADD_PROSPECT_START, { data: false });
        reactor.dispatch(actionTypes.SET_PROSPECTS_RELOAD, { data: false });
        reactor.dispatch(dashboardActionTypes.SET_DASHBOARD_RELOAD, { data: false });
      });
    });
}

export function setProspectForm() {
  reactor.dispatch(actionTypes.SET_PROSPECT_FORM);
}

export function setProspectStart(data) {
  reactor.dispatch(actionTypes.SET_PROSPECT_START, { data });
}

export function setProspect(id, data, partial = false) {
  var action = partial ? axios.patch : axios.put;
  action(process.env.__APIURL__ + "/prospects/" + id, data)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_PROSPECT_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.SET_PROSPECT_START, { data: false });
        reactor.dispatch(actionTypes.SET_PROSPECTS_RELOAD, { data: true });
        reactor.dispatch(activityActionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: true });
      });
      notifier.actions.notify({
        message: getIntlMessage("Update Successful"),
        type: "success",
      });
      reactor.dispatch(actionTypes.SET_PROSPECTS_RELOAD, { data: false });
      reactor.dispatch(activityActionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: false });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_PROSPECT_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.SET_PROSPECT_START, { data: false });
        reactor.dispatch(actionTypes.SET_PROSPECTS_RELOAD, { data: false });
        reactor.dispatch(activityActionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: false });
      });
    });
}

export function setProspectsReload(data) {
  reactor.dispatch(actionTypes.SET_PROSPECTS_RELOAD, { data });
}

export function remProspect() {
  reactor.dispatch(actionTypes.REM_PROSPECT);
}

export function setProspectStatusStart(data) {
  reactor.dispatch(actionTypes.SET_PROSPECT_STATUS_START, { data });
}

export function setProspectStatus(id, data) {
  axios
    .put(process.env.__APIURL__ + "/prospects/" + id + "/status", data)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_PROSPECT_STATUS_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.SET_PROSPECT_STATUS_START, { data: false });
        reactor.dispatch(actionTypes.SET_PROSPECTS_RELOAD, { data: true });
      });
      notifier.actions.notify({
        message: getIntlMessage("Update Successful"),
        type: "success",
      });
      reactor.dispatch(actionTypes.SET_PROSPECTS_RELOAD, { data: false });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_PROSPECT_STATUS_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.SET_PROSPECT_STATUS_START, { data: false });
        reactor.dispatch(actionTypes.SET_PROSPECTS_RELOAD, { data: false });
      });
      notifier.actions.notify({
        message: getIntlMessage("Update Failed"),
        type: "error",
      });
    });
}

export function setProspectStatusBatchStart(data) {
  reactor.dispatch(actionTypes.SET_PROSPECT_STATUS_BATCH_START, { data });
}

export function setProspectStatusBatch(data) {
  axios
    .put(process.env.__APIURL__ + "/prospects/status/batch", data)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_PROSPECT_STATUS_BATCH_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.SET_PROSPECT_STATUS_BATCH_START, { data: false });
        reactor.dispatch(actionTypes.SET_PROSPECTS_RELOAD, { data: true });
      });
      notifier.actions.notify({
        message: getIntlMessage("Update Successful"),
        type: "success",
      });
      reactor.dispatch(actionTypes.SET_PROSPECTS_RELOAD, { data: false });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_PROSPECT_STATUS_BATCH_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.SET_PROSPECT_STATUS_BATCH_START, { data: false });
        reactor.dispatch(actionTypes.SET_PROSPECTS_RELOAD, { data: false });
      });
      notifier.actions.notify({
        message: getIntlMessage("Update Failed"),
        type: "error",
      });
    });
}

export function addProspectNoteForm() {
  reactor.dispatch(actionTypes.ADD_PROSPECT_NOTE_FORM);
}

export function addProspectNoteStart(data) {
  reactor.dispatch(actionTypes.ADD_PROSPECT_NOTE_START, { data });
}

export function addProspectNote(id, data) {
  axios
    .post(process.env.__APIURL__ + "/prospects/" + id + "/notes", data)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.ADD_PROSPECT_NOTE_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.ADD_PROSPECT_NOTE_START, { data: false });
        reactor.dispatch(actionTypes.SET_PROSPECTS_RELOAD, { data: true });
        reactor.dispatch(activityActionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: true });
      });
      notifier.actions.notify({
        message: getIntlMessage("Add Successful"),
        type: "success",
      });
      reactor.dispatch(actionTypes.SET_PROSPECTS_RELOAD, { data: false });
      reactor.dispatch(activityActionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: false });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.ADD_PROSPECT_NOTE_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.ADD_PROSPECT_NOTE_START, { data: false });
        reactor.dispatch(actionTypes.SET_PROSPECTS_RELOAD, { data: false });
        reactor.dispatch(activityActionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: false });
      });
    });
}

export function addProspectActivityForm() {
  reactor.dispatch(actionTypes.ADD_PROSPECT_ACTIVITY_FORM);
}

export function addProspectActivityStart(data) {
  reactor.dispatch(actionTypes.ADD_PROSPECT_ACTIVITY_START, { data });
}

export function addProspectActivity(data) {
  axios
    .post(process.env.__APIURL__ + "/activities", data)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.ADD_PROSPECT_ACTIVITY_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.ADD_PROSPECT_ACTIVITY_START, { data: false });
        reactor.dispatch(actionTypes.SET_PROSPECTS_RELOAD, { data: true });
        reactor.dispatch(activityActionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: true });
        reactor.dispatch(activityActionTypes.SET_ACTIVITIES_RELOAD, { data: true });
      });
      notifier.actions.notify({
        message: getIntlMessage("Add Successful"),
        type: "success",
      });
      reactor.dispatch(actionTypes.SET_PROSPECTS_RELOAD, { data: false });
      reactor.dispatch(activityActionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: false });
      reactor.dispatch(activityActionTypes.SET_ACTIVITIES_RELOAD, { data: false });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.ADD_PROSPECT_ACTIVITY_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.ADD_PROSPECT_ACTIVITY_START, { data: false });
        reactor.dispatch(actionTypes.SET_PROSPECTS_RELOAD, { data: false });
        reactor.dispatch(activityActionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: false });
        reactor.dispatch(activityActionTypes.SET_ACTIVITIES_RELOAD, { data: false });
      });
    });
}

export function getProspectsCallList(id, params) {
  let args = "";
  if (params) {
    args = "?" + $.param(params);
  }
  axios
    .get(process.env.__APIURL__ + "/calls/" + id + args)
    .then((res) => {
      reactor.dispatch(actionTypes.GET_PROSPECTS_CALL_LIST_SUCCESS, { data: res.data });
    })
    .catch((res) => {
      reactor.dispatch(actionTypes.GET_PROSPECTS_CALL_LIST_FAIL, { data: res.data });
    });
}
