import React from "react";

import reactor from "../../reactor";
import accounts from "../../modules/accounts";
import i18n from "../../modules/i18n";
import FormModal from "../forms/FormModal";

let getIntlMessage = i18n.messages.getIntlMessage;

var SetPassword = React.createClass({
  mixins: [reactor.ReactMixin],

  propTypes: {
    account: React.PropTypes.object,
  },

  getDefaultProps() {
    return {
      account: {},
    };
  },

  getInitialState() {
    return {
      value: {},
    };
  },

  getDataBindings() {
    return {
      setAccountPasswordForm: accounts.getters.setAccountPasswordFormGetter,
    };
  },

  componentWillReceiveProps(props) {
    if (props.show) {
      this.setState({ value: props.account });
    } else if (!props.show) {
      this.setState({ value: {} });
    }
  },

  onFormSubmit(event, value) {
    if (value) {
      this.setState({
        value: value,
      });
      accounts.actions.setAccountPasswordStart(true);
      accounts.actions.setAccountPassword(this.props.account.id, value);
    }
  },

  onFormChange(value) {
    this.setState({ value });
  },

  render() {
    var form = this.state.setAccountPasswordForm.toJS();
    return (
      <FormModal
        form={form}
        formType="set"
        value={this.state.value}
        onFormChange={this.onFormChange}
        onFormSubmit={this.onFormSubmit}
        title={getIntlMessage("Edit Password")}
        {...this.props}
      />
    );
  },
});

export default SetPassword;
