import React, { Component, PropTypes } from "react";
import { connect } from "nuclear-js-react-addons";
import _ from "lodash";

import app from "../../modules/app";
import auth from "../../modules/auth";
import profile from "../../modules/profile";
import DefaultLoader from "../Loaders";
import Notifier from "../Notifier";
import ErrorNetwork from "../ErrorNetwork";
import ErrorUpdate from "../ErrorUpdate";

export default
@connect((props) => ({
  info: app.getters.appInfoGetter,
  errors: app.getters.errorsGetter,
  profile: profile.getters.profileGetter,
}))
class App extends Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  static propTypes = {
    info: PropTypes.object,
    errors: PropTypes.object,
    profile: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      showErrorNetwork: false,
      showErrorUpdate: false,
    };
  }

  componentWillMount() {
    app.actions.getAppInfo();
    let token = auth.utils.getToken();
    if (token) {
      auth.actions.token({
        token: token,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.errors !== nextProps.errors) {
      let errors = nextProps.errors.toJS();
      this.setState({
        showErrorNetwork: errors.network,
        showErrorUpdate: errors.update,
      });
    }
    if (this.props.profile !== nextProps.profile) {
      let profile = nextProps.profile.toJS();
      app.actions.getAppAccount(profile.id);
      app.actions.getAppAccountSelector();
    }
  }

  onHideErrorNetwork = () => {
    app.actions.setAppErrors({ network: false });
  };

  onHideErrorUpdate = () => {
    app.actions.setAppErrors({ update: false });
  };

  render() {
    let info = this.props.info.toJS();
    let profile = this.props.profile.toJS();
    if (this.state.showErrorNetwork) {
      return <ErrorNetwork show={this.state.showErrorNetwork} onHide={this.onHideErrorNetwork} />;
    }
    if (this.state.showErrorUpdate) {
      return <ErrorUpdate show={this.state.showErrorUpdate} onHide={this.onHideErrorUpdate} />;
    }
    if (_.isEmpty(info)) {
      return <DefaultLoader />;
    }
    return (
      <div>
        <Notifier extraClasses="messenger-fixed messenger-on-top messenger-on-right" theme="flat" />
        {this.props.children && React.cloneElement(this.props.children, { info: info, profile: profile })}
      </div>
    );
  }
}
