import axios from "axios";
import { hashHistory } from "react-router";

import app from "./modules/app";
import auth from "./modules/auth";

let validateClientVersion = function (value) {
  if (value) {
    if (!localStorage.clientVersion) {
      localStorage.clientVersion = value;
    } else {
      if (localStorage.clientVersion !== value) {
        app.actions.setAppErrors({ update: true });
      }
    }
  }
};

axios.interceptors.request.use(function (config) {
  if (localStorage.token) {
    config.headers.Authorization = "JWT " + localStorage.token;
  }
  return config;
});

axios.interceptors.response.use(
  function (response) {
    let clientVersion = response.headers["x-prospectr-client-version"];
    validateClientVersion(clientVersion);
    return response;
  },
  function (error) {
    if (!error.status) {
      app.actions.setAppErrors({ network: true });
    }
    if (error.status === 401) {
      auth.actions.accessDenied();
      hashHistory.replace("/login");
    }
    return Promise.reject(error);
  }
);

$.ajaxPrefilter(function (options) {
  if (!options.beforeSend) {
    options.beforeSend = function (xhr) {
      xhr.setRequestHeader("Authorization", "JWT " + localStorage.token);
    };
  }
  options.error = function (resp) {
    if (resp.readyState === 0) {
      app.actions.setAppErrors({ network: true });
    }
    if (resp.status === 401) {
      auth.actions.accessDenied();
      hashHistory.replace("/login");
    }
  };
});

$(document).ajaxComplete(function (event, xhr, settings) {
  let clientVersion = xhr.getResponseHeader("x-prospectr-client-version");
  validateClientVersion(clientVersion);
});

$.fn.dataTable.ext.errMode = "throw";
