var messages = {
  lang: "fr",
  locales: "fr-CA",
  messages: {
    "A confirmation message has been sent to": "Un message de confirmation a été envoyé à",
    "Access Denied": "Accès refusé",
    Account: "Compte",
    Accounts: "Comptes",
    Action: "Action",
    Activity: "Activité",
    "Activity Ratios": "Ratios d'activités",
    Activities: "Activités",
    Actual: "Actuel",
    Add: "Ajouter",
    "Add +": "Ajouter +",
    "Add Advisor": "Ajouter un conseiller",
    "Add Call": "Ajouter un appel",
    "Add Candidate": "Ajouter un candidat",
    "Add Failed": "Ajout échoué",
    "Add Note": "Ajouter une note",
    "Add Phone Number": "Ajouter un numéro de téléphone",
    "Add Successful": "Ajout réussi",
    "Add Successful with problems, prospect is on the DNCL":
      "Ajout réussi avec problèmes, le prospect est sur la LNNTE.",
    "Add To Activities": "Ajouter aux activités",
    "Add To Call List": "Ajouter à la liste d'appels",
    Adding: "En cours",
    Address: "Adresse",
    Advisor: "Conseiller",
    Advisors: "Conseillers",
    All: "Tous",
    "An error occured": "Une erreur s'est produite",
    Appointment: "Rencontre",
    Appointments: "Rencontres",
    "Are you sure you want to cancel this prospect?": "Êtes-vous certain de vouloir annuler ce prospect?",
    "Are you sure you want to delete this advisor?": "Êtes-vous certain de vouloir supprimer ce conseiller?",
    "Are you sure you want to pause the activity?": "Êtes-vous certain de vouloir suspendre l'activité?",
    "Are you sure you want to resume the activity?": "Êtes-vous certain de vouloir reprendre l'activité?",
    "Are you sure you want to delete this candidate?": "Êtes-vous certain de vouloir supprimer ce candidat?",
    "Are you sure you want to resume this prospect?": "Êtes-vous certain de vouloir reprendre le prospect?",
    Avail: "Disp",
    Availability: "Disponibilité",
    Available: "Disponibles",
    "Available On": "Disponible le",
    "Available Prospects": "Prospects disponibles",
    "Back to Dashboard": "Retour au tableau de bord",
    Birthday: "Date de naissance",
    Board: "Tableau",
    "Break Time": "Temps d'arrêt",
    Bulletins: "Bulletins",
    Call: "Appel",
    Calls: "Appels",
    "Call List": "Liste d'appels",
    Cancel: "Annuler",
    "Cancel Activity": "Annuler l'activité",
    "Cancel Last Step": "Annuler la dernière étape",
    "Cancel Prospect": "Annuler le prospect",
    Canceled: "Annulé",
    Candidate: "Candidat",
    Candidates: "Candidats",
    Category: "Catégorie",
    Change: "Changer",
    "Change Avatar": "Changer l'avatar",
    "Change Email": "Changer le courriel",
    "Change Password": "Changer le mot de passe",
    "Change Photo": "Changer la photo",
    "Change Successful": "Changement réussie",
    City: "Ville",
    Client: "Client",
    Clients: "Clients",
    Clone: "Cloner",
    "Clone Activity": "Cloner l'activité",
    "Clone Successful": "Clone réussi",
    Close: "Fermer",
    Code: "Code",
    Comments: "Commentaires",
    Commission: "Commission",
    Completed: "Complété",
    Confirm: "Confirmer",
    "Confirm Password": "Confirmer le mot de passe",
    Connection: "Connexion",
    Consent: "Consentement",
    Contact: "Contact",
    Content: "Contenu",
    Country: "Pays",
    "Created On": "Créé le",
    Dashboard: "Tableau de bord",
    Day: "Jour",
    Delete: "Supprimer",
    "Delete Advisor": "Supprimer le conseiller",
    "Delete Candidate": "Supprimer le candidat",
    Directors: "Directeurs",
    Director: "Directeur",
    "Does the appointment took place?": "Est-ce que le rendez-vous a eu lieu?",
    Edit: "Modifier",
    "Edit Account": "Modifier le compte",
    "Edit Activity": "Modifier l'activité",
    "Edit Advisor": "Modifier le conseiller",
    "Edit Avatar": "Modifier la photo",
    "Edit Client": "Modifier le client",
    "Edit Object": "Modifier l'objet",
    "Edit Office": "Modifier l'agence",
    "Edit Option": "Modifier l'option",
    "Edit Option List": "Modifier la liste d'option",
    "Edit Password": "Modifier le mot de passe",
    "Edit Profile": "Modifier le profil",
    "Edit Prospect": "Modifier le prospect",
    "Edit Role": "Modifier le rôle",
    Email: "Courriel",
    "Ends On": "Jusqu'en",
    English: "Anglais",
    "Enter a password": "Entrer un mot de passe",
    Evening: "Soir",
    Excellence: "Excellence",
    Excluded: "Exclus",
    "Excluded Phones": "Téléphones exclus",
    Firstname: "Prénom",
    Form: "Formulaire",
    French: "Français",
    Grade: "Classe",
    "Grade Distribution": "Distribution des classes",
    Grades: "Classes",
    "Has Consent": "Consentement obtenu",
    History: "Historique",
    Home: "Accueil",
    IAAH: "IAAH",
    ID: "ID",
    "Insert at least one phone number": "Ajoutez au moins un numéro de téléphone",
    Label: "Libellé",
    "Last Called On": "Dernier contact",
    "last time on": "dernière fois le",
    Lastname: "Nom",
    Latest: "Nouveau",
    List: "Liste",
    Log: "Activité",
    Login: "Connexion",
    "Login Successful": "Connexion réussie",
    Logout: "Déconnexion",
    Logs: "Activités",
    "Lost your password?": "Perdu votre mot de passe?",
    Manager: "Administrateur",
    Managers: "Administrateurs",
    "Market Analysis": "Analyse de marché",
    Message: "Message",
    Mobile: "Cellulaire",
    Name: "Nom",
    "Network Connection Error": "Erreur de connexion au réseau",
    New: "Nouveau",
    "New Account": "Nouveau compte",
    "New Activity": "Nouvelle activité",
    "New Client": "Nouveau client",
    "New Object": "Nouvel objet",
    "New Office": "Nouvelle agence",
    "New Option": "Nouvelle option",
    "New Option List": "Nouvelle liste d'option",
    "New Prospect": "Nouveau prospect",
    "New Prospects": "Nouveaux prospects",
    "New Role": "Nouveau rôle",
    No: "Non",
    None: "Aucun",
    "Not Found!": "Introuvable!",
    Note: "Note",
    Notes: "Notes",
    Number: "Numéro",
    Office: "Agence",
    Offices: "Agences",
    "Office Director": "Directeur d'agence",
    "Office Directors": "Directeurs d'agence",
    Option: "Option",
    Options: "Options",
    "Option List": "Liste d'options",
    "Option Lists": "Listes d'options",
    Optional: "Optionnel",
    "Organizational Chart": "Organigramme",
    Others: "Autres",
    Outdated: "Désuets",
    Owner: "Propriétaire",
    PAC: "PAC",
    "Page Not Found": "Page non trouvée",
    Parent: "Parent",
    Parents: "Parents",
    Partner: "Conjoint(e)",
    "Partner of": "Conjoint(e) de",
    Password: "Mot de passe",
    "Pause Activity": "Suspendre l'activité",
    Pending: "En attente",
    Permissions: "Permissions",
    Phone: "Téléphone",
    "Phone Session": "Séance téléphonique",
    Photo: "Photo",
    Planifications: "Travail conjoint",
    Planned: "Planifié",
    "Planned Activities": "Activités planifiées",
    Position: "Position",
    "Predicted Date": "Date prévue",
    Print: "Imprimer",
    Private: "Privé",
    Profile: "Profil",
    Prospect: "Prospect",
    Prospects: "Prospects",
    "Prospect Consent": "Consentement du prospect",
    "Prospect ID": "Prospect ID",
    Province: "Province",
    "Publish On": "Publier le",
    Quarter: "Trimestre",
    Quarterly: "Trimestriel",
    Ratios: "Ratios",
    Reference: "Référence",
    Referent: "Référent",
    "Referred by": "Référé par",
    RefID: "Identifiant",
    RefType: "RefType",
    Reload: "Recharger",
    "Remote IP": "IP Distant",
    Remove: "Retirer",
    "Remove From Call List": "Retirer de la liste d'appels",
    Reset: "Réinitialiser",
    "Reset your password": "Réinitialiser votre mot de passe",
    Result: "Résultat",
    Resume: "Reprendre",
    "Resume Activity": "Reprendre l'activité",
    "Resume Prospect": "Reprendre le prospect",
    "Resume Successful": "La reprise a réussi",
    Role: "Rôle",
    Roles: "Rôles",
    "Sales Director": "Directeur des ventes",
    "Sales Directors": "Directeurs des ventes",
    "Sales directors did not provide any data": "Les directeurs des ventes n'ont fourni aucune donnée",
    "Sales Interview": "Entrevue de vente",
    Schedule: "Fixer",
    Scheduled: "Fixé",
    "Schedule Activity": "Fixer l'activité",
    "Scheduling Successful": "Planification réussie",
    Schooling: "Scolarité",
    Search: "Recherche",
    Seen: "vu",
    "Select...": "Choisir...",
    "Select an Account": "Choisir un compte",
    Sell: "Vendre",
    "Sell Activity": "Vendre l'activité",
    Send: "Envoyer",
    "Set Candidate": "Modifier le candidate",
    Sold: "Vendu",
    Source: "Source",
    Sources: "Sources",
    Staffing: "Recrutement",
    "Starts On": "À partir de",
    State: "État",
    States: "États",
    Status: "Statut",
    Step: "Étape",
    "Step Revert Successful": "Annulation de l'étape réussi",
    "Step Revert Failed": "Annulation de l'étape échouée",
    Steps: "Étapes",
    Student: "Étudiant",
    Students: "Étudiants",
    "Success Finder": "Success Finder",
    Supervisor: "Surintendant",
    Supervisors: "Surintendants",
    System: "Système",
    Targeted: "Ciblé",
    "The week you are looking for does not exist!": "La semaine que vous recherchez n'existe pas!",
    "There is no statistics available for this period": "Il n'y a pas de statistiques disponibles pour cette période",
    "Times Called": "Fois contacté",
    "This phone number is part of the national do not call list":
      "Ce numéro est dans la liste nationale des numéros de téléphones exclus (LNNTE)",
    Token: "Token",
    Total: "Total",
    "Total Commission": "Commission",
    "Total Completed": "Complété",
    "Total Planned": "Planifié",
    "Total PAC": "PAC",
    "Total IAAH": "IAAH",
    "Total Excellence": "Excellence",
    "Total Reference": "Référence",
    Translations: "Traductions",
    Update: "Modifier",
    "Update Available": "Mise à jour disponible",
    "Update Avatar": "Modifier l'avatar",
    "Update Email": "Modifier le courriel",
    "Update Password": "Modifier le mot de passe",
    "Update Failed": "Modification échouée",
    "Update Successful": "Modification réussie",
    "Updated On": "Modifié le",
    Updating: "En cours",
    Upgrade: "Mettre à jour",
    User: "Utilisateur",
    Users: "Utilisateurs",
    "Using Back Button is not recommended!": "L'utilisation du bouton de retour en arrière n'est pas recommandé!",
    Video: "Vidéo",
    Videos: "Vidéos",
    View: "Voir",
    "View Activity": "Voir l'activité",
    Week: "Semaine",
    "Weekly Activities": "Activités par semaine",
    "Weekly Average": "Moyenne hebdomadaire",
    "Which Step?": "Quelle étape?",
    "Would you like to add this week to your planning?": "Souhaitez-vous ajouter cette semaine à votre planification?",
    Yes: "Oui",
    "You are already logged in": "Vous êtes déjà connecté",
    "You did not provide any data": "Vous n'avez fourni aucune données",
    "You've been logged in!": "Vous êtes connecté!",
    "You've been logged out": "Vous avez été déconnecté",
    "Your password has been changed": "Votre mot de passe a été modifié",
    "Your password has been resetted": "Votre mot de passe a été réinitialisé",
    "Your request has been accepted": "Votre demande a été acceptée",
    "Your token is valid!": "Votre jeton est valide!",
    "Zip Code": "Code postal",
    // Datatables
    "No data available in table": "Aucune donnée disponible dans le tableau",
    "Showing _START_ to _END_ of _TOTAL_ entries": "Affichage de l'élément _START_ à _END_ sur _TOTAL_ éléments",
    "Showing 0 to 0 of 0 entries": "Affichage de l'élément 0 à 0 sur 0 éléments",
    "(filtered from _MAX_ total entries)": "(filtré de _MAX_ éléments au total)",
    "Show _MENU_ entries": "Afficher _MENU_ éléments",
    Loading: "Chargement en cours",
    Processing: "Traitement en cours",
    "No matching records found": "Aucun élément à afficher",
    First: "Premier",
    Last: "Dernier",
    Next: "Suivant",
    Previous: "Précédent",
    ": activate to sort column ascending": ": activer pour trier la colonne par ordre croissant",
    ": activate to sort column descending": ": activer pour trier la colonne par ordre décroissant",
    //
    DATE_ON: "Le",
    DATE_AT: "À",
    DATE_BY: "Par",
    ERROR_404_TEXT: "Nous sommes désolé, la page que vous cherchez n'a pas été trouvée.",
    ERROR_NETWORK_TEXT:
      "Nous sommes désolé, nous avons été incapable de se connecter au réseau. Veuillez recharger où réessayer plus tard.",
    ERROR_UPDATE_TEXT:
      "Une nouvelle version de prospectr est disponible. Pour mettre à jour votre navigateur avec la dernière version, veuillez cliquer sur Mettre à jour. Merci.",
    PROSPECT_CONSENT_TEXT:
      "En rendant disponible un prospect des listes nationale et interne de numéros de télécommunication exclus (LNNTE/LINTE), vous déclarez avoir obtenu le «Consentement exprès» du prospect tel que définit dans les règles et responsabilités du CRTC.",
  },
};

module.exports = messages;
