import React from "react";
import { Router } from "react-router";
import { Panel } from "react-bootstrap";
import _ from "lodash";

import reactor from "../../reactor";
import i18n from "../../modules/i18n";
import activities from "../../modules/activities";
import Page from "../base/Page";
import Content from "../base/Content";
import DataTables from "../DataTables";
import Header from "./Header";
import AddActivityNote from "./AddActivityNote";
import CancelActivity from "./CancelActivity";
import SellActivity from "./SellActivity";
import Activity from "./Activity";

let getIntlMessage = i18n.messages.getIntlMessage;

var Activities = React.createClass({
  mixins: [reactor.ReactMixin],

  getInitialState() {
    return {
      showAddProspectNote: false,
      showActivity: false,
      activityId: null,
      prospectId: null,
      doReload: false,
    };
  },

  getDataBindings() {
    return {
      setActivitiesReload: activities.getters.setActivitiesReloadGetter,
    };
  },

  componentWillReceiveProps(props) {
    if (props.profile !== this.props.profile && !_.isEmpty(props.profile)) {
      this.setState({
        doReload: true,
      });
    }
  },

  setColumns() {
    return [
      { data: "id", name: getIntlMessage("ID"), visible: false, searchable: false, orderable: false },
      { data: "state_name", name: getIntlMessage("State") },
      { data: "step_name", name: getIntlMessage("Step") },
      { data: "prospect_firstname", name: getIntlMessage("Firstname") },
      { data: "prospect_lastname", name: getIntlMessage("Lastname") },
      { data: "created_on", name: getIntlMessage("Created On"), filter: "datetime" },
      { data: "updated_on", name: getIntlMessage("Updated On"), filter: "datetime" },
      { data: null, filter: "actions", searchable: false, orderable: false },
    ];
  },

  setActions() {
    return [{ name: getIntlMessage("View"), href: "" }];
  },

  mDataProps() {
    var props = [];
    _.forEach(this.setColumns(), (n, key) => {
      if (key.data !== null) {
        props.push(n.data);
      }
    });
    return props;
  },

  onShowAddProspectNote(id) {
    this.setState({
      showAddProspectNote: true,
      prospectId: id,
    });
  },

  onShowActivity(id) {
    this.setState({
      showActivity: true,
      activityId: id,
    });
  },

  onHideAddProspectNote() {
    this.setState({
      showAddProspectNote: false,
    });
  },

  onHideActivity() {
    this.setState({
      showActivity: false,
    });
  },

  render() {
    var doReload = this.state.doReload || this.state.setActivitiesReload;
    return (
      <Page>
        <Header {...this.props} />
        <Content className="activities">
          <Panel>
            <DataTables
              domId={"activities-datatables"}
              ajaxSource={process.env.__APIURL__ + "/activities/datatables"}
              stateSave={true}
              responsive={true}
              columns={this.setColumns()}
              actions={this.setActions()}
              doReload={doReload}
              {...this.props}
            />
          </Panel>
        </Content>
        <AddProspectNote
          show={this.state.showAddProspectNote}
          onHide={this.onHideAddProspectNote}
          prospectId={this.state.prospectId}
          {...this.props}
        />
        <Activity
          show={this.state.showActivity}
          onHide={this.onHideActivity}
          activityId={this.state.activityId}
          onShowAddNoteModal={this.onShowAddActivityNote}
          {...this.props}
        />
      </Page>
    );
  },
});

export default Activities;
