import React, { Component, PropTypes } from "react";
import { Modal, Button } from "react-bootstrap";

import i18n from "../../modules/i18n";
import planifications from "../../modules/planifications";

let getIntlMessage = i18n.messages.getIntlMessage;

export default class DeletePlanificationAdvisor extends Component {
  static propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func,
    periodId: PropTypes.number,
    advisorId: PropTypes.number,
  };

  static defaultProps = {
    periodId: null,
    advisorId: null,
  };

  onDelete = (event) => {
    planifications.actions.deletePlanificationAdvisorStart(true);
    planifications.actions.deletePlanificationAdvisor(this.props.periodId, this.props.advisorId);
    this.props.onHide();
  };

  render() {
    return (
      <Modal
        bsSize="medium"
        className="hmodal-danger"
        show={this.props.show}
        onHide={this.props.onHide}
        {...this.props}
      >
        <div className="color-line" />
        <Modal.Header>
          <Modal.Title>{getIntlMessage("Delete Advisor")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{getIntlMessage("Are you sure you want to delete this advisor?")}</Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onHide}>{getIntlMessage("Close")}</Button>
          <Button name="delete" bsStyle="danger" onClick={this.onDelete}>
            {getIntlMessage("Delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
