import reactor from "../../reactor";

export const notifierGetter = ["notifier"];

export const hasNotificationToPost = [
  ["notifier"],
  (notifier) => {
    return notifier;
  },
];

reactor.observe(exports.hasNotificationToPost, (hasNotif) => {
  if (hasNotif) {
    var notifier = reactor.evaluate(["notifier"]).toJS();
    notifier.forEach((value) => {
      Messenger().post(value);
    });
  }
});
