import { Store, toImmutable } from "nuclear-js";
import _ from "lodash";

import actionTypes from "./actionTypes";

const defaults = {
  type: "info",
  showCloseButton: true,
  hideAfter: 5,
};

export default new Store({
  getInitialState() {
    return toImmutable([]);
  },

  initialize() {
    this.on(actionTypes.NOTIFY, onNotify);
  },
});

function onNotify(state, { data }) {
  var notif = _.clone(data);
  notif["timestamp"] = new Date().getTime();
  var notifier = toImmutable([_.extend(defaults, notif)]);
  return state.merge(notifier);
}
