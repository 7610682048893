import React, { Component, PropTypes } from "react";
import { connect } from "nuclear-js-react-addons";
import Sidebar from "react-sidebar";
import _ from "lodash";

import app from "../../modules/app";
import i18n from "../../modules/i18n";
import notifier from "../../modules/notifier";
import DefaultLoader from "../Loaders";

import Header from "./Header";
import { default as AppSidebar } from "./Sidebar";

let getIntlMessage = i18n.messages.getIntlMessage;

export default
@connect((props) => ({
  sidebar: app.getters.sidebarGetter,
}))
class Manager extends Component {
  static propTypes = {
    sidebar: PropTypes.object,
  };

  onBackButton = (event) => {
    event.preventDefault();
    notifier.actions.notify({
      message: getIntlMessage("Using Back Button is not recommended!"),
      type: "error",
    });
  };

  render() {
    if (_.isEmpty(this.props.profile)) {
      return <DefaultLoader />;
    }
    const sidebarProps = this.props.sidebar.toJS();
    _.extend(sidebarProps, { sidebar: <AppSidebar {...this.props} /> });
    return (
      <Sidebar {...sidebarProps}>
        <Header {...this.props} />
        <section id="main">{this.props.children && React.cloneElement(this.props.children, this.props)}</section>
      </Sidebar>
    );
  }
}
