import { Store, toImmutable } from "nuclear-js";

import actionTypes from "./actionTypes";

let initialState = toImmutable({
  current: {
    stats: {},
    started: false,
  },
  weeks: {
    stats: [],
    started: false,
  },
  quarter: {
    stats: {},
    started: false,
  },
  aggregateWeeks: {
    stats: [],
    started: false,
  },
  aggregateQuarter: {
    stats: {},
    started: false,
  },
  officeDirectorQuarter: {
    stats: {},
    started: false,
  },
  reload: false,
});

export default new Store({
  getInitialState() {
    return initialState;
  },

  initialize() {
    this.on(actionTypes.SET_DASHBOARD_RESET, onSetDashboardReset);
    this.on(actionTypes.GET_DASHBOARD_CURRENT_SUCCESS, onGetDashboardCurrentSuccess);
    this.on(actionTypes.GET_DASHBOARD_CURRENT_FAIL, onGetDashboardCurrentFail);
    this.on(actionTypes.GET_DASHBOARD_CURRENT_START, onGetDashboardCurrentStart);
    this.on(actionTypes.GET_DASHBOARD_WEEKS_SUCCESS, onGetDashboardWeeksSuccess);
    this.on(actionTypes.GET_DASHBOARD_WEEKS_FAIL, onGetDashboardWeeksFail);
    this.on(actionTypes.GET_DASHBOARD_WEEKS_START, onGetDashboardWeeksStart);
    this.on(actionTypes.GET_DASHBOARD_QUARTER_SUCCESS, onGetDashboardQuarterSuccess);
    this.on(actionTypes.GET_DASHBOARD_QUARTER_FAIL, onGetDashboardQuarterFail);
    this.on(actionTypes.GET_DASHBOARD_QUARTER_START, onGetDashboardQuarterStart);
    this.on(actionTypes.GET_DASHBOARD_AGGREGATE_WEEKS_SUCCESS, onGetDashboardAggregateWeeksSuccess);
    this.on(actionTypes.GET_DASHBOARD_AGGREGATE_WEEKS_FAIL, onGetDashboardAggregateWeeksFail);
    this.on(actionTypes.GET_DASHBOARD_AGGREGATE_WEEKS_START, onGetDashboardAggregateWeeksStart);
    this.on(actionTypes.GET_DASHBOARD_AGGREGATE_QUARTER_SUCCESS, onGetDashboardAggregateQuarterSuccess);
    this.on(actionTypes.GET_DASHBOARD_AGGREGATE_QUARTER_FAIL, onGetDashboardAggregateQuarterFail);
    this.on(actionTypes.GET_DASHBOARD_AGGREGATE_QUARTER_START, onGetDashboardAggregateQuarterStart);
    this.on(actionTypes.GET_DASHBOARD_OFFICE_DIRECTOR_QUARTER_SUCCESS, onGetDashboardOfficeDirectorQuarterSuccess);
    this.on(actionTypes.GET_DASHBOARD_OFFICE_DIRECTOR_QUARTER_FAIL, onGetDashboardOfficeDirectorQuarterFail);
    this.on(actionTypes.GET_DASHBOARD_OFFICE_DIRECTOR_QUARTER_START, onGetDashboardOfficeDirectorQuarterStart);
    this.on(actionTypes.SET_DASHBOARD_RELOAD, onSetDashboardReload);
  },
});

function onSetDashboardReset(state) {
  return initialState;
}

function onGetDashboardCurrentSuccess(state, { data }) {
  return state.setIn(["current", "stats"], toImmutable(data.stats));
}

function onGetDashboardCurrentFail(state, { data }) {
  return state.setIn(["current", "stats"], toImmutable({}));
}

function onGetDashboardCurrentStart(state, { data }) {
  return state.setIn(["current", "started"], data);
}

function onGetDashboardWeeksSuccess(state, { data }) {
  return state.setIn(["weeks", "stats"], toImmutable(data.stats));
}

function onGetDashboardWeeksFail(state, { data }) {
  return state.setIn(["weeks", "stats"], toImmutable({}));
}

function onGetDashboardWeeksStart(state, { data }) {
  return state.setIn(["weeks", "started"], data);
}

function onGetDashboardQuarterSuccess(state, { data }) {
  return state.setIn(["quarter", "stats"], toImmutable(data.stats));
}

function onGetDashboardQuarterFail(state, { data }) {
  return state.setIn(["quarter", "stats"], toImmutable({}));
}

function onGetDashboardQuarterStart(state, { data }) {
  return state.setIn(["quarter", "started"], data);
}

function onGetDashboardAggregateWeeksSuccess(state, { data }) {
  return state.setIn(["aggregateWeeks", "stats"], toImmutable(data.stats));
}

function onGetDashboardAggregateWeeksFail(state, { data }) {
  return state.setIn(["aggregateWeeks", "stats"], toImmutable({}));
}

function onGetDashboardAggregateWeeksStart(state, { data }) {
  return state.setIn(["aggregateWeeks", "started"], data);
}

function onGetDashboardAggregateQuarterSuccess(state, { data }) {
  return state.setIn(["aggregateQuarter", "stats"], toImmutable(data.stats));
}

function onGetDashboardAggregateQuarterFail(state, { data }) {
  return state.setIn(["aggregateQuarter", "stats"], toImmutable({}));
}

function onGetDashboardAggregateQuarterStart(state, { data }) {
  return state.setIn(["aggregateQuarter", "started"], data);
}

function onGetDashboardOfficeDirectorQuarterSuccess(state, { data }) {
  return state.setIn(["officeDirectorQuarter", "stats"], toImmutable(data.stats));
}

function onGetDashboardOfficeDirectorQuarterFail(state, { data }) {
  return state.setIn(["officeDirectorQuarter", "stats"], toImmutable({}));
}

function onGetDashboardOfficeDirectorQuarterStart(state, { data }) {
  return state.setIn(["officeDirectorQuarter", "started"], data);
}

function onSetDashboardReload(state, { data }) {
  return state.set("reload", data);
}
