import React from "react";
import { Row, Col } from "react-bootstrap";

import i18n from "../i18n";

let getIntlMessage = i18n.messages.getIntlMessage;

export function CancelActivityLayout(locals) {
  let inputs = locals.inputs;
  return (
    <div>
      <Row>
        <h4 className="font-light">{getIntlMessage("Does the appointment took place?")}</h4>
      </Row>
      <Row>
        <Col xs={12}>{inputs.is_current_step_seen}</Col>
      </Row>
    </div>
  );
}

export var SellActivityLayout = CancelActivityLayout;

export function PauseActivityLayout(locals) {
  let inputs = locals.inputs;
  return (
    <div>
      <Row>
        <h4 className="font-light">{getIntlMessage("Are you sure you want to pause the activity?")}</h4>
      </Row>
      <Row>
        <Col xs={12}>{inputs.is_pausing_activity}</Col>
      </Row>
    </div>
  );
}

export function ResumeActivityLayout(locals) {
  let inputs = locals.inputs;
  return (
    <div>
      <Row>
        <h4 className="font-light">{getIntlMessage("Are you sure you want to resume the activity?")}</h4>
      </Row>
      <Row>
        <Col xs={12}>{inputs.is_resuming_activity}</Col>
      </Row>
    </div>
  );
}

export function ScheduleActivityLayout(locals) {
  let inputs = locals.inputs;
  return (
    <div>
      <Row>
        <h4 className="font-light">{getIntlMessage("Predicted Date")}</h4>
      </Row>
      <Row>
        <Col xs={12}>{inputs.starts_on}</Col>
      </Row>
    </div>
  );
}

export function CloneActivityLayout(locals) {
  let inputs = locals.inputs;
  return (
    <div>
      <Row>
        <h4 className="font-light">{getIntlMessage("Which Step?")}</h4>
      </Row>
      <Row>
        <Col xs={12}>{inputs.step}</Col>
      </Row>
    </div>
  );
}
