import { Store, toImmutable } from "nuclear-js";

import forms from "../forms";
import i18n from "../i18n";
import actionTypes from "./actionTypes";
import * as schemas from "./schemas";

let getIntlMessage = i18n.messages.getIntlMessage;

let addRoleFormDefaults = {
  type: schemas.AddRoleSchema,
  options: schemas.AddRoleSchemaOptions,
  feedback: {
    type: "info",
    messages: [],
  },
  started: false,
};

let setRoleFormDefaults = {
  type: schemas.SetRoleSchema,
  options: schemas.SetRoleSchemaOptions,
  feedback: {
    type: "info",
    messages: [],
  },
  started: false,
};

let initialState = toImmutable({
  role: {},
  addRoleForm: addRoleFormDefaults,
  setRoleForm: setRoleFormDefaults,
  setRolesReload: false,
});

export default new Store({
  getInitialState() {
    return initialState;
  },

  initialize() {
    this.on(actionTypes.SET_ROLE_RESET, onSetRoleReset);
    this.on(actionTypes.GET_ROLE_SUCCESS, onGetRoleSuccess);
    this.on(actionTypes.GET_ROLE_FAIL, onGetRoleFail);
    this.on(actionTypes.ADD_ROLE_FORM, onAddRoleForm);
    this.on(actionTypes.ADD_ROLE_START, onAddRoleStart);
    this.on(actionTypes.ADD_ROLE_SUCCESS, onAddRoleSuccess);
    this.on(actionTypes.ADD_ROLE_FAIL, onAddRoleFail);
    this.on(actionTypes.SET_ROLE_FORM, onSetRoleForm);
    this.on(actionTypes.SET_ROLE_START, onSetRoleStart);
    this.on(actionTypes.SET_ROLE_SUCCESS, onSetRoleSuccess);
    this.on(actionTypes.SET_ROLE_FAIL, onSetRoleFail);
    this.on(actionTypes.SET_ROLES_RELOAD, onSetRolesReload);
    this.on(actionTypes.REM_ROLE, onRemRole);
  },
});

function onSetRoleReset(state) {
  return initialState;
}

function onGetRoleSuccess(state, { data }) {
  return state.set("role", toImmutable(data.role));
}

function onGetRoleFail(state, { data }) {
  return state.set("role", toImmutable({}));
}

function onAddRoleForm(state) {
  return state.set("addRoleForm", toImmutable(addRoleFormDefaults));
}

function onAddRoleStart(state, { data }) {
  return state.setIn(["addRoleForm", "started"], data);
}

function onAddRoleSuccess(state, { data }) {
  let feedback = {
    type: "success",
    messages: [getIntlMessage("Add Successful")],
  };
  let options = addRoleFormDefaults.options;
  return state
    .setIn(["addRoleForm", "feedback"], toImmutable(feedback))
    .setIn(["addRoleForm", "options"], toImmutable(options));
}

function onAddRoleFail(state, { data }) {
  let feedback = forms.utils.setFormFeedback(data);
  let options = forms.utils.setFormOptions(state.getIn(["addRoleForm", "options"]).toJS(), data);
  return state
    .setIn(["addRoleForm", "feedback"], toImmutable(feedback))
    .setIn(["addRoleForm", "options"], toImmutable(options));
}

function onSetRoleForm(state) {
  return state;
}

function onSetRoleStart(state, { data }) {
  return state.setIn(["setRoleForm", "started"], data);
}

function onSetRoleSuccess(state, { data }) {
  return state.set("role", toImmutable(data.role)).set("setRoleForm", toImmutable(setRoleFormDefaults));
}

function onSetRoleFail(state, { data }) {
  let feedback = forms.utils.setFormFeedback(data);
  let options = forms.utils.setFormOptions(state.getIn(["setRoleForm", "options"]).toJS(), data);
  return state
    .setIn(["setRoleForm", "feedback"], toImmutable(feedback))
    .setIn(["setRoleForm", "options"], toImmutable(options));
}

function onSetRolesReload(state, { data }) {
  return state.set("setRolesReload", toImmutable(data));
}

function onRemRole(state) {
  return state.set("role", toImmutable({}));
}
