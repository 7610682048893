import t from "tcomb-form";

import i18n from "../i18n";

let getIntlMessage = i18n.messages.getIntlMessage;

export var SetProfileSchema = t.struct({
  firstname: t.Str,
  lastname: t.Str,
  email: t.Str,
});

export var SetProfilePasswordSchema = t.struct({
  password: t.Str,
  confirm_password: t.Str,
});

export var SetProfileSchemaOptions = {
  config: {
    horizontal: {
      lg: [4, 8],
      md: [4, 8],
    },
  },
  fields: {
    firstname: {
      type: "text",
      label: getIntlMessage("Firstname"),
    },
    lastname: {
      type: "text",
      label: getIntlMessage("Lastname"),
    },
    email: {
      type: "text",
      label: getIntlMessage("Email"),
    },
  },
};

export var SetProfilePasswordSchemaOptions = {
  config: {
    horizontal: {
      lg: [4, 8],
      md: [4, 8],
    },
  },
  fields: {
    password: {
      type: "password",
      label: getIntlMessage("Password"),
    },
    confirm_password: {
      type: "password",
      label: getIntlMessage("Confirm Password"),
    },
  },
};
