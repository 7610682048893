import React, { Component, PropTypes } from "react";
import { Alert, Row, Col } from "react-bootstrap";
import moment from "moment";
import _ from "lodash";

import i18n from "../../modules/i18n";
import ProspectStats from "./ProspectStats";
import ActivityStats from "./ActivityStats";
import QuarterlyStats from "./QuarterlyStats";
import WeekSelector from "./WeekSelector";

let getIntlMessage = i18n.messages.getIntlMessage;

export default class AdvisorStats extends Component {
  static propTypes = {
    account: PropTypes.object,
    current: PropTypes.object,
    weeks: PropTypes.object,
    quarter: PropTypes.object,
  };

  static defaultProps = {
    account: {},
    current: {},
    weeks: {},
    quarter: {},
  };

  getGradeStats = (stats) => {
    let grades = stats.map((item, index) => {
      return {
        value: item[1],
        label: item[0],
      };
    });
    return grades;
  };

  getPreviousProspectStats = (stats, value) => {
    let prospects = stats.map((item, index) => {
      return _.get(item, 'prospects["' + value + '"]') || 0;
    });
    return prospects.reverse();
  };

  getPreviousActivityStats = (stats, value) => {
    let activities = stats.map((item, index) => {
      return _.get(item, 'activities["' + value + '"]') || 0;
    });
    return activities.reverse();
  };

  getPreviousPlannedStats = (stats, value) => {
    let scheduled = stats.map((item, index) => {
      return _.get(item, 'scheduled["' + value + '"]') || 0;
    });
    return scheduled.reverse();
  };

  getScheduledActivityStats = (stats, activity) => {
    let scheduling = [];
    _([1, 2, 3, 4]).forEach((v) => {
      let monday = moment().day(1 + 7 * v);
      let startDate = monday.format("YYYY-MM-DD");
      let week = _.find(stats, { start_date: startDate });
      let value = 0;
      if (week) {
        value = week.scheduled[activity];
      } else {
        value = {
          "sales-interview-scheduled": 0,
          "break-time-scheduled": 0,
          "market-analysis-scheduled": 0,
        };
      }
      scheduling.push({
        start_date: startDate,
        value: value,
      });
    });
    return scheduling;
  };

  renderActivities = (current, weeks) => {
    if (weeks === undefined || _.isEmpty(weeks)) {
      return <div></div>;
    }
    if (weeks.stats.length === 0) {
      return (
        <Alert bsStyle="warning">
          <h3>{getIntlMessage("There is no statistics available for this period")}</h3>
        </Alert>
      );
    }
    let weekStart = moment().day("Monday");
    let selectedWeekStart = moment(weeks.stats[0].start_date).day("Monday");
    let selectedWeek = _.get(current, "stats.current_week");
    if (!weekStart.isSame(selectedWeekStart, "day")) {
      selectedWeek = weeks.stats[0];
    }
    return (
      <Row>
        <Col md={12} lg={4}>
          <Row>
            <Col md={6}>
              <ActivityStats
                title={getIntlMessage("New Prospects")}
                value1Title={getIntlMessage("Total")}
                value1={_.get(selectedWeek, "prospects.total") || 0}
                prevValues1={this.getPreviousProspectStats(weeks.stats, "total")}
                value2Title={getIntlMessage("Available")}
                value2={_.get(selectedWeek, "prospects.available") || 0}
                prevValues2={this.getPreviousProspectStats(weeks.stats, "available")}
                numValues={2}
                iconClass="pe-7s-users"
              />
            </Col>
            <Col md={6}>
              <ActivityStats
                title={getIntlMessage("Calls")}
                value1Title={getIntlMessage("Total")}
                value1={_.get(selectedWeek, "calls.total") || 0}
                prevValues1={this.getPreviousProspectStats(weeks.stats, "total")}
                value2Title={getIntlMessage("Prospects")}
                value2={_.get(selectedWeek, "calls.prospects") || 0}
                prevValues2={this.getPreviousProspectStats(weeks.stats, "prospects")}
                numValues={2}
                iconClass="pe-7s-phone"
              />
            </Col>
          </Row>
        </Col>

        <Col md={12} lg={8}>
          <Row>
            <Col md={4}>
              <ActivityStats
                title={getIntlMessage("Market Analysis")}
                value1Title={getIntlMessage("Scheduled")}
                value1={_.get(selectedWeek, 'activities["market-analysis-scheduled"]') || 0}
                prevValues1={this.getPreviousActivityStats(weeks.stats, "market-analysis-scheduled")}
                value2Title={getIntlMessage("Seen")}
                value2={_.get(selectedWeek, 'activities["market-analysis-seen"]') || 0}
                prevValues2={this.getPreviousActivityStats(weeks.stats, "market-analysis-seen")}
                value3Title={getIntlMessage("Planned")}
                value3={_.get(selectedWeek, 'scheduled["market-analysis-scheduled"]') || 0}
                prevValues3={this.getPreviousPlannedStats(weeks.stats, "market-analysis-scheduled")}
                numValues={3}
                iconClass="pe-7s-graph1"
              />
            </Col>
            <Col md={4}>
              <ActivityStats
                title={getIntlMessage("Break Time")}
                value1Title={getIntlMessage("Scheduled")}
                value1={_.get(selectedWeek, 'activities["break-time-scheduled"]') || 0}
                prevValues1={this.getPreviousActivityStats(weeks.stats, "break-time-scheduled")}
                value2Title={getIntlMessage("Seen")}
                value2={_.get(selectedWeek, 'activities["break-time-seen"]') || 0}
                prevValues2={this.getPreviousActivityStats(weeks.stats, "break-time-seen")}
                value3Title={getIntlMessage("Planned")}
                value3={_.get(selectedWeek, 'scheduled["break-time-scheduled"]') || 0}
                prevValues3={this.getPreviousPlannedStats(weeks.stats, "break-time-scheduled")}
                numValues={3}
                iconClass="pe-7s-coffee"
              />
            </Col>
            <Col md={4}>
              <ActivityStats
                title={getIntlMessage("Sales Interview")}
                value1Title={getIntlMessage("Scheduled")}
                value1={_.get(selectedWeek, 'activities["sales-interview-scheduled"]') || 0}
                prevValues1={this.getPreviousActivityStats(weeks.stats, "sales-interview-scheduled")}
                value2Title={getIntlMessage("Seen")}
                value2={_.get(selectedWeek, 'activities["sales-interview-seen"]') || 0}
                prevValues2={this.getPreviousActivityStats(weeks.stats, "sales-interview-seen")}
                value3Title={getIntlMessage("Planned")}
                value3={_.get(selectedWeek, 'scheduled["sales-interview-scheduled"]') || 0}
                prevValues3={this.getPreviousPlannedStats(weeks.stats, "sales-interview-scheduled")}
                numValues={3}
                iconClass="pe-7s-cup"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  render() {
    let account = this.props.account;
    let current = this.props.current;
    let weeks = this.props.weeks;
    let quarter = this.props.quarter;
    return (
      <div>
        <Row>
          <Col md={4}>
            <h2 className="stats-title">{getIntlMessage("Actual")}</h2>
            <ProspectStats stats={_.get(current, "stats.current", {})} />
          </Col>
          <Col md={8}>
            <h2 className="stats-title">
              {getIntlMessage("Quarterly")} <small>{getIntlMessage("Weekly Average")}</small>
            </h2>
            {<QuarterlyStats stats={quarter} />}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className="pull-left">
              <h2 className="stats-title">{getIntlMessage("Week")}</h2>
            </div>
            <div className="pull-right">{<WeekSelector account={account} />}</div>
          </Col>
        </Row>
        {this.renderActivities(current, weeks)}
      </div>
    );
  }
}
