import React, { Component, PropTypes } from "react";
import { connect } from "nuclear-js-react-addons";
import { Button, ButtonGroup } from "react-bootstrap";

import i18n from "../../modules/i18n";
import SubHeader from "../base/SubHeader";
import PrintCallList from "./PrintCallList";

let getIntlMessage = i18n.messages.getIntlMessage;

export default class Header extends Component {
  static propTypes = {
    ajaxParams: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      showPrintCallList: false,
    };
  }

  onShowPrintCallList = () => {
    this.setState({
      showPrintCallList: true,
    });
  };

  onHidePrintCallList = () => {
    this.setState({
      showPrintCallList: false,
    });
  };

  render() {
    return (
      <SubHeader title={getIntlMessage("Call List")}>
        <ButtonGroup>
          <Button onClick={this.onShowPrintCallList}>
            <i className="fa fa-print"></i>
          </Button>
        </ButtonGroup>
        <PrintCallList show={this.state.showPrintCallList} onHide={this.onHidePrintCallList} {...this.props} />
      </SubHeader>
    );
  }
}
