import React, { Component, PropTypes } from "react";
import { connect } from "nuclear-js-react-addons";
import { Button, ButtonGroup } from "react-bootstrap";

import app from "../../modules/app";
import i18n from "../../modules/i18n";
import SubHeader from "../base/SubHeader";

let getIntlMessage = i18n.messages.getIntlMessage;

export default
@connect((props) => ({
  account: app.getters.appAccountGetter,
}))
class Header extends Component {
  static propTypes = {
    onShowPrintDashboard: PropTypes.func,
  };

  onShowPrintDashboard = () => {
    this.props.onShowPrintDashboard();
  };

  renderPrintDashboard = () => {
    let account = this.props.account.toJS();
    let role = account.main_role_refid;
    if (role !== "sales-director") {
      return null;
    }
    return (
      <ButtonGroup>
        <Button onClick={this.onShowPrintDashboard}>
          <i className="fa fa-print"></i>
        </Button>
      </ButtonGroup>
    );
  };

  render() {
    return <SubHeader title={getIntlMessage("Dashboard")}>{this.renderPrintDashboard()}</SubHeader>;
  }
}
