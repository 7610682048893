import React, { Component, PropTypes } from "react";
import ReactDOMServer from "react-dom/server";
import IntlRelativeFormat from "intl-relativeformat";
import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import _ from "lodash";

import * as helpers from "../modules/utils/helpers";
import i18n from "../modules/i18n";

let getIntlMessage = i18n.messages.getIntlMessage;

export default class Datatables extends Component {
  static propTypes = {
    domId: PropTypes.string,
    ajaxSource: PropTypes.string,
    stateSave: PropTypes.bool,
    columns: PropTypes.array,
    order: PropTypes.array,
    actions: PropTypes.array,
    onSelectRow: PropTypes.func,
    onClickHeader: PropTypes.func,
    onAjax: PropTypes.func,
    doReload: PropTypes.bool,
    mDataProps: PropTypes.array,
  };

  static defaultProps = {
    domId: "datatables",
    ajaxSource: null,
    stateSave: false,
    responsive: true,
    columns: [],
    order: [],
    actions: [],
    onSelectRow: null,
    onClickHeader: null,
    onAjax: null,
    doReload: false,
    mDataProps: [],
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      columns: props.columns,
      datatable: {},
    };
  }

  componentWillMount() {
    let columns = [];
    _.forEach(this.props.columns, (n, key) => {
      let column = n;
      if (column.filter === "actions") {
        column.render = this.renderActions;
      }
      if (column.filter === "label") {
        column.render = this.renderLabel;
      }
      if (column.filter === "moment") {
        column.render = this.renderMoment;
      }
      if (column.filter === "datetime") {
        column.render = this.renderDatetime;
      }
      if (column.filter === "phones") {
        column.render = this.renderPhones;
      }
      columns.push(column);
    });
    this.setState(columns);
  }

  componentDidMount() {
    this.loadDatatables();
  }

  loadDatatables = () => {
    let datatable = $("#" + this.props.domId).dataTable({
      sAjaxSource: this.props.ajaxSource,
      // 'oLanguage': 'fr',
      bAutoWidth: false,
      bDestroy: true,
      bProcessing: true,
      bFilter: true,
      bServerSide: true,
      stateSave: this.props.stateSave,
      responsive: this.props.responsive,
      columns: this.state.columns,
      mDataProps: this.props.mDataProps,
      language: this.getI18N(),
      order: this.props.order,
      drawCallback: () => {
        let api = datatable.api();
        if (this.props.onShowSetModal !== null) {
          api.$("td > div > button").click((event) => {
            event.preventDefault();
            let target = event.currentTarget;
            let action = _.find(this.props.actions, _.matchesProperty("action", target.getAttribute("data-action")));
            let dataId = target.getAttribute("data-id") || null;
            let dataRelatedId = target.getAttribute("data-related-id") || null;
            if (dataId !== null && dataRelatedId !== null) {
              action.onClick(Number(dataId), Number(dataRelatedId));
            } else {
              action.onClick(Number(dataId));
            }
          });
        }
        if (this.props.onSelectRow !== null) {
          api.$("tr").click((event) => {
            let ttype = event.target.toString();
            if (ttype.includes("HTMLTableCellElement") === true) {
              let target = event.currentTarget;
              $(target).toggleClass("selected");
              this.props.onSelectRow(api.rows(".selected").data());
            }
          });
        }
        if (this.props.onAjax !== null) {
          api.on("xhr", (event) => {
            this.props.onAjax(api.ajax);
          });
        }
      },
      headerCallback: (thead) => {
        let api = datatable.api();
        if (this.props.onClickHeader !== null) {
          $(thead).click((event) => {
            this.props.onClickHeader(event, api);
          });
        }
      },
      fnServerData: (sSource, aoData, fnCallback, oSettings) => {
        oSettings.jqXHR = $.ajax({
          dataType: "json",
          type: "GET",
          url: sSource,
          data: aoData,
          success: fnCallback,
        });
      },
      initComplete: () => {
        let api = datatable.api();
        api.columns().every(function () {
          let that = this;
          $("input", this.footer()).on("keyup change", function () {
            if (that.search() !== this.value) {
              that.search(this.value).draw();
            }
          });
        });
      },
    });
  };

  getI18N = () => {
    return {
      emptyTable: getIntlMessage("No data available in table"),
      info: getIntlMessage("Showing _START_ to _END_ of _TOTAL_ entries"),
      infoEmpty: getIntlMessage("Showing 0 to 0 of 0 entries"),
      infoFiltered: getIntlMessage("(filtered from _MAX_ total entries)"),
      infoPostFix: "",
      thousands: ",",
      lengthMenu: getIntlMessage("Show _MENU_ entries"),
      loadingRecords: getIntlMessage("Loading"),
      processing: getIntlMessage("Processing"),
      search: getIntlMessage("Search"),
      zeroRecords: getIntlMessage("No matching records found"),
      paginate: {
        first: getIntlMessage("First"),
        last: getIntlMessage("Last"),
        next: getIntlMessage("Next"),
        previous: getIntlMessage("Previous"),
      },
      aria: {
        sortAscending: getIntlMessage(": activate to sort column ascending"),
        sortDescending: getIntlMessage(": activate to sort column descending"),
      },
    };
  };

  renderHeader = () => {
    let elems = this.props.columns.map((data, index) => {
      // if (data.visible!==false) {
      return <th key={index}>{data.name}</th>;
      // }
    });
    return (
      <thead>
        <tr>{elems}</tr>
      </thead>
    );
  };

  renderFooter = () => {
    let elems = this.props.columns.map((data, index) => {
      if (data.visible !== false) {
        let elem = <span>&nbsp;</span>;
        if (data.data !== null) {
          elem = <input type="text" className="form-control input-sm" placeholder={data.name} />;
        }
        return <td key={index}>{elem}</td>;
      }
    });
    return (
      <tfoot>
        <tr>{elems}</tr>
      </tfoot>
    );
  };

  renderLabel = (data, type, row, meta) => {
    let labels = {
      a: "success",
      active: "success",
      b: "info",
      c: "warning",
      danger: "danger",
      inactive: "danger",
      info: "info",
      pending: "warning",
      success: "success",
      warning: "warning",
    };
    let label = labels[data.toLowerCase()] || "default";
    let value = '<span class="label label-' + label + '"">' + data + "</span>";
    return value;
  };

  renderMoment = (data, type, row, meta) => {
    let rf = new IntlRelativeFormat("en");
    let value = rf.format(new Date(data));
    return value;
  };

  renderDatetime = (data, type, row, meta) => {
    // var options = {
    //   year: 'numeric',
    //   month: 'numeric',
    //   day: 'numeric',
    //   hour: 'numeric',
    //   minute: 'numeric',
    //   second: 'numeric',
    //   hour12: false
    // }
    // var df = new Intl.DateTimeFormat('fr-CA', options);
    // var value = df.format(new Date(data));
    // return value;
    // return helpers.renderMoment(data, 'fr', 'L LTS')
    if (data === null) {
      return "";
    }
    return helpers.renderMoment(data, "fr", "L");
  };

  renderPhones = (data, type, row, meta) => {
    var phones = data
      .split(", ")
      .map((phone, index) => {
        return helpers.formatPhone(phone);
      })
      .join(", ");
    return phones;
  };

  renderActionTooltip = (action) => {
    if (action.hasOwnProperty("help") && action.help !== "") {
      return <Tooltip id={"tooltip-" + _.uniqueId()}>{action.help}</Tooltip>;
    }
    return null;
  };

  renderActionIcon = (action) => {
    if (action.hasOwnProperty("icon")) {
      return <i className={action.icon}></i>;
    }
    return null;
  };

  renderActionName = (action) => {
    if (action.hasOwnProperty("name") && action.name !== "") {
      return <span>{action.name}</span>;
    }
    return null;
  };

  renderActionButtonWithTooltip = (action, btn) => {
    let tooltip = this.renderActionTooltip(action);
    if (tooltip !== null) {
      return (
        <OverlayTrigger placement="top" overlay={tooltip} delayShow={300} delayHide={150}>
          {btn}
        </OverlayTrigger>
      );
    }
    return btn;
  };

  renderActionButton = (action, row) => {
    let dataAttrs = {
      "data-action": action.action,
      "data-id": row.id,
    };
    let relatedIdSource = action.relatedIdSource || null;
    if (relatedIdSource !== null) {
      dataAttrs["data-related-id"] = row[relatedIdSource];
    }
    let BTN = (
      <Button type="button" bsSize="xs" {...dataAttrs}>
        {this.renderActionIcon(action)}
        {this.renderActionName(action)}
      </Button>
    );
    return this.renderActionButtonWithTooltip(action, BTN);
  };

  renderActions = (data, type, row, meta) => {
    let actions = this.props.actions.map((action, index) => {
      return this.renderActionButton(action, row);
    });
    let groupStyle = { width: "110px" };
    return ReactDOMServer.renderToStaticMarkup(
      <div className="btn-group btn-group-xs" role="group" style={groupStyle}>
        {actions}
      </div>
    );
  };

  render() {
    if (this.props.doReload === true) {
      this.loadDatatables();
    }
    return (
      <div className="table-responsive">
        <table className="table table-striped table-bordered" id={this.props.domId} ref={this.props.domId}>
          {this.renderHeader()}
          <tbody></tbody>
          {this.renderFooter()}
        </table>
      </div>
    );
  }
}
