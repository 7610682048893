import keyMirror from "keymirror";

export default keyMirror({
  SET_PROFILE_RESET: null,
  GET_PROFILE_START: null,
  GET_PROFILE_SUCCESS: null,
  GET_PROFILE_FAIL: null,
  SET_PROFILE_FORM: null,
  SET_PROFILE_START: null,
  SET_PROFILE_SUCCESS: null,
  SET_PROFILE_FAIL: null,
  SET_PROFILE_PASSWORD_FORM: null,
  SET_PROFILE_PASSWORD_START: null,
  SET_PROFILE_PASSWORD_SUCCESS: null,
  SET_PROFILE_PASSWORD_FAIL: null,
  SET_PROFILE_AVATAR_START: null,
  SET_PROFILE_AVATAR_SUCCESS: null,
  SET_PROFILE_AVATAR_FAIL: null,
  REM_PROFILE: null,
});
