import axios from "axios";

import reactor from "../../reactor";
import i18n from "../i18n";
import notifier from "../notifier";
import actionTypes from "./actionTypes";

let getIntlMessage = i18n.messages.getIntlMessage;

export function setOfficeReset() {
  reactor.dispatch(actionTypes.SET_OFFICE_RESET);
}

export function getOffice(id) {
  axios
    .get(process.env.__APIURL__ + "/offices/" + id)
    .then((res) => {
      reactor.dispatch(actionTypes.GET_OFFICE_SUCCESS, { data: res.data });
    })
    .catch((res) => {
      reactor.dispatch(actionTypes.GET_OFFICE_FAIL, { data: res.data });
    });
}

export function addOfficeForm() {
  reactor.dispatch(actionTypes.ADD_OFFICE_FORM);
}

export function addOfficeStart(data) {
  reactor.dispatch(actionTypes.ADD_OFFICE_START, { data });
}

export function addOffice(data) {
  axios
    .post(process.env.__APIURL__ + "/offices", data)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.ADD_OFFICE_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.ADD_OFFICE_START, { data: false });
        reactor.dispatch(actionTypes.SET_OFFICES_RELOAD, { data: true });
      });
      notifier.actions.notify({
        message: getIntlMessage("Add Successful"),
        type: "success",
      });
      reactor.dispatch(actionTypes.SET_OFFICES_RELOAD, { data: false });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.ADD_OFFICE_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.ADD_OFFICE_START, { data: false });
        reactor.dispatch(actionTypes.SET_OFFICES_RELOAD, { data: false });
      });
    });
}

export function setOfficeForm() {
  reactor.dispatch(actionTypes.SET_OFFICE_FORM);
}

export function setOfficeStart(data) {
  reactor.dispatch(actionTypes.SET_OFFICE_START, { data });
}

export function setOffice(id, data) {
  axios
    .put(process.env.__APIURL__ + "/offices/" + id, data)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_OFFICE_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.SET_OFFICE_START, { data: false });
        reactor.dispatch(actionTypes.SET_OFFICES_RELOAD, { data: true });
      });
      notifier.actions.notify({
        message: getIntlMessage("Update Successful"),
        type: "success",
      });
      reactor.dispatch(actionTypes.SET_OFFICES_RELOAD, { data: false });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_OFFICE_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.SET_OFFICE_START, { data: false });
        reactor.dispatch(actionTypes.SET_OFFICES_RELOAD, { data: false });
      });
    });
}

export function setOfficesReload(data) {
  reactor.dispatch(actionTypes.SET_OFFICES_RELOAD, { data });
}

export function remOffice() {
  reactor.dispatch(actionTypes.REM_OFFICE);
}
