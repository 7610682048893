import React, { Component, PropTypes } from "react";
import { connect } from "nuclear-js-react-addons";
import { Panel, Button, ButtonGroup } from "react-bootstrap";
import _ from "lodash";

import app from "../../modules/app";
import i18n from "../../modules/i18n";
import activities from "../../modules/activities";
import prospects from "../../modules/prospects";
import Page from "../base/Page";
import Content from "../base/Content";
import DataTables from "../DataTables";
import DefaultLoader from "../Loaders";
import Prospect from "../prospects/Prospect";
import SetProspect from "../prospects/SetProspect";
import AddProspectNote from "../prospects/AddProspectNote";
import Header from "./Header";
import CloneActivity from "./CloneActivity";

let getIntlMessage = i18n.messages.getIntlMessage;

export default
@connect((props) => ({
  account: app.getters.appAccountGetter,
  selector: app.getters.appAccountSelectorGetter,
  setActivitiesReload: activities.getters.setActivitiesReloadGetter,
}))
class SoldActivities extends Component {
  static propTypes = {
    setActivitiesReload: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      activityId: null,
      prospectId: null,
      noteCategory: null,
      showCloneActivity: false,
      showProspect: false,
      showSetProspect: false,
      showAddProspectNote: false,
      doReload: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.profile !== this.props.profile && !_.isEmpty(nextProps.profile)) {
      this.setState({
        doReload: true,
      });
    }
    let nextAccount = nextProps.account.toJS();
    if (nextProps.account !== this.props.account && !_.isEmpty(nextAccount)) {
      this.setState({
        doReload: true,
      });
    }
  }

  setColumns = () => {
    return [
      { data: "id", name: getIntlMessage("ID"), visible: false, searchable: false, orderable: false },
      { data: "prospect_fullname", name: getIntlMessage("Prospect") },
      { data: "state_name", name: getIntlMessage("State") },
      { data: "step_name", name: getIntlMessage("Step") },
      { data: "created_on", name: getIntlMessage("Created On"), filter: "datetime" },
      { data: "updated_on", name: getIntlMessage("Updated On"), filter: "datetime" },
      { data: null, filter: "actions", searchable: false, orderable: false },
    ];
  };

  setActions = () => {
    return [
      {
        help: getIntlMessage("View"),
        icon: "fa fa-eye",
        action: "onShowProspect",
        onClick: this.onShowProspect,
        relatedIdSource: "prospect_id",
      },
      {
        help: getIntlMessage("Clone"),
        icon: "fa fa-clone",
        action: "onShowCloneActivity",
        onClick: this.onShowCloneActivity,
      },
    ];
  };

  mDataProps = () => {
    let props = [];
    _.forEach(this.setColumns(), (n, key) => {
      if (key.data !== null) {
        props.push(n.data);
      }
    });
    return props;
  };

  onShowCloneActivity = (id) => {
    this.setState({
      showCloneActivity: true,
      activityId: id,
    });
  };

  onShowProspect = (id, prospectId) => {
    prospects.actions.remProspect();
    this.setState({
      showProspect: true,
      activityId: id,
      prospectId: prospectId,
    });
  };

  onShowSetProspect = (id) => {
    this.setState({
      showSetProspect: true,
      prospectId: id,
    });
  };

  onShowAddProspectNote = (id, category) => {
    this.setState({
      showAddProspectNote: true,
      prospectId: id,
      noteCategory: category,
    });
  };

  onHideCloneActivity = () => {
    this.setState({
      showCloneActivity: false,
    });
  };

  onHideProspect = () => {
    this.setState({
      showProspect: false,
    });
  };

  onHideSetProspect = () => {
    this.setState({
      showSetProspect: false,
    });
  };

  onHideAddProspectNote = () => {
    this.setState({
      showAddProspectNote: false,
    });
  };

  onAccountSelectorChange = (id, role) => {
    app.actions.getAppAccount(id);
  };

  renderDatatables = (doReload) => {
    let account = this.props.account.toJS();
    if (!account || _.isEmpty(account)) {
      return <DefaultLoader />;
    }
    return (
      <Panel>
        <DataTables
          domId={"activities-datatables"}
          ajaxSource={process.env.__APIURL__ + "/activities/datatables/sold/" + account.id}
          stateSave={true}
          responsive={true}
          columns={this.setColumns()}
          actions={this.setActions()}
          doReload={doReload}
          {...this.props}
        />
      </Panel>
    );
  };

  render() {
    let account = this.props.account.toJS();
    let selector = this.props.selector.toJS();
    let doReload = this.state.doReload || this.props.setActivitiesReload;
    return (
      <Page
        {...this.props}
        account={account}
        accounts={selector.accounts}
        onAccountSelectorChange={this.onAccountSelectorChange}
        showAccountsHeader={true}
      >
        <Header {...this.props} />
        <Content className="activities">{this.renderDatatables(doReload)}</Content>
        <CloneActivity
          show={this.state.showCloneActivity}
          onHide={this.onHideCloneActivity}
          activityId={this.state.activityId}
          {...this.props}
        />
        <Prospect
          show={this.state.showProspect}
          onHide={this.onHideProspect}
          onShowSetProspect={this.onShowSetProspect}
          onShowAddProspectNote={this.onShowAddProspectNote}
          prospectId={this.state.prospectId}
          activityId={this.state.activityId}
          {...this.props}
        />
        <SetProspect
          show={this.state.showSetProspect}
          onHide={this.onHideSetProspect}
          prospectId={this.state.prospectId}
          {...this.props}
        />
        <AddProspectNote
          show={this.state.showAddProspectNote}
          onHide={this.onHideAddProspectNote}
          prospectId={this.state.prospectId}
          noteCategory={this.state.noteCategory}
          {...this.props}
        />
      </Page>
    );
  }
}
