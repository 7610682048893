import React, { Component, PropTypes } from "react";
import { connect } from "nuclear-js-react-addons";
import { Table, Modal, Button } from "react-bootstrap";
import moment from "moment";
import _ from "lodash";

import app from "../../modules/app";
import prospects from "../../modules/prospects";
import i18n from "../../modules/i18n";
import * as helpers from "../../modules/utils/helpers";
import { DefaultLoader } from "../Loaders";

let getIntlMessage = i18n.messages.getIntlMessage;

export default
@connect((props) => ({
  account: app.getters.appAccountGetter,
  prospects: prospects.getters.callListGetter,
  reloadProspects: prospects.getters.setProspectsReloadGetter,
}))
class PrintCallList extends Component {
  static propTypes = {
    // prospects: PropTypes.array,
    reloadProspects: PropTypes.bool,
    show: PropTypes.bool,
    onHide: PropTypes.func,
    ajaxParams: PropTypes.object,
  };

  getAccountId = () => {
    let account = this.props.account.toJS();
    let profile = this.props.profile;
    if (_.isEmpty(account)) {
      return profile.id;
    } else {
      return account.id;
    }
  };

  componentWillUpdate(nextProps, nextState) {
    // let reloadProspects = nextProps.reloadProspects
    // if (reloadProspects && reloadProspects !== this.props.reloadProspects) {
    if (nextProps.show) {
      prospects.actions.getProspectsCallList(this.getAccountId(), this.props.ajaxParams);
    }
    // }
  }

  onPrint = () => {
    let head = document.getElementsByTagName("head")[0].innerHTML;
    let content = document.getElementById("prospects-print").innerHTML;
    helpers.printContent(content, head);
  };

  renderPhones = (phones) => {
    let n = phones
      .map((phone) => {
        return helpers.formatPhone(phone);
      })
      .join(", ");
    return (
      <div className="phone-number">
        <i className="fa fa-phone fa-fw"></i>
        {n}
      </div>
    );
  };

  renderNotes = (notes) => {
    if (_.isEmpty(notes)) {
      return null;
    }
    let data = notes.map((item, index) => {
      return (
        <div key={index}>
          <div className="font-light">{moment(item.created_on).format("DD/MM/YYYY")}</div>
          <div dangerouslySetInnerHTML={{ __html: item.message }} />
        </div>
      );
    });
    return (
      <div>
        <strong>Notes</strong>
        {data}
      </div>
    );
  };

  renderProspects = (data) => {
    let rows = data.map((item, index) => {
      return (
        <tr key={index}>
          <td>
            <div className="activity-detail">
              <div>
                <strong>{item.fullname}</strong>
              </div>
              {this.renderPhones(item.phone_numbers)}
              <div>
                <i className="fa fa-home fa-fw"></i>
                {[item.address, item.city].join(", ")}
              </div>
              <div>
                {item.partner && (
                  <span>
                    <i className="fa fa-venus-mars fa-fw"></i> {item.partner}{" "}
                  </span>
                )}
              </div>
              <div>
                {item.referent && (
                  <span>
                    <i className="fa fa-thumbs-o-up fa-fw"></i> {item.referent}
                  </span>
                )}
              </div>
              <div>
                {item.grade && (
                  <span>
                    <i className="fa fa-thermometer-full fa-fw"></i> {item.grade}
                  </span>
                )}
              </div>
              {item.comments && <div>{item.comments}</div>}
              {this.renderNotes(item.notes)}
            </div>
          </td>
        </tr>
      );
    });
    return rows;
  };

  renderData = (data) => {
    return (
      <div className="prospects-print" id="prospects-print">
        <Table striped condensed hover>
          <thead>
            <tr>
              <th>
                <h3>{getIntlMessage("Call List")}</h3>
              </th>
            </tr>
          </thead>
          <tbody>{this.renderProspects(data)}</tbody>
        </Table>
      </div>
    );
  };

  render() {
    let prospects = this.props.prospects.toJS();
    return (
      <Modal bsSize="medium" show={this.props.show} onHide={this.props.onHide} {...this.props}>
        <div className="color-line" />
        <Modal.Header>
          <Modal.Title>{getIntlMessage("Prospects")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.renderData(prospects)}</Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onHide}>{getIntlMessage("Close")}</Button>
          <Button name="print" bsStyle="primary" onClick={this.onPrint}>
            {getIntlMessage("Print")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
