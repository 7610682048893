import React from "react";
import { Glyphicon, Row, Col, Button, ButtonGroup } from "react-bootstrap";

export function ListLayout(locals) {
  // let inputs = locals.inputs

  function setLabel(label) {
    let value = "";
    switch (label) {
      case "Remove":
        value = <Glyphicon glyph="remove" />;
        break;
      case "Up":
        value = <Glyphicon glyph="triangle-top" />;
        break;
      case "Down":
        value = <Glyphicon glyph="triangle-bottom" />;
        break;
      default:
        value = <Glyphicon glyph="star" />;
    }
    return value;
  }

  return (
    <div>
      {locals.items.map(function (item) {
        return (
          <Row>
            <Col xs={12}>{item.input}</Col>
            <Col xs={12}>
              <ButtonGroup className="pull-right">
                {item.buttons.map(function (button) {
                  return <Button onClick={button.click}>{setLabel(button.label)}</Button>;
                })}
              </ButtonGroup>
            </Col>
          </Row>
        );
      })}
      <Button onClick={locals.add.click}>{locals.add.label}</Button>
    </div>
  );
}
