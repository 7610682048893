import React, { Component, PropTypes } from "react";
import { connect } from "nuclear-js-react-addons";

import activities from "../../modules/activities";
import i18n from "../../modules/i18n";
import FormModal from "../forms/FormModal";

let getIntlMessage = i18n.messages.getIntlMessage;
let defaultValue = {};

export default
@connect((props) => ({
  cloneActivityForm: activities.getters.cloneActivityFormGetter,
}))
class CloneActivity extends Component {
  static propTypes = {
    // cloneActivityForm: PropTypes.object,
    activityId: PropTypes.number,
  };

  static defaultProps = {
    cloneActivityForm: null,
    activityId: null,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      value: defaultValue,
    };
  }

  componentWillReceiveProps(props) {
    if (!props.show) {
      this.setState({
        value: defaultValue,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.cloneActivityForm !== prevProps.cloneActivityForm) {
      var form = this.props.cloneActivityForm.toJS();
      var prevForm = prevProps.cloneActivityForm.toJS();
      if (form.feedback.type !== prevForm.feedback.type && form.feedback.type === "success") {
        this.props.onHide();
      }
    }
  }

  onFormSubmit = (event, value) => {
    if (value) {
      this.setState({
        value: value,
      });
      activities.actions.setActivityCloneStart(true);
      activities.actions.setActivityClone(this.props.activityId, { step: value.step });
    }
  };

  onFormChange = (value) => {
    this.setState({ value });
  };

  render() {
    let form = this.props.cloneActivityForm.toJS();
    return (
      <FormModal
        form={form}
        formType="set"
        value={this.state.value}
        onFormChange={this.onFormChange}
        onFormSubmit={this.onFormSubmit}
        bsStyle="info"
        title={getIntlMessage("Clone Activity")}
        actionButtonTitle={getIntlMessage("Clone")}
        showFeedback={false}
        {...this.props}
      />
    );
  }
}
