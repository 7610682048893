import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";

import i18n from "../../modules/i18n";
import SubHeader from "../base/SubHeader";
import AddAccount from "./AddAccount";

let getIntlMessage = i18n.messages.getIntlMessage;

var Header = React.createClass({
  getInitialState() {
    return {
      showAddAccount: false,
    };
  },

  onShowAddAccount() {
    this.setState({
      showAddAccount: true,
    });
  },

  onHideAddAccount() {
    this.setState({
      showAddAccount: false,
    });
  },

  render() {
    return (
      <SubHeader title={getIntlMessage("Accounts")}>
        <ButtonGroup>
          <Button name="add-account" bsSize="small" onClick={this.onShowAddAccount}>
            {getIntlMessage("Add")}
          </Button>
        </ButtonGroup>
        <AddAccount show={this.state.showAddAccount} onHide={this.onHideAddAccount} {...this.props} />
      </SubHeader>
    );
  },
});

export default Header;
