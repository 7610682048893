import t from "tcomb-form";

import forms from "../forms";
import i18n from "../i18n";

let getIntlMessage = i18n.messages.getIntlMessage;

export var AddOptionListSchema = t.struct({
  category: t.Str,
  refid: t.maybe(t.Str),
  option_ids: t.list(t.Num),
  name_translations: forms.schemas.TranslationSchema,
});

export var SetOptionListSchema = AddOptionListSchema;

export var AddOptionListSchemaOptions = {
  config: {
    // for each of lg md sm xs you can specify the columns width
    horizontal: {
      lg: [4, 8],
      md: [4, 8],
    },
  },
  fields: {
    category: {
      type: "text",
      label: getIntlMessage("Category"),
    },
    refid: {
      type: "text",
      label: getIntlMessage("RefID"),
    },
    option_ids: {
      type: "text",
      label: getIntlMessage("Options"),
      factory: forms.widgets.KMultiSelectWidget,
      attrs: {
        culture: "fr-CH",
        placeholder: getIntlMessage("Select..."),
        dataSource: {
          transport: {
            read: {
              url: process.env.__APIURL__ + "/choices/options",
              dataType: "json",
            },
          },
          group: {
            field: "category",
          },
          schema: {
            data: "results",
          },
        },
      },
    },
    name_translations: forms.schemas.TranslationSchemaOptions,
  },
};

export var SetOptionListSchemaOptions = AddOptionListSchemaOptions;
