import axios from "axios";
import t from "tcomb-form";

import forms from "../forms";
import i18n from "../i18n";
import * as layouts from "./layouts";

let getIntlMessage = i18n.messages.getIntlMessage;

let oneOrMore = function (item) {
  return item.length >= 1;
};

let minPhoneLength = (phone) => {
  return phone.length < 10;
};

let maxPhoneLength = (phone) => {
  return phone.length > 10;
};

let phoneLength = (phone) => {
  return phone.length === 10;
};

export var ProspectPhoneSchema = t.struct({
  // id: t.maybe(t.Num),
  label_id: t.Num,
  number: t.subtype(t.Str, phoneLength),
});

export var AddProspectSchema = t.struct({
  firstname: t.Str,
  lastname: t.Str,
  address: t.maybe(t.Str),
  city: t.Str,
  email: t.maybe(t.Str),
  partner: t.maybe(t.Str),
  referent: t.maybe(t.Str),
  comments: t.maybe(t.Str),
  source_id: t.Num,
  grade_id: t.Num,
  phones: t.subtype(t.list(ProspectPhoneSchema), oneOrMore),
  is_available_day: t.Boolean,
  is_available_evening: t.Boolean,
});

export var SetProspectSchema = t.struct({
  // status_id: t.Num,
  firstname: t.Str,
  lastname: t.Str,
  address: t.maybe(t.Str),
  city: t.Str,
  email: t.maybe(t.Str),
  partner: t.maybe(t.Str),
  referent: t.maybe(t.Str),
  comments: t.maybe(t.Str),
  source_id: t.Num,
  grade_id: t.Num,
  phones: t.subtype(t.list(ProspectPhoneSchema), oneOrMore),
  is_available_day: t.Boolean,
  is_available_evening: t.Boolean,
});

export var SetProspectConsentSchema = t.struct({
  has_consent: t.Boolean,
});

export var AddProspectNoteSchema = t.struct({
  // private: t.Bool,
  message: t.Str,
});

export var AddProspectActivitySchema = t.struct({
  step: t.Str,
});

export var AddProspectSchemaOptions = {
  templates: {
    struct: layouts.AddProspectLayout,
  },
  fields: {
    firstname: {
      type: "text",
      label: getIntlMessage("Firstname"),
    },
    lastname: {
      type: "text",
      label: getIntlMessage("Lastname"),
    },
    address: {
      type: "text",
      label: getIntlMessage("Address"),
    },
    city: {
      type: "text",
      label: getIntlMessage("City"),
    },
    email: {
      type: "text",
      label: getIntlMessage("Email"),
    },
    partner: {
      type: "text",
      label: getIntlMessage("Partner"),
    },
    referent: {
      type: "text",
      label: getIntlMessage("Referent"),
    },
    comments: {
      type: "textarea",
      label: getIntlMessage("Comments"),
    },
    source_id: {
      type: "text",
      label: getIntlMessage("Source"),
      factory: forms.widgets.KDropDownListWidget,
      attrs: {
        meta: {
          reactive: true,
        },
        culture: "fr-CA",
        optionLabel: getIntlMessage("Select..."),
        dataSource: {
          transport: {
            read: {
              url: process.env.__APIURL__ + "/choices/lists/source",
              dataType: "json",
            },
          },
          schema: {
            data: "results",
          },
        },
      },
    },
    grade_id: {
      type: "text",
      label: getIntlMessage("Grade"),
      factory: forms.widgets.KDropDownListWidget,
      attrs: {
        meta: {
          reactive: true,
        },
        culture: "fr-CA",
        optionLabel: getIntlMessage("Select..."),
        dataSource: {
          transport: {
            read: {
              url: process.env.__APIURL__ + "/choices/lists/grade",
              dataType: "json",
            },
          },
          schema: {
            data: "results",
          },
        },
      },
    },
    phones: {
      auto: "none",
      disableOrder: true,
      template: layouts.ProspectPhoneListLayout,
      error: getIntlMessage("Insert at least one phone number"),
      item: {
        // factory: PhoneStruct,
        template: layouts.ProspectPhoneItemLayout,
        fields: {
          label_id: {
            type: "text",
            // label: getIntlMessage('Label'),
            factory: forms.widgets.KDropDownListWidget,
            attrs: {
              meta: {
                reactive: true,
              },
              culture: "fr-CA",
              optionLabel: getIntlMessage("Select..."),
              dataSource: {
                transport: {
                  read: {
                    url: process.env.__APIURL__ + "/choices/lists/phone",
                    dataType: "json",
                  },
                },
                schema: {
                  data: "results",
                },
              },
            },
          },
          number: {
            // factory: NumberTextbox,
            type: "text",
            label: "", // getIntlMessage('Number')
            attrs: {
              placeholder: "Format: 1234567890",
            },
            // error: (value) => {
            //   if (t.Str.is(value)) {
            //     if (phoneLength(value)) {
            //       console.log('validate excluded')
            //       //console.log(phoneExcluded(value))
            //       if (!phoneExcluded(value)) {
            //         console.log('EXCLUDED!!!')
            //         return 'This number is on the national excluded list'
            //       }
            //     }
            //   }
            // }
          },
        },
      },
    },
    is_available_day: {
      label: getIntlMessage("Day"),
    },
    is_available_evening: {
      label: getIntlMessage("Evening"),
    },
  },
};

export var SetProspectSchemaOptions = {
  templates: {
    struct: layouts.SetProspectLayout,
  },
  fields: {
    status_id: {
      type: "text",
      label: getIntlMessage("Status"),
      factory: forms.widgets.KDropDownListWidget,
      attrs: {
        culture: "fr-CA",
        optionLabel: getIntlMessage("Select..."),
        dataSource: {
          transport: {
            read: {
              url: process.env.__APIURL__ + "/choices/lists/status",
              dataType: "json",
            },
          },
          schema: {
            data: "results",
          },
        },
      },
    },
    firstname: {
      type: "text",
      label: getIntlMessage("Firstname"),
    },
    lastname: {
      type: "text",
      label: getIntlMessage("Lastname"),
    },
    address: {
      type: "text",
      label: getIntlMessage("Address"),
    },
    city: {
      type: "text",
      label: getIntlMessage("City"),
    },
    email: {
      type: "text",
      label: getIntlMessage("Email"),
    },
    partner: {
      type: "text",
      label: getIntlMessage("Partner"),
    },
    referent: {
      type: "text",
      label: getIntlMessage("Referent"),
    },
    comments: {
      type: "textarea",
      label: getIntlMessage("Comments"),
    },
    source_id: {
      type: "text",
      label: getIntlMessage("Source"),
      factory: forms.widgets.KDropDownListWidget,
      attrs: {
        culture: "fr-CA",
        optionLabel: getIntlMessage("Select..."),
        dataSource: {
          transport: {
            read: {
              url: process.env.__APIURL__ + "/choices/lists/source",
              dataType: "json",
            },
          },
          schema: {
            data: "results",
          },
        },
      },
    },
    grade_id: {
      type: "text",
      label: getIntlMessage("Grade"),
      factory: forms.widgets.KDropDownListWidget,
      attrs: {
        culture: "fr-CA",
        optionLabel: getIntlMessage("Select..."),
        dataSource: {
          transport: {
            read: {
              url: process.env.__APIURL__ + "/choices/lists/grade",
              dataType: "json",
            },
          },
          schema: {
            data: "results",
          },
        },
      },
    },
    phones: {
      auto: "none",
      disableOrder: true,
      template: layouts.ProspectPhoneListLayout,
      error: getIntlMessage("Insert at least one phone number"),
      item: {
        template: layouts.ProspectPhoneItemLayout,
        fields: {
          label_id: {
            type: "text",
            factory: forms.widgets.KDropDownListWidget,
            attrs: {
              culture: "fr-CA",
              optionLabel: getIntlMessage("Select..."),
              dataSource: {
                transport: {
                  read: {
                    url: process.env.__APIURL__ + "/choices/lists/phone",
                    dataType: "json",
                  },
                },
                schema: {
                  data: "results",
                },
              },
            },
          },
          number: {
            type: "text",
            label: "",
            attrs: {
              placeholder: "Format: 1234567890",
            },
          },
        },
      },
    },
    is_available_day: {
      label: getIntlMessage("Day"),
    },
    is_available_evening: {
      label: getIntlMessage("Evening"),
    },
  },
};

export var SetProspectConsentSchemaOptions = {
  templates: {
    struct: layouts.SetProspectConsentLayout,
  },
  fields: {
    has_consent: {
      label: getIntlMessage("Yes"),
    },
  },
};

export var AddProspectNoteSchemaOptions = {
  fields: {
    // private: {
    //   type: 'text',
    //   label: getIntlMessage('Private')
    // },
    message: {
      type: "textarea",
      label: getIntlMessage("Message"),
      optional: "",
      factory: forms.widgets.KEditorWidget,
      attrs: {
        culture: "fr-CH",
      },
    },
  },
};

export var AddProspectActivitySchemaOptions = {
  templates: {
    struct: layouts.AddProspectActivityLayout,
  },
  fields: {
    step: {
      type: "text",
      label: getIntlMessage("Step"),
      factory: forms.widgets.KDropDownListWidget,
      attrs: {
        culture: "fr-CA",
        optionLabel: getIntlMessage("Select..."),
        dataSource: [
          { label: getIntlMessage("Market Analysis"), value: "market-analysis" },
          { label: getIntlMessage("Break Time"), value: "break-time" },
          { label: getIntlMessage("Sales Interview"), value: "sales-interview" },
        ],
        dataTextField: "label",
        dataValueField: "value",
      },
    },
  },
};
