import _ from "lodash";

export function setFormFeedback(data) {
  let feedback = {
    type: "error",
    messages: [],
  };
  let hasError = _.has(data, "errors") && data.errors[0] ? true : false;
  if (hasError) {
    data.errors.forEach((error, index) => {
      feedback.messages.push(error.message);
    });
  }
  return feedback;
}

export function setFormOptions(opts, data) {
  let options = _.extend({}, opts);
  let fields = _.extend({}, options.fields);
  let hasError = _.has(data, "errors") && data.errors[0].form ? true : false;
  Object.keys(options.fields).forEach((name, index) => {
    fields[name] = _.extend(
      {
        hasError: hasError,
        error: hasError ? data.errors[0].form[name] : "",
      },
      fields[name]
    );
  });
  options.fields = _.extend(options.fields, fields);
  return options;
}
