import React from "react";
import { Modal, Button } from "react-bootstrap";
import t from "tcomb-form";

import { ButtonLoader } from "../Loaders";

import i18n from "../../modules/i18n";
import Feedback from "./Feedback";

let getIntlMessage = i18n.messages.getIntlMessage;

var FormModal = React.createClass({
  propTypes: {
    form: React.PropTypes.object,
    formType: React.PropTypes.string,
    formLayout: React.PropTypes.oneOf(["inline", "horizontal", "vertical", "free"]),
    value: React.PropTypes.object,
    onFormChange: React.PropTypes.func,
    onFormSubmit: React.PropTypes.func,
    bsSize: React.PropTypes.string,
    bsStyle: React.PropTypes.string,
    title: React.PropTypes.string,
    actionType: React.PropTypes.string,
    actionButtonTitle: React.PropTypes.string,
    showFeedback: React.PropTypes.bool,
  },

  getDefaultProps() {
    return {
      form: {},
      formType: "add",
      formLayout: "horizontal",
      value: {},
      onFormChange: null,
      onFormSubmit: null,
      bsSize: "medium",
      bsStyle: "default",
      title: getIntlMessage("Form"),
      actionType: null,
      actionButtonTitle: null,
      showFeedback: true,
    };
  },

  onSubmit(event) {
    event.preventDefault();
    var value = this.refs.form.getValue();
    if (value) {
      this.props.onFormSubmit(event, value);
    }
  },

  onChange(value, path) {
    var component = this.refs.form.getComponent(path);
    if (component) {
      var validate = component.validate();
    }
    this.props.onFormChange(value);
  },

  getFormLayoutClassName() {
    if (this.props.formLayout === "horizontal") {
      return "form-horizontal";
    } else if (this.props.formLayout === "vertical") {
      return "form-vertical";
    } else if (this.props.formLayout === "inline") {
      return "form-inline";
    } else {
      return "";
    }
  },

  getFormStyleClassName() {
    return "hmodal-" + this.props.bsStyle;
  },

  getFormTypeButtonTitle() {
    if (this.props.actionButtonTitle !== null) {
      return this.props.actionButtonTitle;
    }
    if (this.props.formType === "add") {
      return getIntlMessage("Add");
    } else if (this.props.formType === "set") {
      return getIntlMessage("Edit");
    }
    return "...";
  },

  getFormStyleButtonClassName() {
    var className = "primary";
    if (this.props.bsStyle !== "default") {
      className = this.props.bsStyle;
    }
    return className;
  },

  render() {
    return (
      <Modal
        bsSize={this.props.bsSize}
        className={this.getFormStyleClassName()}
        show={this.props.show}
        onHide={this.props.onHide}
        {...this.props}
      >
        <div className="color-line" />
        <Modal.Header>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Feedback feedback={this.props.form.feedback} show={this.props.showFeedback} />
        <Modal.Body>
          <div className={this.getFormLayoutClassName()}>
            <t.form.Form
              ref="form"
              type={this.props.form.type}
              options={this.props.form.options}
              value={this.props.value}
              onChange={this.onChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onHide}>{getIntlMessage("Close")}</Button>
          <Button
            name={this.props.formType}
            bsStyle={this.getFormStyleButtonClassName()}
            disabled={this.props.form.started}
            onClick={this.onSubmit}
          >
            {this.getFormTypeButtonTitle()}
            <ButtonLoader loaded={!this.props.form.started} color={"#fff"} />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  },
});

// module.exports = {
//   FormModal,
//   default: FormModal,
// };

export default FormModal;

//module.exports = FormModal;
