import axios from "axios";

import reactor from "../../reactor";
import i18n from "../i18n";
import notifier from "../notifier";
import actionTypes from "./actionTypes";

let getIntlMessage = i18n.messages.getIntlMessage;

export function setOptionListReset() {
  reactor.dispatch(actionTypes.SET_OPTIONLIST_RESET);
}

export function getOptionList(id) {
  axios
    .get(process.env.__APIURL__ + "/option-lists/" + id)
    .then((res) => {
      reactor.dispatch(actionTypes.GET_OPTIONLIST_SUCCESS, { data: res.data });
    })
    .catch((res) => {
      reactor.dispatch(actionTypes.GET_OPTIONLIST_FAIL, { data: res.data });
    });
}

export function addOptionListForm() {
  reactor.dispatch(actionTypes.ADD_OPTIONLIST_FORM);
}

export function addOptionListStart(data) {
  reactor.dispatch(actionTypes.ADD_OPTIONLIST_START, { data });
}

export function addOptionList(data) {
  axios
    .post(process.env.__APIURL__ + "/option-lists", data)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.ADD_OPTIONLIST_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.ADD_OPTIONLIST_START, { data: false });
        reactor.dispatch(actionTypes.SET_OPTIONLISTS_RELOAD, { data: true });
      });
      notifier.actions.notify({
        message: getIntlMessage("Add Successful"),
        type: "success",
      });
      reactor.dispatch(actionTypes.SET_OPTIONLISTS_RELOAD, { data: false });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.ADD_OPTIONLIST_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.ADD_OPTIONLIST_START, { data: false });
        reactor.dispatch(actionTypes.SET_OPTIONLISTS_RELOAD, { data: false });
      });
    });
}

export function setOptionListForm() {
  reactor.dispatch(actionTypes.SET_OPTIONLIST_FORM);
}

export function setOptionListStart(data) {
  reactor.dispatch(actionTypes.SET_OPTIONLIST_START, { data });
}

export function setOptionList(id, data) {
  axios
    .put(process.env.__APIURL__ + "/option-lists/" + id, data)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_OPTIONLIST_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.SET_OPTIONLIST_START, { data: false });
        reactor.dispatch(actionTypes.SET_OPTIONLISTS_RELOAD, { data: true });
      });
      notifier.actions.notify({
        message: getIntlMessage("Update Successful"),
        type: "success",
      });
      reactor.dispatch(actionTypes.SET_OPTIONLISTS_RELOAD, { data: false });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_OPTIONLIST_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.SET_OPTIONLIST_START, { data: false });
        reactor.dispatch(actionTypes.SET_OPTIONLISTS_RELOAD, { data: false });
      });
    });
}

export function setOptionListsReload(data) {
  reactor.dispatch(actionTypes.SET_OPTIONLISTS_RELOAD, { data });
}

export function remOptionList() {
  reactor.dispatch(actionTypes.REM_OPTIONLIST);
}
