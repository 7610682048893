import axios from "axios";

import reactor from "../../reactor";
import i18n from "../i18n";
import notifier from "../notifier";
import actionTypes from "./actionTypes";

let getIntlMessage = i18n.messages.getIntlMessage;

export function setRoleReset() {
  reactor.dispatch(actionTypes.SET_ROLE_RESET);
}

export function getRole(id) {
  axios
    .get(process.env.__APIURL__ + "/roles/" + id)
    .then((res) => {
      reactor.dispatch(actionTypes.GET_ROLE_SUCCESS, { data: res.data });
    })
    .catch((res) => {
      reactor.dispatch(actionTypes.GET_ROLE_FAIL, { data: res.data });
    });
}

export function addRoleForm() {
  reactor.dispatch(actionTypes.ADD_ROLE_FORM);
}

export function addRoleStart(data) {
  reactor.dispatch(actionTypes.ADD_ROLE_START, { data });
}

export function addRole(data) {
  axios
    .post(process.env.__APIURL__ + "/roles", data)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.ADD_ROLE_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.ADD_ROLE_START, { data: false });
        reactor.dispatch(actionTypes.SET_ROLES_RELOAD, { data: true });
      });
      notifier.actions.notify({
        message: getIntlMessage("Add Successful"),
        type: "success",
      });
      reactor.dispatch(actionTypes.SET_ROLES_RELOAD, { data: false });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.ADD_ROLE_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.ADD_ROLE_START, { data: false });
        reactor.dispatch(actionTypes.SET_ROLES_RELOAD, { data: false });
      });
    });
}

export function setRoleForm() {
  reactor.dispatch(actionTypes.SET_ROLE_FORM);
}

export function setRoleStart(data) {
  reactor.dispatch(actionTypes.SET_ROLE_START, { data });
}

export function setRole(id, data) {
  axios
    .put(process.env.__APIURL__ + "/roles/" + id, data)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_ROLE_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.SET_ROLE_START, { data: false });
        reactor.dispatch(actionTypes.SET_ROLES_RELOAD, { data: true });
      });
      notifier.actions.notify({
        message: getIntlMessage("Update Successful"),
        type: "success",
      });
      reactor.dispatch(actionTypes.SET_ROLES_RELOAD, { data: false });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_ROLE_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.SET_ROLE_START, { data: false });
        reactor.dispatch(actionTypes.SET_ROLES_RELOAD, { data: false });
      });
    });
}

export function setRolesReload(data) {
  reactor.dispatch(actionTypes.SET_ROLES_RELOAD, { data });
}

export function remRole() {
  reactor.dispatch(actionTypes.REM_ROLE);
}
