import React from "react";
import ReactDOM from "react-dom";
import { Modal, Button } from "react-bootstrap";
import t from "tcomb-form";
import AvatarEditor from "react-avatar-editor";

import reactor from "../../reactor";
import profile from "../../modules/profile";
import i18n from "../../modules/i18n";
import { ButtonLoader } from "../Loaders";

let getIntlMessage = i18n.messages.getIntlMessage;

var FileUpload = React.createClass({
  onHandleFile(e) {
    var reader = new FileReader();
    var file = e.target.files[0];
    if (!file) return;
    reader.onload = function (img) {
      ReactDOM.findDOMNode(this.refs.in).value = "";
      this.props.handleFileChange(img.target.result);
    }.bind(this);
    reader.readAsDataURL(file);
  },

  render() {
    return <input ref="in" type="file" accept="image/*" onChange={this.onHandleFile} />;
  },
});

var SetAvatar = React.createClass({
  mixins: [reactor.ReactMixin],

  propTypes: {
    profile: React.PropTypes.object,
  },

  getDefaultProps() {
    return {
      profile: {},
    };
  },

  getInitialState() {
    return {
      img: null,
      scale: 1,
    };
  },

  getDataBindings() {
    return {
      setProfileAvatar: profile.getters.setProfileAvatarGetter,
    };
  },

  componentWillReceiveProps(props) {
    if (props.show && props !== this.props) {
      this.setState({
        img: props.profile.avatar_url,
      });
    }
  },

  onHandleFileChange(dataURI) {
    this.setState({
      img: dataURI,
      // croppedImg: this.state.croppedImg,
      // cropperOpen: true
    });
  },

  onHandleScale() {
    var scale = this.refs.scale.getDOMNode().value;
    this.setState({ scale: scale });
  },

  onHandleSave(data) {
    var img = this.refs.avatar.getImage();
    if (img) {
      var data = { file: img };
      profile.actions.setProfileAvatar(data);
    }
  },

  render() {
    var setProfileAvatar = this.state.setProfileAvatar.toJS();
    return (
      <Modal show={this.props.show} onHide={this.props.onHide} dialogClassName="avatar-modal" {...this.props}>
        <div className="color-line" />
        <Modal.Header>
          <Modal.Title>{getIntlMessage("Edit Avatar")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AvatarEditor
            ref="avatar"
            scale={parseFloat(this.state.scale)}
            width={250}
            height={250}
            border={10}
            onSave={this.onHandleSave}
            image={this.state.img}
          />
          <input
            name="scale"
            type="range"
            ref="scale"
            min="1"
            max="2"
            step="0.01"
            defaultValue="1"
            onChange={this.onHandleScale}
          />
          <FileUpload handleFileChange={this.onHandleFileChange} />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onHide}>{getIntlMessage("Close")}</Button>
          <Button name="edit" bsStyle="primary" disabled={setProfileAvatar.started} onClick={this.onHandleSave}>
            {getIntlMessage("Edit")}
            <ButtonLoader loaded={!setProfileAvatar.started} color={"#fff"} />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  },
});

export default SetAvatar;
