import React, { Component, PropTypes } from "react";
import { connect } from "nuclear-js-react-addons";
import { Panel } from "react-bootstrap";
import _ from "lodash";

import planifications from "../../modules/planifications";
import Page from "../base/Page";
import Content from "../base/Content";
import i18n from "../../modules/i18n";
import DefaultLoader from "../Loaders";
import Header from "./Header";
import SummaryOffice from "./SummaryOffice";

let getIntlMessage = i18n.messages.getIntlMessage;

export default
@connect((props) => ({
  summary: planifications.getters.planificationSummaryGetter,
}))
class PlanificationSummary extends Component {
  static propTypes = {
    summary: PropTypes.object,
  };

  static defaultProps = {
    summary: {},
  };

  renderOffices = () => {
    let summary = this.props.summary.toJS().data;
    let offices;

    if (_.isEmpty(summary)) {
      offices = (
        <Panel className="hpanel hyellow text-center">
          <h2 className="text-warning">{getIntlMessage("The week you are looking for does not exist!")}</h2>
          <h4>{getIntlMessage("Sales directors did not provide any data")}</h4>
        </Panel>
      );
    } else {
      offices = Object.keys(summary)
        .sort()
        .map((key) => <SummaryOffice office={summary[key]} key={key} />);
    }
    return offices;
  };

  render() {
    return (
      <Page {...this.props}>
        <Header {...this.props} />
        <Content className="planification-summary">{this.renderOffices()}</Content>
      </Page>
    );
  }
}
