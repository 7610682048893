import axios from "axios";

import reactor from "../../reactor";
import i18n from "../i18n";
import notifier from "../notifier";
import actionTypes from "./actionTypes";

let getIntlMessage = i18n.messages.getIntlMessage;

export function setOptionReset() {
  reactor.dispatch(actionTypes.SET_OPTION_RESET);
}

export function getOption(id) {
  axios
    .get(process.env.__APIURL__ + "/options/" + id)
    .then((res) => {
      reactor.dispatch(actionTypes.GET_OPTION_SUCCESS, { data: res.data });
    })
    .catch((res) => {
      reactor.dispatch(actionTypes.GET_OPTION_FAIL, { data: res.data });
    });
}

export function addOptionForm() {
  reactor.dispatch(actionTypes.ADD_OPTION_FORM);
}

export function addOptionStart(data) {
  reactor.dispatch(actionTypes.ADD_OPTION_START, { data });
}

export function addOption(data) {
  axios
    .post(process.env.__APIURL__ + "/options", data)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.ADD_OPTION_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.ADD_OPTION_START, { data: false });
        reactor.dispatch(actionTypes.SET_OPTIONS_RELOAD, { data: true });
      });
      notifier.actions.notify({
        message: getIntlMessage("Add Successful"),
        type: "success",
      });
      reactor.dispatch(actionTypes.SET_OPTIONS_RELOAD, { data: false });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.ADD_OPTION_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.ADD_OPTION_START, { data: false });
        reactor.dispatch(actionTypes.SET_OPTIONS_RELOAD, { data: false });
      });
    });
}

export function setOptionForm() {
  reactor.dispatch(actionTypes.SET_OPTION_FORM);
}

export function setOptionStart(data) {
  reactor.dispatch(actionTypes.SET_OPTION_START, { data });
}

export function setOption(id, data) {
  axios
    .put(process.env.__APIURL__ + "/options/" + id, data)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_OPTION_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.SET_OPTION_START, { data: false });
        reactor.dispatch(actionTypes.SET_OPTIONS_RELOAD, { data: true });
      });
      notifier.actions.notify({
        message: getIntlMessage("Update Successful"),
        type: "success",
      });
      reactor.dispatch(actionTypes.SET_OPTIONS_RELOAD, { data: false });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_OPTION_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.SET_OPTION_START, { data: false });
        reactor.dispatch(actionTypes.SET_OPTIONS_RELOAD, { data: false });
      });
    });
}

export function setOptionsReload(data) {
  reactor.dispatch(actionTypes.SET_OPTIONS_RELOAD, { data });
}

export function remOption() {
  reactor.dispatch(actionTypes.REM_OPTION);
}
