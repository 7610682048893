import axios from "axios";

import reactor from "../../reactor";
import i18n from "../i18n";
import notifier from "../notifier";
import profile from "../profile";
import actionTypes from "./actionTypes";

// other action types
import AccountActionTypes from "../accounts/actionTypes";
import ActivityActionTypes from "../activities/actionTypes";
import AuthActionTypes from "../auth/actionTypes";
import DashboardActionTypes from "../dashboards/actionTypes";
import DNCLActionTypes from "../dncl/actionTypes";
import OfficeActionTypes from "../offices/actionTypes";
import OptionListActionTypes from "../optionlists/actionTypes";
import OptionActionTypes from "../options/actionTypes";
import ProfileActionTypes from "../profile/actionTypes";
import ProspectActionTypes from "../prospects/actionTypes";
import RoleActionTypes from "../roles/actionTypes";

let getIntlMessage = i18n.messages.getIntlMessage;

export function setAuthReset() {
  reactor.dispatch(actionTypes.SET_AUTH_RESET);
}

export function loginForm() {
  reactor.dispatch(actionTypes.LOGIN_FORM);
}

export function loginStart(data) {
  reactor.dispatch(actionTypes.LOGIN_START, { data });
}

export function login(data) {
  axios
    .post(process.env.__APIURL__ + "/auth/login", data)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.LOGIN_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.LOGIN_START, { data: false });
      });
      profile.actions.getProfileStart(true);
      profile.actions.getProfile();
      notifier.actions.notify({
        message: getIntlMessage("You've been logged in!"),
        type: "success",
      });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.LOGIN_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.LOGIN_START, { data: false });
      });
      profile.actions.remProfile();
    });
}

export function logout(data) {
  reactor.batch(() => {
    reactor.dispatch(actionTypes.LOGOUT, { data });
    reactor.dispatch(AccountActionTypes.SET_ACCOUNT_RESET, {});
    reactor.dispatch(ActivityActionTypes.SET_ACTIVITIES_RESET, {});
    reactor.dispatch(AuthActionTypes.SET_AUTH_RESET, {});
    reactor.dispatch(DashboardActionTypes.SET_DASHBOARD_RESET, {});
    reactor.dispatch(DNCLActionTypes.SET_DNCL_RESET, {});
    reactor.dispatch(OfficeActionTypes.SET_OFFICE_RESET, {});
    reactor.dispatch(OptionListActionTypes.SET_OPTIONLIST_RESET, {});
    reactor.dispatch(OptionActionTypes.SET_OPTION_RESET, {});
    reactor.dispatch(ProfileActionTypes.SET_PROFILE_RESET, {});
    reactor.dispatch(ProspectActionTypes.SET_PROSPECT_RESET, {});
    reactor.dispatch(RoleActionTypes.SET_ROLE_RESET, {});
  });
}

export function accessDenied(data) {
  notifier.actions.notify({
    message: getIntlMessage("Access Denied"),
    type: "error",
  });
  // exports.logout(data);
  reactor.dispatch(actionTypes.LOGOUT, { data });
}

export function token(data) {
  axios
    .post(process.env.__APIURL__ + "/auth/token", data)
    .then((res) => {
      reactor.dispatch(actionTypes.TOKEN_SUCCESS, { data: res.data });
      profile.actions.getProfileStart(true);
      profile.actions.getProfile();
    })
    .catch((res) => {
      reactor.dispatch(actionTypes.TOKEN_FAIL, { data: res.data });
      profile.actions.remProfile();
    });
}

export function passwordLostForm() {
  reactor.dispatch(actionTypes.PASSWORD_LOST_FORM);
}

export function passwordLostStart(data) {
  reactor.dispatch(actionTypes.PASSWORD_LOST_START, { data });
}

export function passwordLost(data) {
  axios
    .post(process.env.__APIURL__ + "/auth/password/lost", data)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.PASSWORD_LOST_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.PASSWORD_LOST_START, { data: false });
      });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.PASSWORD_LOST_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.PASSWORD_LOST_START, { data: false });
      });
    });
}

export function passwordResetForm() {
  reactor.dispatch(actionTypes.PASSWORD_RESET_FORM);
}

export function passwordResetStart(data) {
  reactor.dispatch(actionTypes.PASSWORD_RESET_START, { data });
}

export function passwordReset(data) {
  axios
    .post(process.env.__APIURL__ + "/auth/password/reset", data)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.PASSWORD_RESET_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.PASSWORD_RESET_START, { data: false });
      });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.PASSWORD_RESET_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.PASSWORD_RESET_START, { data: false });
      });
    });
}
