import React, { Component, PropTypes } from "react";
import { Row, Col } from "react-bootstrap";

import SummaryPlanifications from "./SummaryPlanifications";

export default class SummaryOffice extends Component {
  static propTypes = {
    office: PropTypes.object,
  };

  static defaultProps = {
    office: {},
  };

  render() {
    let office = this.props.office;

    return (
      <Row>
        <Col md={12}>
          <h2 className="summary-office-title">
            {office.office_code} - {office.office_name}
          </h2>
          <SummaryPlanifications planifications={office.planifications} />
        </Col>
      </Row>
    );
  }
}
