import React, { Component, PropTypes } from "react";
import { connect } from "nuclear-js-react-addons";
import { Panel, Button, ButtonGroup } from "react-bootstrap";
import _ from "lodash";

import app from "../../modules/app";
import i18n from "../../modules/i18n";
import prospects from "../../modules/prospects";
import Page from "../base/Page";
import Content from "../base/Content";
import DataTables from "../DataTables";
import DefaultLoader from "../Loaders";
import Header from "./Header";
import Prospect from "./Prospect";
import SetProspect from "./SetProspect";
import AddProspectNote from "./AddProspectNote";
import CancelProspect from "./CancelProspect";

let getIntlMessage = i18n.messages.getIntlMessage;

export default
@connect((props) => ({
  account: app.getters.appAccountGetter,
  selector: app.getters.appAccountSelectorGetter,
  setProspectsReload: prospects.getters.setProspectsReloadGetter,
  setProspectStatusBatch: prospects.getters.setProspectStatusBatchGetter,
}))
class AvailableProspects extends Component {
  static propTypes = {
    setProspectsReload: PropTypes.bool,
    setProspectStatusBatch: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      showProspect: false,
      showSetProspect: false,
      showAddProspectNote: false,
      showCancelProspect: false,
      prospectId: null,
      noteCategory: null,
      selectedRows: [],
      doReload: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.profile !== this.props.profile && !_.isEmpty(nextProps.profile)) {
      this.setState({
        doReload: true,
      });
    }
    let nextAccount = nextProps.account.toJS();
    if (nextProps.account !== this.props.account && !_.isEmpty(nextAccount)) {
      this.setState({
        doReload: true,
      });
    }
  }

  setColumns = () => {
    return [
      { data: "id", name: getIntlMessage("ID"), visible: false, searchable: false, orderable: false },
      { data: "fullname", name: getIntlMessage("Name") },
      { data: "city", name: getIntlMessage("City") },
      { data: "source_name", name: getIntlMessage("Source") },
      { data: "grade_name", name: getIntlMessage("Grade"), filter: "label" },
      { data: "last_called_on", name: getIntlMessage("Last Called On"), filter: "datetime" },
      { data: "times_called", name: getIntlMessage("Times Called") },
      { data: "created_on", name: getIntlMessage("Created On"), filter: "datetime" },
      { data: null, filter: "actions", searchable: false, orderable: false },
    ];
  };

  setActions = () => {
    return [
      {
        help: getIntlMessage("View"),
        icon: "fa fa-eye",
        action: "onShowProspect",
        onClick: this.onShowProspect,
      },
      {
        help: getIntlMessage("Cancel"),
        icon: "fa fa-ban",
        action: "onShowCancelProspect",
        onClick: this.onShowCancelProspect,
      },
    ];
  };

  mDataProps = () => {
    let props = [];
    _.forEach(this.setColumns(), (n, key) => {
      if (key.data !== null) {
        props.push(n.data);
      }
    });
    return props;
  };

  onShowProspect = (id) => {
    prospects.actions.remProspect();
    this.setState({
      showProspect: true,
      prospectId: id,
    });
  };

  onShowSetProspect = (id) => {
    this.setState({
      showSetProspect: true,
      prospectId: id,
    });
  };

  onShowAddProspectNote = (id, category) => {
    this.setState({
      showAddProspectNote: true,
      prospectId: id,
      noteCategory: category,
    });
  };

  onShowCancelProspect = (id) => {
    this.setState({
      showCancelProspect: true,
      prospectId: id,
    });
  };

  onHideProspect = () => {
    this.setState({
      showProspect: false,
    });
  };

  onHideSetProspect = () => {
    this.setState({
      showSetProspect: false,
    });
  };

  onHideAddProspectNote = () => {
    this.setState({
      showAddProspectNote: false,
    });
  };

  onHideCancelProspect = () => {
    this.setState({
      showCancelProspect: false,
    });
  };

  onSelectRow = (rows) => {
    this.setState({
      selectedRows: rows,
    });
  };

  onAddToCallList = () => {
    let batch = [];
    let rows = this.state.selectedRows;
    _.forEach(rows, (row) => {
      batch.push({ prospect_id: row.id, refid: "listed" });
    });
    prospects.actions.setProspectStatusBatchStart(true);
    prospects.actions.setProspectStatusBatch({ items: batch });
  };

  onAccountSelectorChange = (id, role) => {
    app.actions.getAppAccount(id);
  };

  renderDatatables = (doReload) => {
    let account = this.props.account.toJS();
    let setProspectStatusBatch = this.props.setProspectStatusBatch.toJS();
    if (!account || _.isEmpty(account)) {
      return <DefaultLoader />;
    }
    return (
      <Panel>
        <DataTables
          domId={"prospects-datatables-available"}
          ajaxSource={process.env.__APIURL__ + "/prospects/datatables/available/" + account.id}
          stateSave={true}
          responsive={true}
          columns={this.setColumns()}
          actions={this.setActions()}
          onSelectRow={this.onSelectRow}
          doReload={doReload}
          {...this.props}
        />
        <ButtonGroup className="pull-right">
          <Button
            name="add-call-list"
            bsSize="medium"
            bsStyle="primary"
            disabled={setProspectStatusBatch.started}
            onClick={this.onAddToCallList}
          >
            {getIntlMessage("Add To Call List")}
          </Button>
        </ButtonGroup>
      </Panel>
    );
  };

  render() {
    let account = this.props.account.toJS();
    let selector = this.props.selector.toJS();
    let doReload = this.state.doReload || this.props.setProspectsReload;
    return (
      <Page
        {...this.props}
        account={account}
        accounts={selector.accounts}
        onAccountSelectorChange={this.onAccountSelectorChange}
        showAccountsHeader={true}
      >
        <Header {...this.props} />
        <Content className="prospects">{this.renderDatatables(doReload)}</Content>
        <Prospect
          show={this.state.showProspect}
          onHide={this.onHideProspect}
          onShowSetProspect={this.onShowSetProspect}
          onShowAddProspectNote={this.onShowAddProspectNote}
          prospectId={this.state.prospectId}
          {...this.props}
        />
        <SetProspect
          show={this.state.showSetProspect}
          onHide={this.onHideSetProspect}
          prospectId={this.state.prospectId}
          {...this.props}
        />
        <AddProspectNote
          show={this.state.showAddProspectNote}
          onHide={this.onHideAddProspectNote}
          prospectId={this.state.prospectId}
          noteCategory={this.state.noteCategory}
          {...this.props}
        />
        <CancelProspect
          show={this.state.showCancelProspect}
          onHide={this.onHideCancelProspect}
          prospectId={this.state.prospectId}
          {...this.props}
        />
      </Page>
    );
  }
}
