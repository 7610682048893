import React from "react";

import reactor from "../../reactor";
import activities from "../../modules/activities";
import i18n from "../../modules/i18n";
import FormModal from "../forms/FormModal";

let getIntlMessage = i18n.messages.getIntlMessage;

var defaultValue = {};

var PauseActivity = React.createClass({
  mixins: [reactor.ReactMixin],

  propTypes: {
    activityId: React.PropTypes.number,
  },

  getDefaultProps() {
    return {
      activityId: null,
    };
  },

  getInitialState() {
    return {
      value: defaultValue,
    };
  },

  getDataBindings() {
    return {
      pauseActivityForm: activities.getters.pauseActivityFormGetter,
    };
  },

  componentWillReceiveProps(props) {
    if (!props.show) {
      this.setState({
        value: defaultValue,
      });
    }
  },

  componentDidUpdate(prevProps, prevState) {
    if (this.state.pauseActivityForm !== prevState.pauseActivityForm) {
      var form = this.state.pauseActivityForm.toJS();
      var prevForm = prevState.pauseActivityForm.toJS();
      if (form.feedback.type !== prevForm.feedback.type && form.feedback.type === "success") {
        this.props.onHide();
      }
    }
  },

  onFormSubmit(event, value) {
    if (!value.is_pausing_activity) {
      this.props.onHide();
      return;
    }
    if (value) {
      this.setState({
        value: value,
      });
      var val = {
        refid: "pending",
        is_current_step_seen: false,
      };
      activities.actions.setActivityPauseStart(true);
      activities.actions.setActivityPause(this.props.activityId, val);
    }
  },

  onFormChange(value) {
    this.setState({ value });
  },

  render() {
    var form = this.state.pauseActivityForm.toJS();
    return (
      <FormModal
        form={form}
        formType="set"
        value={this.state.value}
        onFormChange={this.onFormChange}
        onFormSubmit={this.onFormSubmit}
        bsStyle="warning"
        title={getIntlMessage("Pause Activity")}
        actionButtonTitle={getIntlMessage("Confirm")}
        showFeedback={false}
        {...this.props}
      />
    );
  },
});

export default PauseActivity;
