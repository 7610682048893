import React from "react";
import { Panel } from "react-bootstrap";
import _ from "lodash";

import reactor from "../../reactor";
import i18n from "../../modules/i18n";
import options from "../../modules/options";
import Page from "../base/Page";
import Content from "../base/Content";
import DataTables from "../DataTables";
import Header from "./Header";
import SetOption from "./SetOption";

let getIntlMessage = i18n.messages.getIntlMessage;

var Options = React.createClass({
  mixins: [reactor.ReactMixin],

  getInitialState() {
    return {
      showSetOption: false,
      optionId: null,
      doReload: false,
    };
  },

  getDataBindings() {
    return {
      setOptionsReload: options.getters.setOptionsReloadGetter,
    };
  },

  componentWillReceiveProps(props) {
    if (props.profile !== this.props.profile && !_.isEmpty(props.profile)) {
      this.setState({
        doReload: true,
      });
    }
  },

  setColumns() {
    return [
      { data: "id", name: getIntlMessage("ID"), visible: false, searchable: false, orderable: false },
      { data: "category", name: getIntlMessage("Category") },
      { data: "refid", name: getIntlMessage("RefID") },
      { data: "name", name: getIntlMessage("Name") },
      { data: "created_on", name: getIntlMessage("Created On"), filter: "datetime" },
      { data: null, filter: "actions", searchable: false, orderable: false },
    ];
  },

  setActions() {
    return [
      {
        help: getIntlMessage("Edit"),
        icon: "fa fa-edit",
        action: "onShowSetOption",
        onClick: this.onShowSetOption,
      },
    ];
  },

  mDataProps() {
    var props = [];
    _.forEach(this.setColumns(), (n, key) => {
      if (key.data !== null) {
        props.push(n.data);
      }
    });
    return props;
  },

  onShowSetOption(id) {
    this.setState({
      showSetOption: true,
      optionId: id,
    });
  },

  onHideSetOption() {
    this.setState({
      showSetOption: false,
    });
  },

  render() {
    var doReload = this.state.doReload || this.state.setOptionsReload;
    return (
      <Page>
        <Header {...this.props} />
        <Content className="options">
          <Panel>
            <DataTables
              domId={"options-datatables"}
              ajaxSource={process.env.__APIURL__ + "/options/datatables"}
              stateSave={true}
              responsive={true}
              columns={this.setColumns()}
              actions={this.setActions()}
              doReload={doReload}
              {...this.props}
            />
          </Panel>
        </Content>
        <SetOption
          show={this.state.showSetOption}
          onHide={this.onHideSetOption}
          optionId={this.state.optionId}
          {...this.props}
        />
      </Page>
    );
  },
});

export default Options;
