export const activitiesGetter = ["activities"];
export const boardGetter = ["activities", "board"];
export const setBoardReloadGetter = ["activities", "setBoardReload"];
export const activityGetter = ["activities", "activity"];
export const addActivityFormGetter = ["activities", "addActivityForm"];
export const setActivityFormGetter = ["activities", "setActivityForm"];
export const cancelActivityFormGetter = ["activities", "cancelActivityForm"];
export const sellActivityFormGetter = ["activities", "sellActivityForm"];
export const pauseActivityFormGetter = ["activities", "pauseActivityForm"];
export const resumeActivityFormGetter = ["activities", "resumeActivityForm"];
export const scheduleActivityFormGetter = ["activities", "scheduleActivityForm"];
export const cloneActivityFormGetter = ["activities", "cloneActivityForm"];
export const addActivityBatchGetter = ["activities", "addActivityBatch"];
export const addActivityNoteFormGetter = ["activities", "addActivityNoteForm"];
export const setActivitiesReloadGetter = ["activities", "setActivitiesReload"];
export const setActivityStepRevertGetter = ["activities", "setActivityStepRevert"];
