import React, { Component, PropTypes } from "react";
import { Button, ButtonGroup, Glyphicon } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

import planifications from "../../modules/planifications";
import i18n from "../../modules/i18n";

let getIntlMessage = i18n.messages.getIntlMessage;

export default class PlanificationPeriodAdvisors extends Component {
  static propTypes = {
    periodId: PropTypes.number,
    advisors: PropTypes.arrayOf(PropTypes.object),
    onShowSetPlanificationAdvisor: React.PropTypes.func,
    onShowDeletePlanificationAdvisor: React.PropTypes.func,
  };

  static defaultProps = {
    periodId: null,
    advisors: [],
    onShowSetPlanificationAdvisor: null,
    onShowDeletePlanificationAdvisor: null,
  };

  onShowSetPlanificationAdvisor = (advisorId) => {
    planifications.actions.setPlanificationAdvisorForm();
    this.props.onShowSetPlanificationAdvisor(advisorId);
  };

  onShowDeletePlanificationAdvisor = (advisorId) => {
    planifications.actions.deletePlanificationAdvisorForm();
    this.props.onShowDeletePlanificationAdvisor(advisorId);
  };

  renderActions = (cell, row) => {
    return (
      <ButtonGroup>
        <Button
          name="set-advisor"
          bsSize="xsmall"
          bsStyle="default"
          onClick={() => this.onShowSetPlanificationAdvisor(cell)}
        >
          <Glyphicon glyph="pencil" />
        </Button>
        <Button
          name="delete-advisor"
          bsSize="xsmall"
          bsStyle="default"
          onClick={() => this.onShowDeletePlanificationAdvisor(cell)}
        >
          <Glyphicon glyph="remove" />
        </Button>
      </ButtonGroup>
    );
  };

  render() {
    return (
      <BootstrapTable
        ref="advisors-table"
        data={this.props.advisors}
        striped={true}
        hover={true}
        condensed={true}
        height="300"
      >
        <TableHeaderColumn isKey={true} dataSort={true} dataField="advisor_name">
          {getIntlMessage("Name")}
        </TableHeaderColumn>
        <TableHeaderColumn dataSort={true} dataField="planned" width="100">
          {getIntlMessage("Planned")}
        </TableHeaderColumn>
        <TableHeaderColumn dataSort={true} dataField="completed" width="100">
          {getIntlMessage("Completed")}
        </TableHeaderColumn>
        <TableHeaderColumn dataSort={true} dataField="commission" width="100">
          {getIntlMessage("Commission")}
        </TableHeaderColumn>
        <TableHeaderColumn dataSort={true} dataField="pac" width="100">
          {getIntlMessage("PAC")}
        </TableHeaderColumn>
        <TableHeaderColumn dataSort={true} dataField="iaah" width="100">
          {getIntlMessage("IAAH")}
        </TableHeaderColumn>
        <TableHeaderColumn dataSort={true} dataField="excellence" width="100">
          {getIntlMessage("Excellence")}
        </TableHeaderColumn>
        <TableHeaderColumn dataSort={true} dataField="reference" width="100">
          {getIntlMessage("Reference")}
        </TableHeaderColumn>
        <TableHeaderColumn dataField="advisor_id" dataFormat={this.renderActions} width="80">
          Actions
        </TableHeaderColumn>
      </BootstrapTable>
    );
  }
}
