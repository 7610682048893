import { Store, toImmutable } from "nuclear-js";
import _ from "lodash";
import actionTypes from "./actionTypes";

let initialState = toImmutable({
  account: {},
  selector: {
    accounts: [],
    started: false,
  },
  info: {},
  sidebar: {
    open: false,
    docked: true,
  },
  errors: {
    network: false,
    update: false,
  },
});

export default new Store({
  getInitialState() {
    return initialState;
  },

  initialize() {
    this.on(actionTypes.SET_APP_RESET, onSetAppReset);
    this.on(actionTypes.GET_APP_INFO_SUCCESS, onGetAppInfoSuccess);
    this.on(actionTypes.GET_APP_INFO_FAIL, onGetAppInfoFail);
    this.on(actionTypes.GET_APP_ACCOUNT_SUCCESS, onGetAppAccountSuccess);
    this.on(actionTypes.GET_APP_ACCOUNT_FAIL, onGetAppAccountFail);
    this.on(actionTypes.GET_APP_ACCOUNT_SELECTOR_SUCCESS, onGetAppAccountSelectorSuccess);
    this.on(actionTypes.GET_APP_ACCOUNT_SELECTOR_FAIL, onGetAppAccountSelectorFail);
    this.on(actionTypes.GET_APP_ACCOUNT_SELECTOR_START, onGetAppAccountSelectorStart);
    this.on(actionTypes.SIDEBAR_OPEN, onSidebarOpen);
    this.on(actionTypes.SIDEBAR_DOCKED, onSidebarDocked);
    this.on(actionTypes.SET_APP_ERRORS, onSetAppErrors);
  },
});

function onSetAppReset(state) {
  return initialState;
}

function onGetAppInfoSuccess(state, { data }) {
  return state.set("info", toImmutable(data.info));
}

function onGetAppInfoFail(state, { data }) {
  return state.set("info", toImmutable({}));
}

function onGetAppAccountSuccess(state, { data }) {
  return state.set("account", toImmutable(data.account));
}

function onGetAppAccountFail(state, { data }) {
  return state.set("account", toImmutable({}));
}

function onGetAppAccountSelectorSuccess(state, { data }) {
  return state.setIn(["selector", "accounts"], toImmutable(data));
}

function onGetAppAccountSelectorFail(state, { data }) {
  return state.setIn(["selector", "accounts"], toImmutable([]));
}

function onGetAppAccountSelectorStart(state, { data }) {
  return state.setIn(["selector", "started"], data);
}

function onSidebarOpen(state) {
  let sidebar = state.getIn(["sidebar"]).toJS();
  return state.setIn(["sidebar", "open"], !sidebar.open);
}

function onSidebarDocked(state) {
  let sidebar = state.getIn(["sidebar"]).toJS();
  return state.setIn(["sidebar", "docked"], !sidebar.docked);
}

function onSetAppErrors(state, { data }) {
  let _errors = state.getIn(["errors"]).toJS();
  let errors = _.extend(_errors, data);
  return state.setIn(["errors"], toImmutable(errors));
}
