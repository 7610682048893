import React from "react";

import reactor from "../../reactor";
import prospects from "../../modules/prospects";
import forms from "../../modules/forms";
import i18n from "../../modules/i18n";
import FormModal from "../forms/FormModal";

let getIntlMessage = i18n.messages.getIntlMessage;

var defaultValue = {};

var SetProspectConsent = React.createClass({
  mixins: [reactor.ReactMixin],

  propTypes: {
    prospectId: React.PropTypes.number,
  },

  getDefaultProps() {
    return {
      prospectId: null,
    };
  },

  getInitialState() {
    return {
      value: defaultValue,
    };
  },

  getDataBindings() {
    return {
      setProspectConsentForm: prospects.getters.setProspectConsentFormGetter,
    };
  },

  componentWillReceiveProps(props) {
    if (!props.show) {
      this.setState({
        value: defaultValue,
      });
    }
  },

  componentDidUpdate(prevProps, prevState) {
    if (this.state.setProspectConsentForm !== prevState.setProspectConsentForm) {
      var form = this.state.setProspectConsentForm.toJS();
      var prevForm = prevState.setProspectConsentForm.toJS();
      if (form.feedback.type !== prevForm.feedback.type && form.feedback.type === "success") {
        this.props.onHide();
      }
    }
  },

  onFormSubmit(event, value) {
    if (value) {
      this.setState({
        value: value,
      });
      if (value.has_consent) {
        prospects.actions.setProspectStart(true);
        prospects.actions.setProspect(this.props.prospectId, value, true);
      } else {
        this.props.onHide();
      }
    }
  },

  onFormChange(value) {
    this.setState({ value });
  },

  render() {
    var form = this.state.setProspectConsentForm.toJS();
    return (
      <FormModal
        form={form}
        formType="set"
        value={this.state.value}
        onFormChange={this.onFormChange}
        onFormSubmit={this.onFormSubmit}
        bsStyle="warning"
        title={getIntlMessage("Prospect Consent")}
        actionButtonTitle={getIntlMessage("Confirm")}
        showFeedback={false}
        {...this.props}
      />
    );
  },
});

export default SetProspectConsent;
