import keyMirror from "keymirror";

export default keyMirror({
  SET_PROSPECT_RESET: null,
  GET_PROSPECT_SUCCESS: null,
  GET_PROSPECT_FAIL: null,
  ADD_PROSPECT_FORM: null,
  ADD_PROSPECT_START: null,
  ADD_PROSPECT_SUCCESS: null,
  ADD_PROSPECT_FAIL: null,
  SET_PROSPECT_FORM: null,
  SET_PROSPECT_START: null,
  SET_PROSPECT_SUCCESS: null,
  SET_PROSPECT_FAIL: null,
  DEL_PROSPECT_SUCCESS: null,
  DEL_PROSPECT_FAIL: null,
  REM_PROSPECT: null,
  SET_PROSPECTS_RELOAD: null,
  SET_PROSPECT_CONSENT_FORM: null,
  SET_PROSPECT_STATUS_START: null,
  SET_PROSPECT_STATUS_SUCCESS: null,
  SET_PROSPECT_STATUS_FAIL: null,
  SET_PROSPECT_STATUS_BATCH_START: null,
  SET_PROSPECT_STATUS_BATCH_SUCCESS: null,
  SET_PROSPECT_STATUS_BATCH_FAIL: null,
  ADD_PROSPECT_NOTE_FORM: null,
  ADD_PROSPECT_NOTE_START: null,
  ADD_PROSPECT_NOTE_SUCCESS: null,
  ADD_PROSPECT_NOTE_FAIL: null,
  ADD_PROSPECT_ACTIVITY_FORM: null,
  ADD_PROSPECT_ACTIVITY_START: null,
  ADD_PROSPECT_ACTIVITY_SUCCESS: null,
  ADD_PROSPECT_ACTIVITY_FAIL: null,
  GET_PROSPECTS_CALL_LIST_SUCCESS: null,
  GET_PROSPECTS_CALL_LIST_FAIL: null,
});
