import React from "react";
import { ButtonGroup, Button } from "react-bootstrap";

import i18n from "../../modules/i18n";
import SubHeader from "../base/SubHeader";
import AddRole from "./AddRole";

let getIntlMessage = i18n.messages.getIntlMessage;

var Header = React.createClass({
  getInitialState() {
    return {
      showAddRole: false,
    };
  },

  onShowAddRole() {
    this.setState({
      showAddRole: true,
    });
  },

  onHideAddRole() {
    this.setState({
      showAddRole: false,
    });
  },

  render() {
    return (
      <SubHeader title={getIntlMessage("Roles")}>
        <ButtonGroup>
          <Button name="add-role" bsSize="small" onClick={this.onShowAddRole}>
            {getIntlMessage("Add")}
          </Button>
        </ButtonGroup>
        <AddRole show={this.state.showAddRole} onHide={this.onHideAddRole} {...this.props} />
      </SubHeader>
    );
  },
});

export default Header;
