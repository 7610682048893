import axios from "axios";

import reactor from "../../reactor";
import i18n from "../i18n";
import notifier from "../notifier";
import { default as prospectActionTypes } from "../prospects/actionTypes";
import actionTypes from "./actionTypes";

let getIntlMessage = i18n.messages.getIntlMessage;

export function setActivitiesReset() {
  reactor.dispatch(actionTypes.SET_ACTIVITIES_RESET);
}

export function getBoard(id) {
  let path = "/activities/board";
  if (id) {
    path += "?account_id=" + id;
  }
  axios
    .get(process.env.__APIURL__ + path)
    .then((res) => {
      reactor.dispatch(actionTypes.GET_ACTIVITIES_BOARD_SUCCESS, { data: res.data });
    })
    .catch((res) => {
      reactor.dispatch(actionTypes.GET_ACTIVITIES_BOARD_FAIL, { data: res.data });
    });
}

export function setBoardStep(step, data) {
  axios
    .put(process.env.__APIURL__ + "/activities/board/" + step, data)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_ACTIVITIES_BOARD_STEP_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: true });
      });
      notifier.actions.notify({
        message: getIntlMessage("Update Successful"),
        type: "success",
      });
      reactor.dispatch(actionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: false });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_ACTIVITIES_BOARD_STEP_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: false });
      });
    });
}

export function setActivitiesBoardReload(data) {
  reactor.dispatch(actionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data });
}

export function getActivity(id) {
  axios
    .get(process.env.__APIURL__ + "/activities/" + id)
    .then((res) => {
      reactor.dispatch(actionTypes.GET_ACTIVITY_SUCCESS, { data: res.data });
    })
    .catch((res) => {
      reactor.dispatch(actionTypes.GET_ACTIVITY_FAIL, { data: res.data });
    });
}

export function addActivityForm() {
  reactor.dispatch(actionTypes.ADD_ACTIVITY_FORM);
}

export function addActivityStart(data) {
  reactor.dispatch(actionTypes.ADD_ACTIVITY_START, { data });
}

export function addActivity(data) {
  axios
    .post(process.env.__APIURL__ + "/activities", data)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.ADD_ACTIVITY_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.ADD_ACTIVITY_START, { data: false });
        reactor.dispatch(actionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: true });
        reactor.dispatch(actionTypes.SET_ACTIVITIES_RELOAD, { data: true });
      });
      notifier.actions.notify({
        message: getIntlMessage("Add Successful"),
        type: "success",
      });
      reactor.dispatch(actionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: false });
      reactor.dispatch(actionTypes.SET_ACTIVITIES_RELOAD, { data: false });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.ADD_ACTIVITY_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.ADD_ACTIVITY_START, { data: false });
        reactor.dispatch(actionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: false });
        reactor.dispatch(actionTypes.SET_ACTIVITIES_RELOAD, { data: false });
      });
    });
}

export function setActivityForm() {
  reactor.dispatch(actionTypes.SET_ACTIVITY_FORM);
}

export function setActivityStart(data) {
  reactor.dispatch(actionTypes.SET_ACTIVITY_START, { data });
}

export function setActivity(id, data) {
  axios
    .put(process.env.__APIURL__ + "/activities/" + id, data)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_ACTIVITY_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.SET_ACTIVITY_START, { data: false });
        reactor.dispatch(actionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: true });
        reactor.dispatch(actionTypes.SET_ACTIVITIES_RELOAD, { data: true });
      });
      notifier.actions.notify({
        message: getIntlMessage("Update Successful"),
        type: "success",
      });
      reactor.dispatch(actionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: false });
      reactor.dispatch(actionTypes.SET_ACTIVITIES_RELOAD, { data: false });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_ACTIVITY_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.SET_ACTIVITY_START, { data: false });
        reactor.dispatch(actionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: false });
        reactor.dispatch(actionTypes.SET_ACTIVITIES_RELOAD, { data: false });
      });
    });
}

export function setActivityState(id, data) {
  axios
    .put(process.env.__APIURL__ + "/activities/" + id + "/state", data)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_ACTIVITY_STATE_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.SET_ACTIVITY_STATE_START, { data: false });
        reactor.dispatch(actionTypes.SET_ACTIVITIES_RELOAD, { data: true });
      });
      notifier.actions.notify({
        message: getIntlMessage("Update Successful"),
        type: "success",
      });
      // reactor.dispatch(actionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: false });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_ACTIVITY_STATE_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.SET_ACTIVITY_STATE_START, { data: false });
        // reactor.dispatch(actionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: false });
      });
    });
}

export function setActivityStep(id, data) {
  axios
    .put(process.env.__APIURL__ + "/activities/" + id + "/step", data)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_ACTIVITY_STEP_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.SET_ACTIVITY_STEP_START, { data: false });
        // reactor.dispatch(actionTypes.SET_ACTIVITIES_BOARD_RELOAD, {data: true})
      });
      notifier.actions.notify({
        message: getIntlMessage("Update Successful"),
        type: "success",
      });
      // reactor.dispatch(actionTypes.SET_ACTIVITIES_BOARD_RELOAD, {data: false})
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_ACTIVITY_STEP_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.SET_ACTIVITY_STEP_START, { data: false });
        // reactor.dispatch(actionTypes.SET_ACTIVITIES_BOARD_RELOAD, {data: false})
      });
    });
}

export function setActivityStepRevertStart(data) {
  reactor.dispatch(actionTypes.SET_ACTIVITY_STEP_REVERT_START, { data });
}

export function setActivityStepRevert(id) {
  axios
    .put(process.env.__APIURL__ + "/activities/" + id + "/step/revert-last", {})
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_ACTIVITY_STEP_REVERT_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.SET_ACTIVITY_STEP_REVERT_START, { data: false });
        reactor.dispatch(actionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: true });
      });
      notifier.actions.notify({
        message: getIntlMessage("Step Revert Successful"),
        type: "success",
      });
      // reactor.dispatch(actionTypes.SET_ACTIVITIES_BOARD_RELOAD, {data: false})
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_ACTIVITY_STEP_REVERT_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.SET_ACTIVITY_STEP_REVERT_START, { data: false });
        // reactor.dispatch(actionTypes.SET_ACTIVITIES_BOARD_RELOAD, {data: false})
      });
      notifier.actions.notify({
        message: getIntlMessage("Step Revert Failed"),
        type: "warning",
      });
    });
}

export function setActivityCancelForm() {
  reactor.dispatch(actionTypes.SET_ACTIVITY_CANCEL_FORM);
}

export function setActivityCancelStart(data) {
  reactor.dispatch(actionTypes.SET_ACTIVITY_CANCEL_START, { data });
}

export function setActivityCancel(id, data) {
  axios
    .put(process.env.__APIURL__ + "/activities/" + id + "/state", data)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_ACTIVITY_CANCEL_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.SET_ACTIVITY_CANCEL_START, { data: false });
        reactor.dispatch(actionTypes.SET_ACTIVITIES_RELOAD, { data: true });
        reactor.dispatch(actionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: true });
      });
      notifier.actions.notify({
        message: getIntlMessage("Update Successful"),
        type: "success",
      });
      reactor.dispatch(actionTypes.SET_ACTIVITIES_RELOAD, { data: false });
      reactor.dispatch(actionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: false });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_ACTIVITY_CANCEL_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.SET_ACTIVITY_CANCEL_START, { data: false });
        reactor.dispatch(actionTypes.SET_ACTIVITIES_RELOAD, { data: false });
        reactor.dispatch(actionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: false });
      });
    });
}

export function setActivitySellForm() {
  reactor.dispatch(actionTypes.SET_ACTIVITY_SELL_FORM);
}

export function setActivitySellStart(data) {
  reactor.dispatch(actionTypes.SET_ACTIVITY_SELL_START, { data });
}

export function setActivitySell(id, data) {
  axios
    .put(process.env.__APIURL__ + "/activities/" + id + "/state", data)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_ACTIVITY_SELL_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.SET_ACTIVITY_SELL_START, { data: false });
        reactor.dispatch(actionTypes.SET_ACTIVITIES_RELOAD, { data: true });
        reactor.dispatch(actionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: true });
      });
      notifier.actions.notify({
        message: getIntlMessage("Update Successful"),
        type: "success",
      });
      reactor.dispatch(actionTypes.SET_ACTIVITIES_RELOAD, { data: false });
      reactor.dispatch(actionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: false });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_ACTIVITY_SELL_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.SET_ACTIVITY_SELL_START, { data: false });
        reactor.dispatch(actionTypes.SET_ACTIVITIES_RELOAD, { data: false });
        reactor.dispatch(actionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: false });
      });
    });
}

export function setActivityPauseForm() {
  reactor.dispatch(actionTypes.SET_ACTIVITY_PAUSE_FORM);
}

export function setActivityPauseStart(data) {
  reactor.dispatch(actionTypes.SET_ACTIVITY_PAUSE_START, { data });
}

export function setActivityPause(id, data) {
  axios
    .put(process.env.__APIURL__ + "/activities/" + id + "/state", data)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_ACTIVITY_PAUSE_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.SET_ACTIVITY_PAUSE_START, { data: false });
        reactor.dispatch(actionTypes.SET_ACTIVITIES_RELOAD, { data: true });
        reactor.dispatch(actionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: true });
      });
      notifier.actions.notify({
        message: getIntlMessage("Update Successful"),
        type: "success",
      });
      reactor.dispatch(actionTypes.SET_ACTIVITIES_RELOAD, { data: false });
      reactor.dispatch(actionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: false });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_ACTIVITY_PAUSE_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.SET_ACTIVITY_PAUSE_START, { data: false });
        reactor.dispatch(actionTypes.SET_ACTIVITIES_RELOAD, { data: false });
        reactor.dispatch(actionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: false });
      });
    });
}

export function setActivityResumeForm() {
  reactor.dispatch(actionTypes.SET_ACTIVITY_RESUME_FORM);
}

export function setActivityResumeStart(data) {
  reactor.dispatch(actionTypes.SET_ACTIVITY_RESUME_START, { data });
}

export function setActivityResume(id, data) {
  axios
    .put(process.env.__APIURL__ + "/activities/" + id + "/state", data)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_ACTIVITY_RESUME_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.SET_ACTIVITY_RESUME_START, { data: false });
        reactor.dispatch(actionTypes.SET_ACTIVITIES_RELOAD, { data: true });
        reactor.dispatch(actionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: true });
      });
      notifier.actions.notify({
        message: getIntlMessage("Update Successful"),
        type: "success",
      });
      reactor.dispatch(actionTypes.SET_ACTIVITIES_RELOAD, { data: false });
      reactor.dispatch(actionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: false });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_ACTIVITY_RESUME_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.SET_ACTIVITY_RESUME_START, { data: false });
        reactor.dispatch(actionTypes.SET_ACTIVITIES_RELOAD, { data: false });
        reactor.dispatch(actionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: false });
      });
    });
}

export function setActivityScheduleForm() {
  reactor.dispatch(actionTypes.SET_ACTIVITY_SCHEDULE_FORM);
}

export function setActivityScheduleStart(data) {
  reactor.dispatch(actionTypes.SET_ACTIVITY_SCHEDULE_START, { data });
}

export function setActivitySchedule(id, stepId, data) {
  axios
    .put(process.env.__APIURL__ + "/activities/" + id + "/step/" + stepId + "/schedule", data)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_ACTIVITY_SCHEDULE_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.SET_ACTIVITY_SCHEDULE_START, { data: false });
        reactor.dispatch(actionTypes.SET_ACTIVITIES_RELOAD, { data: true });
        reactor.dispatch(actionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: true });
      });
      notifier.actions.notify({
        message: getIntlMessage("Update Successful"),
        type: "success",
      });
      reactor.dispatch(actionTypes.SET_ACTIVITIES_RELOAD, { data: false });
      reactor.dispatch(actionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: false });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_ACTIVITY_SCHEDULE_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.SET_ACTIVITY_SCHEDULE_START, { data: false });
        reactor.dispatch(actionTypes.SET_ACTIVITIES_RELOAD, { data: false });
        reactor.dispatch(actionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: false });
      });
    });
}

export function setActivityCloneForm() {
  reactor.dispatch(actionTypes.SET_ACTIVITY_CLONE_FORM);
}

export function setActivityCloneStart(data) {
  reactor.dispatch(actionTypes.SET_ACTIVITY_CLONE_START, { data });
}

export function setActivityClone(id, data) {
  axios
    .post(process.env.__APIURL__ + "/activities/" + id + "/clone", data)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_ACTIVITY_CLONE_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.SET_ACTIVITY_CLONE_START, { data: false });
        reactor.dispatch(actionTypes.SET_ACTIVITIES_RELOAD, { data: true });
        reactor.dispatch(actionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: true });
      });
      notifier.actions.notify({
        message: getIntlMessage("Clone Successful"),
        type: "success",
      });
      reactor.dispatch(actionTypes.SET_ACTIVITIES_RELOAD, { data: false });
      reactor.dispatch(actionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: false });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_ACTIVITY_CLONE_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.SET_ACTIVITY_CLONE_START, { data: false });
        reactor.dispatch(actionTypes.SET_ACTIVITIES_RELOAD, { data: false });
        reactor.dispatch(actionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: false });
      });
    });
}

export function addActivityBatchStart(data) {
  reactor.dispatch(actionTypes.ADD_ACTIVITY_BATCH_START, { data });
}

export function addActivityBatch(data) {
  axios
    .post(process.env.__APIURL__ + "/activities/batch", data)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.ADD_ACTIVITY_BATCH_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.ADD_ACTIVITY_BATCH_START, { data: false });
        reactor.dispatch(prospectActionTypes.SET_PROSPECTS_RELOAD, { data: true });
      });
      notifier.actions.notify({
        message: getIntlMessage("Add Successful"),
        type: "success",
      });
      reactor.dispatch(prospectActionTypes.SET_PROSPECTS_RELOAD, { data: false });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.ADD_ACTIVITY_BATCH_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.ADD_ACTIVITY_BATCH_START, { data: false });
        reactor.dispatch(prospectActionTypes.SET_PROSPECTS_RELOAD, { data: false });
      });
    });
}

export function addActivityNoteForm() {
  reactor.dispatch(actionTypes.ADD_ACTIVITY_NOTE_FORM);
}

export function addActivityNoteStart(data) {
  reactor.dispatch(actionTypes.ADD_ACTIVITY_NOTE_START, { data });
}

export function addActivityNote(id, data) {
  axios
    .post(process.env.__APIURL__ + "/activities/" + id + "/notes", data)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.ADD_ACTIVITY_NOTE_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.ADD_ACTIVITY_NOTE_START, { data: false });
        reactor.dispatch(actionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: true });
      });
      notifier.actions.notify({
        message: getIntlMessage("Add Successful"),
        type: "success",
      });
      reactor.dispatch(actionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: false });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.ADD_ACTIVITY_NOTE_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.ADD_ACTIVITY_NOTE_START, { data: false });
        reactor.dispatch(actionTypes.SET_ACTIVITIES_BOARD_RELOAD, { data: false });
      });
    });
}

export function setActivitiesReload(data) {
  reactor.dispatch(actionTypes.SET_ACTIVITIES_RELOAD, { data });
}

export function remActivity() {
  reactor.dispatch(actionTypes.REM_ACTIVITY);
}
