import React, { Component } from "react";

import i18n from "../../modules/i18n";

let getIntlMessage = i18n.messages.getIntlMessage;

export default class ManagerStats extends Component {
  render() {
    return <h2 className="stats-title">{getIntlMessage("Manager")}</h2>;
  }
}
