import axios from "axios";

import reactor from "../../reactor";
import actionTypes from "./actionTypes";

export function setAppReset() {
  reactor.dispatch(actionTypes.SET_APP_RESET);
}

export function getAppInfo() {
  axios
    .get(process.env.__APIURL__)
    .then((res) => {
      reactor.dispatch(actionTypes.GET_APP_INFO_SUCCESS, { data: res.data });
    })
    .catch((res) => {
      reactor.dispatch(actionTypes.GET_APP_INFO_FAIL, { data: res.data });
    });
}

export function getAppAccount(id) {
  axios
    .get(process.env.__APIURL__ + "/accounts/" + id)
    .then((res) => {
      reactor.dispatch(actionTypes.GET_APP_ACCOUNT_SUCCESS, { data: res.data });
    })
    .catch((res) => {
      reactor.dispatch(actionTypes.GET_APP_ACCOUNT_FAIL, { data: res.data });
    });
}

export function getAppAccountSelectorStart(data) {
  reactor.dispatch(actionTypes.GET_APP_ACCOUNT_SELECTOR_START, { data });
}

export function getAppAccountSelector() {
  axios
    .get(process.env.__APIURL__ + "/choices/selector/accounts")
    .then((res) => {
      reactor.dispatch(actionTypes.GET_APP_ACCOUNT_SELECTOR_SUCCESS, { data: res.data });
      reactor.dispatch(actionTypes.GET_APP_ACCOUNT_SELECTOR_START, { data: false });
    })
    .catch((res) => {
      reactor.dispatch(actionTypes.GET_APP_ACCOUNT_SELECTOR_FAIL, { data: res.data });
      reactor.dispatch(actionTypes.GET_APP_ACCOUNT_SELECTOR_START, { data: false });
    });
}

export function sidebarOpen() {
  reactor.dispatch(actionTypes.SIDEBAR_OPEN);
}

export function sidebarDocked() {
  reactor.dispatch(actionTypes.SIDEBAR_DOCKED);
}

export function setAppErrors(data) {
  reactor.dispatch(actionTypes.SET_APP_ERRORS, { data });
}
