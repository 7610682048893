import React from "react";
import { Panel } from "react-bootstrap";
import _ from "lodash";

import reactor from "../../reactor";
import accounts from "../../modules/accounts";
import i18n from "../../modules/i18n";
import Page from "../base/Page";
import Content from "../base/Content";
import DataTables from "../DataTables";
import Header from "./Header";

let getIntlMessage = i18n.messages.getIntlMessage;

var Accounts = React.createClass({
  mixins: [reactor.ReactMixin],

  getInitialState() {
    return {
      doReload: false,
    };
  },

  getDataBindings() {
    return {
      setAccountsReload: accounts.getters.setAccountsReloadGetter,
    };
  },

  componentWillReceiveProps(props) {
    if (props.profile !== this.props.profile && !_.isEmpty(props.profile)) {
      this.setState({
        doReload: true,
      });
    }
  },

  setColumns() {
    return [
      { data: "id", name: getIntlMessage("ID"), visible: false, searchable: false, orderable: false },
      { data: "firstname", name: getIntlMessage("Firstname") },
      { data: "lastname", name: getIntlMessage("Lastname") },
      { data: "email", name: getIntlMessage("Email") },
      { data: "created_on", name: getIntlMessage("Created On"), filter: "datetime" },
      { data: "status_name", name: getIntlMessage("Status"), filter: "label" },
      { data: null, filter: "actions", searchable: false, orderable: false },
    ];
  },

  setActions() {
    return [
      {
        help: getIntlMessage("View"),
        icon: "fa fa-eye",
        action: "onRedirectAccount",
        onClick: this.onRedirectAccount,
      },
    ];
  },

  mDataProps() {
    var props = [];
    _.forEach(this.setColumns(), (n, key) => {
      if (key.data !== null) {
        props.push(n.data);
      }
    });
    return props;
  },

  onRedirectAccount(id) {
    const path = `/system/accounts/${id}`;
    this.props.history.push(path);
  },

  render() {
    var doReload = this.state.doReload || this.state.setAccountsReload;
    return (
      <Page {...this.props}>
        <Header {...this.props} />
        <Content className="accounts">
          <Panel>
            <DataTables
              domId={"accounts-datatables"}
              ajaxSource={process.env.__APIURL__ + "/accounts/datatables"}
              stateSave={true}
              responsive={true}
              columns={this.setColumns()}
              actions={this.setActions()}
              doReload={doReload}
              {...this.props}
            />
          </Panel>
        </Content>
      </Page>
    );
  },
});

export default Accounts;
