import React from "react";
import { Modal, Button, ButtonGroup, ButtonToolbar, Tabs, Tab } from "react-bootstrap";
import t from "tcomb-form";
import classNames from "classnames";
import moment from "moment";
import _ from "lodash";

import reactor from "../../reactor";
import activities from "../../modules/activities";
import prospects from "../../modules/prospects";
import i18n from "../../modules/i18n";
import * as helpers from "../../modules/utils/helpers";
import DefaultLoader from "../Loaders";
import ProspectNotes from "../prospects/ProspectNotes";
import ActivitySteps from "./ActivitySteps";

let getIntlMessage = i18n.messages.getIntlMessage;

var Activity = React.createClass({
  mixins: [reactor.ReactMixin],

  propTypes: {
    activityId: React.PropTypes.number,
    onShowAddNoteModal: React.PropTypes.func,
    onShowSetProspect: React.PropTypes.func,
  },

  getDefaultProps() {
    return {
      activityId: null,
      onShowAddNoteModal: null,
      onShowSetProspect: null,
    };
  },

  getInitialState() {
    return {
      value: {},
    };
  },

  getDataBindings() {
    return {
      activity: activities.getters.activityGetter,
      setActivitiesReload: activities.getters.setActivitiesReloadGetter,
    };
  },

  componentWillReceiveProps(props) {
    if (props.show) {
      activities.actions.getActivity(props.activityId);
    } else if (!props.show && props.show !== this.props.show) {
      activities.actions.remActivity();
    }
  },

  onAddProspectNote() {
    var activity = this.state.activity.toJS();
    prospects.actions.addProspectNoteForm();
    this.props.onShowAddNoteModal(activity.prospect.id);
  },

  onSetProspect() {
    var activity = this.state.activity.toJS();
    prospects.actions.setProspectForm();
    this.props.onShowSetProspect(activity.prospect.id);
  },

  showTitle(activity) {
    //return getIntlMessage('View Activity');
    return activity.prospect.fullname;
  },

  showPhones(phones) {
    var n = phones
      .map(function (phone) {
        return helpers.formatPhone(phone);
      })
      .join(", ");
    return <h4>{n}</h4>;
  },

  showExcluded(card) {
    if (!card.prospect.has_consent) {
      return null;
    }
    return <span className="label label-danger card-label">X</span>;
  },

  showSchedule(card) {
    var schedule = card.step.schedule;
    if (!schedule) {
      return null;
    }
    return (
      <span className="label label-info card-label">
        <i className="fa fa-calendar"></i> {moment(schedule.starts_on).format("DD/MM")}
      </span>
    );
  },

  getGradeClassName(card) {
    var grade = "label-" + card.prospect.grade.label;
    var names = ["label", grade, "card-label"];
    return classNames(names);
  },

  render() {
    var activity = this.state.activity.toJS();
    if (_.isEmpty(activity)) {
      return <DefaultLoader />;
    }
    return (
      <Modal bsSize="medium" show={this.props.show} onHide={this.props.onHide} {...this.props}>
        <div className="color-line" />
        <Modal.Header>
          <Modal.Title>{this.showTitle(activity)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-horizontal activity-detail">
            <ButtonToolbar className="pull-right">
              <ButtonGroup>
                <Button bsSize="xsmall" onClick={this.onSetProspect}>
                  <i className="fa fa-pencil"></i>
                </Button>
                <Button bsSize="xsmall" onClick={this.onAddProspectNote}>
                  <i className="fa fa-comment-o"></i>
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
            <h3>{this.showTitle(activity)}</h3>
            {this.showPhones(activity.prospect.phone_numbers)}
            {[activity.prospect.address, activity.prospect.city].join(" ")}
            <br />
            {activity.prospect.email && <div>{activity.prospect.email}</div>}
            <div className="activity-relations">
              {activity.prospect.partner && (
                <span>
                  <i className="fa fa-venus-mars fa-fw"></i> {activity.prospect.partner}
                </span>
              )}
              &nbsp;
              {activity.prospect.referent && (
                <span>
                  <i className="fa fa-thumbs-o-up fa-fw"></i> {activity.prospect.referent}
                </span>
              )}
            </div>
            <div className="activity-quality">
              <span className={this.getGradeClassName(activity)}>{activity.prospect.grade.name}</span>
              {this.showExcluded(activity)}
              <span className="label label-light card-label prospect-source">{activity.prospect.source.name}</span>
              {this.showSchedule(activity)}
            </div>
          </div>
          <div className="activity-tabs">
            <Tabs defaultActiveKey={1}>
              <Tab eventKey={1} title={getIntlMessage("Notes")}>
                <ProspectNotes notes={activity.prospect.notes} />
              </Tab>
              <Tab eventKey={2} title={getIntlMessage("Comments")}>
                <div className="activity-comments">{activity.prospect.comments}</div>
              </Tab>
              <Tab eventKey={3} title={getIntlMessage("Steps")}>
                <ActivitySteps activity={activity} steps={activity.steps} />
              </Tab>
            </Tabs>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onHide}>{getIntlMessage("Close")}</Button>
        </Modal.Footer>
      </Modal>
    );
  },
});

export default Activity;
