import React from "react";
import { History } from "react-router";
import { Nav, NavItem, NavDropdown, MenuItem } from "react-bootstrap";

import auth from "../../modules/auth";
import i18n from "../../modules/i18n";
import notifier from "../../modules/notifier";
import prospects from "../../modules/prospects";
import AddProspect from "../prospects/AddProspect";

let getIntlMessage = i18n.messages.getIntlMessage;

var Navbar = React.createClass({
  mixins: [History],

  propTypes: {
    profile: React.PropTypes.object,
  },

  getDefaultProps() {
    return {
      profile: {},
    };
  },

  getInitialState() {
    return {
      loaded: false,
      value: {},
      showProspect: false,
    };
  },

  onShowAddProspect() {
    this.setState({
      showAddProspect: true,
    });
    prospects.actions.addProspectForm();
  },

  onHideAddProspect() {
    this.setState({
      showAddProspect: false,
    });
  },

  onLogout() {
    auth.actions.logout({});
    this.history.push("/login");
    notifier.actions.notify({
      message: getIntlMessage("You've been logged out"),
      type: "success",
    });
  },

  renderProfile() {
    var profile = this.props.profile;
    return (
      <div className="nav-item-profile">
        <div className="avatar">
          <img alt="me" className="img-responsive img-circle" src={profile.avatar_url} width={28} />
        </div>
        <div className="name">{profile.firstname}</div>
      </div>
    );
  },

  render() {
    return (
      <div className="navbar-right header-navbar-right">
        <Nav navbar className="no-borders">
          <NavItem onClick={this.onShowAddProspect}>
            <i className="pe-7s-add-user"></i>
          </NavItem>
          <NavDropdown title={this.renderProfile()} buttonClassName="label-menu-corner" noCaret>
            <MenuItem
              active={false}
              onClick={() => {
                this.history.push("/profile");
              }}
            >
              {getIntlMessage("Profile")}
            </MenuItem>
            <MenuItem
              active={false}
              href="http://us12.campaign-archive2.com/home/?u=5b9f556e1cc8917dd086ef62a&id=4f9a974e11"
              target="_blank"
            >
              {getIntlMessage("Bulletins")}
            </MenuItem>
            <MenuItem active={false} href="http://videos.prospectr.ca" target="_blank">
              {getIntlMessage("Videos")}
            </MenuItem>
            <MenuItem divider />
            <MenuItem>
              <span onClick={this.onLogout}>{getIntlMessage("Logout")}</span>
            </MenuItem>
          </NavDropdown>
        </Nav>
        <AddProspect show={this.state.showAddProspect} onHide={this.onHideAddProspect} {...this.props} />
      </div>
    );
  },
});

export default Navbar;
