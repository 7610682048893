import React, { Component, PropTypes } from "react";
import classNames from "classnames";

import auth from "../../modules/auth";
import i18n from "../../modules/i18n";
import AccountSelector from "./AccountSelector";

export default class Page extends Component {
  static propTypes = {
    className: PropTypes.string,
    profile: PropTypes.object,
    account: PropTypes.object,
    accounts: PropTypes.array,
    onAccountSelectorChange: PropTypes.func,
    showAccountsHeader: PropTypes.bool,
  };

  static defaultProps = {
    className: "",
    profile: {},
    account: {},
    accounts: [],
    onAccountSelectorChange: null,
    showAccountsHeader: false,
  };

  renderAccountHeader = () => {
    let profile = this.props.profile;
    let account = this.props.account;
    let accounts = this.props.accounts;
    let showAccountsHeader = this.props.showAccountsHeader;
    if (!showAccountsHeader) {
      return null;
    }
    let roles = profile.role_refids;
    let main_role = profile.main_role_refid;
    let hasNotAccess = auth.utils.hasOneRoleOf(roles, ["student", "advisor"]);
    if (hasNotAccess && main_role !== "manager") {
      return null;
    }
    return <AccountSelector account={account} accounts={accounts} onChange={this.props.onAccountSelectorChange} />;
  };

  render() {
    return (
      <div className={classNames("page", this.props.className)}>
        {this.renderAccountHeader()}
        {this.props.children}
      </div>
    );
  }
}
