import React, { Component, PropTypes } from "react";
import { connect } from "nuclear-js-react-addons";
import _ from "lodash";

import prospects from "../../modules/prospects";
import i18n from "../../modules/i18n";
import FormModal from "../forms/FormModal";

let getIntlMessage = i18n.messages.getIntlMessage;
let defaultValue = {};

export default
@connect((props) => ({
  addProspectNoteForm: prospects.getters.addProspectNoteFormGetter,
}))
class AddProspectNote extends Component {
  static propTypes = {
    show: React.PropTypes.bool,
    onHide: React.PropTypes.func,
    prospectId: PropTypes.number,
    noteCategory: PropTypes.string,
  };

  static defaultProps = {
    addProspectNoteForm: null,
    prospectId: null,
    noteCategory: "comment",
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      value: defaultValue,
    };
  }

  componentWillReceiveProps(props) {
    if (!props.show) {
      this.setState({
        value: defaultValue,
      });
    } else {
      if (this.props.addProspectNoteForm !== props.addProspectNoteForm) {
        let form = this.props.addProspectNoteForm.toJS();
        if (form.feedback.type === "info") {
          this.props.onHide();
        }
      }
    }
  }

  getTitle = () => {
    if (this.props.noteCategory === "call") {
      return getIntlMessage("Add Call");
    }
    return getIntlMessage("Add Note");
  };

  onFormSubmit = (event, value) => {
    if (value) {
      this.setState({
        value: value,
      });
      var data = _.extend(this.state.value, { category: this.props.noteCategory });
      prospects.actions.addProspectNoteStart(true);
      prospects.actions.addProspectNote(this.props.prospectId, data);
    }
  };

  onFormChange = (value) => {
    this.setState({ value });
  };

  render() {
    let form = this.props.addProspectNoteForm.toJS();
    let title = this.getTitle();
    return (
      <FormModal
        form={form}
        formType="add"
        formLayout="vertical"
        value={this.state.value}
        onFormChange={this.onFormChange}
        onFormSubmit={this.onFormSubmit}
        title={title}
        {...this.props}
      />
    );
  }
}
