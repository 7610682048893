import { Store, toImmutable } from "nuclear-js";

import forms from "../forms";
import i18n from "../i18n";
import actionTypes from "./actionTypes";
import * as schemas from "./schemas";

let getIntlMessage = i18n.messages.getIntlMessage;

let addOfficeFormDefaults = {
  type: schemas.AddOfficeSchema,
  options: schemas.AddOfficeSchemaOptions,
  feedback: {
    type: "info",
    messages: [],
  },
  started: false,
};

let setOfficeFormDefaults = {
  type: schemas.SetOfficeSchema,
  options: schemas.SetOfficeSchemaOptions,
  feedback: {
    type: "info",
    messages: [],
  },
  started: false,
};

let initialState = toImmutable({
  office: {},
  addOfficeForm: addOfficeFormDefaults,
  setOfficeForm: setOfficeFormDefaults,
  setOfficesReload: false,
});

export default new Store({
  getInitialState() {
    return initialState;
  },

  initialize() {
    this.on(actionTypes.SET_OFFICE_RESET, onSetOfficeReset);
    this.on(actionTypes.GET_OFFICE_SUCCESS, onGetOfficeSuccess);
    this.on(actionTypes.GET_OFFICE_FAIL, onGetOfficeFail);
    this.on(actionTypes.ADD_OFFICE_FORM, onAddOfficeForm);
    this.on(actionTypes.ADD_OFFICE_START, onAddOfficeStart);
    this.on(actionTypes.ADD_OFFICE_SUCCESS, onAddOfficeSuccess);
    this.on(actionTypes.ADD_OFFICE_FAIL, onAddOfficeFail);
    this.on(actionTypes.SET_OFFICE_FORM, onSetOfficeForm);
    this.on(actionTypes.SET_OFFICE_START, onSetOfficeStart);
    this.on(actionTypes.SET_OFFICE_SUCCESS, onSetOfficeSuccess);
    this.on(actionTypes.SET_OFFICE_FAIL, onSetOfficeFail);
    this.on(actionTypes.SET_OFFICES_RELOAD, onSetOfficesReload);
    this.on(actionTypes.REM_OFFICE, onRemOffice);
  },
});

function onSetOfficeReset(state) {
  return initialState;
}

function onGetOfficeSuccess(state, { data }) {
  return state.set("office", toImmutable(data.office));
}

function onGetOfficeFail(state, { data }) {
  return state.set("office", toImmutable({}));
}

function onAddOfficeForm(state) {
  return state.set("addOfficeForm", toImmutable(addOfficeFormDefaults));
}

function onAddOfficeStart(state, { data }) {
  return state.setIn(["addOfficeForm", "started"], data);
}

function onAddOfficeSuccess(state, { data }) {
  let feedback = {
    type: "success",
    messages: [getIntlMessage("Add Successful")],
  };
  let options = addOfficeFormDefaults.options;
  return state
    .setIn(["addOfficeForm", "feedback"], toImmutable(feedback))
    .setIn(["addOfficeForm", "options"], toImmutable(options));
}

function onAddOfficeFail(state, { data }) {
  let feedback = forms.utils.setFormFeedback(data);
  let options = forms.utils.setFormOptions(state.getIn(["addOfficeForm", "options"]).toJS(), data);
  return state
    .setIn(["addOfficeForm", "feedback"], toImmutable(feedback))
    .setIn(["addOfficeForm", "options"], toImmutable(options));
}

function onSetOfficeForm(state) {
  return state;
}

function onSetOfficeStart(state, { data }) {
  return state.setIn(["setOfficeForm", "started"], data);
}

function onSetOfficeSuccess(state, { data }) {
  return state.set("office", toImmutable(data.office)).set("setOfficeForm", toImmutable(setOfficeFormDefaults));
}

function onSetOfficeFail(state, { data }) {
  let feedback = forms.utils.setFormFeedback(data);
  let options = forms.utils.setFormOptions(state.getIn(["setOfficeForm", "options"]).toJS(), data);
  return state
    .setIn(["setOfficeForm", "feedback"], toImmutable(feedback))
    .setIn(["setOfficeForm", "options"], toImmutable(options));
}

function onSetOfficesReload(state, { data }) {
  return state.set("setOfficesReload", toImmutable(data));
}

function onRemOffice(state) {
  return state.set("office", toImmutable({}));
}
