import React, { Component, PropTypes } from "react";
import { connect } from "nuclear-js-react-addons";
import { Button, ButtonGroup } from "react-bootstrap";

import app from "../../modules/app";
import i18n from "../../modules/i18n";
import SubHeader from "../base/SubHeader";

import AddStaffingCandidate from "./AddStaffingCandidate";

let getIntlMessage = i18n.messages.getIntlMessage;

export default
@connect((props) => ({
  account: app.getters.appAccountGetter,
}))
class Header extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showAddCandidate: false,
    };
  }

  onShowAddCandidate = () => {
    this.setState({
      showAddCandidate: true,
    });
  };

  onHideAddCandidate = () => {
    this.setState({
      showAddCandidate: false,
    });
  };

  renderAddButton() {
    let profile = this.props.profile;
    if (profile.main_role_refid !== "sales-director") {
      return null;
    }
    return (
      <ButtonGroup>
        <Button name="add-candidate" bsSize="small" onClick={this.onShowAddCandidate}>
          {getIntlMessage("Add")}
        </Button>
      </ButtonGroup>
    );
  }

  render() {
    return (
      <SubHeader title={getIntlMessage("Staffing")}>
        {this.renderAddButton()}
        <AddStaffingCandidate show={this.state.showAddCandidate} onHide={this.onHideAddCandidate} {...this.props} />
      </SubHeader>
    );
  }
}
