import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router";
import { Row, Col } from "react-bootstrap";
import classNames from "classnames";
import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import _ from "lodash";

import reactor from "../../reactor";
import i18n from "../../modules/i18n";
import app from "../../modules/app";
import activities from "../../modules/activities";
import prospects from "../../modules/prospects";
import Page from "../base/Page";
import Content from "../base/Content";
import Header from "./Header";
import DefaultLoader from "../Loaders";
import Prospect from "../prospects/Prospect";
import SetProspect from "../prospects/SetProspect";
import AddProspectNote from "../prospects/AddProspectNote";
import CancelActivity from "./CancelActivity";
import SellActivity from "./SellActivity";
import PauseActivity from "./PauseActivity";
import ScheduleActivity from "./ScheduleActivity";
import CloneActivity from "./CloneActivity";
import StepCol from "./StepCol";

let getIntlMessage = i18n.messages.getIntlMessage;

var Board = React.createClass({
  propTypes: {
    profile: React.PropTypes.object,
    account: React.PropTypes.object,
    selector: React.PropTypes.object,
    board: React.PropTypes.object.isRequired,
    setBoardReload: React.PropTypes.bool.isRequired,
  },

  getInitialState() {
    return {
      showCancelActivity: false,
      showSellActivity: false,
      showPauseActivity: false,
      showScheduleActivity: false,
      showCloneActivity: false,
      activityId: null,
      stepId: null,
      showProspect: false,
      showSetProspect: false,
      showAddProspectNote: false,
      prospectId: null,
      noteCategory: null,
      data: {},
    };
  },

  componentWillMount() {
    this.setState({ data: this.props.board.toJS() });
  },

  componentWillReceiveProps(nextProps) {
    var nextBoard = nextProps.board.toJS();
    if (!_.isEmpty(nextBoard)) {
      this.setState({ data: nextBoard });
    }
  },

  onShowCancelActivity(id) {
    this.setState({
      showCancelActivity: true,
      activityId: id,
    });
    activities.actions.setActivityCancelForm();
  },

  onShowSellActivity(id) {
    this.setState({
      showSellActivity: true,
      activityId: id,
    });
    activities.actions.setActivitySellForm();
  },

  onShowPauseActivity(id) {
    this.setState({
      showPauseActivity: true,
      activityId: id,
    });
    activities.actions.setActivityPauseForm();
  },

  onShowScheduleActivity(id, stepId) {
    this.setState({
      showScheduleActivity: true,
      activityId: id,
      stepId: stepId,
    });
    activities.actions.setActivityScheduleForm();
  },

  onShowCloneActivity(id) {
    this.setState({
      showCloneActivity: true,
      activityId: id,
    });
    activities.actions.setActivityCloneForm();
  },

  // onShowActivity(id) {
  //   this.setState({
  //     showActivity: true,
  //     activityId: id
  //   });
  // },

  onHideCancelActivity() {
    this.setState({
      showCancelActivity: false,
    });
  },

  onHideSellActivity() {
    this.setState({
      showSellActivity: false,
    });
  },

  onHidePauseActivity() {
    this.setState({
      showPauseActivity: false,
    });
  },

  onHideScheduleActivity() {
    this.setState({
      showScheduleActivity: false,
    });
  },

  onHideCloneActivity() {
    this.setState({
      showCloneActivity: false,
    });
  },

  onHideActivity() {
    this.setState({
      showActivity: false,
    });
  },

  onShowProspect(id, activityId) {
    prospects.actions.remProspect();
    this.setState({
      showProspect: true,
      prospectId: id,
      activityId: activityId,
    });
  },

  onShowSetProspect(id) {
    this.setState({
      showSetProspect: true,
      prospectId: id,
    });
  },

  onShowAddProspectNote(id, category) {
    this.setState({
      showAddProspectNote: true,
      prospectId: id,
      noteCategory: category,
    });
  },

  onHideProspect() {
    this.setState({
      showProspect: false,
    });
  },

  onHideSetProspect() {
    this.setState({
      showSetProspect: false,
    });
  },

  onHideAddProspectNote() {
    this.setState({
      showAddProspectNote: false,
    });
  },

  onMoveCardToStep(source, targetStep, targetIndex) {
    var board = this.state.data;
    var boardArray = [];
    // could be more effective
    _.each(board, (value, key) => {
      _.each(value, (data) => {
        boardArray.push({
          id: _.get(data, "id"),
          step: key,
        });
      });
    });
    var sourceStep = _.result(_.find(boardArray, { id: source.id }), "step");
    var sourceData = _.get(board, sourceStep);
    var sourceIndex = _.findIndex(sourceData, { id: source.id });
    var sourceCard = sourceData[sourceIndex];
    if (!sourceCard) {
      return;
    }
    var targetStep = targetStep.replace("-", "_");
    var targetData = _.get(board, targetStep);
    var targetIndex = targetIndex || 0;
    sourceCard.dataStep = targetStep;
    sourceData.splice(sourceIndex, 1);
    targetData.splice(targetIndex, 0, sourceCard);
    this.setState({ data: board });
  },

  onMoveCard(sourceId, targetId) {
    var board = this.state.data;
    var boardArray = [];
    // could be more effective
    _.each(board, (value, key) => {
      _.each(value, (data) => {
        boardArray.push({
          id: _.get(data, "id"),
          step: key,
        });
      });
    });
    var sourceStep = _.result(_.find(boardArray, { id: sourceId }), "step");
    var sourceData = _.get(board, sourceStep);
    var sourceIndex = _.findIndex(sourceData, { id: sourceId });
    var sourceCard = sourceData[sourceIndex];
    if (!sourceCard) {
      return;
    }
    var targetStep = _.result(_.find(boardArray, { id: targetId }), "step");
    var targetData = _.get(board, targetStep);
    var targetIndex = _.findIndex(targetData, { id: targetId });
    sourceCard.dataStep = targetStep;
    sourceData.splice(sourceIndex, 1);
    targetData.splice(targetIndex, 0, sourceCard);
    this.setState({ data: board });
  },

  onAccountSelectorChange(id, role) {
    app.actions.getAppAccount(id);
    activities.actions.getBoard(id);
  },

  render() {
    var board = this.state.data;
    if (_.isEmpty(board)) {
      return <DefaultLoader />;
    }
    let account = this.props.account.toJS();
    let selector = this.props.selector.toJS();
    let visible =
      this.props.profile.main_role_refid === "student" || this.props.profile.main_role_refid === "advisor"
        ? true
        : false;
    let admin =
      this.props.profile.main_role_refid !== "student" && this.props.profile.main_role_refid !== "advisor"
        ? true
        : false;
    let boardClass = classNames({
      "activities-board": true,
      "admin-header": admin,
    });
    return (
      <Page
        {...this.props}
        account={account}
        accounts={selector.accounts}
        onAccountSelectorChange={this.onAccountSelectorChange}
        showAccountsHeader={true}
      >
        <Header {...this.props} />
        <Content className={boardClass}>
          <Row className="activities-board-row">
            <Col sm={4} className="activities-board-col">
              <StepCol
                dataStep="market-analysis"
                data={board.market_analysis}
                name={getIntlMessage("Market Analysis")}
                onShowCancelModal={this.onShowCancelActivity}
                onShowSellModal={this.onShowSellActivity}
                onShowPauseModal={this.onShowPauseActivity}
                onShowScheduleModal={this.onShowScheduleActivity}
                onShowCloneModal={this.onShowCloneActivity}
                onShowModal={this.onShowProspect}
                onMoveCardToStep={this.onMoveCardToStep}
                onMoveCard={this.onMoveCard}
                {...this.props}
              />
            </Col>
            <Col sm={4} className="activities-board-col">
              <StepCol
                dataStep="break-time"
                data={board.break_time}
                name={getIntlMessage("Break Time")}
                onShowCancelModal={this.onShowCancelActivity}
                onShowSellModal={this.onShowSellActivity}
                onShowPauseModal={this.onShowPauseActivity}
                onShowScheduleModal={this.onShowScheduleActivity}
                onShowCloneModal={this.onShowCloneActivity}
                onShowModal={this.onShowProspect}
                onMoveCardToStep={this.onMoveCardToStep}
                onMoveCard={this.onMoveCard}
                {...this.props}
              />
            </Col>
            <Col sm={4} className="activities-board-col">
              <StepCol
                dataStep="sales-interview"
                data={board.sales_interview}
                name={getIntlMessage("Sales Interview")}
                onShowCancelModal={this.onShowCancelActivity}
                onShowSellModal={this.onShowSellActivity}
                onShowPauseModal={this.onShowPauseActivity}
                onShowScheduleModal={this.onShowScheduleActivity}
                onShowCloneModal={this.onShowCloneActivity}
                onShowModal={this.onShowProspect}
                onMoveCardToStep={this.onMoveCardToStep}
                onMoveCard={this.onMoveCard}
                {...this.props}
              />
            </Col>
          </Row>
        </Content>
        <Prospect
          show={this.state.showProspect}
          onHide={this.onHideProspect}
          prospectId={this.state.prospectId}
          activityId={this.state.activityId}
          onShowSetProspect={this.onShowSetProspect}
          onShowAddProspectNote={this.onShowAddProspectNote}
          {...this.props}
        />
        <SetProspect
          show={this.state.showSetProspect}
          onHide={this.onHideSetProspect}
          prospectId={this.state.prospectId}
          {...this.props}
        />
        <AddProspectNote
          show={this.state.showAddProspectNote}
          onHide={this.onHideAddProspectNote}
          prospectId={this.state.prospectId}
          noteCategory={this.state.noteCategory}
          {...this.props}
        />
        <CancelActivity
          show={this.state.showCancelActivity}
          onHide={this.onHideCancelActivity}
          activityId={this.state.activityId}
          {...this.props}
        />
        <SellActivity
          show={this.state.showSellActivity}
          onHide={this.onHideSellActivity}
          activityId={this.state.activityId}
          {...this.props}
        />
        <PauseActivity
          show={this.state.showPauseActivity}
          onHide={this.onHidePauseActivity}
          activityId={this.state.activityId}
          {...this.props}
        />
        <ScheduleActivity
          show={this.state.showScheduleActivity}
          onHide={this.onHideScheduleActivity}
          activityId={this.state.activityId}
          stepId={this.state.stepId}
          {...this.props}
        />
        <CloneActivity
          show={this.state.showCloneActivity}
          onHide={this.onHideCloneActivity}
          activityId={this.state.activityId}
          {...this.props}
        />
      </Page>
    );
  },
});

var BoardProvider = React.createClass({
  mixins: [reactor.ReactMixin],
  getDataBindings() {
    return {
      account: app.getters.appAccountGetter,
      selector: app.getters.appAccountSelectorGetter,
      board: activities.getters.boardGetter,
      setBoardReload: activities.getters.setBoardReloadGetter,
    };
  },
  componentWillMount() {
    let account = this.state.account.toJS();
    activities.actions.getBoard(account.id);
  },
  componentWillReceiveProps(props) {
    if (props.profile !== this.props.profile && !_.isEmpty(props.profile)) {
      let account = this.state.account.toJS();
      activities.actions.getBoard(account.id);
    }
  },
  componentWillUpdate(nextProps, nextState) {
    var boardReload = nextState.setBoardReload;
    if (boardReload || boardReload !== this.state.setBoardReload) {
      let account = this.state.account.toJS();
      activities.actions.getBoard(account.id);
    }
  },
  render() {
    return (
      <Board
        profile={this.props.profile}
        account={this.state.account}
        selector={this.state.selector}
        board={this.state.board}
        setBoardReload={this.state.setBoardReload}
        {...this.props}
      />
    );
  },
});

export default DragDropContext(HTML5Backend)(BoardProvider);
