import React, { Component, PropTypes } from "react";
import { connect } from "nuclear-js-react-addons";
import { Panel, Button, ButtonToolbar } from "react-bootstrap";
import _ from "lodash";

import app from "../../modules/app";
import i18n from "../../modules/i18n";
import prospects from "../../modules/prospects";
import activities from "../../modules/activities";
import Page from "../base/Page";
import Content from "../base/Content";
import DataTables from "../DataTables";
import DefaultLoader from "../Loaders";
import Prospect from "../prospects/Prospect";
import SetProspect from "../prospects/SetProspect";
import AddProspectNote from "../prospects/AddProspectNote";
import CancelProspect from "../prospects/CancelProspect";
import Header from "./Header";
import AddProspectActivity from "./AddProspectActivity";

let getIntlMessage = i18n.messages.getIntlMessage;

let defaultAjaxParams = {
  mDataProp_1: "fullname",
  sSearch_1: null,
  bRegex_1: false,
  bSearchable_1: true,
  bSortable_1: true,
  sSearch: null,
  bRegex: false,
  iSortCol_0: 1,
  sSortDir_0: "asc",
  iSortingCols: 1,
};

export default
@connect((props) => ({
  account: app.getters.appAccountGetter,
  selector: app.getters.appAccountSelectorGetter,
  setProspectsReload: prospects.getters.setProspectsReloadGetter,
  addActivityBatch: activities.getters.addActivityBatchGetter,
}))
class CallList extends Component {
  static propTypes = {
    setProspectsReload: React.PropTypes.bool,
    addActivityBatch: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      showProspect: false,
      showSetProspect: false,
      showAddProspectNote: false,
      showAddProspectActivity: false,
      showCancelProspect: false,
      prospectId: null,
      noteCategory: null,
      selectedRows: [],
      doReload: false,
      ajaxParams: defaultAjaxParams,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.profile !== this.props.profile && !_.isEmpty(nextProps.profile)) {
      this.setState({
        doReload: true,
      });
    }
    let nextAccount = nextProps.account.toJS();
    if (nextProps.account !== this.props.account && !_.isEmpty(nextAccount)) {
      this.setState({
        doReload: true,
      });
    }
  }

  setColumns = () => {
    return [
      { data: "id", name: getIntlMessage("ID"), visible: false, searchable: false, orderable: false },
      { data: "fullname", name: getIntlMessage("Name") },
      { data: "phones", filter: "phones", name: getIntlMessage("Phone") },
      { data: "city", name: getIntlMessage("City") },
      { data: "source_name", name: getIntlMessage("Source") },
      { data: "grade_name", name: getIntlMessage("Grade"), filter: "label" },
      { data: "last_called_on", name: getIntlMessage("Last Called On"), filter: "datetime" },
      { data: "times_called", name: getIntlMessage("Times Called") },
      { data: "created_on", name: getIntlMessage("Created On"), filter: "datetime" },
      { data: null, filter: "actions", searchable: false, orderable: false },
    ];
  };

  setOrder = () => {
    return [[1, "asc"]];
  };

  setActions = () => {
    return [
      {
        help: getIntlMessage("View"),
        icon: "fa fa-eye",
        action: "onShowProspect",
        onClick: this.onShowProspect,
      },
      {
        help: getIntlMessage("Call"),
        icon: "fa fa-phone",
        action: "onShowAddProspectNote",
        onClick: this.onShowAddProspectNote,
      },
      {
        help: getIntlMessage("Add"),
        icon: "fa fa-plus",
        action: "onShowAddProspectActivity",
        onClick: this.onShowAddProspectActivity,
      },
      {
        help: getIntlMessage("Cancel"),
        icon: "fa fa-ban",
        action: "onShowCancelProspect",
        onClick: this.onShowCancelProspect,
      },
      {
        help: getIntlMessage("Remove"),
        icon: "fa fa-remove",
        action: "onRemoveProspectFromCallList",
        onClick: this.onRemoveProspectFromCallList,
      },
    ];
  };

  mDataProps = () => {
    let props = [];
    _.forEach(this.setColumns(), (n, key) => {
      if (key.data !== null) {
        props.push(n.data);
      }
    });
    return props;
  };

  onShowProspect = (id) => {
    prospects.actions.remProspect();
    this.setState({
      showProspect: true,
      prospectId: id,
    });
  };

  onShowSetProspect = (id) => {
    this.setState({
      showSetProspect: true,
      prospectId: id,
    });
  };

  onShowAddProspectNote = (id, category) => {
    let cat = category || "call";
    prospects.actions.addProspectNoteForm();
    this.setState({
      showAddProspectNote: true,
      prospectId: id,
      noteCategory: cat,
    });
  };

  onShowAddProspectActivity = (id) => {
    this.setState({
      showAddProspectActivity: true,
      prospectId: id,
    });
  };

  onShowCancelProspect = (id) => {
    this.setState({
      showCancelProspect: true,
      prospectId: id,
    });
  };

  onHideProspect = () => {
    this.setState({
      showProspect: false,
    });
  };

  onHideSetProspect = () => {
    this.setState({
      showSetProspect: false,
    });
  };

  onHideAddProspectNote = () => {
    this.setState({
      showAddProspectNote: false,
    });
  };

  onHideAddProspectActivity = () => {
    this.setState({
      showAddProspectActivity: false,
    });
  };

  onHideCancelProspect = () => {
    this.setState({
      showCancelProspect: false,
    });
  };

  onSelectRow = (rows) => {
    this.setState({
      selectedRows: rows,
    });
  };

  onAjax = (ajax) => {
    let params = ajax.params();
    if (!_.isEqual(this.state.ajaxParams, params)) {
      this.setState({ ajaxParams: params });
    }
  };

  onRemoveProspectFromCallList = (id) => {
    let batch = [{ prospect_id: id, refid: "available" }];
    prospects.actions.setProspectStatusBatchStart(true);
    prospects.actions.setProspectStatusBatch({ items: batch });
  };

  onRemoveFromCallList = () => {
    let batch = [];
    let rows = this.state.selectedRows;
    _.forEach(rows, (row) => {
      batch.push({ prospect_id: row.id, refid: "available" });
    });
    prospects.actions.setProspectStatusBatchStart(true);
    prospects.actions.setProspectStatusBatch({ items: batch });
  };

  onAddToActivities = () => {
    let batch = [];
    let rows = this.state.selectedRows;
    _.forEach(rows, (row) => {
      batch.push({ prospect_id: row.id });
    });
    activities.actions.addActivityBatchStart(true);
    activities.actions.addActivityBatch({ items: batch });
  };

  onAccountSelectorChange = (id, role) => {
    app.actions.getAppAccount(id);
  };

  renderDatatables = (doReload) => {
    let account = this.props.account.toJS();
    let addActivityBatch = this.props.addActivityBatch.toJS();
    if (!account || _.isEmpty(account)) {
      return <DefaultLoader />;
    }
    return (
      <Panel>
        <DataTables
          domId={"calls-datatables"}
          ajaxSource={process.env.__APIURL__ + "/calls/datatables/" + account.id}
          stateSave={true}
          responsive={true}
          columns={this.setColumns()}
          order={this.setOrder()}
          actions={this.setActions()}
          onSelectRow={this.onSelectRow}
          onAjax={this.onAjax}
          doReload={doReload}
          {...this.props}
        />
        <ButtonToolbar className="pull-right">
          <Button
            name="add-activity"
            bsSize="medium"
            bsStyle="primary"
            disabled={addActivityBatch.started}
            onClick={this.onAddToActivities}
          >
            {getIntlMessage("Add To Activities")}
          </Button>
          <Button name="remove-call-list" bsSize="medium" bsStyle="default" onClick={this.onRemoveFromCallList}>
            {getIntlMessage("Remove From Call List")}
          </Button>
        </ButtonToolbar>
      </Panel>
    );
  };

  render() {
    let account = this.props.account.toJS();
    let selector = this.props.selector.toJS();
    let doReload = this.state.doReload || this.props.setProspectsReload;
    let ajaxParams = this.state.ajaxParams;
    return (
      <Page
        {...this.props}
        account={account}
        accounts={selector.accounts}
        onAccountSelectorChange={this.onAccountSelectorChange}
        showAccountsHeader={true}
      >
        <Header ajaxParams={ajaxParams} {...this.props} />
        <Content className="calls">{this.renderDatatables(doReload)}</Content>
        <Prospect
          show={this.state.showProspect}
          onHide={this.onHideProspect}
          onShowSetProspect={this.onShowSetProspect}
          onShowAddProspectNote={this.onShowAddProspectNote}
          prospectId={this.state.prospectId}
          {...this.props}
        />
        <SetProspect
          show={this.state.showSetProspect}
          onHide={this.onHideSetProspect}
          prospectId={this.state.prospectId}
          {...this.props}
        />
        <AddProspectNote
          show={this.state.showAddProspectNote}
          onHide={this.onHideAddProspectNote}
          prospectId={this.state.prospectId}
          noteCategory={this.state.noteCategory}
          {...this.props}
        />
        <CancelProspect
          show={this.state.showCancelProspect}
          onHide={this.onHideCancelProspect}
          prospectId={this.state.prospectId}
          {...this.props}
        />
        <AddProspectActivity
          show={this.state.showAddProspectActivity}
          onHide={this.onHideAddProspectActivity}
          prospectId={this.state.prospectId}
          {...this.props}
        />
      </Page>
    );
  }
}
