import React from "react";
import _ from "lodash";

import reactor from "../../reactor";
import roles from "../../modules/roles";
import i18n from "../../modules/i18n";
import FormModal from "../forms/FormModal";

let getIntlMessage = i18n.messages.getIntlMessage;

var SetRole = React.createClass({
  mixins: [reactor.ReactMixin],

  propTypes: {
    roleId: React.PropTypes.number,
  },

  getDefaultProps() {
    return {
      roleId: null,
    };
  },

  getInitialState() {
    return {
      value: {},
    };
  },

  getDataBindings() {
    return {
      role: roles.getters.roleGetter,
      setRoleForm: roles.getters.setRoleFormGetter,
      setRolesReload: roles.getters.setRolesReloadGetter,
    };
  },

  componentWillReceiveProps(props) {
    if (props.show && (_.isEmpty(this.state.value) || this.state.setRolesReload)) {
      roles.actions.getRole(props.roleId);
    } else if (!props.show && !_.isEmpty(this.state.value)) {
      roles.actions.remRole();
    }
  },

  componentDidUpdate(prevProps, prevState) {
    if (this.state.role !== prevState.role) {
      this.setState({ value: this.state.role.toJS() });
    }
  },

  onFormSubmit(event, value) {
    if (value) {
      this.setState({
        value: value,
      });
      roles.actions.setRoleStart(true);
      roles.actions.setRole(this.props.roleId, value);
    }
  },

  onFormChange(value) {
    this.setState({ value });
  },

  render() {
    var form = this.state.setRoleForm.toJS();
    return (
      <FormModal
        form={form}
        formType="set"
        value={this.state.value}
        onFormChange={this.onFormChange}
        onFormSubmit={this.onFormSubmit}
        title={getIntlMessage("Edit Role")}
        {...this.props}
      />
    );
  },
});

export default SetRole;
