import t from "tcomb-form";

import forms from "../forms";
import i18n from "../i18n";

let getIntlMessage = i18n.messages.getIntlMessage;

export var AddPlanificationAdvisorSchema = t.struct({
  advisor_id: t.Num,
  planned: t.Num,
  completed: t.Num,
  commission: t.Num,
  pac: t.Num,
  iaah: t.Num,
  excellence: t.Num,
  reference: t.Num,
});

export var SetPlanificationAdvisorSchema = t.struct({
  advisor_id: t.Num,
  planned: t.Num,
  completed: t.Num,
  commission: t.Num,
  pac: t.Num,
  iaah: t.Num,
  excellence: t.Num,
  reference: t.Num,
});

export var AddPlanificationAdvisorSchemaOptions = {
  fields: {
    advisor_id: {
      type: "text",
      label: getIntlMessage("Advisor"),
      factory: forms.widgets.KDropDownListWidget,
      attrs: {
        meta: {
          reactive: true,
        },
        culture: "fr-CA",
        optionLabel: getIntlMessage("Select..."),
        dataSource: {
          transport: {
            read: {
              url: process.env.__APIURL__ + "/choices/accounts/advisors",
              dataType: "json",
            },
          },
          schema: {
            data: "results",
          },
        },
      },
    },
    planned: {
      type: "text",
      label: getIntlMessage("Planned"),
    },
    completed: {
      type: "text",
      label: getIntlMessage("Completed"),
    },
    commission: {
      type: "text",
      label: getIntlMessage("Commission"),
    },
    pac: {
      type: "text",
      label: getIntlMessage("PAC"),
    },
    iaah: {
      type: "text",
      label: getIntlMessage("IAAH"),
    },
    excellence: {
      type: "text",
      label: getIntlMessage("Excellence"),
    },
    reference: {
      type: "text",
      label: getIntlMessage("Reference"),
    },
  },
};

export var SetPlanificationAdvisorSchemaOptions = {
  fields: {
    advisor_id: {
      type: "hidden",
    },
    planned: {
      type: "text",
      label: getIntlMessage("Planned"),
    },
    completed: {
      type: "text",
      label: getIntlMessage("Completed"),
    },
    commission: {
      type: "text",
      label: getIntlMessage("Commission"),
    },
    pac: {
      type: "text",
      label: getIntlMessage("PAC"),
    },
    iaah: {
      type: "text",
      label: getIntlMessage("IAAH"),
    },
    excellence: {
      type: "text",
      label: getIntlMessage("Excellence"),
    },
    reference: {
      type: "text",
      label: getIntlMessage("Reference"),
    },
  },
};
