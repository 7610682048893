import React from "react";
import { Modal, ButtonGroup, ButtonToolbar, Button, Tabs, Tab } from "react-bootstrap";
import t from "tcomb-form";
import classNames from "classnames";
import moment from "moment";
import _ from "lodash";

import reactor from "../../reactor";
import activities from "../../modules/activities";
import prospects from "../../modules/prospects";
import i18n from "../../modules/i18n";
import * as helpers from "../../modules/utils/helpers";
import ActivitySteps from "../activities/ActivitySteps";
import ProspectNotes from "./ProspectNotes";

let getIntlMessage = i18n.messages.getIntlMessage;

var Prospect = React.createClass({
  mixins: [reactor.ReactMixin],

  propTypes: {
    prospectId: React.PropTypes.number,
    activityId: React.PropTypes.number,
    onShowSetProspect: React.PropTypes.func,
    onShowAddProspectNote: React.PropTypes.func,
  },

  getDefaultProps() {
    return {
      prospectId: null,
      activityId: null,
      onShowSetProspect: null,
      onShowAddProspectNote: null,
    };
  },

  getDataBindings() {
    return {
      prospect: prospects.getters.prospectGetter,
      activity: activities.getters.activityGetter,
      setActivitiesReload: activities.getters.setActivitiesReloadGetter,
    };
  },

  componentWillReceiveProps(props) {
    if (props.show) {
      prospects.actions.getProspect(props.prospectId);
      if (props.activityId) {
        activities.actions.getActivity(props.activityId);
      }
    } else if (!props.show && props.show !== this.props.show) {
      // prospects.actions.remProspect();
      // activities.actions.remActivity();
    }
  },

  onShowSetProspect() {
    prospects.actions.setProspectForm();
    this.props.onShowSetProspect(this.props.prospectId);
  },

  onShowAddProspectNote() {
    prospects.actions.addProspectNoteForm();
    this.props.onShowAddProspectNote(this.props.prospectId, "comment");
  },

  onShowAddProspectCall() {
    prospects.actions.addProspectNoteForm();
    this.props.onShowAddProspectNote(this.props.prospectId, "call");
  },

  onHideSetProspect() {
    this.setState({
      showSetProspect: false,
    });
  },

  onHideAddProspectNote() {
    this.setState({
      showAddProspectNote: false,
    });
  },

  showTitle(prospect) {
    return prospect.fullname;
  },

  showPhones(data) {
    var phones = data.map((phone, index) => {
      let phoneLabel = "fa fa-phone";
      let label = phone.label;
      if (label === "home") {
        phoneLabel = "fa fa-home";
      } else if (label === "mobile") {
        phoneLabel = "fa fa-mobile";
      }
      return (
        <span className="card-phone">
          <i className={phoneLabel}></i> {helpers.formatPhone(phone.number)}
        </span>
      );
    });
    return <h4>{phones}</h4>;
  },

  showExcluded(prospect) {
    if (!prospect.has_consent) {
      return null;
    }
    return <span className="label label-danger card-label">X</span>;
  },

  showSchedule(activityId) {
    if (!activityId) {
      return null;
    }
    var activity = this.state.activity.toJS();
    var schedule = _.get(activity, "step.schedule");
    if (!schedule) {
      return null;
    }
    return (
      <span className="label label-info card-label">
        <i className="fa fa-calendar"></i> {moment(schedule.starts_on).format("DD/MM")}
      </span>
    );
  },

  showActivitySteps(key) {
    if (!this.props.activityId) {
      return null;
    }
    var activity = this.state.activity.toJS();
    return (
      <Tab eventKey={key} title={getIntlMessage("Steps")}>
        <ActivitySteps activity={activity} steps={activity.steps} />
      </Tab>
    );
  },

  showCalledInfo(prospect) {
    var when = moment(prospect.last_called_on);
    return (
      <div>
        <i className="fa fa-phone"></i>&nbsp;
        {prospect.times_called} {getIntlMessage("Times Called").toLowerCase()}, {getIntlMessage("last time on")}{" "}
        {when.format("DD/MM/YYYY")}
      </div>
    );
  },

  showAvailability(prospect) {
    var isDay = prospect.is_available_day;
    var isEvening = prospect.is_available_evening;
    var none = (
      <span>
        <i className="fa fa-ban"></i> {getIntlMessage("None").toLowerCase()}
      </span>
    );
    var day = (
      <span>
        <i className="fa fa-sun-o"></i> {getIntlMessage("Day").toLowerCase()}
      </span>
    );
    var evening = (
      <span>
        <i className="fa fa-moon-o"></i> {getIntlMessage("Evening").toLowerCase()}
      </span>
    );
    var avail = none;
    if (isDay && !isEvening) {
      avail = day;
    } else if (!isDay && isEvening) {
      avail = evening;
    } else if (isDay && isEvening) {
      avail = (
        <span>
          {day} {evening}
        </span>
      );
    }
    return <div>{avail}</div>;
  },

  getGradeClassName(prospect) {
    var grade = "label-" + prospect.grade.label;
    var names = ["label", grade, "card-label"];
    return classNames(names);
  },

  renderCallButton(prospect) {
    // if (prospect.status.status.refid !== 'listed') {
    //   return null
    // }
    return (
      <Button bsSize="xsmall" onClick={this.onShowAddProspectCall}>
        <i className="fa fa-phone"></i>
      </Button>
    );
  },

  render() {
    var prospect = this.state.prospect.toJS();
    if (_.isEmpty(prospect)) {
      return <div />;
    }
    return (
      <div>
        <Modal bsSize="medium" show={this.props.show} onHide={this.props.onHide} {...this.props}>
          <div className="color-line" />
          <Modal.Header>
            <Modal.Title>{this.showTitle(prospect)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-horizontal activity-detail">
              <ButtonToolbar className="pull-right">
                <ButtonGroup>
                  <Button bsSize="xsmall" onClick={this.onShowSetProspect}>
                    <i className="fa fa-pencil"></i>
                  </Button>
                  <Button bsSize="xsmall" onClick={this.onShowAddProspectNote}>
                    <i className="fa fa-comment-o"></i>
                  </Button>
                  {this.renderCallButton(prospect)}
                </ButtonGroup>
              </ButtonToolbar>
              <h3>{this.showTitle(prospect)}</h3>
              {this.showPhones(prospect.phone_numbers_with_label)}
              {[prospect.address, prospect.city].join(" ")}
              <br />
              {prospect.email && <div>{prospect.email}</div>}
              {prospect.last_called_on && this.showCalledInfo(prospect)}
              {this.showAvailability(prospect)}
              <div className="activity-relations">
                {prospect.partner && (
                  <span>
                    <i className="fa fa-venus-mars fa-fw"></i> {prospect.partner}
                  </span>
                )}
                &nbsp;
                {prospect.referent && (
                  <span>
                    <i className="fa fa-thumbs-o-up fa-fw"></i> {prospect.referent}
                  </span>
                )}
              </div>
              <div className="activity-quality">
                <span className={this.getGradeClassName(prospect)}>{prospect.grade.name}</span>
                {this.showExcluded(prospect)}
                <span className="label label-light card-label prospect-source">{prospect.source.name}</span>
                {this.showSchedule(this.props.activityId)}
              </div>
            </div>
            <div className="activity-tabs">
              <Tabs defaultActiveKey={1}>
                <Tab eventKey={1} title={getIntlMessage("Notes")}>
                  <ProspectNotes notes={prospect.notes} />
                </Tab>
                <Tab eventKey={2} title={getIntlMessage("Comments")}>
                  <div className="activity-comments">{prospect.comments}</div>
                </Tab>
                {this.showActivitySteps(3)}
              </Tabs>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.props.onHide}>{getIntlMessage("Close")}</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  },
});

export default Prospect;
