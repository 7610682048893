import React, { Component, PropTypes } from "react";
import { connect } from "nuclear-js-react-addons";
import { Row, Col, Button, ButtonGroup, Panel } from "react-bootstrap";
import _ from "lodash";

import planifications from "../../modules/planifications";

import app from "../../modules/app";
import i18n from "../../modules/i18n";
import Page from "../base/Page";
import Content from "../base/Content";
import DefaultLoader from "../Loaders";
import Header from "./Header";
import AddPlanificationAdvisor from "./AddPlanificationAdvisor";
import SetPlanificationAdvisor from "./SetPlanificationAdvisor";
import DeletePlanificationAdvisor from "./DeletePlanificationAdvisor";
import PlanificationPeriodAdvisors from "./PeriodAdvisors";

let getIntlMessage = i18n.messages.getIntlMessage;

export default
@connect((props) => ({
  account: app.getters.appAccountGetter,
  period: planifications.getters.planificationPeriodGetter,
}))
class PlanificationPeriod extends Component {
  static propTypes = {
    period: PropTypes.object,
  };

  static defaultProps = {
    period: {},
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      showAddPlanificationAdvisor: false,
      showSetPlanificationAdvisor: false,
      advisordId: null,
      periodId: null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.period !== prevProps.period) {
      let period = this.props.period.toJS();
      if (this.state.periodId !== period.data.id) {
        this.setState({ periodId: period.data.id });
      }
    }
  }

  onShowAddPlanificationAdvisor = () => {
    this.setState({
      showAddPlanificationAdvisor: true,
    });
  };

  onShowDeletePlanificationAdvisor = (advisorId) => {
    this.setState({
      advisorId: advisorId,
      showDeletePlanificationAdvisor: true,
    });
  };

  onHideAddPlanificationAdvisor = () => {
    this.setState({
      showAddPlanificationAdvisor: false,
    });
  };

  onHideDeletePlanificationAdvisor = () => {
    this.setState({
      showDeletePlanificationAdvisor: false,
    });
  };

  onAddPlanificationPeriod = () => {
    let profile = this.props.profile;
    let account = this.props.account.toJS();
    if (profile.main_role_refid === "sales-director") {
      account = profile;
    }
    let period = this.props.period.toJS();
    planifications.actions.addPlanificationPeriod(period.selector.year, period.selector.week, account.id);
  };

  onShowSetPlanificationAdvisor = (id) => {
    this.setState({
      showSetPlanificationAdvisor: true,
      advisorId: id,
    });
  };

  onHideSetPlanificationAdvisor = () => {
    planifications.actions.setPlanificationAdvisorReset();
    this.setState({
      showSetPlanificationAdvisor: false,
    });
  };

  renderAdvisors = () => {
    let period = this.props.period.toJS().data;
    let advisors;

    if (_.isEmpty(period)) {
      advisors = (
        <Panel className="hpanel hyellow text-center">
          <h2 className="text-warning">{getIntlMessage("The week you are looking for does not exist!")}</h2>
          <h4>{getIntlMessage("Would you like to add this week to your planning?")}</h4>
          <Button name="add-planification" bsSize="medium" bsStyle="primary" onClick={this.onAddPlanificationPeriod}>
            {getIntlMessage("Yes")}
          </Button>
        </Panel>
      );
    } else {
      advisors = (
        <div>
          <PlanificationPeriodAdvisors
            advisors={period.advisors}
            onShowSetPlanificationAdvisor={this.onShowSetPlanificationAdvisor}
            onShowDeletePlanificationAdvisor={this.onShowDeletePlanificationAdvisor}
            period={period}
          />
          <ButtonGroup className="pull-right">
            <Button name="add-advisor" bsSize="medium" bsStyle="primary" onClick={this.onShowAddPlanificationAdvisor}>
              {getIntlMessage("Add Advisor")}
            </Button>
          </ButtonGroup>
        </div>
      );
    }
    return advisors;
  };

  render() {
    return (
      <Page {...this.props}>
        <Header {...this.props} />
        <Content className="planification-period">{this.renderAdvisors()}</Content>
        <AddPlanificationAdvisor
          show={this.state.showAddPlanificationAdvisor}
          onHide={this.onHideAddPlanificationAdvisor}
          periodId={this.state.periodId}
          {...this.props}
        />
        <SetPlanificationAdvisor
          show={this.state.showSetPlanificationAdvisor}
          onHide={this.onHideSetPlanificationAdvisor}
          periodId={this.state.periodId}
          advisorId={this.state.advisorId}
          {...this.props}
        />
        <DeletePlanificationAdvisor
          show={this.state.showDeletePlanificationAdvisor}
          onHide={this.onHideDeletePlanificationAdvisor}
          periodId={this.state.periodId}
          advisorId={this.state.advisorId}
          {...this.props}
        />
      </Page>
    );
  }
}
