import React, { Component, PropTypes } from "react";
import { connect } from "nuclear-js-react-addons";

import staffing from "../../modules/staffing";

import Page from "../base/Page";
import Content from "../base/Content";
import i18n from "../../modules/i18n";
import DefaultLoader from "../Loaders";
import Header from "./Header";
import StaffingCandidates from "./StaffingCandidates";

let getIntlMessage = i18n.messages.getIntlMessage;

export default
@connect((props) => ({
  candidates: staffing.getters.staffingCandidatesGetter,
}))
class Staffing extends Component {
  static propTypes = {
    candidates: PropTypes.object,
  };

  static defaultProps = {
    candidates: {},
  };

  componentWillMount() {
    staffing.actions.getStaffingCandidatesStart();
    staffing.actions.getStaffingCandidates();
  }

  renderCandidates() {
    let profile = this.props.profile;
    let candidates = this.props.candidates.toJS();
    let role = profile.main_role_refid;
    if (role === "sales-director" || role === "office-director" || role === "supervisor" || role === "manager") {
      return <StaffingCandidates role={role} candidates={candidates.data.candidates} profile={this.props.profile} />;
    } else {
      return <h3>NO ACCESS</h3>;
    }
  }

  render() {
    return (
      <Page {...this.props}>
        <Header {...this.props} />
        <Content className="staffing-data">{this.renderCandidates()}</Content>
      </Page>
    );
  }
}
