import React from "react";
import * as ReactDOM from "react-dom";
import { History, State, Link } from "react-router";
import metisMenu from "metismenu";
import classNames from "classnames";
import _ from "lodash";

import i18n from "../../modules/i18n";
let getIntlMessage = i18n.messages.getIntlMessage;

var RootMenu = React.createClass({
  propTypes: {
    profile: React.PropTypes.object,
    roles: React.PropTypes.array,
  },

  getDefaultProps() {
    return {
      profile: {},
      roles: [],
    };
  },

  render() {
    var id = this.props.id;
    var cnames = classNames("nav", this.props.className);
    return (
      <ul className={cnames} id={id}>
        {this.props.children}
      </ul>
    );
  },
});

var ParentMenuItem = React.createClass({
  mixins: [History, State],

  propTypes: {
    profile: React.PropTypes.object,
    roles: React.PropTypes.array,
  },

  getDefaultProps() {
    return {
      profile: {},
      roles: [],
    };
  },

  render() {
    var profile = this.props.profile;
    var roles = this.props.roles;
    if (!_.isEmpty(roles)) {
      var permitted = _.intersection(profile.role_refids, this.props.roles);
      if (_.isEmpty(profile) || _.isEmpty(permitted)) {
        return <div />;
      }
    }
    var isActive = this.props.history.isActive(this.props.to, this.props.query);
    var className = isActive ? "active" : "";
    return (
      <li className={className}>
        <Link to={this.props.to} query={this.props.query}>
          <span className="nav-label">{this.props.title}</span>
          <span className="fa arrow"></span>
        </Link>
        {this.props.children}
      </li>
    );
  },
});

var MenuItem = React.createClass({
  mixins: [History, State],

  propTypes: {
    profile: React.PropTypes.object,
    className: React.PropTypes.string,
  },

  getDefaultProps() {
    return {
      profile: {},
      className: "",
    };
  },

  getClassName() {
    let className = this.props.className;
    const isActive = this.props.history.isActive(this.props.to, this.props.query);
    if (isActive) {
      className += " active";
    }
    return className;
  },

  render() {
    var profile = this.props.profile;
    var roles = this.props.roles;
    if (!_.isEmpty(roles)) {
      var permitted = _.intersection(profile.role_refids, this.props.roles);
      if (_.isEmpty(profile) || _.isEmpty(permitted)) {
        return <div />;
      }
    }
    let className = this.getClassName();
    return (
      <li className={className.trim()}>
        <Link to={this.props.to} query={this.props.query || ""}>
          {this.props.children}
        </Link>
      </li>
    );
  },
});

var Sidemenu = React.createClass({
  propTypes: {
    profile: React.PropTypes.object,
  },

  getDefaultProps() {
    return {
      profile: {},
    };
  },

  componentDidMount() {
    var elem = ReactDOM.findDOMNode(this);
    var $elem = $(elem);
    $elem.metisMenu();
  },

  componentDidUpdate() {
    var elem = ReactDOM.findDOMNode(this);
    var $elem = $(elem);
    $elem.metisMenu();
  },

  render() {
    return (
      <RootMenu id="side-menu" {...this.props}>
        <MenuItem to="/dashboard" {...this.props}>
          <span className="nav-label">{getIntlMessage("Dashboard")}</span>
        </MenuItem>
        <MenuItem to="/prospects/available" {...this.props}>
          <span className="nav-label">{getIntlMessage("Prospects")}</span>
        </MenuItem>
        <MenuItem to="/calls" {...this.props}>
          <span className="nav-label">{getIntlMessage("Call List")}</span>
        </MenuItem>
        <MenuItem to="/activities/board" {...this.props}>
          <span className="nav-label">{getIntlMessage("Activities")}</span>
        </MenuItem>
        <MenuItem
          to="/planifications"
          roles={["manager", "supervisor", "office-director", "sales-director"]}
          className={"nav-item-admin"}
          {...this.props}
        >
          <span className="nav-label">{getIntlMessage("Planifications")}</span>
        </MenuItem>
        <MenuItem
          to="/staffing"
          roles={["manager", "supervisor", "office-director", "sales-director"]}
          className={"nav-item-admin"}
          {...this.props}
        >
          <span className="nav-label">{getIntlMessage("Staffing")}</span>
        </MenuItem>
        <MenuItem
          to="/organizational-chart"
          roles={["manager", "supervisor", "office-director", "sales-director"]}
          className={"nav-item-admin"}
          {...this.props}
        >
          <span className="nav-label">{getIntlMessage("Organizational Chart")}</span>
        </MenuItem>
        <ParentMenuItem to="/system" title={getIntlMessage("System")} roles={["manager"]} {...this.props}>
          <RootMenu className="nav-second-level" {...this.props}>
            <MenuItem to="/system/accounts" roles={["manager"]} {...this.props}>
              {getIntlMessage("Accounts")}
            </MenuItem>
            <MenuItem to="/system/offices" roles={["manager"]} {...this.props}>
              {getIntlMessage("Offices")}
            </MenuItem>
            <MenuItem to="/system/roles" roles={["manager"]} {...this.props}>
              {getIntlMessage("Roles")}
            </MenuItem>
            <MenuItem to="/system/option-lists" roles={["manager"]} {...this.props}>
              {getIntlMessage("Option Lists")}
            </MenuItem>
            <MenuItem to="/system/options" roles={["manager"]} {...this.props}>
              {getIntlMessage("Options")}
            </MenuItem>
          </RootMenu>
        </ParentMenuItem>
      </RootMenu>
    );
  },
});

var Sidebar = React.createClass({
  propTypes: {
    profile: React.PropTypes.object,
  },

  getDefaultProps() {
    return {
      profile: {},
    };
  },

  render() {
    const logo = new URL("../../img/ia-logo-white.png", import.meta.url);
    return (
      <aside id="menu">
        <div className="color-line" />
        <div id="sidebar-logo" className="light-version">
          <img src={logo} alt="logo" height="30" /> • PROSPECTR
        </div>
        <div id="navigation">
          <Sidemenu {...this.props} />
        </div>
      </aside>
    );
  },
});

export default Sidebar;
