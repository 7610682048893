import axios from "axios";

import reactor from "../../reactor";
import i18n from "../i18n";
import notifier from "../notifier";
import actionTypes from "./actionTypes";

let getIntlMessage = i18n.messages.getIntlMessage;

export function getStaffingCandidates() {
  axios
    .get(process.env.__APIURL__ + "/staffings")
    .then((res) => {
      reactor.dispatch(actionTypes.GET_STAFFING_CANDIDATES_SUCCESS, { data: res.data });
      reactor.dispatch(actionTypes.GET_STAFFING_CANDIDATES_START, { data: false });
    })
    .catch((res) => {
      reactor.dispatch(actionTypes.GET_STAFFING_CANDIDATES_FAIL, { data: res.data });
      reactor.dispatch(actionTypes.GET_STAFFING_CANDIDATES_START, { data: false });
    });
}

export function getStaffingCandidatesStart(data) {
  reactor.dispatch(actionTypes.GET_STAFFING_CANDIDATES_START, { data: true });
}

export function setStaffingReset() {
  reactor.dispatch(actionTypes.SET_STAFFING_RESET);
}

export function addStaffingCandidateForm() {
  reactor.dispatch(actionTypes.ADD_STAFFING_CANDIDATE_FORM);
}

export function addStaffingCandidateStart(data) {
  reactor.dispatch(actionTypes.ADD_STAFFING_CANDIDATE_START, { data });
}

export function getStaffingCandidate(id) {
  axios
    .get(process.env.__APIURL__ + "/staffings/" + id)
    .then((res) => {
      reactor.dispatch(actionTypes.GET_STAFFING_CANDIDATE_SUCCESS, { data: res.data });
    })
    .catch((res) => {
      reactor.dispatch(actionTypes.GET_STAFFING_CANDIDATE_FAIL, { data: res.data });
    });
}

export function setStaffingCandidateReset() {
  reactor.dispatch(actionTypes.SET_STAFFING_CANDIDATE_RESET);
}

export function addStaffingCandidate(data) {
  axios
    .post(process.env.__APIURL__ + "/staffings", data)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.ADD_STAFFING_CANDIDATE_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.ADD_STAFFING_CANDIDATE_START, { data: false });
      });
      notifier.actions.notify({
        message: getIntlMessage("Add Successful"),
        type: "success",
      });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.ADD_STAFFING_CANDIDATE_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.ADD_STAFFING_CANDIDATE_START, { data: false });
      });
    });
}

export function setStaffingCandidateForm() {
  reactor.dispatch(actionTypes.SET_STAFFING_CANDIDATE_FORM);
}

export function setStaffingCandidateStart(data) {
  reactor.dispatch(actionTypes.SET_STAFFING_CANDIDATE_START, { data });
}

export function setStaffingCandidate(id, data, partial = false) {
  var action = partial ? axios.patch : axios.put;
  action(process.env.__APIURL__ + "/staffings/" + id, data)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_STAFFING_CANDIDATE_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.SET_STAFFING_CANDIDATE_START, { data: false });
      });
      notifier.actions.notify({
        message: getIntlMessage("Update Successful"),
        type: "success",
      });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_STAFFING_CANDIDATE_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.SET_STAFFING_CANDIDATE_START, { data: false });
      });
    });
}

export function deleteStaffingCandidateForm() {
  reactor.dispatch(actionTypes.DELETE_STAFFING_CANDIDATE_FORM);
}

export function deleteStaffingCandidateStart(data) {
  reactor.dispatch(actionTypes.DELETE_STAFFING_CANDIDATE_START, { data });
}

export function deleteStaffingCandidate(id) {
  axios
    .delete(process.env.__APIURL__ + "/staffings/" + id)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.DELETE_STAFFING_CANDIDATE_SUCCESS, { data: { id: id } });
        reactor.dispatch(actionTypes.DELETE_STAFFING_CANDIDATE_START, { data: false });
      });
      notifier.actions.notify({
        message: getIntlMessage("Delete Successful"),
        type: "success",
      });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.DELETE_STAFFING_CANDIDATE_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.DELETE_STAFFING_CANDIDATE_START, { data: false });
      });
    });
}
