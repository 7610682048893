import React from "react";
import { Router } from "react-router";
import { ButtonToolbar, ButtonGroup, Button } from "react-bootstrap";
import classNames from "classnames";
import moment from "moment";
import { DragSource, DropTarget } from "react-dnd";
import _ from "lodash";

import i18n from "../../modules/i18n";
import * as helpers from "../../modules/utils/helpers";
import activities from "../../modules/activities";
let getIntlMessage = i18n.messages.getIntlMessage;

var Card = React.createClass({
  propTypes: {
    connectDragSource: React.PropTypes.func.isRequired,
    isDragging: React.PropTypes.bool.isRequired,
    connectDropTarget: React.PropTypes.func.isRequired,
    isOver: React.PropTypes.bool.isRequired,
    isOverCurrent: React.PropTypes.bool.isRequired,
    canDrop: React.PropTypes.bool.isRequired,
    id: React.PropTypes.number,
    index: React.PropTypes.number,
    dataStep: React.PropTypes.string,
    activity: React.PropTypes.object,
    onShowCancelModal: React.PropTypes.func,
    onShowSellModal: React.PropTypes.func,
    onShowPauseModal: React.PropTypes.func,
    onShowScheduleModal: React.PropTypes.func,
    onShowCloneModal: React.PropTypes.func,
    onShowModal: React.PropTypes.func,
    onMoveCardToStep: React.PropTypes.func,
    onMoveCard: React.PropTypes.func,
  },

  getDefaultProps() {
    return {
      activity: {},
      onShowCancelModal: null,
      onShowSellModal: null,
      onShowPauseModal: null,
      onShowScheduleModal: null,
      onShowCloneModal: null,
      onShowModal: null,
    };
  },

  getCardClassName(card) {
    var names = ["board-card"];
    var starts_on = card.step.starts_on;
    var label = "success-status";
    if (moment(starts_on).calendar() <= moment().subtract(4, "weeks").calendar()) {
      label = "danger-status";
    } else if (moment(starts_on).calendar() <= moment().subtract(2, "weeks").calendar()) {
      label = "warning-status";
    }
    names.push(label);
    if (this.props.isDragging) {
      names.push("is-dragging");
    }
    // if (this.props.isOver) {
    //   names.push('on-over');
    // }
    return classNames(names);
  },

  getGradeClassName(card) {
    var grade = "label-" + card.prospect.grade.label;
    var names = ["label", grade, "card-label"];
    return classNames(names);
  },

  onCancelActivity() {
    this.props.onShowCancelModal(this.props.activity.id);
  },

  onSellActivity() {
    this.props.onShowSellModal(this.props.activity.id);
  },

  onPauseActivity() {
    this.props.onShowPauseModal(this.props.activity.id);
  },

  onScheduleActivity() {
    this.props.onShowScheduleModal(this.props.activity.id, this.props.activity.step_id);
  },

  onCloneActivity() {
    this.props.onShowCloneModal(this.props.activity.id);
  },

  onProspect() {
    var activity = this.props.activity;
    this.props.onShowModal(activity.prospect.id, activity.id);
  },

  showPhones(data) {
    var phones = data.map((phone, index) => {
      let phoneLabel = "fa fa-phone";
      let label = phone.label;
      if (label === "home") {
        phoneLabel = "fa fa-home";
      } else if (label === "mobile") {
        phoneLabel = "fa fa-mobile";
      }
      return (
        <span key={index} index={index} className="card-phone">
          <i className={phoneLabel}></i> {helpers.formatPhone(phone.number)}
        </span>
      );
    });
    return <div>{phones}</div>;
  },

  showExcluded(card) {
    if (!card.prospect.has_consent) {
      return null;
    }
    return <span className="label label-danger card-label">X</span>;
  },

  showSchedule(card) {
    var schedule = _.get(card, "step.schedule");
    if (!schedule) {
      return null;
    }
    return (
      <span className="label label-info card-label">
        <i className="fa fa-calendar"></i> {moment(schedule.starts_on).format("DD/MM")}
      </span>
    );
  },

  showAvailability(prospect) {
    var isDay = prospect.is_available_day;
    var isEvening = prospect.is_available_evening;
    var none = (
      <span>
        <i className="fa fa-ban"></i> {getIntlMessage("None").toLowerCase()}
      </span>
    );
    var day = (
      <span>
        <i className="fa fa-sun-o"></i> {getIntlMessage("Day").toLowerCase()}
      </span>
    );
    var evening = (
      <span>
        <i className="fa fa-moon-o"></i> {getIntlMessage("Evening").toLowerCase()}
      </span>
    );
    var avail = none;
    if (isDay && !isEvening) {
      avail = day;
    } else if (!isDay && isEvening) {
      avail = evening;
    } else if (isDay && isEvening) {
      avail = (
        <span>
          {day} {evening}
        </span>
      );
    }
    return <div>{avail}</div>;
  },

  render() {
    var activity = this.props.activity;
    var id = this.props.id;
    var connectDragSource = this.props.connectDragSource;
    var isDragging = this.props.isDragging;
    var connectDropTarget = this.props.connectDropTarget;
    var isOver = this.props.isOver;
    var isOverCurrent = this.props.isOverCurrent;
    var canDrop = this.props.canDrop;
    return connectDragSource(
      connectDropTarget(
        <div className={this.getCardClassName(activity)} data-id={activity.id}>
          <div>
            <strong>{activity.prospect.fullname}</strong>
          </div>
          {this.showPhones(activity.prospect.phone_numbers_with_label)}
          {activity.prospect.city && <div>{activity.prospect.city}</div>}
          {this.showAvailability(activity.prospect)}
          <div className="card-detail">
            <ButtonToolbar className="card-actions pull-right">
              <ButtonGroup>
                <Button bsSize="xsmall" onClick={this.onCancelActivity}>
                  <i className="fa fa-ban text-danger"></i>
                </Button>
                <Button bsSize="xsmall" onClick={this.onSellActivity}>
                  <i className="fa fa-dollar text-success"></i>
                </Button>
                <Button bsSize="xsmall" onClick={this.onPauseActivity}>
                  <i className="fa fa-clock-o text-warning"></i>
                </Button>
                <Button bsSize="xsmall" onClick={this.onScheduleActivity}>
                  <i className="fa fa-calendar"></i>
                </Button>
                <Button bsSize="xsmall" onClick={this.onCloneActivity}>
                  <i className="fa fa-clone"></i>
                </Button>
                <Button bsSize="xsmall" onClick={this.onProspect}>
                  <i className="fa fa-eye"></i>
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
            <span className={this.getGradeClassName(activity)}>{activity.prospect.grade.name}</span>
            {this.showExcluded(activity)}
            <span className="label label-light card-label prospect-source">{activity.prospect.source.name}</span>
            {activity.prospect.notes > 0 && (
              <span className="label label-light card-label activity-notes">
                <i className="fa fa-comment-o"></i> {activity.prospect.notes}
              </span>
            )}
            {this.showSchedule(activity)}
          </div>
        </div>
      )
    );
  },
});

export default _.flow([
  DropTarget(activities.dnd.dndTypes.CARD, activities.dnd.cardTarget, activities.dnd.dropCollect),
  DragSource(activities.dnd.dndTypes.CARD, activities.dnd.cardSource, activities.dnd.dragCollect),
])(Card);
