import keyMirror from "keymirror";

export default keyMirror({
  SET_STAFFING_RESET: null,
  GET_STAFFING_CANDIDATES_SUCCESS: null,
  GET_STAFFING_CANDIDATES_FAIL: null,
  GET_STAFFING_CANDIDATES_START: null,

  ADD_STAFFING_CANDIDATE_FORM: null,
  ADD_STAFFING_CANDIDATE_START: null,
  ADD_STAFFING_CANDIDATE_SUCCESS: null,
  ADD_STAFFING_CANDIDATE_FAIL: null,
  SET_STAFFING_CANDIDATE_FORM: null,
  SET_STAFFING_CANDIDATE_START: null,
  SET_STAFFING_CANDIDATE_SUCCESS: null,
  SET_STAFFING_CANDIDATE_FAIL: null,
  SET_STAFFING_CANDIDATE_RESET: null,
  GET_STAFFING_CANDIDATE_SUCCESS: null,
  GET_STAFFING_CANDIDATE_FAIL: null,

  DELETE_STAFFING_CANDIDATE_FORM: null,
  DELETE_STAFFING_CANDIDATE_START: null,
  DELETE_STAFFING_CANDIDATE_SUCCESS: null,
  DELETE_STAFFING_CANDIDATE_FAIL: null,
});
