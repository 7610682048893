import React, { Component, PropTypes } from "react";
import { Row, Col } from "react-bootstrap";
import _ from "lodash";

import i18n from "../../modules/i18n";
import AdvisorsActivityPilotStats from "./AdvisorsActivityPilotStats";

let getIntlMessage = i18n.messages.getIntlMessage;

export default class SupervisorCurrentStats extends Component {
  static propTypes = {
    accounts: PropTypes.array,
    current: PropTypes.object,
  };

  static defaultProps = {
    accounts: [],
    current: {},
  };

  getData = () => {
    let advisors = [];
    let currentWeek = _.get(this.props.current, "stats.current_week");
    _.forIn(currentWeek, (value, key) => {
      advisors.push({
        name: value.name,
        office: value.office,
        prospects: value.prospects,
        market_analysis_scheduled: _.get(value, "activities.market-analysis-scheduled", 0),
        market_analysis_seen: _.get(value, "activities.market-analysis-seen", 0),
        break_time_scheduled: _.get(value, "activities.break-time-scheduled", 0),
        break_time_seen: _.get(value, "activities.break-time-seen", 0),
        sales_interview_scheduled: _.get(value, "activities.sales-interview-scheduled", 0),
        sales_interview_seen: _.get(value, "activities.sales-interview-seen", 0),
      });
    });
    return { advisors: _.orderBy(advisors, ["office", "name"], ["asc", "asc"]) };
  };

  render() {
    let data = this.getData();
    return (
      <div>
        <Row>
          <Col xs={12}>
            <h2 className="stats-title">{getIntlMessage("Week")}</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <AdvisorsActivityPilotStats data={data.advisors} />
          </Col>
        </Row>
      </div>
    );
  }
}
