import keyMirror from "keymirror";

export default keyMirror({
  SET_ACCOUNT_RESET: null,
  GET_ACCOUNT_SUCCESS: null,
  GET_ACCOUNT_FAIL: null,
  ADD_ACCOUNT_FORM: null,
  ADD_ACCOUNT_START: null,
  ADD_ACCOUNT_SUCCESS: null,
  ADD_ACCOUNT_FAIL: null,
  SET_ACCOUNT_FORM: null,
  SET_ACCOUNT_START: null,
  SET_ACCOUNT_SUCCESS: null,
  SET_ACCOUNT_FAIL: null,
  SET_ACCOUNT_PASSWORD_FORM: null,
  SET_ACCOUNT_PASSWORD_START: null,
  SET_ACCOUNT_PASSWORD_SUCCESS: null,
  SET_ACCOUNT_PASSWORD_FAIL: null,
  SET_ACCOUNT_AVATAR_START: null,
  SET_ACCOUNT_AVATAR_SUCCESS: null,
  SET_ACCOUNT_AVATAR_FAIL: null,
  DEL_ACCOUNT_SUCCESS: null,
  DEL_ACCOUNT_FAIL: null,
  REM_ACCOUNT: null,
  SET_ACCOUNTS_RELOAD: null,
});
