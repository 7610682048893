import React, { Component, PropTypes } from "react";
import { Row, Col } from "react-bootstrap";
import _ from "lodash";

import i18n from "../../modules/i18n";
import SalesDirectorActivityStats from "./SalesDirectorActivityStats";

let getIntlMessage = i18n.messages.getIntlMessage;

export default class OfficeDirectorStats extends Component {
  static propTypes = {
    accounts: PropTypes.array,
    current: PropTypes.object,
  };

  static defaultProps = {
    accounts: [],
    current: {},
  };

  getData = () => {
    let directors = [];
    let currentWeek = _.get(this.props.current, "stats.current_week");
    _.forIn(this.props.accounts, (value, key) => {
      let aid = value.id;
      let stats = _.find(currentWeek, _.matchesProperty("sales-director.id", aid));
      directors.push({
        name: value.fullname,
        new: Math.ceil(_.get(stats, "average_new", 0)),
        scheduled: Math.ceil(_.get(stats, "average_scheduled", 0)),
        seen: Math.ceil(_.get(stats, "average_seen", 0)),
      });
    });
    return {
      directors: _.orderBy(directors, ["new"], ["desc"]),
    };
  };

  render() {
    let data = this.getData();
    return (
      <div>
        <Row>
          <Col xs={12}>
            <h2 className="stats-title">{getIntlMessage("Week")}</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <SalesDirectorActivityStats
              data={data.directors}
              title={getIntlMessage("Directors")}
              dataField1={"name"}
              dataName1={getIntlMessage("Name")}
              dataField2={"new"}
              dataName2={getIntlMessage("New")}
              dataField3={"scheduled"}
              dataName3={getIntlMessage("Scheduled")}
              dataField4={"seen"}
              dataName4={getIntlMessage("Seen")}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
