import t from "tcomb-form";

import forms from "../forms";
import i18n from "../i18n";

let getIntlMessage = i18n.messages.getIntlMessage;

var sourceData = [
  { name: "Référence conseiller" },
  { name: "Emploi Qc." },
  { name: "Site de CV" },
  { name: "Approche direct" },
  { name: "Travail conjoint" },
  { name: "Réseaux sociaux" },
  { name: "Autres" },
];

var schoolingData = [{ name: "DES" }, { name: "DEP" }, { name: "DEC" }, { name: "BAC" }, { name: "Autres" }];

var resultData = [
  { name: "Cédulé" },
  { name: "Rencontre n'a pas eu lieu" },
  { name: "Terminé après première entrevue" },
  { name: "En processus d'entrevue" },
  { name: "Terminé durant le processus" },
  { name: "Aux études" },
];

export var AddStaffingCandidateSchema = t.struct({
  firstname: t.Str,
  lastname: t.Str,
  appointment: t.Dat,
  source: t.Str,
  schooling: t.Str,
  successfinder: t.maybe(t.Str),
  result: t.Str,
});

export var SetStaffingCandidateSchema = t.struct({
  firstname: t.Str,
  lastname: t.Str,
  appointment: t.Dat,
  source: t.Str,
  schooling: t.Str,
  successfinder: t.maybe(t.Str),
  result: t.Str,
});

export var AddStaffingCandidateSchemaOptions = {
  fields: {
    sales_director_id: {
      type: "hidden",
    },
    firstname: {
      type: "text",
      label: getIntlMessage("Firstname"),
    },
    lastname: {
      type: "text",
      label: getIntlMessage("Lastname"),
    },
    appointment: {
      type: "text",
      label: getIntlMessage("Appointment"),
      factory: forms.widgets.KDatePickerWidget,
      attrs: {
        meta: {
          reactive: true,
        },
        culture: "fr-CA",
        format: "dd/MM/yyyy",
        value: new Date(),
      },
    },
    source: {
      type: "text",
      label: getIntlMessage("Source"),
      factory: forms.widgets.KDropDownListWidget,
      attrs: {
        meta: {
          reactive: true,
        },
        culture: "fr-CA",
        optionLabel: getIntlMessage("Select..."),
        dataSource: sourceData,
        dataTextField: "name",
        dataValueField: "name",
      },
    },
    schooling: {
      type: "text",
      label: getIntlMessage("Schooling"),
      factory: forms.widgets.KDropDownListWidget,
      attrs: {
        meta: {
          reactive: true,
        },
        culture: "fr-CA",
        optionLabel: getIntlMessage("Select..."),
        dataSource: schoolingData,
        dataTextField: "name",
        dataValueField: "name",
      },
    },
    successfinder: {
      type: "text",
      label: getIntlMessage("Success Finder"),
    },
    result: {
      type: "text",
      label: getIntlMessage("Result"),
      factory: forms.widgets.KDropDownListWidget,
      attrs: {
        meta: {
          reactive: true,
        },
        culture: "fr-CA",
        optionLabel: getIntlMessage("Select..."),
        dataSource: resultData,
        dataTextField: "name",
        dataValueField: "name",
      },
    },
  },
};

export var SetStaffingCandidateSchemaOptions = {
  fields: {
    sales_director_id: {
      type: "hidden",
    },
    firstname: {
      type: "text",
      label: getIntlMessage("Firstname"),
    },
    lastname: {
      type: "text",
      label: getIntlMessage("Lastname"),
    },
    appointment: {
      type: "text",
      label: getIntlMessage("Appointment"),
      factory: forms.widgets.KDatePickerWidget,
      attrs: {
        meta: {
          reactive: true,
        },
        culture: "fr-CA",
        format: "dd/MM/yyyy",
      },
    },
    source: {
      type: "text",
      label: getIntlMessage("Source"),
      factory: forms.widgets.KDropDownListWidget,
      attrs: {
        meta: {
          reactive: true,
        },
        culture: "fr-CA",
        optionLabel: getIntlMessage("Select..."),
        dataSource: sourceData,
        dataTextField: "name",
        dataValueField: "name",
      },
    },
    schooling: {
      type: "text",
      label: getIntlMessage("Schooling"),
      factory: forms.widgets.KDropDownListWidget,
      attrs: {
        meta: {
          reactive: true,
        },
        culture: "fr-CA",
        optionLabel: getIntlMessage("Select..."),
        dataSource: schoolingData,
        dataTextField: "name",
        dataValueField: "name",
      },
    },
    successfinder: {
      type: "text",
      label: getIntlMessage("Success Finder"),
    },
    result: {
      type: "text",
      label: getIntlMessage("Result"),
      factory: forms.widgets.KDropDownListWidget,
      attrs: {
        meta: {
          reactive: true,
        },
        culture: "fr-CA",
        optionLabel: getIntlMessage("Select..."),
        dataSource: resultData,
        dataTextField: "name",
        dataValueField: "name",
      },
    },
  },
};
