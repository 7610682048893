import keyMirror from "keymirror";

export default keyMirror({
  SET_DASHBOARD_RESET: null,
  GET_DASHBOARD_CURRENT_SUCCESS: null,
  GET_DASHBOARD_CURRENT_FAIL: null,
  GET_DASHBOARD_CURRENT_START: null,
  GET_DASHBOARD_WEEKS_SUCCESS: null,
  GET_DASHBOARD_WEEKS_FAIL: null,
  GET_DASHBOARD_WEEKS_START: null,
  GET_DASHBOARD_QUARTER_SUCCESS: null,
  GET_DASHBOARD_QUARTER_FAIL: null,
  GET_DASHBOARD_QUARTER_START: null,
  GET_DASHBOARD_AGGREGATE_WEEKS_SUCCESS: null,
  GET_DASHBOARD_AGGREGATE_WEEKS_FAIL: null,
  GET_DASHBOARD_AGGREGATE_WEEKS_START: null,
  GET_DASHBOARD_AGGREGATE_QUARTER_SUCCESS: null,
  GET_DASHBOARD_AGGREGATE_QUARTER_FAIL: null,
  GET_DASHBOARD_AGGREGATE_QUARTER_START: null,
  GET_DASHBOARD_OFFICE_DIRECTOR_QUARTER_SUCCESS: null,
  GET_DASHBOARD_OFFICE_DIRECTOR_QUARTER_FAIL: null,
  GET_DASHBOARD_OFFICE_DIRECTOR_QUARTER_START: null,
  SET_DASHBOARD_RELOAD: null,
});
