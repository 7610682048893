import React from "react";

import reactor from "../../reactor";
import activities from "../../modules/activities";
import i18n from "../../modules/i18n";
import FormModal from "../forms/FormModal";

let getIntlMessage = i18n.messages.getIntlMessage;

var defaultValue = {};

var AddActivityNote = React.createClass({
  mixins: [reactor.ReactMixin],

  propTypes: {
    activityId: React.PropTypes.number,
  },

  getDefaultProps() {
    return {
      activityId: null,
    };
  },

  getInitialState() {
    return {
      value: defaultValue,
    };
  },

  getDataBindings() {
    return {
      addActivityNoteForm: activities.getters.addActivityNoteFormGetter,
    };
  },

  componentWillReceiveProps(props) {
    if (!props.show) {
      this.setState({
        value: defaultValue,
      });
    }
  },

  componentDidUpdate(prevProps, prevState) {
    if (prevState.addActivityNoteForm !== this.state.addActivityNoteForm) {
      var form = this.state.addActivityNoteForm.toJS();
      if (form.feedback.type === "success") {
        this.props.onHide();
      }
    }
  },

  onFormSubmit(event, value) {
    if (value) {
      this.setState({
        value: value,
      });
      activities.actions.addActivityNoteStart(true);
      activities.actions.addActivityNote(this.props.activityId, value);
    }
  },

  onFormChange(value) {
    this.setState({ value });
  },

  render() {
    var form = this.state.addActivityNoteForm.toJS();
    return (
      <FormModal
        form={form}
        formType="add"
        formLayout="vertical"
        value={this.state.value}
        onFormChange={this.onFormChange}
        onFormSubmit={this.onFormSubmit}
        title={getIntlMessage("Add Note")}
        {...this.props}
      />
    );
  },
});

export default AddActivityNote;
