import React, { Component, PropTypes } from "react";
import { default as EPC } from "easy-pie-chart";

export default class EasyPieChart extends Component {
  static propTypes = {
    title: PropTypes.string,
    value: PropTypes.number,
    barColor: PropTypes.string,
    trackColor: PropTypes.string,
    lineWidth: PropTypes.number,
    size: PropTypes.number,
  };

  static defaultProps = {
    title: "0%",
    value: 0,
    barColor: "#2e57a9",
    trackColor: "#f2f2f2",
    lineWidth: 4,
    size: 75,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      chart: null,
    };
  }

  componentDidMount() {
    const { easyPieChart } = this.refs;
    let chart = new EPC(easyPieChart, {
      animate: 1000,
      barColor: this.props.barColor,
      trackColor: this.props.trackColor,
      lineWidth: this.props.lineWidth,
      scaleLength: 0,
      size: this.props.size,
    });
    this.setState({ chart: chart });
  }

  componentDidUpdate(prevProps, prevState) {
    this.state.chart.update(this.props.value);
  }

  render() {
    return (
      <div ref="easyPieChart" className="chart">
        <div className="percentage" data-percent={this.props.value}>
          {this.props.title}
        </div>
      </div>
    );
  }
}
