import React from "react";

import reactor from "../../reactor";
import accounts from "../../modules/accounts";
import i18n from "../../modules/i18n";
import FormModal from "../forms/FormModal";

let getIntlMessage = i18n.messages.getIntlMessage;

var defaultValue = {};

var AddAccount = React.createClass({
  mixins: [reactor.ReactMixin],

  getInitialState() {
    return {
      value: defaultValue,
    };
  },

  getDataBindings() {
    return {
      addAccountForm: accounts.getters.addAccountFormGetter,
    };
  },

  componentWillReceiveProps(props) {
    if (!props.show) {
      this.setState({
        value: defaultValue,
      });
    }
  },

  onFormSubmit(event, value) {
    if (value) {
      this.setState({
        value: value,
      });
      accounts.actions.addAccountStart(true);
      accounts.actions.addAccount(value);
    }
  },

  onFormChange(value) {
    this.setState({ value });
  },

  render() {
    var form = this.state.addAccountForm.toJS();
    return (
      <FormModal
        form={form}
        formType="add"
        value={this.state.value}
        onFormChange={this.onFormChange}
        onFormSubmit={this.onFormSubmit}
        title={getIntlMessage("New Account")}
        {...this.props}
      />
    );
  },
});

export default AddAccount;
