import { Store, toImmutable } from "nuclear-js";

import forms from "../forms";
import i18n from "../i18n";
import actionTypes from "./actionTypes";
import * as schemas from "./schemas";

let getIntlMessage = i18n.messages.getIntlMessage;

let loginFormDefaults = {
  type: schemas.LoginSchema,
  options: schemas.LoginSchemaOptions,
  feedback: {
    type: "info",
    messages: [],
  },
  started: false,
};

let passwordLostFormDefaults = {
  type: schemas.PasswordLostSchema,
  options: schemas.PasswordLostSchemaOptions,
  feedback: {
    type: "info",
    messages: [],
  },
  started: false,
};

let passwordResetFormDefaults = {
  type: schemas.PasswordResetSchema,
  options: schemas.PasswordResetSchemaOptions,
  feedback: {
    type: "info",
    messages: [],
  },
  started: false,
};

let initialState = toImmutable({
  loginForm: loginFormDefaults,
  passwordLostForm: passwordLostFormDefaults,
  passwordResetForm: passwordResetFormDefaults,
});

export default new Store({
  getInitialState() {
    return initialState;
  },

  initialize() {
    this.on(actionTypes.SET_AUTH_RESET, onSetAuthReset);
    this.on(actionTypes.LOGIN_FORM, onLoginForm);
    this.on(actionTypes.LOGIN_START, onLoginStart);
    this.on(actionTypes.LOGIN_SUCCESS, onLoginSuccess);
    this.on(actionTypes.LOGIN_FAIL, onLoginFail);
    this.on(actionTypes.LOGOUT, onLogout);
    this.on(actionTypes.TOKEN_SUCCESS, onLoginSuccess);
    this.on(actionTypes.TOKEN_FAIL, onLogout);
    this.on(actionTypes.PASSWORD_LOST_FORM, onPasswordLostForm);
    this.on(actionTypes.PASSWORD_LOST_START, onPasswordLostStart);
    this.on(actionTypes.PASSWORD_LOST_SUCCESS, onPasswordLostSuccess);
    this.on(actionTypes.PASSWORD_LOST_FAIL, onPasswordLostFail);
    this.on(actionTypes.PASSWORD_RESET_FORM, onPasswordResetForm);
    this.on(actionTypes.PASSWORD_RESET_START, onPasswordResetStart);
    this.on(actionTypes.PASSWORD_RESET_SUCCESS, onPasswordResetSuccess);
    this.on(actionTypes.PASSWORD_RESET_FAIL, onPasswordResetFail);
  },
});

function onSetAuthReset(state) {
  return initialState;
}

function onLoginForm(state) {
  return state;
}

function onLoginStart(state, { data }) {
  return state.setIn(["loginForm", "started"], data);
}

function onLoginSuccess(state, { data }) {
  localStorage.setItem("token", data.token);
  return state.set("loginForm", toImmutable(loginFormDefaults));
}

function onLoginFail(state, { data }) {
  if (localStorage.token) {
    localStorage.removeItem("token");
  }
  let feedback = forms.utils.setFormFeedback(data);
  let options = forms.utils.setFormOptions(state.getIn(["loginForm", "options"]).toJS(), data);
  return state
    .setIn(["loginForm", "feedback"], toImmutable(feedback))
    .setIn(["loginForm", "options"], toImmutable(options));
}

function onLogout(state) {
  if (localStorage.token) {
    localStorage.removeItem("token");
  }
  return state;
}

function onPasswordLostForm(state) {
  return state.set("passwordLostForm", toImmutable(passwordLostFormDefaults));
}

function onPasswordLostStart(state, { data }) {
  return state.setIn(["passwordLostForm", "started"], data);
}

function onPasswordLostSuccess(state, { data }) {
  let feedback = {
    type: "success",
    messages: [
      getIntlMessage("Your request has been accepted"),
      getIntlMessage("A confirmation message has been sent to") + " " + data.account.email,
    ],
  };
  let options = passwordLostFormDefaults.options;
  return state
    .setIn(["passwordLostForm", "feedback"], toImmutable(feedback))
    .setIn(["passwordLostForm", "options"], toImmutable(options));
}

function onPasswordLostFail(state, { data }) {
  let feedback = forms.utils.setFormFeedback(data);
  let options = forms.utils.setFormOptions(state.getIn(["passwordLostForm", "options"]).toJS(), data);
  return state
    .setIn(["passwordLostForm", "feedback"], toImmutable(feedback))
    .setIn(["passwordLostForm", "options"], toImmutable(options));
}

function onPasswordResetForm(state) {
  return state.set("passwordResetForm", toImmutable(passwordResetFormDefaults));
}

function onPasswordResetStart(state, { data }) {
  return state.setIn(["passwordResetForm", "started"], data);
}

function onPasswordResetSuccess(state, { data }) {
  let feedback = {
    type: "success",
    messages: [getIntlMessage("Your password has been resetted")],
  };
  let options = passwordResetFormDefaults.options;
  return state
    .setIn(["passwordResetForm", "feedback"], toImmutable(feedback))
    .setIn(["passwordResetForm", "options"], toImmutable(options));
}

function onPasswordResetFail(state, { data }) {
  let feedback = forms.utils.setFormFeedback(data);
  let options = forms.utils.setFormOptions(state.getIn(["passwordResetForm", "options"]).toJS(), data);
  return state
    .setIn(["passwordResetForm", "feedback"], toImmutable(feedback))
    .setIn(["passwordResetForm", "options"], toImmutable(options));
}
