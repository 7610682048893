import keyMirror from "keymirror";

export default keyMirror({
  SET_OPTIONLIST_RESET: null,
  GET_OPTIONLIST_SUCCESS: null,
  GET_OPTIONLIST_FAIL: null,
  ADD_OPTIONLIST_FORM: null,
  ADD_OPTIONLIST_START: null,
  ADD_OPTIONLIST_SUCCESS: null,
  ADD_OPTIONLIST_FAIL: null,
  SET_OPTIONLIST_FORM: null,
  SET_OPTIONLIST_START: null,
  SET_OPTIONLIST_SUCCESS: null,
  SET_OPTIONLIST_FAIL: null,
  DEL_OPTIONLIST_SUCCESS: null,
  DEL_OPTIONLIST_FAIL: null,
  REM_OPTIONLIST: null,
  SET_OPTIONLISTS_RELOAD: null,
});
