import React from "react";
import _ from "lodash";

import reactor from "../../reactor";
import prospects from "../../modules/prospects";
import i18n from "../../modules/i18n";
import FormModal from "../forms/FormModal";

let getIntlMessage = i18n.messages.getIntlMessage;

var SetProspect = React.createClass({
  mixins: [reactor.ReactMixin],

  propTypes: {
    prospectId: React.PropTypes.number,
  },

  getDefaultProps() {
    return {
      prospectId: null,
    };
  },

  getInitialState() {
    return {
      value: {},
    };
  },

  getDataBindings() {
    return {
      prospect: prospects.getters.prospectGetter,
      setProspectForm: prospects.getters.setProspectFormGetter,
      setProspectsReload: prospects.getters.setProspectsReloadGetter,
    };
  },

  componentWillReceiveProps(props) {
    if (props.show && (_.isEmpty(this.state.value) || this.state.setProspectsReload)) {
      prospects.actions.getProspect(props.prospectId);
    } else if (!props.show && !_.isEmpty(this.state.value)) {
      // prospects.actions.remProspect();
    }
  },

  componentDidUpdate(prevProps, prevState) {
    if (this.state.prospect !== prevState.prospect) {
      this.setState({ value: this.state.prospect.toJS() });
    }
  },

  onFormSubmit(event, value) {
    if (value) {
      this.setState({
        value: value,
      });
      prospects.actions.setProspectStart(true);
      prospects.actions.setProspect(this.props.prospectId, value);
    }
  },

  onFormChange(value) {
    this.setState({ value });
  },

  render() {
    var form = this.state.setProspectForm.toJS();
    return (
      <FormModal
        form={form}
        formType="set"
        formLayout="free"
        value={this.state.value}
        onFormChange={this.onFormChange}
        onFormSubmit={this.onFormSubmit}
        title={getIntlMessage("Edit Prospect")}
        {...this.props}
      />
    );
  },
});

export default SetProspect;
