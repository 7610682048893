import keyMirror from "keymirror";

export default keyMirror({
  SET_ROLE_RESET: null,
  GET_ROLE_SUCCESS: null,
  GET_ROLE_FAIL: null,
  ADD_ROLE_FORM: null,
  ADD_ROLE_START: null,
  ADD_ROLE_SUCCESS: null,
  ADD_ROLE_FAIL: null,
  SET_ROLE_FORM: null,
  SET_ROLE_START: null,
  SET_ROLE_SUCCESS: null,
  SET_ROLE_FAIL: null,
  DEL_ROLE_SUCCESS: null,
  DEL_ROLE_FAIL: null,
  REM_ROLE: null,
  SET_ROLES_RELOAD: null,
});
