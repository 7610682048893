import JWTDecode from "jwt-decode";
import _ from "lodash";

import reactor from "../../reactor";

export function authenticated() {
  if (localStorage.token) {
    let token = JWTDecode(localStorage.token);
    let now = Math.floor(new Date().getTime() / 1000);
    return true ? now <= token.exp : false;
  }
  return false;
}

export function getToken() {
  return localStorage.token;
}

export function hasRole(target, role) {
  if (_.includes(target, "manager") || _.includes(target, role)) {
    return true;
  }
  return false;
}

export function hasOneRoleOf(target, roles) {
  if (_.includes(target, "manager")) {
    return true;
  }
  return true ? !_.isEmpty(_.intersection(target, roles)) : false;
}

export function hasPerm(target, perm) {
  if (_.includes(target, "manager") || _.includes(target, perm)) {
    return true;
  }
  return false;
}

export function hasOnePermOf(target, perms) {
  if (_.includes(target, "*")) {
    return true;
  }
  return true ? !_.isEmpty(_.intersection(target, perms)) : false;
}
