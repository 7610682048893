import t from "tcomb-form";

import forms from "../forms";
import i18n from "../i18n";
import * as layouts from "./layouts";

let getIntlMessage = i18n.messages.getIntlMessage;

export var AddActivitySchema = t.struct({
  status_id: t.Num,
  prospect_id: t.Num,
});

export var SetActivitySchema = AddActivitySchema;

export var CancelActivitySchema = t.struct({
  is_current_step_seen: t.Bool,
});

export var SellActivitySchema = CancelActivitySchema;

export var PauseActivitySchema = t.struct({
  is_pausing_activity: t.Bool,
});

export var ResumeActivitySchema = t.struct({
  is_resuming_activity: t.Bool,
});

export var ScheduleActivitySchema = t.struct({
  starts_on: t.Dat,
});

export var CloneActivitySchema = t.struct({
  step: t.Str,
});

export var AddActivityNoteSchema = t.struct({
  // private: t.Bool,
  message: t.Str,
});

export var AddActivitySchemaOptions = {
  config: {
    // for each of lg md sm xs you can specify the columns width
    horizontal: {
      lg: [2, 10],
      md: [2, 10],
    },
  },
  fields: {
    status_id: {
      type: "text",
      label: getIntlMessage("Status"),
      factory: forms.widgets.KDropDownListWidget,
      attrs: {
        culture: "fr-CA",
        optionLabel: getIntlMessage("Select..."),
        dataSource: {
          transport: {
            read: {
              url: process.env.__APIURL__ + "/choices/lists/status",
              dataType: "json",
            },
          },
          schema: {
            data: "results",
          },
        },
      },
    },
    prospect_id: {
      type: "text",
      label: getIntlMessage("Prospect"),
      factory: forms.widgets.KDropDownListWidget,
      attrs: {
        culture: "fr-CA",
        optionLabel: getIntlMessage("Select..."),
        dataSource: {
          transport: {
            read: {
              url: process.env.__APIURL__ + "/choices/prospects",
              dataType: "json",
            },
          },
          schema: {
            data: "results",
          },
        },
      },
    },
  },
};

export var SetActivitySchemaOptions = AddActivitySchemaOptions;

export var CancelActivitySchemaOptions = {
  templates: {
    struct: layouts.CancelActivityLayout,
  },
  fields: {
    is_current_step_seen: {
      label: getIntlMessage("Yes"),
    },
  },
};

export var SellActivitySchemaOptions = CancelActivitySchemaOptions;

export var PauseActivitySchemaOptions = {
  templates: {
    struct: layouts.PauseActivityLayout,
  },
  fields: {
    is_pausing_activity: {
      label: getIntlMessage("Yes"),
    },
  },
};

export var ResumeActivitySchemaOptions = {
  templates: {
    struct: layouts.ResumeActivityLayout,
  },
  fields: {
    is_resuming_activity: {
      label: getIntlMessage("Yes"),
    },
  },
};

export var ScheduleActivitySchemaOptions = {
  templates: {
    struct: layouts.ScheduleActivityLayout,
  },
  fields: {
    starts_on: {
      type: "text",
      label: getIntlMessage("Starts On"),
      factory: forms.widgets.KDatePickerWidget,
      attrs: {
        culture: "fr-CA",
        format: "dd/MM/yyyy",
        value: new Date(),
      },
    },
  },
};

export var CloneActivitySchemaOptions = {
  templates: {
    struct: layouts.CloneActivityLayout,
  },
  fields: {
    step: {
      type: "text",
      label: getIntlMessage("Step"),
      factory: forms.widgets.KDropDownListWidget,
      attrs: {
        culture: "fr-CA",
        optionLabel: getIntlMessage("Select..."),
        dataSource: [
          { label: getIntlMessage("Market Analysis"), value: "market-analysis" },
          { label: getIntlMessage("Break Time"), value: "break-time" },
          { label: getIntlMessage("Sales Interview"), value: "sales-interview" },
        ],
        dataTextField: "label",
        dataValueField: "value",
      },
    },
  },
};

export var AddActivityNoteSchemaOptions = {
  fields: {
    // private: {
    //   type: 'text',
    //   label: getIntlMessage('Private')
    // },
    message: {
      type: "textarea",
      label: getIntlMessage("Message"),
      optional: "",
      factory: forms.widgets.KEditorWidget,
      attrs: {
        culture: "fr-CH",
      },
    },
  },
};
