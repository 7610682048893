import reactor from "../../reactor";
import * as actions from "./actions";
import * as getters from "./getters";
import * as messages from "./messages";
import store from "./store";
import * as mixin from "./mixin";

reactor.registerStores({
  i18n: store,
});

export default {
  actions,
  getters,
  messages,
  mixin,
};
