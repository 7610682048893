import React from "react";
import { Panel } from "react-bootstrap";

var SubHeader = React.createClass({
  getDefaultProps() {
    return {
      title: "-",
    };
  },

  render() {
    return (
      <Panel className="hpanel panel-subheader">
        <div className="hpanel-body">
          <div className="pull-right">{this.props.children}</div>
          <h3>{this.props.title}</h3>
        </div>
      </Panel>
    );
  },
});

export default SubHeader;
