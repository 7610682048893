import t from "tcomb-form";

import forms from "../forms";
import i18n from "../i18n";

let getIntlMessage = i18n.messages.getIntlMessage;

export var SetAccountSchema = t.struct({
  status_id: t.Num,
  parent_ids: t.list(t.Num),
  firstname: t.Str,
  lastname: t.Str,
  email: t.Str,
  role_ids: t.list(t.Num),
});

export var SetAccountPasswordSchema = t.struct({
  password: t.Str,
  confirm_password: t.Str,
});

export var AddAccountSchema = t.struct({
  status_id: t.Num,
  parent_ids: t.list(t.Num),
  firstname: t.Str,
  lastname: t.Str,
  email: t.Str,
  password: t.maybe(t.Str),
  role_ids: t.list(t.Num),
});

export var SetAccountSchemaOptions = {
  config: {
    horizontal: {
      lg: [4, 8],
      md: [4, 8],
    },
  },
  fields: {
    status_id: {
      type: "text",
      label: getIntlMessage("Status"),
      factory: forms.widgets.KDropDownListWidget,
      attrs: {
        culture: "fr-CA",
        optionLabel: getIntlMessage("Select..."),
        dataSource: {
          transport: {
            read: {
              url: process.env.__APIURL__ + "/choices/lists/status",
              dataType: "json",
            },
          },
          schema: {
            data: "results",
          },
        },
      },
    },
    parent_ids: {
      type: "text",
      label: getIntlMessage("Parents"),
      factory: forms.widgets.KMultiSelectWidget,
      attrs: {
        culture: "fr-CA",
        optionLabel: getIntlMessage("Select..."),
        dataSource: {
          transport: {
            read: {
              url: process.env.__APIURL__ + "/choices/accounts/management",
              dataType: "json",
            },
          },
          group: { field: "role" },
          schema: {
            data: "results",
          },
        },
      },
    },
    email: {
      type: "text",
      label: getIntlMessage("Email"),
    },
    role_ids: {
      type: "text",
      label: getIntlMessage("Roles"),
      factory: forms.widgets.KMultiSelectWidget,
      attrs: {
        culture: "fr-CA",
        placeholder: getIntlMessage("Select..."),
        dataSource: {
          transport: {
            read: {
              url: process.env.__APIURL__ + "/choices/system/roles",
              dataType: "json",
            },
          },
          schema: {
            data: "results",
          },
        },
      },
    },
  },
};

export var SetAccountPasswordSchemaOptions = {
  config: {
    horizontal: {
      lg: [4, 8],
      md: [4, 8],
    },
  },
  fields: {
    password: {
      type: "password",
      label: getIntlMessage("Password"),
    },
    confirm_password: {
      type: "password",
      label: getIntlMessage("Confirm Password"),
    },
  },
};

export var AddAccountSchemaOptions = {
  config: {
    horizontal: {
      lg: [4, 8],
      md: [4, 8],
    },
  },
  fields: {
    status_id: {
      type: "text",
      label: getIntlMessage("Status"),
      factory: forms.widgets.KDropDownListWidget,
      attrs: {
        culture: "fr-CA",
        optionLabel: getIntlMessage("Select..."),
        dataSource: {
          transport: {
            read: {
              url: process.env.__APIURL__ + "/choices/lists/status",
              dataType: "json",
            },
          },
          schema: {
            data: "results",
          },
        },
      },
    },
    parent_ids: {
      type: "text",
      label: getIntlMessage("Parents"),
      factory: forms.widgets.KMultiSelectWidget,
      attrs: {
        culture: "fr-CA",
        optionLabel: getIntlMessage("Select..."),
        dataSource: {
          transport: {
            read: {
              url: process.env.__APIURL__ + "/choices/accounts/management",
              dataType: "json",
            },
          },
          group: { field: "role" },
          schema: {
            data: "results",
          },
        },
      },
    },
    email: {
      type: "text",
      label: getIntlMessage("Email"),
    },
    password: {
      type: "password",
      label: getIntlMessage("Password"),
    },
    role_ids: {
      type: "text",
      label: getIntlMessage("Roles"),
      factory: forms.widgets.KMultiSelectWidget,
      attrs: {
        culture: "fr-CA",
        placeholder: getIntlMessage("Select..."),
        dataSource: {
          transport: {
            read: {
              url: process.env.__APIURL__ + "/choices/system/roles",
              dataType: "json",
            },
          },
          schema: {
            data: "results",
          },
        },
      },
    },
  },
};
