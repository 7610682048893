import React, { Component, PropTypes } from "react";
import { Pie } from "react-chartjs";

export default class GradeChart extends Component {
  static propTypes = {
    data: PropTypes.array,
    options: PropTypes.object,
  };

  static defaultProps = {
    data: [],
    options: {
      percentageInnerCutout: 50,
      segmentShowStroke: true,
      segmentStrokeWidth: 2,
      animationSteps: 100,
      responsive: true,
    },
  };

  render() {
    return <div></div>;
    // return <Pie data={this.props.data} options={this.props.options} />;
  }
}
