import React, { Component, PropTypes } from "react";
import { connect } from "nuclear-js-react-addons";
import { Row, Col, Modal, Button } from "react-bootstrap";
import _ from "lodash";

import app from "../../modules/app";
import dashboards from "../../modules/dashboards";
import i18n from "../../modules/i18n";
import * as helpers from "../../modules/utils/helpers";
import SalesDirectorCurrentStats from "./SalesDirectorCurrentStats";
import SalesDirectorQuarterStats from "./SalesDirectorQuarterStats";

let getIntlMessage = i18n.messages.getIntlMessage;

export default
@connect((props) => ({
  account: app.getters.appAccountGetter,
  selector: app.getters.appAccountSelectorGetter,
  current: dashboards.getters.dashboardCurrentGetter,
  aggregateQuarter: dashboards.getters.dashboardAggregateQuarterGetter,
}))
class PrintSalesDirectorStats extends Component {
  static propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func,
  };

  onPrint = () => {
    let head = document.getElementsByTagName("head")[0].innerHTML;
    let content = document.getElementById("dashboard-print").innerHTML;
    helpers.printContent(content, head);
  };

  renderData = () => {
    let account = this.props.account.toJS();
    let selector = this.props.selector.toJS();
    let accounts = selector.accounts;
    let accs = [];
    let role = account.main_role_refid;
    if (role !== "sales-director") {
      return null;
    }
    _(account.account_ids).each((value) => {
      let acc = _.find(accounts, { id: value });
      if (acc && acc.id !== account.id && acc.role_refid !== "student") {
        accs.push(acc);
      }
    });
    return (
      <div className="dashboard-print" id="dashboard-print">
        <Row>
          <Col xs={12}>
            <SalesDirectorCurrentStats accounts={accs} current={this.props.current.toJS()} />
          </Col>
          <Col xs={12}>
            <SalesDirectorQuarterStats accounts={accs} quarter={this.props.aggregateQuarter.toJS()} />
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    return (
      <Modal bsSize="medium" show={this.props.show} onHide={this.props.onHide} {...this.props}>
        <div className="color-line" />
        <Modal.Header>
          <Modal.Title>{getIntlMessage("Dashboard")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.renderData()}</Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onHide}>{getIntlMessage("Close")}</Button>
          <Button name="print" bsStyle="primary" onClick={this.onPrint}>
            {getIntlMessage("Print")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
