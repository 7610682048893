import React, { Component, PropTypes } from "react";
import { Row, Col } from "react-bootstrap";

import SalesDirectorCurrentStats from "./SalesDirectorCurrentStats";
import SalesDirectorWeekStats from "./SalesDirectorWeekStats";
import SalesDirectorQuarterStats from "./SalesDirectorQuarterStats";

export default class SalesDirectorStats extends Component {
  static propTypes = {
    account: PropTypes.object,
    accounts: PropTypes.array,
    current: PropTypes.object,
    weeks: PropTypes.object,
    quarter: PropTypes.object,
  };

  static defaultProps = {
    accounts: [],
    current: {},
    weeks: {},
    quarter: {},
  };

  render() {
    return (
      <div>
        <Row>
          <Col md={6}>
            <SalesDirectorCurrentStats
              accounts={this.props.accounts}
              current={this.props.current}
              width={"70"}
              height={"300"}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <SalesDirectorWeekStats
              accounts={this.props.accounts}
              current={this.props.current}
              width={"70"}
              height={"300"}
            />
          </Col>
          <Col md={6}>
            <SalesDirectorQuarterStats
              accounts={this.props.accounts}
              quarter={this.props.quarter}
              width={"70"}
              height={"300"}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
