import React, { Component, PropTypes } from "react";
import { Row, Col } from "react-bootstrap";

import SupervisorCurrentStats from "./SupervisorCurrentStats";

export default class SupervisorStats extends Component {
  static propTypes = {
    accounts: PropTypes.array,
    current: PropTypes.object,
  };

  static defaultProps = {
    accounts: [],
    current: {},
  };

  render() {
    return (
      <div>
        <Row>
          <Col xs={12}>
            <SupervisorCurrentStats accounts={this.props.accounts} current={this.props.current} />
          </Col>
        </Row>
      </div>
    );
  }
}
