import React from "react";
import _ from "lodash";

import reactor from "../../reactor";
import options from "../../modules/options";
import i18n from "../../modules/i18n";
import FormModal from "../forms/FormModal";

let getIntlMessage = i18n.messages.getIntlMessage;

var SetOption = React.createClass({
  mixins: [reactor.ReactMixin],

  propTypes: {
    optionId: React.PropTypes.number,
  },

  getDefaultProps() {
    return {
      optionId: null,
    };
  },

  getInitialState() {
    return {
      value: {},
    };
  },

  getDataBindings() {
    return {
      option: options.getters.optionGetter,
      setOptionForm: options.getters.setOptionFormGetter,
      setOptionsReload: options.getters.setOptionsReloadGetter,
    };
  },

  componentWillReceiveProps(props) {
    if (props.show && (_.isEmpty(this.state.value) || this.state.setOptionsReload)) {
      options.actions.getOption(props.optionId);
    } else if (!props.show && !_.isEmpty(this.state.value)) {
      options.actions.remOption();
    }
  },

  componentDidUpdate(prevProps, prevState) {
    if (this.state.option !== prevState.option) {
      this.setState({ value: this.state.option.toJS() });
    }
  },

  onFormSubmit(event, value) {
    if (value) {
      this.setState({
        value: value,
      });
      options.actions.setOptionStart(true);
      options.actions.setOption(this.props.optionId, value);
    }
  },

  onFormChange(value) {
    this.setState({ value });
  },

  render() {
    var form = this.state.setOptionForm.toJS();
    return (
      <FormModal
        form={form}
        formType="set"
        value={this.state.value}
        onFormChange={this.onFormChange}
        onFormSubmit={this.onFormSubmit}
        title={getIntlMessage("Edit Option")}
        {...this.props}
      />
    );
  },
});

export default SetOption;
