import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";

import i18n from "../modules/i18n";

let getIntlMessage = i18n.messages.getIntlMessage;

export default class ErrorUpdate extends Component {
  static propTypes = {
    show: React.PropTypes.bool,
    onHide: React.PropTypes.func,
  };

  onUpgrade = (event) => {
    event.preventDefault();
    if (localStorage.clientVersion) {
      localStorage.removeItem("clientVersion");
    }
    if (localStorage.token) {
      localStorage.removeItem("token");
    }
    this.props.onHide();
    window.location.reload();
  };

  render() {
    return (
      <Modal
        bsSize={this.props.bsSize}
        className="hmodal-info"
        show={this.props.show}
        onHide={this.props.onHide}
        {...this.props}
      >
        <div className="color-line" />
        <Modal.Header>
          <Modal.Title>{getIntlMessage("Update Available")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{getIntlMessage("ERROR_UPDATE_TEXT")}</Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onHide}>{getIntlMessage("Close")}</Button>
          <Button name="upgrade" bsStyle="info" onClick={this.onUpgrade}>
            {getIntlMessage("Upgrade")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
