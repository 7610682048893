import reactor from "../../reactor";
import * as actions from "./actions";
import * as getters from "./getters";
import store from "./store";

reactor.registerStores({
  dashboards: store,
});

export default {
  actions,
  getters,
};
