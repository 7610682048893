import React, { Component, PropTypes } from "react";
import { Panel, ButtonGroup, Label } from "react-bootstrap";
import moment from "moment";

import dashboards from "../../modules/dashboards";

var k = (React.Kendo = require("kendo-ui-react"));

import i18n from "../../modules/i18n";

let getIntlMessage = i18n.messages.getIntlMessage;

export default class WeekSelector extends Component {
  static propTypes = {
    account: PropTypes.object,
  };

  static defaultProps = {
    account: {},
  };

  constructor(props, context) {
    super(props, context);
    let today = moment({ hour: 0, minute: 0, seconds: 0 });
    let weekNum = moment(today).week();
    let weekYear = moment(today).year();
    let weekStart = moment(today).day("Monday").year(weekYear).week(weekNum);
    let weekEnd = moment(today).day("Friday").year(weekYear).week(weekNum);
    let weeksAgo = weekStart.diff(weekStart, "weeks");
    this.state = {
      today: today,
      todayWeekNum: weekNum,
      todayWeekStart: weekStart,
      weekNum: weekNum,
      weekYear: weekYear,
      weekStart: weekStart,
      weekEnd: weekEnd,
      weeksAgo: weeksAgo,
    };
  }

  setWeekState = (when) => {
    let weekNum = moment(when).week();
    let weekYear = moment(when).year();
    let weekStart = moment(when).day("Monday").year(weekYear).week(weekNum).hours(0).minutes(0).seconds(0);
    let weekEnd = moment(when).day("Friday").year(weekYear).week(weekNum).hours(0).minutes(0).seconds(0);
    let weeksAgo = this.state.todayWeekStart.diff(weekStart, "weeks");
    this.setState({
      weekNum: weekNum,
      weekYear: weekYear,
      weekStart: weekStart,
      weekEnd: weekEnd,
      weeksAgo: weeksAgo,
    });
    let account = this.props.account;
    dashboards.actions.getDashboardWeeks(account.id, weeksAgo);
  };

  onChange = (event) => {
    let sender = event.sender;
    let value = sender.value();
    let when = moment(value);
    this.setWeekState(when);
  };

  getOptions = () => {
    return {
      culture: "fr-CA",
      format: "yyyy-MM-dd",
      change: this.onChange,
    };
  };

  render() {
    let options = this.getOptions();
    let weekStart = this.state.weekStart.format("YYYY/MM/DD");
    let weekEnd = this.state.weekEnd.format("YYYY/MM/DD");
    return (
      <div>
        <div className="pull-left">
          <h2 className="stats-title">
            {weekStart} - {weekEnd}
          </h2>
        </div>
        <div className="pull-right week-selector">
          <k.DatePicker options={options} tag={"div"} />
        </div>
      </div>
    );
  }
}
