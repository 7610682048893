import React, { Component, PropTypes } from "react";
import { Row, Col, Table } from "react-bootstrap";
import classNames from "classnames";
import moment from "moment";
import _ from "lodash";

import ActivitySparklines from "./ActivitySparklines";

export default class ActivityStats extends Component {
  static propTypes = {
    title: PropTypes.string,
    value1Title: PropTypes.string,
    value1: PropTypes.number,
    prevValues1: PropTypes.array,
    value2Title: PropTypes.string,
    value2: PropTypes.number,
    prevValues2: PropTypes.array,
    extraValueTitle: PropTypes.string,
    extraValues: PropTypes.array,
    numValues: PropTypes.number,
    message: PropTypes.string,
    iconClass: PropTypes.string,
  };

  static defaultProps = {
    title: "Page Views",
    value1Title: "Value 1",
    value1: 0,
    prevValues1: [],
    value2Title: "Value 2",
    value2: 0,
    prevValues2: [],
    value3Title: "Value 3",
    value3: 0,
    prevValues3: [],
    extraValueTitle: "Extra Value Title",
    extraValues: [],
    numValues: 2,
    message: "",
    iconClass: "pe-7s-monitor",
  };

  getData = (values, now) => {
    let items = values.slice(0);
    items.push(now);
    return items;
  };

  renderColValue = (title, value, data, colSpan) => {
    return (
      <Col md={colSpan}>
        <h1 className="text-info">
          {value}&nbsp;
          <ActivitySparklines data={data} />
        </h1>
        <p>{title}</p>
      </Col>
    );
  };

  renderExtraValues = (title, values) => {
    let thead = values.map((item, index) => {
      return <td>{moment(item.start_date).format("DD/MM")}</td>;
    });
    let tbody = values.map((item, index) => {
      return <td>{item.value}</td>;
    });
    if (_.isEmpty(values)) {
      return null;
    }
    return (
      <div className="extra-values">
        <div className="stats-title pull-left">
          <h4>{title}</h4>
        </div>
        <Table striped condensed hover>
          <thead>
            <tr>{thead}</tr>
          </thead>
          <tbody>
            <tr>{tbody}</tr>
          </tbody>
        </Table>
      </div>
    );
  };

  render() {
    let iconClassNames = classNames(this.props.iconClass, "fa-4x");
    let values1 = this.getData(this.props.prevValues1, this.props.value1);
    let values2 = this.getData(this.props.prevValues2, this.props.value2);
    let values3 = this.getData(this.props.prevValues3, this.props.value3);
    let numValues = this.props.numValues;
    let colSpan = 4;
    if (numValues === 1) {
      colSpan = 12;
    }
    return (
      <div className="hpanel">
        <div className="panel-body">
          <div className="stats-title pull-left">
            <h4>{this.props.title}</h4>
          </div>
          <div className="stats-icon stats-icon-absolute-right">
            <i className={iconClassNames}></i>
          </div>
          <div className="m-t-xl">
            <Row>
              {numValues >= 1 && this.renderColValue(this.props.value1Title, this.props.value1, values1, colSpan)}
              {numValues >= 2 && this.renderColValue(this.props.value2Title, this.props.value2, values2, colSpan)}
              {numValues >= 3 && this.renderColValue(this.props.value3Title, this.props.value3, values3, colSpan)}
            </Row>
          </div>
          {this.renderExtraValues(this.props.extraValueTitle, this.props.extraValues)}
        </div>
      </div>
    );
  }
}
