import React from "react";
import { Panel } from "react-bootstrap";
import _ from "lodash";

import reactor from "../../reactor";
import i18n from "../../modules/i18n";
import roles from "../../modules/roles";
import Page from "../base/Page";
import Content from "../base/Content";
import DataTables from "../DataTables";
import Header from "./Header";
import SetRole from "./SetRole";

let getIntlMessage = i18n.messages.getIntlMessage;

var Roles = React.createClass({
  mixins: [reactor.ReactMixin],

  getInitialState() {
    return {
      showSetRole: false,
      roleId: null,
      doReload: false,
    };
  },

  getDataBindings() {
    return {
      setRolesReload: roles.getters.setRolesReloadGetter,
    };
  },

  componentWillReceiveProps(props) {
    if (props.profile !== this.props.profile && !_.isEmpty(props.profile)) {
      this.setState({
        doReload: true,
      });
    }
  },

  setColumns() {
    return [
      { data: "id", name: getIntlMessage("ID"), visible: false, searchable: false, orderable: false },
      { data: "refid", name: getIntlMessage("RefID") },
      { data: "name", name: getIntlMessage("Name") },
      { data: "created_on", name: getIntlMessage("Created On"), filter: "datetime" },
      { data: "status_name", name: getIntlMessage("Status"), filter: "label" },
      { data: null, filter: "actions", searchable: false, orderable: false },
    ];
  },

  setActions() {
    return [
      {
        help: getIntlMessage("Edit"),
        icon: "fa fa-edit",
        action: "onShowSetRole",
        onClick: this.onShowSetRole,
      },
    ];
  },

  mDataProps() {
    var props = [];
    _.forEach(this.setColumns(), (n, key) => {
      if (key.data !== null) {
        props.push(n.data);
      }
    });
    return props;
  },

  onShowSetRole(id) {
    this.setState({
      showSetRole: true,
      roleId: id,
    });
  },

  onHideSetRole() {
    this.setState({
      showSetRole: false,
    });
  },

  render() {
    var doReload = this.state.doReload || this.state.setRolesReload;
    return (
      <Page>
        <Header {...this.props} />
        <Content className="roles">
          <Panel>
            <DataTables
              domId={"roles-datatables"}
              ajaxSource={process.env.__APIURL__ + "/roles/datatables"}
              stateSave={true}
              responsive={true}
              columns={this.setColumns()}
              actions={this.setActions()}
              doReload={doReload}
              {...this.props}
            />
          </Panel>
        </Content>
        <SetRole show={this.state.showSetRole} onHide={this.onHideSetRole} roleId={this.state.roleId} {...this.props} />
      </Page>
    );
  },
});

export default Roles;
