import React from "react";

var Public = React.createClass({
  render() {
    return (
      <div>
        <div className="color-line" />
        {this.props.children}
      </div>
    );
  },
});

export default Public;
