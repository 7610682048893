import React from "react";
import t from "tcomb-form";
import bootstrap from "uvdom-bootstrap";
import _ from "lodash";

var k = (React.Kendo = require("kendo-ui-react"));

const Any = t.Any;
const maybe = t.maybe;
const noobj = {};

const Positive = t.subtype(
  t.Num,
  function (n) {
    return n % 1 === 0 && n >= 0;
  },
  "Positive"
);

const Cols = t.subtype(
  t.tuple([Positive, Positive]),
  function (cols) {
    return cols[0] + cols[1] === 12;
  },
  "Cols"
);

const Breakpoints = t.struct(
  {
    xs: maybe(Cols),
    sm: maybe(Cols),
    md: maybe(Cols),
    lg: maybe(Cols),
  },
  "Breakpoints"
);

Breakpoints.prototype.getBreakpoints = function (index) {
  const breakpoints = {};
  for (const size in this) {
    if (this.hasOwnProperty(size) && !t.Nil.is(this[size])) {
      breakpoints[size] = this[size][index];
    }
  }
  return breakpoints;
};

Breakpoints.prototype.getLabelClassName = function () {
  return bootstrap.getBreakpoints(this.getBreakpoints(0));
};

Breakpoints.prototype.getInputClassName = function () {
  return bootstrap.getBreakpoints(this.getBreakpoints(1));
};

Breakpoints.prototype.getOffsetClassName = function () {
  return t.mixin(bootstrap.getOffsets(this.getBreakpoints(1)), bootstrap.getBreakpoints(this.getBreakpoints(1)));
};

Breakpoints.prototype.getFieldsetClassName = function () {
  return {
    "col-xs-12": true,
  };
};

const Size = t.enums.of("xs sm md lg", "Size");

const TextboxConfig = t.struct(
  {
    addonBefore: Any,
    addonAfter: Any,
    horizontal: maybe(Breakpoints),
    size: maybe(Size),
    buttonBefore: Any,
    buttonAfter: Any,
  },
  "TextboxConfig"
);

const CheckboxConfig = t.struct(
  {
    horizontal: maybe(Breakpoints),
  },
  "CheckboxConfig"
);

const SelectConfig = t.struct(
  {
    addonBefore: Any,
    addonAfter: Any,
    horizontal: maybe(Breakpoints),
    size: maybe(Size),
  },
  "SelectConfig"
);

const RadioConfig = t.struct(
  {
    horizontal: maybe(Breakpoints),
  },
  "RadioConfig"
);

const DateConfig = t.struct(
  {
    horizontal: maybe(Breakpoints),
  },
  "DateConfig"
);

const StructConfig = t.struct(
  {
    horizontal: maybe(Breakpoints),
  },
  "StructConfig"
);

const ListConfig = t.struct(
  {
    horizontal: maybe(Breakpoints),
  },
  "ListConfig"
);

const Transformer = t.struct({
  format: t.Func, // from value to string, it must be idempotent
  parse: t.Func, // from string to value
});

function getLabel({ label, breakpoints, htmlFor, id, align }) {
  if (!label) {
    return;
  }

  const className = breakpoints ? breakpoints.getLabelClassName() : null;

  return bootstrap.getLabel({
    align,
    className,
    htmlFor,
    id,
    label,
  });
}

function getHelp({ help, attrs }) {
  if (!help) {
    return;
  }
  return bootstrap.getHelpBlock({
    help,
    id: attrs.id + "-tip",
  });
}

function getError({ hasError, error }) {
  if (!hasError || !error) {
    return;
  }
  return bootstrap.getErrorBlock({
    error,
    hasError,
  });
}

function getHiddenTextbox({ value, name }) {
  return {
    tag: "input",
    attrs: {
      type: "hidden",
      value,
      name,
    },
  };
}

function getInputGroupButton(button) {
  return {
    tag: "div",
    attrs: {
      className: {
        "input-group-btn": true,
      },
    },
    children: button,
  };
}

export const listTransformer = {
  format: function (value) {
    // return Array.isArray(value) ? value.join(',') : value;
    return value;
  },
  parse: function (value) {
    if (Array.isArray(value)) {
      return value.map(String);
    }
    return value ? value.split(",") : [];
  },
};

export class KDatePickerWidget extends t.form.Textbox {
  getTemplate() {
    return (locals) => {
      const config = new SelectConfig(locals.config || noobj);
      const attrs = t.mixin({}, locals.attrs);
      attrs.className = t.mixin({}, attrs.className);
      attrs.className["form-control"] = true;

      if (locals.help) {
        attrs["aria-describedby"] = attrs["aria-describedby"] || attrs.id + "-tip";
      }

      var value = null;
      if (locals.value) {
        value = new Date(locals.value);
      } else if (attrs.value) {
        value = attrs.value;
      } else {
        value = new Date();
      }

      let onChange =
        locals.onChange ||
        function (x) {
          console.log(x);
        };
      let options = {
        value: value,
        culture: _.get(attrs, "culture", "en-US"),
        format: _.get(attrs, "format", ""),
        placeholder: _.get(attrs, "placeholder", ""),
        change: function (event) {
          var v = this.value();
          locals.onChange(v);
        },
      };

      const control = (
        <k.DatePicker
          options={options}
          tag={_.get(attrs, "meta.tag", "input")}
          reactive={_.get(attrs, "meta.reactive", false)}
          debug={_.get(attrs, "meta.debug", false)}
        />
      );

      const horizontal = config.horizontal;
      const label = getLabel({
        label: locals.label,
        htmlFor: attrs.id,
        breakpoints: config.horizontal,
      });
      const error = getError(locals);
      const help = getHelp(locals);

      let children = [label, control, error, help];

      if (horizontal) {
        children = [
          label,
          {
            tag: "div",
            attrs: {
              className: label ? horizontal.getInputClassName() : horizontal.getOffsetClassName(),
            },
            children: [control, error, help],
          },
        ];
      }

      return bootstrap.getFormGroup({
        className: "form-group-depth-" + locals.path.length,
        hasError: locals.hasError,
        children: children,
      });
    };
  }
}

export const toolGroups = {
  basic: ["bold", "italic", "underline"],
  alignment: ["justifyLeft", "justifyCenter", "justifyRight"],
  lists: ["insertUnorderedList", "insertOrderedList"],
  indenting: ["indent", "outdent"],
  links: ["createLink", "unlink"],
  colors: ["foreColor", "backColor"],
  fonts: ["fontName", "fontSize"],
  tables: ["createTable", "addColumnLeft", "addColumnRight", "addRowAbove", "addRowBelow", "deleteRow", "deleteColumn"],
};

export const toolPresets = {
  default: [].concat.call(toolGroups.basic, toolGroups.alignment),
  all: [].concat.call(
    ["formatting"],
    toolGroups.basic,
    toolGroups.alignment,
    toolGroups.lists,
    toolGroups.indenting,
    toolGroups.links,
    toolGroups.colors,
    toolGroups.fonts,
    ["insertImage"],
    toolGroups.tables,
    ["viewHtml"]
  ),
};

export class KEditorWidget extends t.form.Textbox {
  getTemplate() {
    return (locals) => {
      const config = new TextboxConfig(locals.config || noobj);
      const attrs = t.mixin({}, locals.attrs);
      attrs.className = t.mixin({}, attrs.className);
      attrs.className["form-control"] = true;

      if (locals.help) {
        attrs["aria-describedby"] = attrs["aria-describedby"] || attrs.id + "-tip";
      }

      let onChange =
        locals.onChange ||
        function (x) {
          console.log(x);
        };
      let options = {
        value: locals.value || "",
        culture: _.get(attrs, "culture", "en-US"),
        tools: _.get(attrs, "tools", toolPresets["default"]),
        placeholder: _.get(attrs, "placeholder", ""),
        change: function (event) {
          var value = this.value();
          locals.onChange(value);
        },
      };
      const control = (
        <k.Editor
          options={options}
          tag={_.get(attrs, "meta.tag", "textarea")}
          reactive={_.get(attrs, "meta.reactive", false)}
          debug={_.get(attrs, "meta.debug", false)}
        />
      );

      const horizontal = config.horizontal;
      const label = getLabel({
        label: locals.label,
        htmlFor: attrs.id,
        breakpoints: config.horizontal,
      });
      const error = getError(locals);
      const help = getHelp(locals);

      let children = [label, control, error, help];

      if (horizontal) {
        children = [
          label,
          {
            tag: "div",
            attrs: {
              className: label ? horizontal.getInputClassName() : horizontal.getOffsetClassName(),
            },
            children: [control, error, help],
          },
        ];
      }

      return bootstrap.getFormGroup({
        className: "form-group-depth-" + locals.path.length,
        hasError: locals.hasError,
        children: children,
      });
    };
  }
}

export class KDropDownListWidget extends t.form.Textbox {
  getTemplate() {
    return (locals) => {
      const config = new TextboxConfig(locals.config || noobj);
      const attrs = t.mixin({}, locals.attrs);
      attrs.className = t.mixin({}, attrs.className);
      attrs.className["form-control"] = true;

      if (locals.help) {
        attrs["aria-describedby"] = attrs["aria-describedby"] || attrs.id + "-tip";
      }

      let onChange =
        locals.onChange ||
        function (x) {
          console.log(x);
        };
      let options = {
        value: locals.value || "",
        culture: _.get(attrs, "culture", "en-US"),
        dataTextField: _.get(attrs, "dataTextField", "text"),
        dataValueField: _.get(attrs, "dataValueField", "value"),
        dataSource: _.get(attrs, "dataSource", []),
        index: _.get(attrs, "index", 0),
        optionLabel: _.get(attrs, "optionLabel", ""),
        placeholder: _.get(attrs, "placeholder", ""),
        change: function (event) {
          var v = this.value();
          locals.onChange(v);
        },
      };
      const control = (
        <k.DropDownList
          options={options}
          tag={_.get(attrs, "meta.tag", "input")}
          reactive={_.get(attrs, "meta.reactive", false)}
          debug={_.get(attrs, "meta.debug", false)}
        />
      );

      const horizontal = config.horizontal;
      const label = getLabel({
        label: locals.label,
        htmlFor: attrs.id,
        breakpoints: config.horizontal,
      });
      const error = getError(locals);
      const help = getHelp(locals);

      let children = [label, control, error, help];

      if (horizontal) {
        children = [
          label,
          {
            tag: "div",
            attrs: {
              className: label ? horizontal.getInputClassName() : horizontal.getOffsetClassName(),
            },
            children: [control, error, help],
          },
        ];
      }

      return bootstrap.getFormGroup({
        className: "form-group-depth-" + locals.path.length,
        hasError: locals.hasError,
        children: children,
      });
    };
  }
}

export class KMultiSelectWidget extends t.form.Textbox {
  getTemplate() {
    return (locals) => {
      const config = new TextboxConfig(locals.config || noobj);
      const attrs = t.mixin({}, locals.attrs);
      attrs.className = t.mixin({}, attrs.className);
      attrs.className["form-control"] = true;

      if (locals.help) {
        attrs["aria-describedby"] = attrs["aria-describedby"] || attrs.id + "-tip";
      }

      let onChange =
        locals.onChange ||
        function (x) {
          console.log(x);
        };
      let options = {
        value: locals.value || "",
        culture: _.get(attrs, "culture", "en-US"),
        dataTextField: _.get(attrs, "dataTextField", "text"),
        dataValueField: _.get(attrs, "dataValueField", "value"),
        dataSource: _.get(attrs, "dataSource", []),
        index: _.get(attrs, "index", 0),
        optionLabel: _.get(attrs, "optionLabel", ""),
        placeholder: _.get(attrs, "placeholder", ""),
        change: function (event) {
          var v = this.value();
          locals.onChange(v);
        },
      };
      const control = (
        <k.MultiSelect
          options={options}
          tag={_.get(attrs, "meta.tag", "input")}
          reactive={_.get(attrs, "meta.reactive", false)}
          debug={_.get(attrs, "meta.debug", false)}
        />
      );

      const horizontal = config.horizontal;
      const label = getLabel({
        label: locals.label,
        htmlFor: attrs.id,
        breakpoints: config.horizontal,
      });
      const error = getError(locals);
      const help = getHelp(locals);

      let children = [label, control, error, help];

      if (horizontal) {
        children = [
          label,
          {
            tag: "div",
            attrs: {
              className: label ? horizontal.getInputClassName() : horizontal.getOffsetClassName(),
            },
            children: [control, error, help],
          },
        ];
      }

      return bootstrap.getFormGroup({
        className: "form-group-depth-" + locals.path.length,
        hasError: locals.hasError,
        children: children,
      });
    };
  }
}
