import React, { Component, PropTypes } from "react";
import Loader from "react-loader";

export default class DefaultLoader extends Component {
  static propTypes = {
    loaded: PropTypes.bool,
    color: PropTypes.string,
  };

  static defaultProps = {
    loaded: false,
    color: "#000",
  };

  render() {
    return <Loader loaded={this.props.loaded} color={this.props.color} />;
  }
}

export class ButtonLoader extends Component {
  static propTypes = {
    loaded: PropTypes.bool,
    color: PropTypes.string,
  };

  static defaultProps = {
    loaded: false,
    color: "#000",
  };

  render() {
    return <Loader loaded={this.props.loaded} length={4} width={2} radius={4} color={this.props.color} />;
  }
}
