import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";

import i18n from "../../modules/i18n";
import prospects from "../../modules/prospects";

let getIntlMessage = i18n.messages.getIntlMessage;

export default class ResumeProspect extends Component {
  static propTypes = {
    show: React.PropTypes.bool,
    onHide: React.PropTypes.func,
    prospectId: React.PropTypes.number,
  };

  static defaultProps = {
    prospectId: null,
  };

  onResume = (event) => {
    prospects.actions.setProspectStatusStart(true);
    prospects.actions.setProspectStatus(this.props.prospectId, { refid: "available" });
    this.props.onHide();
  };

  render() {
    return (
      <Modal bsSize="medium" className="hmodal-info" show={this.props.show} onHide={this.props.onHide} {...this.props}>
        <div className="color-line" />
        <Modal.Header>
          <Modal.Title>{getIntlMessage("Resume Prospect")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{getIntlMessage("Are you sure you want to resume this prospect?")}</Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onHide}>{getIntlMessage("Close")}</Button>
          <Button name="resume" bsStyle="info" onClick={this.onResume}>
            {getIntlMessage("Resume")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
