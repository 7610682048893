import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";

import app from "../modules/app";
import i18n from "../modules/i18n";

let getIntlMessage = i18n.messages.getIntlMessage;

export default class ErrorNetwork extends Component {
  static propTypes = {
    show: React.PropTypes.bool,
    onHide: React.PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);
  }

  onReload = (event) => {
    event.preventDefault();
    window.location.reload();
    this.props.onHide();
  };

  render() {
    return (
      <Modal
        bsSize={this.props.bsSize}
        className="hmodal-warning"
        show={this.props.show}
        onHide={this.props.onHide}
        {...this.props}
      >
        <div className="color-line" />
        <Modal.Header>
          <Modal.Title>{getIntlMessage("Network Connection Error")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{getIntlMessage("ERROR_NETWORK_TEXT")}</Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onHide}>{getIntlMessage("Close")}</Button>
          <Button name="reload" bsStyle="warning" onClick={this.onReload}>
            {getIntlMessage("Reload")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
