import React from "react";
import classNames from "classnames";

var Feedback = React.createClass({
  propTypes: {
    feedback: React.PropTypes.object,
    show: React.PropTypes.bool,
  },

  getDefaultProps() {
    return {
      feedback: {
        type: "info",
        messages: [],
      },
      show: true,
    };
  },

  render() {
    var show = this.props.show;
    if (!show) {
      return <div />;
    }
    var feedback = this.props.feedback;
    if (feedback.messages.length === 0) {
      return null;
    }
    var alertTypes = {
      success: "success",
      error: "warning",
    };
    var cnames = classNames("alert", "alert-" + alertTypes[feedback.type], "text-center");
    var messages = feedback.messages.map((message, index) => {
      return <p key={index}>{message}</p>;
    });
    return <div className={cnames}>{messages}</div>;
  },
});

export default Feedback;
