import React, { Component } from "react";
import { Panel } from "react-bootstrap";

import i18n from "../modules/i18n";

let getIntlMessage = i18n.messages.getIntlMessage;

export default class Error404 extends Component {
  render() {
    return (
      <div>
        <div className="error-container">
          <i className="pe-7s-way text-success big-icon"></i>
          <h1>404</h1>
          <strong>{getIntlMessage("Page Not Found")}</strong>
          <p>{getIntlMessage("ERROR_404_TEXT")}</p>
          <a href="/#/dashboard" className="btn btn-xs btn-success">
            {getIntlMessage("Back to Dashboard")}
          </a>
        </div>
      </div>
    );
  }
}
