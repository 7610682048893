import React, { Component, PropTypes } from "react";
import { Row, Col } from "react-bootstrap";
import _ from "lodash";

import i18n from "../../modules/i18n";
import AdvisorsActivityStats from "./AdvisorsActivityStats";

let getIntlMessage = i18n.messages.getIntlMessage;

export default class SalesDirectorCurrentStats extends Component {
  static propTypes = {
    accounts: PropTypes.array,
    current: PropTypes.object,
    width: PropTypes.string,
    height: PropTypes.string,
  };

  static defaultProps = {
    accounts: [],
    current: {},
    width: "auto",
    height: "auto",
  };

  getData = () => {
    let prospects = [];
    let current = _.get(this.props.current, "stats.current");
    _.forIn(this.props.accounts, (value, key) => {
      let aid = value.id;
      let stats = _.find(current, { account_id: aid });
      prospects.push({
        name: value.fullname,
        available: _.get(stats, "prospects.available", 0),
        total_available: _.get(stats, "prospects.total_available", 0),
      });
    });
    return {
      prospects: _.orderBy(prospects, ["available"], ["desc"]),
    };
  };

  render() {
    let data = this.getData();
    return (
      <div>
        <Row>
          <Col xs={12}>
            <h2 className="stats-title">{getIntlMessage("Actual")}</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <AdvisorsActivityStats
              data={data.prospects}
              title={getIntlMessage("Prospects")}
              dataField1={"name"}
              dataName1={getIntlMessage("Name")}
              dataField2={"total_available"}
              dataName2={getIntlMessage("Total")}
              dataField3={"available"}
              dataName3={getIntlMessage("Avail")}
              width={this.props.width}
              height={this.props.height}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
