import React, { Component, PropTypes } from "react";
import { Button } from "react-bootstrap";
import t from "tcomb-form";

import auth from "../../modules/auth";
import i18n from "../../modules/i18n";

import Feedback from "../forms/Feedback";
import { ButtonLoader } from "../Loaders";
import PasswordLostView from "./PasswordLostView";
import PasswordResetView from "./PasswordResetView";

let getIntlMessage = i18n.messages.getIntlMessage;

export default class LoginView extends Component {
  static propTypes = {
    loginForm: PropTypes.object,
    passwordLostForm: PropTypes.object,
    token: PropTypes.string,
    showPasswordLost: PropTypes.bool,
    showPasswordReset: PropTypes.bool,
    onShowPasswordLost: PropTypes.func,
    onHidePasswordLost: PropTypes.func,
    onShowPasswordReset: PropTypes.func,
    onHidePasswordReset: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      value: {},
    };
  }

  onFormSubmit = (event) => {
    event.preventDefault();
    var value = this.refs.form.getValue();
    if (value) {
      this.setState({ value });
      auth.actions.loginStart(true);
      auth.actions.login(value);
    }
  };

  onFormChange = (value, path) => {
    this.refs.form.getComponent(path).validate();
  };

  render() {
    const loginForm = this.props.loginForm;
    const logo = new URL("../../img/ia-logo.png", import.meta.url);
    return (
      <div className="login-container">
        <div className="row">
          <div className="col-md-12">
            <div className="text-center m-b-md">
              <img src={logo} />
              <h2>PROSPECTR</h2>
            </div>
            <div className="hpanel">
              <div className="panel-heading hbuilt text-center font-uppercase">
                <h4>{getIntlMessage("Login")}</h4>
              </div>
              <Feedback feedback={loginForm.feedback} />
              <div className="panel-body">
                <form action="#" id="loginForm">
                  <t.form.Form
                    ref="form"
                    type={loginForm.type}
                    options={loginForm.options}
                    value={this.state.value}
                    onChange={this.onFormChange}
                  />
                  <Button bsStyle="info" block={true} disabled={loginForm.started} onClick={this.onFormSubmit}>
                    {getIntlMessage("Login")}
                    <ButtonLoader loaded={!loginForm.started} color={"#fff"} />
                  </Button>
                  <Button
                    name="password-lost"
                    bsStyle="default"
                    block={true}
                    disabled={loginForm.started}
                    onClick={this.props.onShowPasswordLost}
                  >
                    {getIntlMessage("Lost your password?")}
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center">
            <strong>iA • PROSPECTR</strong>
            <br /> Copyright P2L Technologies
          </div>
        </div>
        <PasswordLostView
          show={this.props.showPasswordLost}
          onHide={this.props.onHidePasswordLost}
          passwordLostForm={this.props.passwordLostForm}
          {...this.props}
        />
        <PasswordResetView
          token={this.props.token}
          show={this.props.showPasswordReset}
          onHide={this.props.onHidePasswordReset}
          {...this.props}
        />
      </div>
    );
  }
}
