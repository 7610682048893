import { Store, toImmutable } from "nuclear-js";

import forms from "../forms";
import i18n from "../i18n";
import actionTypes from "./actionTypes";
import * as schemas from "./schemas";

let getIntlMessage = i18n.messages.getIntlMessage;

let addProspectFormDefaults = {
  type: schemas.AddProspectSchema,
  options: schemas.AddProspectSchemaOptions,
  feedback: {
    type: "info",
    messages: [],
  },
  started: false,
};

let setProspectFormDefaults = {
  type: schemas.SetProspectSchema,
  options: schemas.SetProspectSchemaOptions,
  feedback: {
    type: "info",
    messages: [],
  },
  started: false,
};

let setProspectConsentFormDefaults = {
  type: schemas.SetProspectConsentSchema,
  options: schemas.SetProspectConsentSchemaOptions,
  feedback: {
    type: "info",
    messages: [],
  },
  started: false,
};

let setProspectStatusDefaults = {
  feedback: {
    type: "info",
    messages: [],
  },
  started: false,
};

let setProspectStatusBatchDefaults = {
  feedback: {
    type: "info",
    messages: [],
  },
  started: false,
};

let addProspectNoteFormDefaults = {
  type: schemas.AddProspectNoteSchema,
  options: schemas.AddProspectNoteSchemaOptions,
  feedback: {
    type: "info",
    messages: [],
  },
  started: false,
};

let addProspectActivityFormDefaults = {
  type: schemas.AddProspectActivitySchema,
  options: schemas.AddProspectActivitySchemaOptions,
  feedback: {
    type: "info",
    messages: [],
  },
  started: false,
};

let initialState = toImmutable({
  prospect: {},
  addProspectForm: addProspectFormDefaults,
  setProspectForm: setProspectFormDefaults,
  setProspectConsentForm: setProspectConsentFormDefaults,
  setProspectsReload: false,
  setProspectStatus: setProspectStatusDefaults,
  setProspectStatusBatch: setProspectStatusBatchDefaults,
  addProspectNoteForm: addProspectNoteFormDefaults,
  addProspectActivityForm: addProspectActivityFormDefaults,
  callList: [],
});

export default new Store({
  getInitialState() {
    return initialState;
  },

  initialize() {
    this.on(actionTypes.SET_PROSPECT_RESET, onSetProspectReset);
    this.on(actionTypes.GET_PROSPECT_SUCCESS, onGetProspectSuccess);
    this.on(actionTypes.GET_PROSPECT_FAIL, onGetProspectFail);
    this.on(actionTypes.ADD_PROSPECT_FORM, onAddProspectForm);
    this.on(actionTypes.ADD_PROSPECT_START, onAddProspectStart);
    this.on(actionTypes.ADD_PROSPECT_SUCCESS, onAddProspectSuccess);
    this.on(actionTypes.ADD_PROSPECT_FAIL, onAddProspectFail);
    this.on(actionTypes.SET_PROSPECT_FORM, onSetProspectForm);
    this.on(actionTypes.SET_PROSPECT_START, onSetProspectStart);
    this.on(actionTypes.SET_PROSPECT_SUCCESS, onSetProspectSuccess);
    this.on(actionTypes.SET_PROSPECT_FAIL, onSetProspectFail);
    this.on(actionTypes.SET_PROSPECTS_RELOAD, onSetProspectsReload);
    this.on(actionTypes.REM_PROSPECT, onRemProspect);
    this.on(actionTypes.SET_PROSPECT_CONSENT_FORM, onSetProspectConsentForm);
    this.on(actionTypes.SET_PROSPECT_STATUS_START, onSetProspectStatusStart);
    this.on(actionTypes.SET_PROSPECT_STATUS_SUCCESS, onSetProspectStatusSuccess);
    this.on(actionTypes.SET_PROSPECT_STATUS_FAIL, onSetProspectStatusFail);
    this.on(actionTypes.SET_PROSPECT_STATUS_BATCH_START, onSetProspectStatusBatchStart);
    this.on(actionTypes.SET_PROSPECT_STATUS_BATCH_SUCCESS, onSetProspectStatusBatchSuccess);
    this.on(actionTypes.SET_PROSPECT_STATUS_BATCH_FAIL, onSetProspectStatusBatchFail);
    this.on(actionTypes.ADD_PROSPECT_NOTE_FORM, onAddProspectNoteForm);
    this.on(actionTypes.ADD_PROSPECT_NOTE_START, onAddProspectNoteStart);
    this.on(actionTypes.ADD_PROSPECT_NOTE_SUCCESS, onAddProspectNoteSuccess);
    this.on(actionTypes.ADD_PROSPECT_NOTE_FAIL, onAddProspectNoteFail);
    this.on(actionTypes.ADD_PROSPECT_ACTIVITY_FORM, onAddProspectActivityForm);
    this.on(actionTypes.ADD_PROSPECT_ACTIVITY_START, onAddProspectActivityStart);
    this.on(actionTypes.ADD_PROSPECT_ACTIVITY_SUCCESS, onAddProspectActivitySuccess);
    this.on(actionTypes.ADD_PROSPECT_ACTIVITY_FAIL, onAddProspectActivityFail);
    this.on(actionTypes.GET_PROSPECTS_CALL_LIST_SUCCESS, onGetProspectsCallListSuccess);
    this.on(actionTypes.GET_PROSPECTS_CALL_LIST_FAIL, onGetProspectsCallListFail);
  },
});

function onSetProspectReset(state) {
  return initialState;
}

function onGetProspectSuccess(state, { data }) {
  return state.set("prospect", toImmutable(data.prospect));
}

function onGetProspectFail(state, { data }) {
  return state.set("prospect", toImmutable({}));
}

function onAddProspectForm(state) {
  return state.set("addProspectForm", toImmutable(addProspectFormDefaults));
}

function onAddProspectStart(state, { data }) {
  return state.setIn(["addProspectForm", "started"], data);
}

function onAddProspectSuccess(state, { data }) {
  let status = data.prospect.status.status.refid;
  let feedback = {};
  if (status === "excluded") {
    feedback = {
      type: "error",
      messages: [getIntlMessage("Add Successful with problems, prospect is on the DNCL")],
    };
  } else {
    feedback = {
      type: "success",
      messages: [getIntlMessage("Add Successful")],
    };
  }
  let options = addProspectFormDefaults.options;
  return state;
  // .set('prospect', toImmutable({}))
  // .setIn(['addProspectForm', 'feedback'], toImmutable(feedback))
  // .setIn(['addProspectForm', 'options'], toImmutable(options))
}

function onAddProspectFail(state, { data }) {
  var feedback = forms.utils.setFormFeedback(data);
  var options = forms.utils.setFormOptions(state.getIn(["addProspectForm", "options"]).toJS(), data);
  return state
    .set("prospect", toImmutable(data.prospect))
    .setIn(["addProspectForm", "feedback"], toImmutable(feedback))
    .setIn(["addProspectForm", "options"], toImmutable(options));
}

function onSetProspectForm(state) {
  return state;
}

function onSetProspectStart(state, { data }) {
  return state.setIn(["setProspectForm", "started"], data);
}

function onSetProspectSuccess(state, { data }) {
  return state.set("prospect", toImmutable(data.prospect)).set("setProspectForm", toImmutable(setProspectFormDefaults));
}

function onSetProspectFail(state, { data }) {
  let feedback = forms.utils.setFormFeedback(data);
  let options = forms.utils.setFormOptions(state.getIn(["setProspectForm", "options"]).toJS(), data);
  return state
    .setIn(["setProspectForm", "feedback"], toImmutable(feedback))
    .setIn(["setProspectForm", "options"], toImmutable(options));
}

function onSetProspectsReload(state, { data }) {
  return state.set("setProspectsReload", toImmutable(data));
}

function onRemProspect(state) {
  return state.set("prospect", toImmutable({}));
}

function onSetProspectConsentForm(state) {
  return state.set("setProspectConsentForm", toImmutable(setProspectConsentFormDefaults));
}

function onSetProspectStatusStart(state, { data }) {
  return state.setIn(["setProspectStatus", "started"], data);
}

function onSetProspectStatusSuccess(state, { data }) {
  let feedback = {
    type: "success",
    messages: [getIntlMessage("Add Successful")],
  };
  return state.setIn(["setProspectStatus", "feedback"], toImmutable(feedback));
}

function onSetProspectStatusFail(state, { data }) {
  let feedback = {
    type: "error",
    messages: [getIntlMessage("An error occured")],
  };
  return state.setIn(["setProspectStatus", "feedback"], toImmutable(feedback));
}

function onSetProspectStatusBatchStart(state, { data }) {
  return state.setIn(["setProspectStatusBatch", "started"], data);
}

function onSetProspectStatusBatchSuccess(state, { data }) {
  let feedback = {
    type: "success",
    messages: [getIntlMessage("Add Successful")],
  };
  return state.setIn(["setProspectStatusBatch", "feedback"], toImmutable(feedback));
}

function onSetProspectStatusBatchFail(state, { data }) {
  let feedback = {
    type: "error",
    messages: [getIntlMessage("An error occured")],
  };
  return state.setIn(["setProspectStatusBatch", "feedback"], toImmutable(feedback));
}

function onAddProspectNoteForm(state) {
  return state.set("addProspectNoteForm", toImmutable(addProspectNoteFormDefaults));
}

function onAddProspectNoteStart(state, { data }) {
  return state.setIn(["addProspectNoteForm", "started"], data);
}

function onAddProspectNoteSuccess(state, { data }) {
  let feedback = {
    type: "success",
    messages: [getIntlMessage("Add Successful")],
  };
  let options = addProspectFormDefaults.options;
  return state
    .setIn(["addProspectNoteForm", "feedback"], toImmutable(feedback))
    .setIn(["addProspectNoteForm", "options"], toImmutable(options));
}

function onAddProspectNoteFail(state, { data }) {
  let feedback = forms.utils.setFormFeedback(data);
  let options = forms.utils.setFormOptions(state.getIn(["addProspectNoteForm", "options"]).toJS(), data);
  return state
    .setIn(["addProspectNoteForm", "feedback"], toImmutable(feedback))
    .setIn(["addProspectNoteForm", "options"], toImmutable(options));
}

function onAddProspectActivityForm(state) {
  return state.set("addProspectActivityForm", toImmutable(addProspectActivityFormDefaults));
}

function onAddProspectActivityStart(state, { data }) {
  return state.setIn(["addProspectActivityForm", "started"], data);
}

function onAddProspectActivitySuccess(state, { data }) {
  let feedback = {
    type: "success",
    messages: [getIntlMessage("Add Successful")],
  };
  let options = addProspectActivityFormDefaults.options;
  return state
    .setIn(["addProspectActivityForm", "feedback"], toImmutable(feedback))
    .setIn(["addProspectActivityForm", "options"], toImmutable(options));
}

function onAddProspectActivityFail(state, { data }) {
  let feedback = forms.utils.setFormFeedback(data);
  let options = forms.utils.setFormOptions(state.getIn(["addProspectActivityForm", "options"]).toJS(), data);
  return state
    .setIn(["addProspectActivityForm", "feedback"], toImmutable(feedback))
    .setIn(["addProspectActivityForm", "options"], toImmutable(options));
}

function onGetProspectsCallListSuccess(state, { data }) {
  return state.set("callList", toImmutable(data.prospects));
}

function onGetProspectsCallListFail(state, { data }) {
  return state.set("callList", toImmutable([]));
}
