var React = require("react");
import reactor from "../reactor";
import notifier from "../modules/notifier";

var Notifier = React.createClass({
  mixins: [reactor.ReactMixin],

  getDataBindings() {
    return {
      notifier: notifier.getters.notifierGetter,
    };
  },

  componentWillMount() {
    Messenger.options = {
      extraClasses: this.props.extraClasses,
      theme: this.props.theme,
    };
  },

  // componentWillUpdate(nextProps, nextState) {
  //   var notifier = this.state.notifier.toJS();
  //   console.log(nextState);
  //   Messenger().post(notifier.message)
  // },

  // onPost(data) {
  //   Messenger().post(data);
  // },

  render() {
    return false;
  },
});

export default Notifier;
