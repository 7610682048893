import React from "react";
import _ from "lodash";

import reactor from "../../reactor";
import offices from "../../modules/offices";
import i18n from "../../modules/i18n";
import FormModal from "../forms/FormModal";

let getIntlMessage = i18n.messages.getIntlMessage;

var SetOffice = React.createClass({
  mixins: [reactor.ReactMixin],

  propTypes: {
    officeId: React.PropTypes.number,
  },

  getDefaultProps() {
    return {
      officeId: null,
    };
  },

  getInitialState() {
    return {
      value: {},
    };
  },

  getDataBindings() {
    return {
      office: offices.getters.officeGetter,
      setOfficeForm: offices.getters.setOfficeFormGetter,
      setOfficsReload: offices.getters.setOfficesReloadGetter,
    };
  },

  componentWillReceiveProps(props) {
    if (props.show && (_.isEmpty(this.state.value) || this.state.setOfficesReload)) {
      offices.actions.getOffice(props.officeId);
    } else if (!props.show && !_.isEmpty(this.state.value)) {
      offices.actions.remOffice();
    }
  },

  componentDidUpdate(prevProps, prevState) {
    if (this.state.office !== prevState.office) {
      this.setState({ value: this.state.office.toJS() });
    }
  },

  onFormSubmit(event, value) {
    if (value) {
      this.setState({
        value: value,
      });
      offices.actions.setOfficeStart(true);
      offices.actions.setOffice(this.props.officeId, value);
    }
  },

  onFormChange(value) {
    this.setState({ value });
  },

  render() {
    var form = this.state.setOfficeForm.toJS();
    return (
      <FormModal
        form={form}
        formType="set"
        value={this.state.value}
        onFormChange={this.onFormChange}
        onFormSubmit={this.onFormSubmit}
        title={getIntlMessage("Edit Office")}
        {...this.props}
      />
    );
  },
});

export default SetOffice;
