import React, { Component } from "react";
import { connect } from "nuclear-js-react-addons";

import app from "../../modules/app";
import PlanificationSummary from "./Summary";
import PlanificationPeriod from "./Period";

export default
@connect((props) => ({
  account: app.getters.appAccountGetter,
}))
class PlanificationPage extends Component {
  render() {
    let profile = this.props.profile;
    let account = this.props.account.toJS();
    if (
      profile.main_role_ref_id === "sales-director" ||
      account.main_role_refid === "advisor" ||
      account.main_role_refid === "student"
    ) {
      account = profile;
    }
    let role = account.main_role_refid;
    if (role === "sales-director") {
      return <PlanificationPeriod {...this.props} />;
    }
    if (role === "office-director" || role === "supervisor" || role === "manager") {
      return <PlanificationSummary {...this.props} />;
    }
  }
}
