import React, { Component, PropTypes } from "react";

import EasyPieChart from "../EasyPieChart";

export default class ActivityRatioStats extends Component {
  static propTypes = {
    title: PropTypes.string,
    value: PropTypes.number,
    description: PropTypes.string,
  };

  static defaultProps = {
    title: "Value 1",
    value: 0,
    description: "",
  };

  render() {
    return (
      <div className="activity-ratio">
        <EasyPieChart title={this.props.title} value={this.props.value} lineWidth={6} />
        {this.props.description && <p className="description">{this.props.description}</p>}
      </div>
    );
  }
}
