import t from "tcomb-form";

import forms from "../forms";
import i18n from "../i18n";

let getIntlMessage = i18n.messages.getIntlMessage;

export var AddRoleSchema = t.struct({
  status_id: t.Num,
  refid: t.Str,
  permissions: t.list(t.Str),
  name_translations: forms.schemas.TranslationSchema,
});

export var SetRoleSchema = AddRoleSchema;

export var AddRoleSchemaOptions = {
  config: {
    // for each of lg md sm xs you can specify the columns width
    horizontal: {
      lg: [4, 8],
      md: [4, 8],
    },
  },
  fields: {
    status_id: {
      type: "text",
      label: getIntlMessage("Status"),
      factory: forms.widgets.KDropDownListWidget,
      attrs: {
        culture: "fr-CH",
        optionLabel: getIntlMessage("Select..."),
        dataSource: {
          transport: {
            read: {
              url: process.env.__APIURL__ + "/choices/lists/status",
              dataType: "json",
            },
          },
          schema: {
            data: "results",
          },
        },
      },
    },
    refid: {
      type: "text",
      label: getIntlMessage("RefID"),
    },
    permissions: {
      type: "text",
      label: getIntlMessage("Permissions"),
      factory: forms.widgets.KMultiSelectWidget,
      attrs: {
        culture: "fr-CH",
        placeholder: getIntlMessage("Select..."),
        dataSource: {
          transport: {
            read: {
              url: process.env.__APIURL__ + "/choices/system/permissions",
              dataType: "json",
            },
          },
          schema: {
            data: "results",
          },
        },
      },
    },
    name_translations: forms.schemas.TranslationSchemaOptions,
  },
};

export var SetRoleSchemaOptions = AddRoleSchemaOptions;
