import React, { Component } from "react";
import { connect } from "nuclear-js-react-addons";
import _ from "lodash";

import app from "../../modules/app";
import dashboards from "../../modules/dashboards";
import Page from "../base/Page";
import Content from "../base/Content";
import DefaultLoader from "../Loaders";
import Header from "./Header";
import AdvisorStats from "./AdvisorStats";
import SalesDirectorStats from "./SalesDirectorStats";
import OfficeDirectorStats from "./OfficeDirectorStats";
import SupervisorStats from "./SupervisorStats";
import ManagerStats from "./ManagerStats";
import PrintSalesDirectorStats from "./PrintSalesDirectorStats";

export default
@connect((props) => ({
  account: app.getters.appAccountGetter,
  selector: app.getters.appAccountSelectorGetter,
  current: dashboards.getters.dashboardCurrentGetter,
  weeks: dashboards.getters.dashboardWeeksGetter,
  quarter: dashboards.getters.dashboardQuarterGetter,
  aggregateWeeks: dashboards.getters.dashboardAggregateWeeksGetter,
  aggregateQuarter: dashboards.getters.dashboardAggregateQuarterGetter,
  officeDirectorQuarter: dashboards.getters.dashboardOfficeDirectorQuarterGetter,
  reload: dashboards.getters.dashboardReloadGetter,
}))
class Dashboard extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showPrintDashboard: false,
    };
  }

  componentWillMount() {
    let account = this.props.account.toJS();
    let profile = this.props.profile;
    if (_.isEmpty(account)) {
      dashboards.actions.getDashboard(profile.id);
    } else {
      dashboards.actions.getDashboard(account.id);
    }
  }

  renderStats = (
    account,
    current,
    weeks,
    quarter,
    aggregateWeeks,
    aggregateQuarter,
    officeDirectorQuarter,
    accounts
  ) => {
    let reload = this.props.reload;
    if (reload || current.started) {
      return <DefaultLoader />;
    }
    if (account.main_role_refid === "advisor" || account.main_role_refid === "student") {
      return <AdvisorStats account={account} current={current} weeks={weeks} quarter={quarter} />;
    } else if (account.main_role_refid === "sales-director") {
      let accs = [];
      _(account.account_ids).each((value) => {
        let acc = _.find(accounts, { id: value });
        if (acc && acc.id !== account.id && acc.role_refid !== "student") {
          accs.push(acc);
        }
      });
      return (
        <SalesDirectorStats
          account={account}
          accounts={accs}
          current={current}
          weeks={aggregateWeeks}
          quarter={aggregateQuarter}
        />
      );
    } else if (account.main_role_refid === "office-director") {
      let accs = [];
      _(account.account_ids).each((value) => {
        let acc = _.find(accounts, { id: value });
        if (acc && acc.id !== account.id && acc.role_refid === "sales-director") {
          accs.push(acc);
        }
      });
      return <OfficeDirectorStats accounts={accs} current={current} quarter={officeDirectorQuarter} />;
    } else if (account.main_role_refid === "supervisor") {
      let accs = [];
      _(account.account_ids).each((value) => {
        let acc = _.find(accounts, { id: value });
        if (acc && acc.id !== account.id && acc.role_refid !== "student") {
          accs.push(acc);
        }
      });
      return <SupervisorStats accounts={accs} current={current} />;
    } else if (account.main_role_refid === "manager") {
      return <ManagerStats />;
    }
    return null;
  };

  onAccountSelectorChange = (id, role) => {
    app.actions.getAppAccount(id);
    dashboards.actions.getDashboardCurrentStart(true);
    dashboards.actions.getDashboard(id, role);
  };

  onShowPrintDashboard = () => {
    this.setState({
      showPrintDashboard: true,
    });
  };

  onHidePrintDashboard = () => {
    this.setState({
      showPrintDashboard: false,
    });
  };

  render() {
    let account = this.props.account.toJS();
    let selector = this.props.selector.toJS();
    let current = this.props.current.toJS();
    let weeks = this.props.weeks.toJS();
    let quarter = this.props.quarter.toJS();
    let aggregateWeeks = this.props.aggregateWeeks.toJS();
    let aggregateQuarter = this.props.aggregateQuarter.toJS();
    let officeDirectorQuarter = this.props.officeDirectorQuarter.toJS();
    return (
      <Page
        {...this.props}
        account={account}
        accounts={selector.accounts}
        onAccountSelectorChange={this.onAccountSelectorChange}
        showAccountsHeader={true}
      >
        <Header onShowPrintDashboard={this.onShowPrintDashboard} {...this.props} />
        <Content className="dashboard">
          {this.renderStats(
            account,
            current,
            weeks,
            quarter,
            aggregateWeeks,
            aggregateQuarter,
            officeDirectorQuarter,
            selector.accounts
          )}
        </Content>
        <PrintSalesDirectorStats
          show={this.state.showPrintDashboard}
          onHide={this.onHidePrintDashboard}
          account={account}
          accounts={selector.accounts}
          current={current}
          quarter={aggregateQuarter}
          {...this.props}
        />
      </Page>
    );
  }
}
