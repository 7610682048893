import { Store, toImmutable } from "nuclear-js";

import forms from "../forms";
import i18n from "../i18n";
import actionTypes from "./actionTypes";
import * as schemas from "./schemas";

let getIntlMessage = i18n.messages.getIntlMessage;

let addActivityFormDefaults = {
  type: schemas.AddActivitySchema,
  options: schemas.AddActivitySchemaOptions,
  feedback: {
    type: "info",
    messages: [],
  },
  started: false,
};

let setActivityFormDefaults = {
  type: schemas.SetActivitySchema,
  options: schemas.SetActivitySchemaOptions,
  feedback: {
    type: "info",
    messages: [],
  },
  started: false,
};

let cancelActivityFormDefaults = {
  type: schemas.CancelActivitySchema,
  options: schemas.CancelActivitySchemaOptions,
  feedback: {
    type: "info",
    messages: [],
  },
  started: false,
};

let sellActivityFormDefaults = {
  type: schemas.SellActivitySchema,
  options: schemas.SellActivitySchemaOptions,
  feedback: {
    type: "info",
    messages: [],
  },
  started: false,
};

let pauseActivityFormDefaults = {
  type: schemas.PauseActivitySchema,
  options: schemas.PauseActivitySchemaOptions,
  feedback: {
    type: "info",
    messages: [],
  },
  started: false,
};

let resumeActivityFormDefaults = {
  type: schemas.ResumeActivitySchema,
  options: schemas.ResumeActivitySchemaOptions,
  feedback: {
    type: "info",
    messages: [],
  },
  started: false,
};

let scheduleActivityFormDefaults = {
  type: schemas.ScheduleActivitySchema,
  options: schemas.ScheduleActivitySchemaOptions,
  feedback: {
    type: "info",
    messages: [],
  },
  started: false,
};

let cloneActivityFormDefaults = {
  type: schemas.CloneActivitySchema,
  options: schemas.CloneActivitySchemaOptions,
  feedback: {
    type: "info",
    messages: [],
  },
  started: false,
};

let addActivityBatchDefaults = {
  feedback: {
    type: "info",
    messages: [],
  },
  started: false,
};

let addActivityNoteFormDefaults = {
  type: schemas.AddActivityNoteSchema,
  options: schemas.AddActivityNoteSchemaOptions,
  feedback: {
    type: "info",
    messages: [],
  },
  started: false,
};

let initialState = toImmutable({
  board: {},
  activity: {},
  addActivityForm: addActivityFormDefaults,
  setActivityForm: setActivityFormDefaults,
  cancelActivityForm: cancelActivityFormDefaults,
  sellActivityForm: sellActivityFormDefaults,
  pauseActivityForm: pauseActivityFormDefaults,
  resumeActivityForm: resumeActivityFormDefaults,
  scheduleActivityForm: scheduleActivityFormDefaults,
  cloneActivityForm: cloneActivityFormDefaults,
  addActivityBatch: addActivityBatchDefaults,
  addActivityNoteForm: addActivityNoteFormDefaults,
  setBoardReload: false,
  setActivitiesReload: false,
  setActivityStepRevert: { started: false },
});

export default new Store({
  getInitialState() {
    return initialState;
  },

  initialize() {
    this.on(actionTypes.SET_ACTIVITIES_RESET, onSetActivitiesReset);
    this.on(actionTypes.GET_ACTIVITIES_BOARD_SUCCESS, onGetActivitiesBoardSuccess);
    this.on(actionTypes.GET_ACTIVITIES_BOARD_FAIL, onGetActivitiesBoardFail);
    this.on(actionTypes.SET_ACTIVITIES_BOARD_STEP_SUCCESS, onSetActivitiesBoardStepSuccess);
    this.on(actionTypes.SET_ACTIVITIES_BOARD_STEP_FAIL, onSetActivitiesBoardStepFail);
    this.on(actionTypes.SET_ACTIVITIES_BOARD_RELOAD, onSetActivitiesBoardReload);
    this.on(actionTypes.GET_ACTIVITY_SUCCESS, onGetActivitySuccess);
    this.on(actionTypes.GET_ACTIVITY_FAIL, onGetActivityFail);
    this.on(actionTypes.ADD_ACTIVITY_FORM, onAddActivityForm);
    this.on(actionTypes.ADD_ACTIVITY_START, onAddActivityStart);
    this.on(actionTypes.ADD_ACTIVITY_SUCCESS, onAddActivitySuccess);
    this.on(actionTypes.ADD_ACTIVITY_FAIL, onAddActivityFail);
    this.on(actionTypes.SET_ACTIVITY_FORM, onSetActivityForm);
    this.on(actionTypes.SET_ACTIVITY_START, onSetActivityStart);
    this.on(actionTypes.SET_ACTIVITY_SUCCESS, onSetActivitySuccess);
    this.on(actionTypes.SET_ACTIVITY_FAIL, onSetActivityFail);
    this.on(actionTypes.SET_ACTIVITY_STATE_START, onSetActivityStateStart);
    this.on(actionTypes.SET_ACTIVITY_STATE_SUCCESS, onSetActivityStateSuccess);
    this.on(actionTypes.SET_ACTIVITY_STATE_FAIL, onSetActivityStateFail);
    this.on(actionTypes.SET_ACTIVITY_STEP_START, onSetActivityStepStart);
    this.on(actionTypes.SET_ACTIVITY_STEP_SUCCESS, onSetActivityStepSuccess);
    this.on(actionTypes.SET_ACTIVITY_STEP_FAIL, onSetActivityStepFail);
    this.on(actionTypes.SET_ACTIVITY_STEP_REVERT_START, onSetActivityStepRevertStart);
    this.on(actionTypes.SET_ACTIVITY_CANCEL_FORM, onSetActivityCancelForm);
    this.on(actionTypes.SET_ACTIVITY_CANCEL_START, onSetActivityCancelStart);
    this.on(actionTypes.SET_ACTIVITY_CANCEL_SUCCESS, onSetActivityCancelSuccess);
    this.on(actionTypes.SET_ACTIVITY_CANCEL_FAIL, onSetActivityCancelFail);
    this.on(actionTypes.SET_ACTIVITY_SELL_FORM, onSetActivitySellForm);
    this.on(actionTypes.SET_ACTIVITY_SELL_START, onSetActivitySellStart);
    this.on(actionTypes.SET_ACTIVITY_SELL_SUCCESS, onSetActivitySellSuccess);
    this.on(actionTypes.SET_ACTIVITY_SELL_FAIL, onSetActivitySellFail);
    this.on(actionTypes.SET_ACTIVITY_PAUSE_FORM, onSetActivityPauseForm);
    this.on(actionTypes.SET_ACTIVITY_PAUSE_START, onSetActivityPauseStart);
    this.on(actionTypes.SET_ACTIVITY_PAUSE_SUCCESS, onSetActivityPauseSuccess);
    this.on(actionTypes.SET_ACTIVITY_PAUSE_FAIL, onSetActivityPauseFail);
    this.on(actionTypes.SET_ACTIVITY_RESUME_FORM, onSetActivityResumeForm);
    this.on(actionTypes.SET_ACTIVITY_RESUME_START, onSetActivityResumeStart);
    this.on(actionTypes.SET_ACTIVITY_RESUME_SUCCESS, onSetActivityResumeSuccess);
    this.on(actionTypes.SET_ACTIVITY_RESUME_FAIL, onSetActivityResumeFail);
    this.on(actionTypes.SET_ACTIVITY_SCHEDULE_FORM, onSetActivityScheduleForm);
    this.on(actionTypes.SET_ACTIVITY_SCHEDULE_START, onSetActivityScheduleStart);
    this.on(actionTypes.SET_ACTIVITY_SCHEDULE_SUCCESS, onSetActivityScheduleSuccess);
    this.on(actionTypes.SET_ACTIVITY_SCHEDULE_FAIL, onSetActivityScheduleFail);
    this.on(actionTypes.SET_ACTIVITY_CLONE_FORM, onSetActivityCloneForm);
    this.on(actionTypes.SET_ACTIVITY_CLONE_START, onSetActivityCloneStart);
    this.on(actionTypes.SET_ACTIVITY_CLONE_SUCCESS, onSetActivityCloneSuccess);
    this.on(actionTypes.SET_ACTIVITY_CLONE_FAIL, onSetActivityCloneFail);
    this.on(actionTypes.ADD_ACTIVITY_BATCH_START, onAddActivityBatchStart);
    this.on(actionTypes.ADD_ACTIVITY_BATCH_SUCCESS, onAddActivityBatchSuccess);
    this.on(actionTypes.ADD_ACTIVITY_BATCH_FAIL, onAddActivityBatchFail);
    this.on(actionTypes.ADD_ACTIVITY_NOTE_FORM, onAddActivityNoteForm);
    this.on(actionTypes.ADD_ACTIVITY_NOTE_START, onAddActivityNoteStart);
    this.on(actionTypes.ADD_ACTIVITY_NOTE_SUCCESS, onAddActivityNoteSuccess);
    this.on(actionTypes.ADD_ACTIVITY_NOTE_FAIL, onAddActivityNoteFail);
    this.on(actionTypes.SET_ACTIVITIES_RELOAD, onSetActivitiesReload);
    this.on(actionTypes.REM_ACTIVITY, onRemActivity);
  },
});

function onSetActivitiesReset(state) {
  return initialState;
}

function onGetActivitiesBoardSuccess(state, { data }) {
  return state.set("board", toImmutable(data.board));
}

function onGetActivitiesBoardFail(state, { data }) {
  return state.set("board", toImmutable({}));
}

function onSetActivitiesBoardStepSuccess(state, { data }) {
  return state.set("board", toImmutable(data.board));
}

function onSetActivitiesBoardStepFail(state, { data }) {
  return state;
}

function onSetActivitiesBoardReload(state, { data }) {
  return state.set("setBoardReload", toImmutable(data));
}

function onGetActivitySuccess(state, { data }) {
  return state.set("activity", toImmutable(data.activity));
}

function onGetActivityFail(state, { data }) {
  return state.set("activity", toImmutable({}));
}

function onAddActivityForm(state) {
  return state.set("addActivityForm", toImmutable(addActivityFormDefaults));
}

function onAddActivityStart(state, { data }) {
  return state.setIn(["addActivityForm", "started"], data);
}

function onAddActivitySuccess(state, { data }) {
  let feedback = {
    type: "success",
    messages: [getIntlMessage("Add Successful")],
  };
  let options = addActivityFormDefaults.options;
  return state
    .setIn(["addActivityForm", "feedback"], toImmutable(feedback))
    .setIn(["addActivityForm", "options"], toImmutable(options));
}

function onAddActivityFail(state, { data }) {
  let feedback = forms.utils.setFormFeedback(data);
  let options = forms.utils.setFormOptions(state.getIn(["addActivityForm", "options"]).toJS(), data);
  return state
    .setIn(["addActivityForm", "feedback"], toImmutable(feedback))
    .setIn(["addActivityForm", "options"], toImmutable(options));
}

function onSetActivityForm(state) {
  return state;
}

function onSetActivityStart(state, { data }) {
  return state.setIn(["setActivityForm", "started"], data);
}

function onSetActivitySuccess(state, { data }) {
  return state.set("activity", toImmutable(data.activity)).set("setActivityForm", toImmutable(setActivityFormDefaults));
}

function onSetActivityFail(state, { data }) {
  let feedback = forms.utils.setFormFeedback(data);
  let options = forms.utils.setFormOptions(state.getIn(["setActivityForm", "options"]).toJS(), data);
  return state
    .setIn(["setActivityForm", "feedback"], toImmutable(feedback))
    .setIn(["setActivityForm", "options"], toImmutable(options));
}

function onSetActivityStateStart(state, { data }) {
  return state.setIn(["setActivityStateForm", "started"], data);
}

function onSetActivityStateSuccess(state, { data }) {
  return state.set("activity", toImmutable(data.activity));
  // .set('setActivityStateForm', toImmutable(setActivityFormDefaults))
}

function onSetActivityStateFail(state, { data }) {
  // let feedback = forms.utils.setFormFeedback(data)
  // let options = forms.utils.setFormOptions(
  //   state.getIn(['setActivityStateForm', 'options']).toJS(),
  //   data
  // );
  return state;
  // .setIn(['setActivityStateForm', 'feedback'], toImmutable(feedback))
  // .setIn(['setActivityStateForm', 'options'], toImmutable(options))
}

function onSetActivityStepStart(state, { data }) {
  return state.setIn(["setActivityStepForm", "started"], data);
}

function onSetActivityStepSuccess(state, { data }) {
  return state.set("activity", toImmutable(data.activity));
  // .set('setActivityStepForm', toImmutable(setActivityStepFormDefaults));
}

function onSetActivityStepFail(state, { data }) {
  // let feedback = forms.utils.setFormFeedback(data)
  // let options = forms.utils.setFormOptions(
  //   state.getIn(['setActivityStepForm', 'options']).toJS(),
  //   data
  // );
  return state;
  // .setIn(['setActivityStepForm', 'feedback'], toImmutable(feedback))
  // .setIn(['setActivityStepForm', 'options'], toImmutable(options))
}

function onSetActivityStepRevertStart(state, { data }) {
  return state.setIn(["setActivityStepRevert", "started"], data);
}

function onSetActivityCancelForm(state) {
  return state.set("cancelActivityForm", toImmutable(cancelActivityFormDefaults));
}

function onSetActivityCancelStart(state, { data }) {
  return state.setIn(["cancelActivityForm", "started"], data);
}

function onSetActivityCancelSuccess(state, { data }) {
  let feedback = {
    type: "success",
    messages: [getIntlMessage("Add Successful")],
  };
  let options = cancelActivityFormDefaults.options;
  return state
    .setIn(["cancelActivityForm", "feedback"], toImmutable(feedback))
    .setIn(["cancelActivityForm", "options"], toImmutable(options));
}

function onSetActivityCancelFail(state, { data }) {
  let feedback = forms.utils.setFormFeedback(data);
  let options = forms.utils.setFormOptions(state.getIn(["cancelActivityForm", "options"]).toJS(), data);
  return state
    .setIn(["cancelActivityForm", "feedback"], toImmutable(feedback))
    .setIn(["cancelActivityForm", "options"], toImmutable(options));
}

function onSetActivitySellForm(state) {
  return state.set("sellActivityForm", toImmutable(sellActivityFormDefaults));
}

function onSetActivitySellStart(state, { data }) {
  return state.setIn(["sellActivityForm", "started"], data);
}

function onSetActivitySellSuccess(state, { data }) {
  let feedback = {
    type: "success",
    messages: [getIntlMessage("Add Successful")],
  };
  let options = sellActivityFormDefaults.options;
  return state
    .setIn(["sellActivityForm", "feedback"], toImmutable(feedback))
    .setIn(["sellActivityForm", "options"], toImmutable(options));
}

function onSetActivitySellFail(state, { data }) {
  let feedback = forms.utils.setFormFeedback(data);
  let options = forms.utils.setFormOptions(state.getIn(["sellActivityForm", "options"]).toJS(), data);
  return state
    .setIn(["sellActivityForm", "feedback"], toImmutable(feedback))
    .setIn(["sellActivityForm", "options"], toImmutable(options));
}

function onSetActivityPauseForm(state) {
  return state.set("pauseActivityForm", toImmutable(pauseActivityFormDefaults));
}

function onSetActivityPauseStart(state, { data }) {
  return state.setIn(["pauseActivityForm", "started"], data);
}

function onSetActivityPauseSuccess(state, { data }) {
  let feedback = {
    type: "success",
    messages: [getIntlMessage("Add Successful")],
  };
  let options = pauseActivityFormDefaults.options;
  return state
    .setIn(["pauseActivityForm", "feedback"], toImmutable(feedback))
    .setIn(["pauseActivityForm", "options"], toImmutable(options));
}

function onSetActivityPauseFail(state, { data }) {
  let feedback = forms.utils.setFormFeedback(data);
  let options = forms.utils.setFormOptions(state.getIn(["pauseActivityForm", "options"]).toJS(), data);
  return state
    .setIn(["pauseActivityForm", "feedback"], toImmutable(feedback))
    .setIn(["pauseActivityForm", "options"], toImmutable(options));
}

function onSetActivityResumeForm(state) {
  return state.set("resumeActivityForm", toImmutable(resumeActivityFormDefaults));
}

function onSetActivityResumeStart(state, { data }) {
  return state.setIn(["resumeActivityForm", "started"], data);
}

function onSetActivityResumeSuccess(state, { data }) {
  let feedback = {
    type: "success",
    messages: [getIntlMessage("Resume Successful")],
  };
  let options = resumeActivityFormDefaults.options;
  return state
    .setIn(["resumeActivityForm", "feedback"], toImmutable(feedback))
    .setIn(["resumeActivityForm", "options"], toImmutable(options));
}

function onSetActivityResumeFail(state, { data }) {
  let feedback = forms.utils.setFormFeedback(data);
  let options = forms.utils.setFormOptions(state.getIn(["resumeActivityForm", "options"]).toJS(), data);
  return state
    .setIn(["resumeActivityForm", "feedback"], toImmutable(feedback))
    .setIn(["resumeActivityForm", "options"], toImmutable(options));
}

function onSetActivityScheduleForm(state) {
  return state.set("scheduleActivityForm", toImmutable(scheduleActivityFormDefaults));
}

function onSetActivityScheduleStart(state, { data }) {
  return state.setIn(["scheduleActivityForm", "started"], data);
}

function onSetActivityScheduleSuccess(state, { data }) {
  let feedback = {
    type: "success",
    messages: [getIntlMessage("Scheduling Successful")],
  };
  let options = scheduleActivityFormDefaults.options;
  return state
    .setIn(["scheduleActivityForm", "feedback"], toImmutable(feedback))
    .setIn(["scheduleActivityForm", "options"], toImmutable(options));
}

function onSetActivityScheduleFail(state, { data }) {
  let feedback = forms.utils.setFormFeedback(data);
  let options = forms.utils.setFormOptions(state.getIn(["scheduleActivityForm", "options"]).toJS(), data);
  return state
    .setIn(["scheduleActivityForm", "feedback"], toImmutable(feedback))
    .setIn(["scheduleActivityForm", "options"], toImmutable(options));
}

function onSetActivityCloneForm(state) {
  return state.set("cloneActivityForm", toImmutable(cloneActivityFormDefaults));
}

function onSetActivityCloneStart(state, { data }) {
  return state.setIn(["cloneActivityForm", "started"], data);
}

function onSetActivityCloneSuccess(state, { data }) {
  let feedback = {
    type: "success",
    messages: [getIntlMessage("Clone Successful")],
  };
  let options = cloneActivityFormDefaults.options;
  return state
    .setIn(["cloneActivityForm", "feedback"], toImmutable(feedback))
    .setIn(["cloneActivityForm", "options"], toImmutable(options));
}

function onSetActivityCloneFail(state, { data }) {
  let feedback = forms.utils.setFormFeedback(data);
  let options = forms.utils.setFormOptions(state.getIn(["cloneActivityForm", "options"]).toJS(), data);
  return state
    .setIn(["cloneActivityForm", "feedback"], toImmutable(feedback))
    .setIn(["cloneActivityForm", "options"], toImmutable(options));
}

function onAddActivityBatchStart(state, { data }) {
  return state.setIn(["addActivityBatch", "started"], data);
}

function onAddActivityBatchSuccess(state, { data }) {
  let feedback = {
    type: "success",
    messages: [getIntlMessage("Add Successful")],
  };
  return state.setIn(["addActivityBatch", "feedback"], toImmutable(feedback));
}

function onAddActivityBatchFail(state, { data }) {
  let feedback = {
    type: "error",
    messages: [getIntlMessage("An error occured")],
  };
  return state.setIn(["addActivityBatch", "feedback"], toImmutable(feedback));
}

function onAddActivityNoteForm(state) {
  return state.set("addActivityNoteForm", toImmutable(addActivityNoteFormDefaults));
}

function onAddActivityNoteStart(state, { data }) {
  return state.setIn(["addActivityNoteForm", "started"], data);
}

function onAddActivityNoteSuccess(state, { data }) {
  let feedback = {
    type: "success",
    messages: [getIntlMessage("Add Successful")],
  };
  let options = addActivityFormDefaults.options;
  return state
    .setIn(["addActivityNoteForm", "feedback"], toImmutable(feedback))
    .setIn(["addActivityNoteForm", "options"], toImmutable(options));
}

function onAddActivityNoteFail(state, { data }) {
  let feedback = forms.utils.setFormFeedback(data);
  let options = forms.utils.setFormOptions(state.getIn(["addActivityNoteForm", "options"]).toJS(), data);
  return state
    .setIn(["addActivityNoteForm", "feedback"], toImmutable(feedback))
    .setIn(["addActivityNoteForm", "options"], toImmutable(options));
}

function onSetActivitiesReload(state, { data }) {
  return state.set("setActivitiesReload", toImmutable(data));
}

function onRemActivity(state) {
  return state.set("activity", toImmutable({}));
}
