export const prospectsGetter = ["prospects"];
export const prospectGetter = ["prospects", "prospect"];
export const addProspectFormGetter = ["prospects", "addProspectForm"];
export const setProspectFormGetter = ["prospects", "setProspectForm"];
export const setProspectConsentFormGetter = ["prospects", "setProspectConsentForm"];
export const setProspectsReloadGetter = ["prospects", "setProspectsReload"];
export const setProspectStatusGetter = ["prospects", "setProspectStatus"];
export const setProspectStatusBatchGetter = ["prospects", "setProspectStatusBatch"];
export const addProspectNoteFormGetter = ["prospects", "addProspectNoteForm"];
export const addProspectActivityFormGetter = ["prospects", "addProspectActivityForm"];
export const callListGetter = ["prospects", "callList"];
