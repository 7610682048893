import React from "react";
import { Label } from "react-bootstrap";
import IntlRelativeFormat from "intl-relativeformat";
import Intl from "intl";
import moment from "moment";

export function renderStatus(status) {
  if (status) {
    return <Label bsStyle={status.label}>{status.name}</Label>;
  }
  return null;
}

export function renderLabel(data) {
  const labels = {
    active: "success",
    pending: "warning",
    inactive: "danger",
  };
  var style = labels[data] || "default";
  return <Label bsStyle={style}>{data}</Label>;
}

export function renderRelative(data) {
  let rf = new IntlRelativeFormat("en");
  return rf.format(new Date(data));
}

export function renderDatetime(data) {
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    // hour: 'numeric',
    // minute: 'numeric',
    // hour12: false
  };
  let df = new Intl.DateTimeFormat("en", options);
  return df.format(new Date(data));
}

export function renderMoment(date, locale, format) {
  let _date = moment(date);
  let _locale = locale || "fr";
  let _format = format || "L";
  return _date.locale(_locale).format(_format);
}

export function formatPhone(phone) {
  phone = phone.replace(/[^0-9]/g, "");
  phone = phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  return phone;
}

// from MDN
// https://developer.mozilla.org/en-US/docs/Printing
export function closePrint() {
  document.body.removeChild(this.__container__);
}

export function setPrintPage() {
  this.contentWindow.__container__ = this;
  this.contentWindow.onbeforeunload = exports.closePrint;
  this.contentWindow.onafterprint = exports.closePrint;
  this.contentWindow.focus(); // Required for IE
  this.contentWindow.print();
}

export function printPage(sURL) {
  let oHiddFrame = document.createElement("iframe");
  oHiddFrame.onload = exports.setPrintPage;
  oHiddFrame.style.visibility = "hidden";
  oHiddFrame.style.position = "fixed";
  oHiddFrame.style.right = "0";
  oHiddFrame.style.bottom = "0";
  oHiddFrame.src = sURL;
  document.body.appendChild(oHiddFrame);
}

export function printContent(content, head) {
  let oHiddFrame = document.createElement("iframe");
  oHiddFrame.style.visibility = "hidden";
  oHiddFrame.style.position = "fixed";
  oHiddFrame.style.right = "0";
  oHiddFrame.style.bottom = "0";
  document.body.appendChild(oHiddFrame);
  let frameDoc = oHiddFrame.contentWindow
    ? oHiddFrame.contentWindow
    : oHiddFrame.contentDocument.document
    ? oHiddFrame.contentDocument.document
    : oHiddFrame.contentDocument;
  frameDoc.document.open();
  frameDoc.document.write("<html><head>");
  frameDoc.document.write(head);
  frameDoc.document.write("</head><body>");
  frameDoc.document.write(content);
  frameDoc.document.write("</body></html>");
  frameDoc.document.close();
  setTimeout(function () {
    frameDoc.focus();
    frameDoc.print();
    document.body.removeChild(oHiddFrame);
  }, 500);
}
