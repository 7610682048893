import keyMirror from "keymirror";

export default keyMirror({
  SET_OPTION_RESET: null,
  GET_OPTION_SUCCESS: null,
  GET_OPTION_FAIL: null,
  ADD_OPTION_FORM: null,
  ADD_OPTION_START: null,
  ADD_OPTION_SUCCESS: null,
  ADD_OPTION_FAIL: null,
  SET_OPTION_FORM: null,
  SET_OPTION_START: null,
  SET_OPTION_SUCCESS: null,
  SET_OPTION_FAIL: null,
  DEL_OPTION_SUCCESS: null,
  DEL_OPTION_FAIL: null,
  REM_OPTION: null,
  SET_OPTIONS_RELOAD: null,
});
