import React from "react";
import { ButtonGroup, Button } from "react-bootstrap";

import i18n from "../../modules/i18n";
import SubHeader from "../base/SubHeader";
import AddOffice from "./AddOffice";

let getIntlMessage = i18n.messages.getIntlMessage;

var Header = React.createClass({
  getInitialState() {
    return {
      showAddOffice: false,
    };
  },

  onShowAddOffice() {
    this.setState({
      showAddOffice: true,
    });
  },

  onHideAddOffice() {
    this.setState({
      showAddOffice: false,
    });
  },

  render() {
    return (
      <SubHeader title={getIntlMessage("Offices")}>
        <ButtonGroup>
          <Button name="add-option" bsSize="small" onClick={this.onShowAddOffice}>
            {getIntlMessage("Add")}
          </Button>
        </ButtonGroup>
        <AddOffice show={this.state.showAddOffice} onHide={this.onHideAddOffice} {...this.props} />
      </SubHeader>
    );
  },
});

export default Header;
