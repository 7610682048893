import reactor from "../../reactor";
import * as actions from "./actions";
import * as getters from "./getters";
import * as schemas from "./schemas";
import store from "./store";

reactor.registerStores({
  optionLists: store,
});

export default {
  actions,
  getters,
  schemas,
};
