import React, { Component, PropTypes } from "react";
import { Panel, Button, ButtonGroup, Glyphicon } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import _ from "lodash";

import moment from "moment";

import i18n from "../../modules/i18n";
import staffing from "../../modules/staffing";
import SetStaffingCandidate from "./SetStaffingCandidate";
import DeleteStaffingCandidate from "./DeleteStaffingCandidate";

let getIntlMessage = i18n.messages.getIntlMessage;

export default class StaffingCandidates extends Component {
  static propTypes = {
    role: PropTypes.string,
    candidates: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    role: null,
    candidates: [],
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      showDeleteStaffingCandidate: false,
      showSetStaffingCandidate: false,
      candidateId: null,
    };
  }

  onShowSetStaffingCandidate = (id) => {
    staffing.actions.getStaffingCandidate(id);
    this.setState({
      showSetStaffingCandidate: true,
      candidateId: id,
    });
  };

  onHideSetStaffingCandidate = () => {
    staffing.actions.setStaffingCandidateReset();
    this.setState({
      showSetStaffingCandidate: false,
    });
  };

  onShowDeleteStaffingCandidate = (id) => {
    this.setState({
      candidateId: id,
      showDeleteStaffingCandidate: true,
    });
  };

  onHideDeleteStaffingCandidate = () => {
    this.setState({
      showDeleteStaffingCandidate: false,
    });
  };

  formatOffice = (cell, row) => {
    return row.office_code + " - " + row.office_name;
  };

  formatCandidate = (cell, row) => {
    return row.firstname + " " + row.lastname;
  };

  formatAppointment = (cell, row) => {
    return moment(cell).format("DD/MM/YYYY");
  };

  formatActions = (cell, row) => {
    return (
      <ButtonGroup>
        <Button
          name="set-advisor"
          bsSize="xsmall"
          bsStyle="default"
          onClick={() => this.onShowSetStaffingCandidate(row.id)}
        >
          <Glyphicon glyph="pencil" />
        </Button>
        <Button
          name="delete-advisor"
          bsSize="xsmall"
          bsStyle="default"
          onClick={() => this.onShowDeleteStaffingCandidate(row.id)}
        >
          <Glyphicon glyph="remove" />
        </Button>
      </ButtonGroup>
    );
  };

  renderID = () => {
    return (
      <TableHeaderColumn isKey={true} dataSort={true} dataField="id" hidden>
        ID
      </TableHeaderColumn>
    );
  };

  renderOffice = () => {
    return (
      <TableHeaderColumn dataSort={true} dataField="office_code" dataFormat={this.formatOffice}>
        {getIntlMessage("Office")}
      </TableHeaderColumn>
    );
  };

  renderSalesDirector = () => {
    return (
      <TableHeaderColumn dataSort={true} dataField="sales_director_name">
        {getIntlMessage("Director")}
      </TableHeaderColumn>
    );
  };

  renderCandidate = () => {
    return (
      <TableHeaderColumn dataSort={true} dataField="firstname" dataFormat={this.formatCandidate}>
        {getIntlMessage("Candidate")}
      </TableHeaderColumn>
    );
  };

  renderAppointment = () => {
    return (
      <TableHeaderColumn dataSort={true} dataField="appointment" dataFormat={this.formatAppointment}>
        {getIntlMessage("Appointment")}
      </TableHeaderColumn>
    );
  };

  renderSource = () => {
    return (
      <TableHeaderColumn dataSort={true} dataField="source">
        {getIntlMessage("Source")}
      </TableHeaderColumn>
    );
  };

  renderSchooling = () => {
    return (
      <TableHeaderColumn dataSort={true} dataField="schooling">
        {getIntlMessage("Schooling")}
      </TableHeaderColumn>
    );
  };

  renderSuccessfinder = () => {
    return (
      <TableHeaderColumn dataSort={true} dataField="successfinder">
        {getIntlMessage("Success Finder")}
      </TableHeaderColumn>
    );
  };

  renderResult = () => {
    return (
      <TableHeaderColumn dataSort={true} dataField="result">
        {getIntlMessage("Result")}
      </TableHeaderColumn>
    );
  };

  renderActions = () => {
    return (
      <TableHeaderColumn dataField="advisor_id" dataFormat={this.formatActions} width="80">
        Actions
      </TableHeaderColumn>
    );
  };

  renderManagerTable = (candidates) => {
    return (
      <BootstrapTable
        ref="candidates-table"
        data={candidates}
        striped={true}
        hover={true}
        condensed={true}
        height="600"
      >
        {this.renderID()}
        {this.renderOffice()}
        {this.renderSalesDirector()}
        {this.renderCandidate()}
        {this.renderAppointment()}
        {this.renderSource()}
        {this.renderSchooling()}
        {this.renderSuccessfinder()}
        {this.renderResult()}
      </BootstrapTable>
    );
  };

  renderOfficeDirectorTable = (candidates) => {
    return (
      <BootstrapTable
        ref="candidates-table"
        data={candidates}
        striped={true}
        hover={true}
        condensed={true}
        height="600"
      >
        {this.renderID()}
        {this.renderSalesDirector()}
        {this.renderCandidate()}
        {this.renderAppointment()}
        {this.renderSource()}
        {this.renderSchooling()}
        {this.renderSuccessfinder()}
        {this.renderResult()}
      </BootstrapTable>
    );
  };

  renderSalesDirectorTable = (candidates) => {
    return (
      <BootstrapTable
        ref="candidates-table"
        data={candidates}
        striped={true}
        hover={true}
        condensed={true}
        height="600"
      >
        {this.renderID()}
        {this.renderCandidate()}
        {this.renderAppointment()}
        {this.renderSource()}
        {this.renderSchooling()}
        {this.renderSuccessfinder()}
        {this.renderResult()}
        {this.renderActions()}
      </BootstrapTable>
    );
  };

  render() {
    let role = this.props.role;
    let candidates = this.props.candidates;
    if (_.isEmpty(candidates)) {
      return (
        <Panel className="hpanel hyellow text-center">
          <h2 className="text-warning">{getIntlMessage("Sales directors did not provide any data")}</h2>
        </Panel>
      );
    }
    if (role === "sales-director") {
      return (
        <div>
          <SetStaffingCandidate
            show={this.state.showSetStaffingCandidate}
            onHide={this.onHideSetStaffingCandidate}
            {...this.props}
          />
          <DeleteStaffingCandidate
            show={this.state.showDeleteStaffingCandidate}
            onHide={this.onHideDeleteStaffingCandidate}
            candidateId={this.state.candidateId}
            {...this.props}
          />
          {this.renderSalesDirectorTable(candidates)}
        </div>
      );
    } else if (role === "office-director") {
      return this.renderOfficeDirectorTable(candidates);
    } else if (role === "supervisor" || role === "manager") {
      return this.renderManagerTable(candidates);
    }
    return null;
  }
}
