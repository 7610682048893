import React from "react";
import _ from "lodash";

import reactor from "../../reactor";
import activities from "../../modules/activities";
import forms from "../../modules/forms";
import i18n from "../../modules/i18n";
import FormModal from "../forms/FormModal";

let getIntlMessage = i18n.messages.getIntlMessage;

var defaultValue = {};

var CancelActivity = React.createClass({
  mixins: [reactor.ReactMixin],

  propTypes: {
    activityId: React.PropTypes.number,
  },

  getDefaultProps() {
    return {
      activityId: null,
    };
  },

  getInitialState() {
    return {
      value: defaultValue,
    };
  },

  getDataBindings() {
    return {
      cancelActivityForm: activities.getters.cancelActivityFormGetter,
    };
  },

  componentWillReceiveProps(props) {
    if (!props.show) {
      this.setState({
        value: defaultValue,
      });
    }
  },

  componentDidUpdate(prevProps, prevState) {
    if (this.state.cancelActivityForm !== prevState.cancelActivityForm) {
      var form = this.state.cancelActivityForm.toJS();
      var prevForm = prevState.cancelActivityForm.toJS();
      if (form.feedback.type !== prevForm.feedback.type && form.feedback.type === "success") {
        this.props.onHide();
      }
    }
  },

  onFormSubmit(event, value) {
    if (value) {
      this.setState({
        value: value,
      });
      var val = _.extend(_.cloneDeep(value), { refid: "cancelled" });
      activities.actions.setActivityCancelStart(true);
      activities.actions.setActivityCancel(this.props.activityId, val);
    }
  },

  onFormChange(value) {
    this.setState({ value });
  },

  render() {
    var form = this.state.cancelActivityForm.toJS();
    return (
      <FormModal
        form={form}
        formType="set"
        value={this.state.value}
        onFormChange={this.onFormChange}
        onFormSubmit={this.onFormSubmit}
        bsStyle="danger"
        title={getIntlMessage("Cancel Activity")}
        actionButtonTitle={getIntlMessage("Confirm")}
        showFeedback={false}
        {...this.props}
      />
    );
  },
});

export default CancelActivity;
