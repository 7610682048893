import keyMirror from "keymirror";

export default keyMirror({
  SET_APP_RESET: null,
  GET_APP_INFO_SUCCESS: null,
  GET_APP_INFO_FAIL: null,
  GET_APP_ACCOUNT_SELECTOR_SUCCESS: null,
  GET_APP_ACCOUNT_SELECTOR_FAIL: null,
  GET_APP_ACCOUNT_SELECTOR_START: null,
  GET_APP_ACCOUNT_SUCCESS: null,
  GET_APP_ACCOUNT_FAIL: null,
  SIDEBAR_OPEN: null,
  SIDEBAR_DOCKED: null,
  SET_APP_ERRORS: null,
});
