import keyMirror from "keymirror";

export default keyMirror({
  SET_DNCL_RESET: null,
  GET_DNCL_SUCCESS: null,
  GET_DNCL_FAIL: null,
  VALIDATE_DNCL_NUMBER_SUCCESS: null,
  VALIDATE_DNCL_NUMBER_FAIL: null,
  VALIDATE_DNCL_NUMBERS_SUCCESS: null,
  VALIDATE_DNCL_NUMBERS_FAIL: null,
  VALIDATE_DNCL_NUMBERS_RESET: null,
});
