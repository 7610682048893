import React from "react";
import { State } from "react-router";
import { Row, Col, Panel, ButtonGroup, Button } from "react-bootstrap";
import t from "tcomb-form";
import _ from "lodash";

import reactor from "../../reactor";
import forms from "../../modules/forms";
import i18n from "../../modules/i18n";
import notifier from "../../modules/notifier";
import profile from "../../modules/profile";
import Page from "../base/Page";
import Content from "../base/Content";
import DefaultLoader from "../Loaders";
import Header from "./Header";
import SetProfile from "./SetProfile";
import SetPassword from "./SetPassword";
import SetAvatar from "./SetAvatar";

let getIntlMessage = i18n.messages.getIntlMessage;

var Profile = React.createClass({
  mixins: [reactor.ReactMixin, State],

  getInitialState() {
    return {
      showSetProfile: false,
      showSetAvatar: false,
      showSetPassword: false,
    };
  },

  getDataBindings() {
    return {
      profile: profile.getters.profileGetter,
    };
  },

  componentWillMount() {
    if (_.isEmpty(this.state.profile.toJS())) {
      profile.actions.getProfile();
    }
  },

  componentWillReceiveProps(props) {
    if (_.isEmpty(this.state.profile.toJS())) {
      profile.actions.getProfile();
    }
  },

  onShowSetProfile() {
    this.setState({
      showSetProfile: true,
    });
  },

  onHideSetProfile() {
    this.setState({
      showSetProfile: false,
    });
  },

  onShowSetAvatar() {
    this.setState({
      showSetAvatar: true,
    });
  },

  onHideSetAvatar() {
    this.setState({
      showSetAvatar: false,
    });
  },

  onShowSetPassword() {
    this.setState({
      showSetPassword: true,
    });
  },

  onHideSetPassword() {
    this.setState({
      showSetPassword: false,
    });
  },

  render() {
    var profile = this.state.profile.toJS();
    if (_.isEmpty(profile)) {
      return <DefaultLoader />;
    }
    return (
      <Page>
        <Header {...this.props} />
        <Content className="profile">
          <Row>
            <Col lg={4}>
              <Panel className="hpanel hblue">
                <img alt="logo" width="100" className="img-circle m-b m-t-md" src={profile.avatar_url} />
                <h3>{profile.fullname}</h3>
                <p>{profile.email}</p>
                <ButtonGroup>
                  <Button name="edit-profile" bsSize="xsmall" onClick={this.onShowSetProfile}>
                    {getIntlMessage("Edit Profile")}
                  </Button>
                  <Button name="edit-password" bsSize="xsmall" onClick={this.onShowSetPassword}>
                    {getIntlMessage("Edit Password")}
                  </Button>
                  <Button name="edit-avatar" bsSize="xsmall" onClick={this.onShowSetAvatar}>
                    {getIntlMessage("Edit Avatar")}
                  </Button>
                </ButtonGroup>
              </Panel>
            </Col>
          </Row>
          <SetProfile
            show={this.state.showSetProfile}
            onHide={this.onHideSetProfile}
            profile={profile}
            {...this.props}
          />
          <SetPassword
            show={this.state.showSetPassword}
            onHide={this.onHideSetPassword}
            profile={profile}
            {...this.props}
          />
          <SetAvatar show={this.state.showSetAvatar} onHide={this.onHideSetAvatar} profile={profile} {...this.props} />
        </Content>
      </Page>
    );
  },
});

export default Profile;
