import keyMirror from "keymirror";

export default keyMirror({
  SET_ACTIVITIES_RESET: null,
  GET_ACTIVITIES_BOARD_SUCCESS: null,
  GET_ACTIVITIES_BOARD_FAIL: null,
  SET_ACTIVITIES_BOARD_RELOAD: null,
  SET_ACTIVITIES_BOARD_STEP_SUCCESS: null,
  SET_ACTIVITIES_BOARD_STEP_FAIL: null,
  GET_ACTIVITY_SUCCESS: null,
  GET_ACTIVITY_FAIL: null,
  ADD_ACTIVITY_FORM: null,
  ADD_ACTIVITY_START: null,
  ADD_ACTIVITY_SUCCESS: null,
  ADD_ACTIVITY_FAIL: null,
  SET_ACTIVITY_FORM: null,
  SET_ACTIVITY_START: null,
  SET_ACTIVITY_SUCCESS: null,
  SET_ACTIVITY_FAIL: null,
  SET_ACTIVITY_STATE_START: null,
  SET_ACTIVITY_STATE_SUCCESS: null,
  SET_ACTIVITY_STATE_FAIL: null,
  SET_ACTIVITY_STEP_START: null,
  SET_ACTIVITY_STEP_SUCCESS: null,
  SET_ACTIVITY_STEP_FAIL: null,
  SET_ACTIVITY_STEP_REVERT_START: null,
  SET_ACTIVITY_STEP_REVERT_SUCCESS: null,
  SET_ACTIVITY_STEP_REVERT_FAIL: null,
  SET_ACTIVITY_CANCEL_FORM: null,
  SET_ACTIVITY_CANCEL_START: null,
  SET_ACTIVITY_CANCEL_SUCCESS: null,
  SET_ACTIVITY_CANCEL_FAIL: null,
  SET_ACTIVITY_SELL_FORM: null,
  SET_ACTIVITY_SELL_START: null,
  SET_ACTIVITY_SELL_SUCCESS: null,
  SET_ACTIVITY_SELL_FAIL: null,
  SET_ACTIVITY_PAUSE_FORM: null,
  SET_ACTIVITY_PAUSE_START: null,
  SET_ACTIVITY_PAUSE_SUCCESS: null,
  SET_ACTIVITY_PAUSE_FAIL: null,
  SET_ACTIVITY_RESUME_FORM: null,
  SET_ACTIVITY_RESUME_START: null,
  SET_ACTIVITY_RESUME_SUCCESS: null,
  SET_ACTIVITY_RESUME_FAIL: null,
  SET_ACTIVITY_SCHEDULE_FORM: null,
  SET_ACTIVITY_SCHEDULE_START: null,
  SET_ACTIVITY_SCHEDULE_SUCCESS: null,
  SET_ACTIVITY_SCHEDULE_FAIL: null,
  SET_ACTIVITY_CLONE_FORM: null,
  SET_ACTIVITY_CLONE_START: null,
  SET_ACTIVITY_CLONE_SUCCESS: null,
  SET_ACTIVITY_CLONE_FAIL: null,
  DEL_ACTIVITY_SUCCESS: null,
  DEL_ACTIVITY_FAIL: null,
  ADD_ACTIVITY_BATCH_START: null,
  ADD_ACTIVITY_BATCH_SUCCESS: null,
  ADD_ACTIVITY_BATCH_FAIL: null,
  ADD_ACTIVITY_NOTE_FORM: null,
  ADD_ACTIVITY_NOTE_START: null,
  ADD_ACTIVITY_NOTE_SUCCESS: null,
  ADD_ACTIVITY_NOTE_FAIL: null,
  SET_ACTIVITIES_RELOAD: null,
  REM_ACTIVITY: null,
});
