import React from "react";
import { Navigation, State } from "react-router";
import { Row, Col, Panel, Button, ButtonGroup } from "react-bootstrap";
import t from "tcomb-form";
import _ from "lodash";

import reactor from "../../reactor";
import i18n from "../../modules/i18n";
import accounts from "../../modules/accounts";
import Page from "../base/Page";
import Content from "../base/Content";
import DefaultLoader from "../Loaders";
import Header from "./Header";
import SetAccount from "./SetAccount";
import SetPassword from "./SetPassword";
import SetAvatar from "./SetAvatar";

let getIntlMessage = i18n.messages.getIntlMessage;

var Account = React.createClass({
  mixins: [reactor.ReactMixin, Navigation, State],

  getInitialState() {
    return {
      showSetAccount: false,
      showSetAvatar: false,
      showSetPassword: false,
    };
  },

  getDataBindings() {
    return {
      account: accounts.getters.accountGetter,
      setAccountsReload: accounts.getters.setAccountsReloadGetter,
    };
  },

  componentWillMount() {
    accounts.actions.getAccount(this.props.params.id);
  },

  componentWillReceiveProps(props) {
    if (_.isEmpty(this.state.account.toJS()) || this.state.setAccountsReload) {
      accounts.actions.getAccount(this.props.params.id);
    }
  },

  componentWillUnmount() {
    accounts.actions.remAccount();
  },

  onShowSetAccount() {
    this.setState({
      showSetAccount: true,
    });
  },

  onHideSetAccount() {
    this.setState({
      showSetAccount: false,
    });
  },

  onShowSetAvatar() {
    this.setState({
      showSetAvatar: true,
    });
  },

  onHideSetAvatar() {
    this.setState({
      showSetAvatar: false,
    });
  },

  onShowSetPassword() {
    this.setState({
      showSetPassword: true,
    });
  },

  onHideSetPassword() {
    this.setState({
      showSetPassword: false,
    });
  },

  render() {
    var account = this.state.account.toJS();
    if (_.isEmpty(account)) {
      return <DefaultLoader />;
    }
    return (
      <Page>
        <Header {...this.props} />
        <Content className="account">
          <Row>
            <Col lg={4}>
              <Panel className="hpanel hblue">
                <img alt="logo" width="100" className="img-circle m-b m-t-md" src={account.avatar_url} />
                <h3>{account.fullname}</h3>
                <p>{account.email}</p>
                <ButtonGroup>
                  <Button name="edit-account" bsSize="xsmall" onClick={this.onShowSetAccount}>
                    {getIntlMessage("Edit Account")}
                  </Button>
                  <Button name="edit-password" bsSize="xsmall" onClick={this.onShowSetPassword}>
                    {getIntlMessage("Edit Password")}
                  </Button>
                  <Button name="edit-avatar" bsSize="xsmall" onClick={this.onShowSetAvatar}>
                    {getIntlMessage("Edit Avatar")}
                  </Button>
                </ButtonGroup>
              </Panel>
            </Col>
          </Row>
          <SetAccount
            show={this.state.showSetAccount}
            onHide={this.onHideSetAccount}
            account={account}
            {...this.props}
          />
          <SetPassword
            show={this.state.showSetPassword}
            onHide={this.onHideSetPassword}
            account={account}
            {...this.props}
          />
          <SetAvatar show={this.state.showSetAvatar} onHide={this.onHideSetAvatar} account={account} {...this.props} />
        </Content>
      </Page>
    );
  },
});

export default Account;
