import keyMirror from "keymirror";

export default keyMirror({
  SET_AUTH_RESET: null,
  LOGIN_FORM: null,
  LOGIN_START: null,
  LOGIN_SUCCESS: null,
  LOGIN_FAIL: null,
  LOGOUT: null,
  TOKEN_SUCCESS: null,
  TOKEN_FAIL: null,
  PASSWORD_LOST_FORM: null,
  PASSWORD_LOST_START: null,
  PASSWORD_LOST_SUCCESS: null,
  PASSWORD_LOST_FAIL: null,
  PASSWORD_RESET_FORM: null,
  PASSWORD_RESET_START: null,
  PASSWORD_RESET_SUCCESS: null,
  PASSWORD_RESET_FAIL: null,
});
