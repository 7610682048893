import { Store, toImmutable } from "nuclear-js";

import forms from "../forms";
import i18n from "../i18n";
import actionTypes from "./actionTypes";
import * as schemas from "./schemas";

let getIntlMessage = i18n.messages.getIntlMessage;

let addOptionFormDefaults = {
  type: schemas.AddOptionSchema,
  options: schemas.AddOptionSchemaOptions,
  feedback: {
    type: "info",
    messages: [],
  },
  started: false,
};

let setOptionFormDefaults = {
  type: schemas.SetOptionSchema,
  options: schemas.SetOptionSchemaOptions,
  feedback: {
    type: "info",
    messages: [],
  },
  started: false,
};

let initialState = toImmutable({
  option: {},
  addOptionForm: addOptionFormDefaults,
  setOptionForm: setOptionFormDefaults,
  setOptionsReload: false,
});

export default new Store({
  getInitialState() {
    return initialState;
  },

  initialize() {
    this.on(actionTypes.SET_OPTION_RESET, onSetOptionReset);
    this.on(actionTypes.GET_OPTION_SUCCESS, onGetOptionSuccess);
    this.on(actionTypes.GET_OPTION_FAIL, onGetOptionFail);
    this.on(actionTypes.ADD_OPTION_FORM, onAddOptionForm);
    this.on(actionTypes.ADD_OPTION_START, onAddOptionStart);
    this.on(actionTypes.ADD_OPTION_SUCCESS, onAddOptionSuccess);
    this.on(actionTypes.ADD_OPTION_FAIL, onAddOptionFail);
    this.on(actionTypes.SET_OPTION_FORM, onSetOptionForm);
    this.on(actionTypes.SET_OPTION_START, onSetOptionStart);
    this.on(actionTypes.SET_OPTION_SUCCESS, onSetOptionSuccess);
    this.on(actionTypes.SET_OPTION_FAIL, onSetOptionFail);
    this.on(actionTypes.SET_OPTIONS_RELOAD, onSetOptionsReload);
    this.on(actionTypes.REM_OPTION, onRemOption);
  },
});

function onSetOptionReset(state) {
  return initialState;
}

function onGetOptionSuccess(state, { data }) {
  return state.set("option", toImmutable(data.option));
}

function onGetOptionFail(state, { data }) {
  return state.set("option", toImmutable({}));
}

function onAddOptionForm(state) {
  return state.set("addOptionForm", toImmutable(addOptionFormDefaults));
}

function onAddOptionStart(state, { data }) {
  return state.setIn(["addOptionForm", "started"], data);
}

function onAddOptionSuccess(state, { data }) {
  let feedback = {
    type: "success",
    messages: [getIntlMessage("Add Successful")],
  };
  let options = addOptionFormDefaults.options;
  return state
    .setIn(["addOptionForm", "feedback"], toImmutable(feedback))
    .setIn(["addOptionForm", "options"], toImmutable(options));
}

function onAddOptionFail(state, { data }) {
  let feedback = forms.utils.setFormFeedback(data);
  let options = forms.utils.setFormOptions(state.getIn(["addOptionForm", "options"]).toJS(), data);
  return state
    .setIn(["addOptionForm", "feedback"], toImmutable(feedback))
    .setIn(["addOptionForm", "options"], toImmutable(options));
}

function onSetOptionForm(state) {
  return state;
}

function onSetOptionStart(state, { data }) {
  return state.setIn(["setOptionForm", "started"], data);
}

function onSetOptionSuccess(state, { data }) {
  return state.set("option", toImmutable(data.option)).set("setOptionForm", toImmutable(setOptionFormDefaults));
}

function onSetOptionFail(state, { data }) {
  let feedback = forms.utils.setFormFeedback(data);
  let options = forms.utils.setFormOptions(state.getIn(["setOptionForm", "options"]).toJS(), data);
  return state
    .setIn(["setOptionForm", "feedback"], toImmutable(feedback))
    .setIn(["setOptionForm", "options"], toImmutable(options));
}

function onSetOptionsReload(state, { data }) {
  return state.set("setOptionsReload", toImmutable(data));
}

function onRemOption(state) {
  return state.set("option", toImmutable({}));
}
