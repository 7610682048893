import React from "react";
import _ from "lodash";

import reactor from "../../reactor";
import optionlists from "../../modules/optionlists";
import i18n from "../../modules/i18n";
import FormModal from "../forms/FormModal";

let getIntlMessage = i18n.messages.getIntlMessage;

var SetOptionList = React.createClass({
  mixins: [reactor.ReactMixin],

  propTypes: {
    optionListId: React.PropTypes.number,
  },

  getDefaultProps() {
    return {
      optionListId: null,
    };
  },

  getInitialState() {
    return {
      value: {},
    };
  },

  getDataBindings() {
    return {
      optionList: optionlists.getters.optionListGetter,
      setOptionListForm: optionlists.getters.setOptionListFormGetter,
      setOptionListsReload: optionlists.getters.setOptionListsReloadGetter,
    };
  },

  componentWillReceiveProps(props) {
    if (props.show && (_.isEmpty(this.state.value) || this.state.setOptionListsReload)) {
      optionlists.actions.getOptionList(props.optionListId);
    } else if (!props.show && !_.isEmpty(this.state.value)) {
      optionlists.actions.remOptionList();
    }
  },

  componentDidUpdate(prevProps, prevState) {
    if (this.state.optionList !== prevState.optionList) {
      this.setState({ value: this.state.optionList.toJS() });
    }
  },

  onFormSubmit(event, value) {
    if (value) {
      this.setState({
        value: value,
      });
      optionlists.actions.setOptionListStart(true);
      optionlists.actions.setOptionList(this.props.optionListId, value);
    }
  },

  onFormChange(value) {
    this.setState({ value });
  },

  render() {
    var form = this.state.setOptionListForm.toJS();
    return (
      <FormModal
        form={form}
        formType="set"
        value={this.state.value}
        onFormChange={this.onFormChange}
        onFormSubmit={this.onFormSubmit}
        title={getIntlMessage("Edit Option List")}
        {...this.props}
      />
    );
  },
});

export default SetOptionList;
