import { Store, toImmutable } from "nuclear-js";

import forms from "../forms";
import i18n from "../i18n";
import actionTypes from "./actionTypes";
import * as schemas from "./schemas";

let getIntlMessage = i18n.messages.getIntlMessage;

let addAccountFormDefaults = {
  type: schemas.AddAccountSchema,
  options: schemas.AddAccountSchemaOptions,
  feedback: {
    type: "info",
    messages: [],
  },
  started: false,
};

let setAccountFormDefaults = {
  type: schemas.SetAccountSchema,
  options: schemas.SetAccountSchemaOptions,
  feedback: {
    type: "info",
    messages: [],
  },
  started: false,
};

let setAccountPasswordFormDefaults = {
  type: schemas.SetAccountPasswordSchema,
  options: schemas.SetAccountPasswordSchemaOptions,
  feedback: {
    type: "info",
    messages: [],
  },
  started: false,
};

let initialState = toImmutable({
  account: {},
  addAccountForm: addAccountFormDefaults,
  setAccountForm: setAccountFormDefaults,
  setAccountPasswordForm: setAccountPasswordFormDefaults,
  setAccountAvatar: {
    feedback: {
      type: "info",
      messages: [],
    },
    started: false,
  },
  setAccountsReload: false,
});

export default new Store({
  getInitialState() {
    return initialState;
  },

  initialize() {
    this.on(actionTypes.SET_ACCOUNT_RESET, onSetAccountReset);
    this.on(actionTypes.GET_ACCOUNT_SUCCESS, onGetAccountSuccess);
    this.on(actionTypes.GET_ACCOUNT_FAIL, onGetAccountFail);
    this.on(actionTypes.ADD_ACCOUNT_FORM, onAddAccountForm);
    this.on(actionTypes.ADD_ACCOUNT_START, onAddAccountStart);
    this.on(actionTypes.ADD_ACCOUNT_SUCCESS, onAddAccountSuccess);
    this.on(actionTypes.ADD_ACCOUNT_FAIL, onAddAccountFail);
    this.on(actionTypes.SET_ACCOUNT_FORM, onSetAccountForm);
    this.on(actionTypes.SET_ACCOUNT_START, onSetAccountStart);
    this.on(actionTypes.SET_ACCOUNT_SUCCESS, onSetAccountSuccess);
    this.on(actionTypes.SET_ACCOUNT_FAIL, onSetAccountFail);
    this.on(actionTypes.SET_ACCOUNT_PASSWORD_FORM, onSetAccountPasswordForm);
    this.on(actionTypes.SET_ACCOUNT_PASSWORD_START, onSetAccountPasswordStart);
    this.on(actionTypes.SET_ACCOUNT_PASSWORD_SUCCESS, onSetAccountPasswordSuccess);
    this.on(actionTypes.SET_ACCOUNT_PASSWORD_FAIL, onSetAccountPasswordFail);
    this.on(actionTypes.SET_ACCOUNT_AVATAR_START, onSetAccountAvatarStart);
    this.on(actionTypes.SET_ACCOUNT_AVATAR_SUCCESS, onSetAccountAvatarSuccess);
    this.on(actionTypes.SET_ACCOUNT_AVATAR_FAIL, onSetAccountAvatarFail);
    this.on(actionTypes.SET_ACCOUNTS_RELOAD, onSetAccountsReload);
    this.on(actionTypes.REM_ACCOUNT, onRemAccount);
  },
});

function onSetAccountReset(state) {
  return initialState;
}

function onGetAccountSuccess(state, { data }) {
  return state.set("account", toImmutable(data.account));
}

function onGetAccountFail(state, { data }) {
  return state.set("account", toImmutable({}));
}

function onAddAccountForm(state) {
  return state.set("addAccountForm", toImmutable(addAccountFormDefaults));
}

function onAddAccountStart(state, { data }) {
  return state.setIn(["addAccountForm", "started"], data);
}

function onAddAccountSuccess(state, { data }) {
  let feedback = {
    type: "success",
    messages: [getIntlMessage("Add Successful")],
  };
  let options = addAccountFormDefaults.options;
  return state
    .setIn(["addAccountForm", "feedback"], toImmutable(feedback))
    .setIn(["addAccountForm", "options"], toImmutable(options));
}

function onAddAccountFail(state, { data }) {
  let feedback = forms.utils.setFormFeedback(data);
  let options = forms.utils.setFormOptions(state.getIn(["addAccountForm", "options"]).toJS(), data);
  return state
    .setIn(["addAccountForm", "feedback"], toImmutable(feedback))
    .setIn(["addAccountForm", "options"], toImmutable(options));
}

function onSetAccountForm(state) {
  return state;
}

function onSetAccountStart(state, { data }) {
  return state.setIn(["setAccountForm", "started"], data);
}

function onSetAccountSuccess(state, { data }) {
  return state.set("account", toImmutable(data.account)).set("setAccountForm", toImmutable(setAccountFormDefaults));
}

function onSetAccountFail(state, { data }) {
  let feedback = forms.utils.setFormFeedback(data);
  let options = forms.utils.setFormOptions(state.getIn(["setAccountForm", "options"]).toJS(), data);
  return state
    .setIn(["setAccountForm", "feedback"], toImmutable(feedback))
    .setIn(["setAccountForm", "options"], toImmutable(options));
}

function onSetAccountPasswordForm(state) {
  return state;
}

function onSetAccountPasswordStart(state, { data }) {
  return state.setIn(["setAccountPasswordForm", "started"], data);
}

function onSetAccountPasswordSuccess(state, { data }) {
  return state
    .set("account", toImmutable(data.account))
    .set("setAccountPasswordForm", toImmutable(setAccountPasswordFormDefaults));
}

function onSetAccountPasswordFail(state, { data }) {
  let feedback = forms.utils.setFormFeedback(data);
  let options = forms.utils.setFormOptions(state.getIn(["setAccountPasswordForm", "options"]).toJS(), data);
  return state
    .setIn(["setAccountPasswordForm", "feedback"], toImmutable(feedback))
    .setIn(["setAccountPasswordForm", "options"], toImmutable(options));
}

function onSetAccountAvatarStart(state, { data }) {
  return state.setIn(["setAccountAvatar", "started"], data);
}

function onSetAccountAvatarSuccess(state, { data }) {
  return state.set("account", toImmutable(data.account));
}

function onSetAccountAvatarFail(state, { data }) {
  return state;
}

function onSetAccountsReload(state, { data }) {
  return state.set("setAccountsReload", toImmutable(data));
}

function onRemAccount(state) {
  return state.set("account", toImmutable({}));
}
