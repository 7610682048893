import React, { Component } from "react";
import { Panel } from "react-bootstrap";

import Page from "./base/Page";
import SubHeader from "./base/SubHeader";
import Content from "./base/Content";

export default class Blank extends Component {
  render() {
    return (
      <Page>
        <SubHeader title="BLANK PAGE" />
        <Content>
          <Panel>BLANK PAGE</Panel>
        </Content>
      </Page>
    );
  }
}
