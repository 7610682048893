import React from "react";
import { History } from "react-router";
import { Nav, NavItem } from "react-bootstrap";

import i18n from "../../modules/i18n";
import SubHeader from "../base/SubHeader";

let getIntlMessage = i18n.messages.getIntlMessage;

var Header = React.createClass({
  mixins: [History],

  onNavSelect(selected, href) {
    this.history.push(href);
  },

  render() {
    return (
      <SubHeader title={getIntlMessage("Prospects")}>
        <Nav
          bsStyle="pills"
          ulClassName="nav-header"
          activeHref={this.props.location.pathname}
          onSelect={this.onNavSelect}
        >
          <NavItem eventKey={1} href="/prospects/available">
            {getIntlMessage("Available")}
          </NavItem>
          <NavItem eventKey={2} href="/prospects/excluded">
            {getIntlMessage("Excluded")}
          </NavItem>
          <NavItem eventKey={3} href="/prospects/outdated">
            {getIntlMessage("Outdated")}
          </NavItem>
          <NavItem eventKey={4} href="/prospects/canceled">
            {getIntlMessage("Canceled")}
          </NavItem>
          <NavItem eventKey={5} href="/prospects/all">
            {getIntlMessage("All")}
          </NavItem>
        </Nav>
      </SubHeader>
    );
  },
});

export default Header;
