import t from "tcomb-form";

import forms from "../forms";
import i18n from "../i18n";

let getIntlMessage = i18n.messages.getIntlMessage;

export var AddOfficeSchema = t.struct({
  status_id: t.Num,
  name: t.Str,
  code: t.Str,
  address: t.maybe(t.Str),
  city: t.Str,
  province: t.maybe(t.Str),
  zipcode: t.maybe(t.Str),
  phone: t.Str,
  email: t.Str,
  supervisor_id: t.maybe(t.Num),
  office_director_id: t.maybe(t.Num),
  sales_director_ids: t.maybe(t.list(t.Num)),
  advisor_ids: t.maybe(t.list(t.Num)),
});

export var SetOfficeSchema = AddOfficeSchema;

export var AddOfficeSchemaOptions = {
  config: {
    // for each of lg md sm xs you can specify the columns width
    horizontal: {
      lg: [4, 8],
      md: [4, 8],
    },
  },
  fields: {
    status_id: {
      type: "text",
      label: getIntlMessage("Status"),
      factory: forms.widgets.KDropDownListWidget,
      attrs: {
        culture: "fr-CA",
        optionLabel: getIntlMessage("Select..."),
        dataSource: {
          transport: {
            read: {
              url: process.env.__APIURL__ + "/choices/lists/status",
              dataType: "json",
            },
          },
          schema: {
            data: "results",
          },
        },
      },
    },
    name: {
      type: "text",
      label: getIntlMessage("Name"),
    },
    code: {
      type: "text",
      label: getIntlMessage("Code"),
    },
    address: {
      type: "text",
      label: getIntlMessage("Address"),
    },
    city: {
      type: "text",
      label: getIntlMessage("City"),
    },
    province: {
      type: "text",
      label: getIntlMessage("Province"),
    },
    zipcode: {
      type: "text",
      label: getIntlMessage("Zip Code"),
    },
    phone: {
      type: "text",
      label: getIntlMessage("Phone"),
    },
    email: {
      type: "text",
      label: getIntlMessage("Email"),
    },
    supervisor_id: {
      type: "text",
      label: getIntlMessage("Supervisor"),
      factory: forms.widgets.KDropDownListWidget,
      attrs: {
        culture: "fr-CA",
        optionLabel: getIntlMessage("Select..."),
        dataSource: {
          transport: {
            read: {
              url: process.env.__APIURL__ + "/choices/accounts/supervisors",
              dataType: "json",
            },
          },
          schema: {
            data: "results",
          },
        },
      },
    },
    office_director_id: {
      type: "text",
      label: getIntlMessage("Office Director"),
      factory: forms.widgets.KDropDownListWidget,
      attrs: {
        culture: "fr-CA",
        optionLabel: getIntlMessage("Select..."),
        dataSource: {
          transport: {
            read: {
              url: process.env.__APIURL__ + "/choices/accounts/office-directors",
              dataType: "json",
            },
          },
          schema: {
            data: "results",
          },
        },
      },
    },
    sales_director_ids: {
      type: "text",
      label: getIntlMessage("Sales Directors"),
      factory: forms.widgets.KMultiSelectWidget,
      attrs: {
        culture: "fr-CA",
        placeholder: getIntlMessage("Select..."),
        dataSource: {
          transport: {
            read: {
              url: process.env.__APIURL__ + "/choices/accounts/sales-directors",
              dataType: "json",
            },
          },
          schema: {
            data: "results",
          },
        },
      },
    },
    advisor_ids: {
      type: "text",
      label: getIntlMessage("Advisors"),
      factory: forms.widgets.KMultiSelectWidget,
      attrs: {
        culture: "fr-CA",
        placeholder: getIntlMessage("Select..."),
        dataSource: {
          transport: {
            read: {
              url: process.env.__APIURL__ + "/choices/accounts/advisors",
              dataType: "json",
            },
          },
          schema: {
            data: "results",
          },
        },
      },
    },
  },
};

export var SetOfficeSchemaOptions = AddOfficeSchemaOptions;
