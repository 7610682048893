import t from "tcomb-form";

import i18n from "../i18n";

let getIntlMessage = i18n.messages.getIntlMessage;

let emailTransformer = {
  format: function (value) {
    return value;
  },
  parse: function (str) {
    if (str) {
      return str.trim().toLowerCase();
    }
    return str;
  },
};

export var LoginSchema = t.struct({
  email: t.Str,
  password: t.Str,
});

export var LoginSchemaOptions = {
  fields: {
    email: {
      type: "text",
      label: getIntlMessage("Email"),
      transformer: emailTransformer,
    },
    password: {
      type: "password",
      label: getIntlMessage("Password"),
    },
  },
};

export var PasswordLostSchema = t.struct({
  email: t.Str,
});

export var PasswordLostSchemaOptions = {
  config: {
    horizontal: {
      lg: [4, 8],
      md: [4, 8],
    },
  },
  fields: {
    email: {
      type: "text",
      label: getIntlMessage("Email"),
      transformer: emailTransformer,
    },
  },
};

export var PasswordResetSchema = t.struct({
  token: t.Str,
  password: t.Str,
  confirm_password: t.Str,
});

export var PasswordResetSchemaOptions = {
  config: {
    horizontal: {
      lg: [4, 8],
      md: [4, 8],
    },
  },
  fields: {
    token: {
      type: "text",
      label: getIntlMessage("Token"),
    },
    password: {
      type: "password",
      label: getIntlMessage("Password"),
    },
    confirm_password: {
      type: "password",
      label: getIntlMessage("Confirm Password"),
    },
  },
};
