import React from "react";
import { History } from "react-router";
import { Nav, NavItem } from "react-bootstrap";

import i18n from "../../modules/i18n";
import SubHeader from "../base/SubHeader";

let getIntlMessage = i18n.messages.getIntlMessage;

var Header = React.createClass({
  mixins: [History],

  onNavSelect(selected, href) {
    this.history.push(href);
  },

  render() {
    return (
      <SubHeader title={getIntlMessage("Activities")}>
        <Nav
          bsStyle="pills"
          ulClassName="nav-header"
          activeHref={this.props.location.pathname}
          onSelect={this.onNavSelect}
        >
          <NavItem eventKey={1} href="/activities/board">
            {getIntlMessage("Board")}
          </NavItem>
          <NavItem eventKey={2} href="/activities/pending">
            {getIntlMessage("Pending")}
          </NavItem>
          <NavItem eventKey={3} href="/activities/sold">
            {getIntlMessage("Sold")}
          </NavItem>
          <NavItem eventKey={4} href="/activities/canceled">
            {getIntlMessage("Canceled")}
          </NavItem>
        </Nav>
      </SubHeader>
    );
  },
});

export default Header;
