import React from "react";

import reactor from "../../reactor";
import profile from "../../modules/profile";
import i18n from "../../modules/i18n";
import FormModal from "../forms/FormModal";

let getIntlMessage = i18n.messages.getIntlMessage;

var SetProfile = React.createClass({
  mixins: [reactor.ReactMixin],

  propTypes: {
    profile: React.PropTypes.object,
  },

  getDefaultProps() {
    return {
      profile: {},
    };
  },

  getInitialState() {
    return {
      value: {},
    };
  },

  getDataBindings() {
    return {
      setProfileForm: profile.getters.setProfileFormGetter,
    };
  },

  componentWillReceiveProps(props) {
    if (props.show) {
      this.setState({ value: props.profile });
    } else if (!props.show) {
      this.setState({ value: {} });
    }
  },

  onFormSubmit(event, value) {
    if (value) {
      this.setState({
        value: value,
      });
      profile.actions.setProfileStart(true);
      profile.actions.setProfile(value);
    }
  },

  onFormChange(value) {
    this.setState({ value });
  },

  render() {
    var form = this.state.setProfileForm.toJS();
    return (
      <FormModal
        form={form}
        formType="set"
        value={this.state.value}
        onFormChange={this.onFormChange}
        onFormSubmit={this.onFormSubmit}
        title={getIntlMessage("Edit Profile")}
        {...this.props}
      />
    );
  },
});

export default SetProfile;
