import * as actions from "./actions";
import _ from "lodash";

const dataSteps = ["market-analysis", "break-time", "sales-interview"];

export const dndTypes = {
  CARD: "card",
};

export var cardSource = {
  beginDrag(props, monitor, component) {
    return props;
  },
  isDragging(props, monitor) {
    return props.id === monitor.getItem().id;
  },
  endDrag(props, monitor, component) {
    var item = monitor.getItem();
    if (!monitor.didDrop()) {
      item.onMoveCardToStep(item, item.dataStep, item.index);
    }
  },
};

export function dragCollect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

export var cardTarget = {
  canDrop(props, monitor) {
    var item = monitor.getItem();
    var target = props;
    var curStepIndex = dataSteps.indexOf(item.dataStep);
    var nextStepIndex = dataSteps.indexOf(target.dataStep);
    if (curStepIndex === nextStepIndex || nextStepIndex === curStepIndex + 1) {
      return !(item.id === target.id);
    }
    return false;
  },
  hover(props, monitor, component) {
    var item = monitor.getItem();
    var target = props;
    if (monitor.canDrop()) {
      if (item && target && item.id !== target.id) {
        target.onMoveCard(item.id, target.id);
      }
    }
  },
};

export var stepColTarget = {
  canDrop(props, monitor) {
    var item = monitor.getItem();
    var target = props;
    var curStepIndex = dataSteps.indexOf(item.dataStep);
    var nextStepIndex = dataSteps.indexOf(target.dataStep);
    if (curStepIndex === nextStepIndex || nextStepIndex === curStepIndex + 1) {
      return true;
    }
    return false;
  },
  hover(props, monitor, component) {
    if (!props.data.length) {
      var item = monitor.getItem();
      if (monitor.canDrop()) {
        item.onMoveCardToStep(item, props.dataStep);
      }
    }
  },
  drop(props, monitor, component) {
    var item = monitor.getItem();
    var targetStep = props.dataStep;
    var targetIndex = _.findIndex(props.data, { id: item.id });
    actions.setBoardStep(targetStep, {
      activity_id: item.id,
      position: targetIndex,
    });
    return props || {};
  },
};

export function dropCollect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType(),
  };
}
