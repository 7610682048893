import React from "react";

import reactor from "../../reactor";
import offices from "../../modules/offices";
import i18n from "../../modules/i18n";
import FormModal from "../forms/FormModal";

let getIntlMessage = i18n.messages.getIntlMessage;

var defaultValue = {
  sales_director_ids: [],
  advisor_ids: [],
};

var AddOffice = React.createClass({
  mixins: [reactor.ReactMixin],

  getInitialState() {
    return {
      value: defaultValue,
    };
  },

  getDataBindings() {
    return {
      addOfficeForm: offices.getters.addOfficeFormGetter,
    };
  },

  componentWillReceiveProps(props) {
    if (!props.show) {
      this.setState({
        value: defaultValue,
      });
    }
  },

  onFormSubmit(event, value) {
    if (value) {
      this.setState({
        value: value,
      });
      offices.actions.addOfficeStart(true);
      offices.actions.addOffice(value);
    }
  },

  onFormChange(value) {
    this.setState({ value });
  },

  render() {
    var form = this.state.addOfficeForm.toJS();
    return (
      <FormModal
        form={form}
        formType="add"
        value={this.state.value}
        onFormChange={this.onFormChange}
        onFormSubmit={this.onFormSubmit}
        title={getIntlMessage("New Office")}
        {...this.props}
      />
    );
  },
});

export default AddOffice;
