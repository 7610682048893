import * as layouts from "./layouts";
import * as schemas from "./schemas";
import * as utils from "./utils";
import * as widgets from "./widgets";

export default {
  layouts,
  schemas,
  utils,
  widgets,
};
