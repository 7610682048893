import React from "react";
import { update } from "react/lib/update";
import { Router } from "react-router";
import classNames from "classnames";
import { DropTarget } from "react-dnd";

import i18n from "../../modules/i18n";
import activities from "../../modules/activities";
import Card from "./Card";

let getIntlMessage = i18n.messages.getIntlMessage;

var StepCol = React.createClass({
  propTypes: {
    connectDropTarget: React.PropTypes.func.isRequired,
    isOver: React.PropTypes.bool.isRequired,
    isOverCurrent: React.PropTypes.bool.isRequired,
    canDrop: React.PropTypes.bool.isRequired,
    dataStep: React.PropTypes.string,
    name: React.PropTypes.string,
    data: React.PropTypes.array,
    onShowCancelModal: React.PropTypes.func,
    onShowSellModal: React.PropTypes.func,
    onShowPauseModal: React.PropTypes.func,
    onShowScheduleModal: React.PropTypes.func,
    onShowCloneModal: React.PropTypes.func,
    onShowModal: React.PropTypes.func,
    onMoveCardToStep: React.PropTypes.func,
    onMoveCard: React.PropTypes.func,
  },

  getDefaultProps() {
    return {
      dataStep: "step-data",
      name: getIntlMessage("Step"),
      data: [],
      onShowCancelModal: null,
      onShowSellModal: null,
      onShowPauseModal: null,
      onShowScheduleModal: null,
      onShowCloneModal: null,
      onShowModal: null,
      onMoveCard: null,
    };
  },

  getInitialState() {
    return {
      cards: this.props.data,
    };
  },

  componentWillReceiveProps(props) {
    if (props.data !== this.props.data) {
      this.setState({
        cards: props.data,
      });
    }
  },

  getBodyClassName(isOver) {
    var names = ["panel-body", "board-cards"];
    if (isOver) {
      names.push("on-over");
    }
    return classNames(names);
  },

  renderData(data) {
    var cards = data.map((item, index) => {
      return (
        <Card
          index={index}
          key={item.id}
          id={item.id}
          dataStep={this.props.dataStep}
          activity={item}
          onShowCancelModal={this.props.onShowCancelModal}
          onShowSellModal={this.props.onShowSellModal}
          onShowPauseModal={this.props.onShowPauseModal}
          onShowScheduleModal={this.props.onShowScheduleModal}
          onShowCloneModal={this.props.onShowCloneModal}
          onShowModal={this.props.onShowModal}
          onMoveCardToStep={this.props.onMoveCardToStep}
          onMoveCard={this.props.onMoveCard}
        />
      );
    });
    return cards;
  },

  render() {
    var isOver = this.props.isOver;
    var isOverCurrent = this.props.isOverCurrent;
    var canDrop = this.props.canDrop;
    var connectDropTarget = this.props.connectDropTarget;
    return connectDropTarget(
      <div className="hpanel activities-board-step">
        <div className="panel-heading hbuilt">
          {this.props.name} ({this.props.data.length})
        </div>
        <div className={this.getBodyClassName(isOver)} data-step={this.props.dataStep} ref="cardList">
          {this.renderData(this.state.cards)}
        </div>
      </div>
    );
  },
});

export default DropTarget(
  activities.dnd.dndTypes.CARD,
  activities.dnd.stepColTarget,
  activities.dnd.dropCollect
)(StepCol);
