import React from "react";
import { ButtonGroup, Button } from "react-bootstrap";

import i18n from "../../modules/i18n";
import SubHeader from "../base/SubHeader";
import AddOptionList from "./AddOptionList";

let getIntlMessage = i18n.messages.getIntlMessage;

var Header = React.createClass({
  getInitialState() {
    return {
      showAddOptionList: false,
    };
  },

  onShowAddOptionList() {
    this.setState({
      showAddOptionList: true,
    });
  },

  onHideAddOptionList() {
    this.setState({
      showAddOptionList: false,
    });
  },

  render() {
    return (
      <SubHeader title={getIntlMessage("Option Lists")}>
        <ButtonGroup>
          <Button name="add-option" bsSize="small" onClick={this.onShowAddOptionList}>
            {getIntlMessage("Add")}
          </Button>
        </ButtonGroup>
        <AddOptionList show={this.state.showAddOptionList} onHide={this.onHideAddOptionList} {...this.props} />
      </SubHeader>
    );
  },
});

export default Header;
