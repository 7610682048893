import React from "react";

import i18n from "../../modules/i18n";
import SubHeader from "../base/SubHeader";

let getIntlMessage = i18n.messages.getIntlMessage;

var Header = React.createClass({
  render() {
    return <SubHeader title={getIntlMessage("Profile")} />;
  },
});

export default Header;
