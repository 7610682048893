import React from "react";

import app from "../../modules/app";
import i18n from "../../modules/i18n";
import Navbar from "./Navbar";

let getIntlMessage = i18n.messages.getIntlMessage;

var Header = React.createClass({
  propTypes: {
    profile: React.PropTypes.object,
  },

  getDefaultProps() {
    return {
      profile: {},
    };
  },

  toggleSidebar() {
    app.actions.sidebarDocked();
  },

  render() {
    return (
      <div id="header">
        <div className="color-line" />
        <nav role="navigation">
          <div className="header-link hide-menu" onClick={this.toggleSidebar}>
            <i className="fa fa-bars"></i>
          </div>
          <div className="small-logo">
            <span className="text-primary">iA • P9N</span>
          </div>
          <Navbar {...this.props} />
        </nav>
      </div>
    );
  },
});

export default Header;
