import React, { Component, PropTypes } from "react";
import { Row, Col } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

import i18n from "../../modules/i18n";

let getIntlMessage = i18n.messages.getIntlMessage;

export default class SummaryPlanifications extends Component {
  static propTypes = {
    planifications: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    planifications: {},
  };

  renderAdvisors = (cell, row) => {
    return cell.join(", ");
  };

  render() {
    return (
      <BootstrapTable
        ref="summary-table"
        data={this.props.planifications}
        striped={true}
        hover={true}
        condensed={true}
        height="300"
      >
        <TableHeaderColumn isKey={true} dataSort={true} dataField="sales_director_name" width="300">
          {getIntlMessage("Directors")}
        </TableHeaderColumn>
        <TableHeaderColumn dataSort={true} dataField={"advisor_names"} dataFormat={this.renderAdvisors}>
          {getIntlMessage("Advisors")}
        </TableHeaderColumn>
        <TableHeaderColumn dataSort={true} dataField="total_planned" width="100">
          {getIntlMessage("Total Planned")}
        </TableHeaderColumn>
        <TableHeaderColumn dataSort={true} dataField="total_completed" width="100">
          {getIntlMessage("Total Completed")}
        </TableHeaderColumn>
        <TableHeaderColumn dataSort={true} dataField="total_commission" width="100">
          {getIntlMessage("Total Commission")}
        </TableHeaderColumn>
        <TableHeaderColumn dataSort={true} dataField="total_pac" width="100">
          {getIntlMessage("Total PAC")}
        </TableHeaderColumn>
        <TableHeaderColumn dataSort={true} dataField="total_iaah" width="100">
          {getIntlMessage("Total IAAH")}
        </TableHeaderColumn>
        <TableHeaderColumn dataSort={true} dataField="total_excellence" width="100">
          {getIntlMessage("Total Excellence")}
        </TableHeaderColumn>
        <TableHeaderColumn dataSort={true} dataField="total_reference" width="100">
          {getIntlMessage("Total Reference")}
        </TableHeaderColumn>
      </BootstrapTable>
    );
  }
}
