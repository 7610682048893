import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";

import i18n from "../../modules/i18n";
import prospects from "../../modules/prospects";

let getIntlMessage = i18n.messages.getIntlMessage;

export default class CancelProspect extends Component {
  static propTypes = {
    show: React.PropTypes.bool,
    onHide: React.PropTypes.func,
    prospectId: React.PropTypes.number,
  };

  static defaultProps = {
    prospectId: null,
  };

  onCancel = (event) => {
    prospects.actions.setProspectStatusStart(true);
    prospects.actions.setProspectStatus(this.props.prospectId, { refid: "cancelled" });
    this.props.onHide();
  };

  render() {
    return (
      <Modal
        bsSize="medium"
        className="hmodal-danger"
        show={this.props.show}
        onHide={this.props.onHide}
        {...this.props}
      >
        <div className="color-line" />
        <Modal.Header>
          <Modal.Title>{getIntlMessage("Cancel Prospect")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{getIntlMessage("Are you sure you want to cancel this prospect?")}</Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onHide}>{getIntlMessage("Close")}</Button>
          <Button name="cancel" bsStyle="danger" onClick={this.onCancel}>
            {getIntlMessage("Cancel")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
