import React, { Component, PropTypes } from "react";
import { connect } from "nuclear-js-react-addons";

import activities from "../../modules/activities";
import prospects from "../../modules/prospects";
import i18n from "../../modules/i18n";
import FormModal from "../forms/FormModal";

let getIntlMessage = i18n.messages.getIntlMessage;
let defaultValue = {};

export default
@connect((props) => ({
  addProspectActivityForm: prospects.getters.addProspectActivityFormGetter,
}))
class addProspectActivity extends Component {
  static propTypes = {
    prospectId: PropTypes.number,
  };

  static defaultProps = {
    addProspectActivityForm: null,
    prospectId: null,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      value: defaultValue,
    };
  }

  componentWillReceiveProps(props) {
    if (!props.show) {
      this.setState({
        value: defaultValue,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.addProspectActivityForm !== prevProps.addProspectActivityForm) {
      var form = this.props.addProspectActivityForm.toJS();
      var prevForm = prevProps.addProspectActivityForm.toJS();
      if (form.feedback.type !== prevForm.feedback.type && form.feedback.type === "success") {
        this.props.onHide();
      }
    }
  }

  onFormSubmit = (event, value) => {
    if (value) {
      this.setState({
        value: value,
      });
      prospects.actions.addProspectActivityStart(true);
      prospects.actions.addProspectActivity({
        prospect_id: this.props.prospectId,
        step: value.step,
      });
    }
  };

  onFormChange = (value) => {
    this.setState({ value });
  };

  render() {
    let form = this.props.addProspectActivityForm.toJS();
    return (
      <FormModal
        form={form}
        formType="set"
        value={this.state.value}
        onFormChange={this.onFormChange}
        onFormSubmit={this.onFormSubmit}
        bsStyle="info"
        title={getIntlMessage("Add To Activities")}
        actionButtonTitle={getIntlMessage("Add")}
        showFeedback={false}
        {...this.props}
      />
    );
  }
}
