import React, { Component, PropTypes } from "react";
import { Sparklines, SparklinesLine, SparklinesReferenceLine } from "react-sparklines";

export default class ActivitySparklines extends Component {
  static propTypes = {
    nodeId: PropTypes.string,
    data: PropTypes.array,
  };

  static defaultProps = {
    data: [],
  };

  render() {
    return (
      <Sparklines data={this.props.data} width={50} height={25}>
        <SparklinesLine color="green" />
        <SparklinesReferenceLine type="avg" />
      </Sparklines>
    );
  }
}
