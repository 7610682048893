import React from "react";

import reactor from "../../reactor";
import roles from "../../modules/roles";
import i18n from "../../modules/i18n";
import FormModal from "../forms/FormModal";

let getIntlMessage = i18n.messages.getIntlMessage;

var defaultValue = {};

var AddRole = React.createClass({
  mixins: [reactor.ReactMixin],

  getInitialState() {
    return {
      value: defaultValue,
    };
  },

  getDataBindings() {
    return {
      addRoleForm: roles.getters.addRoleFormGetter,
    };
  },

  componentWillReceiveProps(props) {
    if (!props.show) {
      this.setState({
        value: defaultValue,
      });
    }
  },

  onFormSubmit(event, value) {
    if (value) {
      this.setState({
        value: value,
      });
      roles.actions.addRoleStart(true);
      roles.actions.addRole(value);
    }
  },

  onFormChange(value) {
    this.setState({ value });
  },

  render() {
    var form = this.state.addRoleForm.toJS();
    return (
      <FormModal
        form={form}
        formType="add"
        value={this.state.value}
        onFormChange={this.onFormChange}
        onFormSubmit={this.onFormSubmit}
        title={getIntlMessage("New Role")}
        {...this.props}
      />
    );
  },
});

export default AddRole;
