import React, { Component, PropTypes } from "react";
import { connect } from "nuclear-js-react-addons";
import { Button, ButtonGroup } from "react-bootstrap";

import app from "../../modules/app";
import i18n from "../../modules/i18n";
import SubHeader from "../base/SubHeader";
import WeekSelector from "./WeekSelector";

let getIntlMessage = i18n.messages.getIntlMessage;

export default
@connect((props) => ({
  account: app.getters.appAccountGetter,
}))
class Header extends Component {
  renderWeekSelector() {
    let profile = this.props.profile;
    let account = this.props.account.toJS();
    if (profile.main_role_refid === "sales-director") {
      account = profile;
    }
    return <WeekSelector account={account} {...this.props} />;
  }

  render() {
    return <SubHeader title={getIntlMessage("Planifications")}>{this.renderWeekSelector()}</SubHeader>;
  }
}
