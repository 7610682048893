import axios from "axios";

import reactor from "../../reactor";
import i18n from "../i18n";
import notifier from "../notifier";
import actionTypes from "./actionTypes";

let getIntlMessage = i18n.messages.getIntlMessage;

export function getPlanificationSummary(year, week) {
  axios
    .get(process.env.__APIURL__ + "/planifications/summary", {
      params: {
        year: year,
        week: week,
      },
    })
    .then((res) => {
      reactor.dispatch(actionTypes.GET_PLANIFICATION_SUMMARY_SUCCESS, { data: res.data });
      reactor.dispatch(actionTypes.GET_PLANIFICATION_SUMMARY_START, { data: false });
    })
    .catch((res) => {
      reactor.dispatch(actionTypes.GET_PLANIFICATION_SUMMARY_FAIL, { data: res.data });
      reactor.dispatch(actionTypes.GET_PLANIFICATION_SUMMARY_START, { data: false });
    });
}

export function getPlanificationSummaryStart(data) {
  reactor.dispatch(actionTypes.GET_PLANIFICATION_SUMMARY_START, { data: true });
}

export function getPlanificationByPeriod(year, week, salesDirectorId) {
  axios
    .get(process.env.__APIURL__ + "/planifications/period", {
      params: {
        year: year,
        week: week,
        sales_director_id: salesDirectorId,
      },
    })
    .then((res) => {
      reactor.dispatch(actionTypes.GET_PLANIFICATION_PERIOD_SUCCESS, { data: res.data });
      reactor.dispatch(actionTypes.GET_PLANIFICATION_PERIOD_START, { data: false });
    })
    .catch((res) => {
      reactor.dispatch(actionTypes.GET_PLANIFICATION_PERIOD_FAIL, { data: res.data });
      reactor.dispatch(actionTypes.GET_PLANIFICATION_PERIOD_START, { data: false });
    });
}

export function setPlanificationPeriodSelector(year, week, salesDirectorId) {
  reactor.dispatch(actionTypes.SET_PLANIFICATION_PERIOD_SELECTOR, { data: { year, week, salesDirectorId } });
}

export function getPlanificationStart(data) {
  reactor.dispatch(actionTypes.GET_PLANIFICATION_START, { data: true });
}

export function setPlanificationReset() {
  reactor.dispatch(actionTypes.SET_PLANIFICATION_RESET);
}

export function setPlanificationReload(data) {
  reactor.dispatch(actionTypes.SET_PLANIFICATION_RELOAD, { data });
}

export function addPlanificationAdvisorForm() {
  reactor.dispatch(actionTypes.ADD_PLANIFICATION_ADVISOR_FORM);
}

export function addPlanificationAdvisorStart(data) {
  reactor.dispatch(actionTypes.ADD_PLANIFICATION_ADVISOR_START, { data });
}

export function addPlanificationPeriod(year, week, salesDirectorId) {
  let data = {
    year: year,
    week: week,
    sales_director_id: salesDirectorId,
  };
  axios
    .post(process.env.__APIURL__ + "/planifications", data)
    .then((res) => {
      reactor.dispatch(actionTypes.ADD_PLANIFICATION_PERIOD_SUCCESS, { data: res.data });
      reactor.dispatch(actionTypes.ADD_PLANIFICATION_PERIOD_START, { data: false });
    })
    .catch((res) => {
      reactor.dispatch(actionTypes.ADD_PLANIFICATION_PERIOD_FAIL, { data: res.data });
      reactor.dispatch(actionTypes.ADD_PLANIFICATION_PERIOD_START, { data: false });
    });
}

export function getPlanificationAdvisor(planificationId, id) {
  axios
    .get(process.env.__APIURL__ + "/planifications/" + planificationId + "/advisors/" + id)
    .then((res) => {
      reactor.dispatch(actionTypes.GET_PLANIFICATION_ADVISOR_SUCCESS, { data: res.data });
    })
    .catch((res) => {
      reactor.dispatch(actionTypes.GET_PLANIFICATION_ADVISOR_FAIL, { data: res.data });
    });
}

export function setPlanificationAdvisorReset() {
  reactor.dispatch(actionTypes.SET_PLANIFICATION_ADVISOR_RESET);
}

export function addPlanificationAdvisor(planificationId, data) {
  axios
    .post(process.env.__APIURL__ + "/planifications/" + planificationId + "/advisors", data)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.ADD_PLANIFICATION_ADVISOR_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.ADD_PLANIFICATION_ADVISOR_START, { data: false });
      });
      notifier.actions.notify({
        message: getIntlMessage("Add Successful"),
        type: "success",
      });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.ADD_PLANIFICATION_ADVISOR_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.ADD_PLANIFICATION_ADVISOR_START, { data: false });
      });
    });
}

export function setPlanificationAdvisorForm() {
  reactor.dispatch(actionTypes.SET_PLANIFICATION_ADVISOR_FORM);
}

export function setPlanificationAdvisorStart(data) {
  reactor.dispatch(actionTypes.SET_PLANIFICATION_ADVISOR_START, { data });
}

export function setPlanificationAdvisor(planificationId, id, data, partial = false) {
  var action = partial ? axios.patch : axios.put;
  action(process.env.__APIURL__ + "/planifications/" + planificationId + "/advisors/" + id, {
    planned: data.planned,
    completed: data.completed,
    commission: data.commission,
    pac: data.pac,
    iaah: data.iaah,
    excellence: data.excellence,
    reference: data.reference,
  })
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_PLANIFICATION_ADVISOR_SUCCESS, { data: res.data });
        reactor.dispatch(actionTypes.SET_PLANIFICATION_ADVISOR_START, { data: false });
      });
      notifier.actions.notify({
        message: getIntlMessage("Update Successful"),
        type: "success",
      });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.SET_PLANIFICATION_ADVISOR_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.SET_PLANIFICATION_ADVISOR_START, { data: false });
      });
    });
}

export function deletePlanificationAdvisorForm() {
  reactor.dispatch(actionTypes.DELETE_PLANIFICATION_ADVISOR_FORM);
}

export function deletePlanificationAdvisorStart(data) {
  reactor.dispatch(actionTypes.DELETE_PLANIFICATION_ADVISOR_START, { data });
}

export function deletePlanificationAdvisor(planificationId, advisorId) {
  axios
    .delete(process.env.__APIURL__ + "/planifications/" + planificationId + "/advisors/" + advisorId)
    .then((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.DELETE_PLANIFICATION_ADVISOR_SUCCESS, { data: { advisorId: advisorId } });
        reactor.dispatch(actionTypes.DELETE_PLANIFICATION_ADVISOR_START, { data: false });
      });
      notifier.actions.notify({
        message: getIntlMessage("Delete Successful"),
        type: "success",
      });
    })
    .catch((res) => {
      reactor.batch(() => {
        reactor.dispatch(actionTypes.DELETE_PLANIFICATION_ADVISOR_FAIL, { data: res.data });
        reactor.dispatch(actionTypes.DELETE_PLANIFICATION_ADVISOR_START, { data: false });
      });
    });
}
